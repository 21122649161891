import React from "react";
import { Button, Divider } from "@material-ui/core";

import { makeStyles } from "@material-ui/styles";
import MathJaxComponent from "../MathJaxComponent";

const useStyles = makeStyles((theme) => ({
  fontsizeheading: {
    fontSize: 14,
    padding: "10px 0",
  },
  cancelBtn: {
    // background: 'none',
    // color: '#999',
  },
}));

const ConfirmationModal = ({ isOpen, title, message, onConfirm, onCancel, type }) => {
  const modalStyle = {
    display: isOpen ? "block" : "none",
    position: "fixed",
    zIndex: 9999,
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    overflow: "auto",
    backgroundColor: "rgba(0,0,0,0.4)",
  };

  const modalContentStyle = {
    backgroundColor: "#fefefe",
    margin: "5% auto",
    border: "1px solid #888",
    width: "50%",
    fontSize: "12px",
    borderRadius: "5px",
    
  };

  const buttonStyle = {
    // Style for buttons
    fontSize: "14px", // Set the font size for buttons
    marginRight: "10px", // Adjust the right margin between buttons
  };

  const classes = useStyles();

  return (
    <div style={modalStyle} id={title}>
      <div style={modalContentStyle}  className="modelContentarea">
        <h2 className="heading_modalview">
          {title}{" "}
          <Button className="popup-heading" onClick={onCancel}>
            X
          </Button>
        </h2>

        <div className="popupContent">
          <p
            style={{ maxHeight: "500px", overflow: "auto" }}
            
          >
            {type ==='OTHER'?  message:
            <MathJaxComponent content={message} />}
          
            </p>
          <div className="btn_wrapper">
            <>
              
            <Button
              style={buttonStyle}
              variant="outlined"
              className="confirm_action_btn"
              onClick={onCancel}
            >
              Cancel
            </Button> </>
            {onConfirm && (
                <Button
                  style={buttonStyle}
                  className="cancle_bnt_show"
                  
                  onClick={onConfirm}
                >
                  Confirm
                </Button>
              )}
           
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
