import { Base64 } from "js-base64";
import "katex/dist/katex.min.css";
import { useEffect, useState } from "react";
import { BlockMath, InlineMath } from "react-katex";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// material
import {
  Box,
  Button,
  ButtonGroup,
  CardMedia,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/styles";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import SchoolIcon from '@mui/icons-material/School';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PostAddIcon from '@mui/icons-material/PostAdd';

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// components
import Page from "../components/Page";
import Loader from "../components/Loader";
import MathJaxComponent from "../components/MathJaxComponent";
import { TestApi } from "../service/index";
import Feedback from "../components/forms/Feedback";
import Utility from "../utils/Utility";

import HSeparator from "../theme/Separator";

import Iframe from "./Iframe";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  centerwidth: {
    margin: "auto",
  },
  floatRight: {
    background: "transparent !important",
    height: "38px",
    marginLeft: 10,
    color: "#999999 !important",
  },
  floatRight2: {
    background: "transparent !important",
    height: "38px",
    marginLeft: 10,
    color: "#999999 !important",
    marginTop: "20px",
    float: "right",
    [theme.breakpoints.up("sm")]: {
      marginTop: "0px",
    },
  },
  themargin: {
    marginTop: "7px",
    borderTop: "1px solid #b1b1b1",
    paddingTop: "0px",
    [theme.breakpoints.up("sm")]: {
      marginTop: "26px",
      paddingTop: "22px",
    },
    [theme.breakpoints.up("xs")]: {
      paddingTop: "22px",
    },
  },
  themarginTeacher: {
    marginTop: "7px",
    paddingTop: "0px",
    [theme.breakpoints.up("sm")]: {
      marginTop: "26px",
      paddingTop: "22px",
    },
  },
  colorgray: {
    background: "transparent !important",
    color: "#808080 !important",
    whiteSpace: "nowrap",
    marginLeft: "0",
    fontSize: "12px",
    padding: "5px",
    boxShadow: "none",
    border: "1px solid #999",
    [theme.breakpoints.up("sm")]: {
      padding: "6px 15px",
      fontSize: "12px",
      margin: "auto",
      display: "block",
    },
  },
  colorgray2: {
    border: "none",
    color: "#808080 !important",
    fontSize: "12px",
    padding: "6px 15px",
  },
  colorgrayactive: {
    background: "#808080 !important",
    color: "#fff !important",
    whiteSpace: "nowrap",
    marginLeft: "0",
    fontSize: "12px",
    padding: "5px",
    [theme.breakpoints.up("sm")]: {
      padding: "6px 15px",
      fontSize: "12px",
      margin: "auto",
      display: "block",
    },
  },
  steppershaodw: {
    boxShadow: "1px 1px 10px #000",
  },
  textcolor: {
    color: "#000000",
    lineHeight: 3.5,
    fontSize: 10,
    [theme.breakpoints.up("xs")]: {
      lineHeight: 1.5,
    },
  },
  fontmain: {
    fontSize: 10,
  },
  textcolor2: {
    color: "#007c7c",
    marginBottom: "20px",
    fontSize: 12,
  },
  textcolor55: {
    color: "#007c7c",
    marginBottom: "20px",
  },
  textcneter: {
    textAlign: "left",
    marginBottom: 18,
  },
  textcneter2: {
    textAlign: "center",
    marginBottom: 0,
  },
  textcneter3: {
    textAlign: "left",
    marginBottom: 0,
    fontWeight: 500,
    marginTop: "3px",
    fontSize: "10px",
    color: "#007c7c",
    [theme.breakpoints.up("xs")]: {
      lineHeight: 1.5,
    },
  },
  thefont: {
    padding: "0px 20px",
  },
  centerwidth2: {
    margin: "auto",
    border: "1px solid #d9d9d9",
    borderRadius: 10,
    marginTop: 40,
  },
  textright: {
    textAlign: "left",
    borderBottom: "1px solid #d9d9d9",
    padding: "10px 30px",
    fontWeight: 600,
  },
  paddingten: {
    padding: 30,
  },
  paddingten2: {
    padding: 30,
    borderBottom: "1px solid #d9d9d9",
  },
  tenalign: {
    fontWeight: 500,
    padding: "12px 72px",
    borderRadius: 4,
    marginLeft: "auto",
    display: "block",
  },
  summarytest: {
    fontWeight: 600,
  },
  bgcheckbox: {
    margin: 30,
    padding: "34px",
    background: "#e7fff1",
    maxWidth: "-webkit-fill-available",
    borderRadius: 10,
  },
  colrotxt: {
    color: "#007c7c",
    fontWeight: 600,
  },
  imgplan2: {
    width: "44%",
    margin: "auto",
  },
  colrotxt2: {
    margin: "9px 0",
    fontWeight: 600,
    display: "inline-flex",
    fontSize: 14,
    width: "72px",
  },
  butnspacing: {
    padding: "0 33px 29px",
  },
  imgplan4: {
    width: "75px",
    margin: "auto",
  },
  textclear: {
    textAlign: "center",
    fontWeight: 500,
  },

  boxicon: {
    border: "1px solid #d7d7d7",
    margin: 10,
    padding: 15,
    borderRadius: 6,
  },
  textclear1: {
    color: "#007c7c",
    textAlign: "center",
    fontWeight: 500,
  },
  bgsummary: {
    background: "#ececec",
    padding: "15px",
    borderRadius: 5,
    marginBottom: 30,
    margin: 10,
  },
  summarytext: {
    margin: 0,
    fontWeight: 600,
  },
  summarytext2: {
    margin: 0,
    fontWeight: 600,
    textAlign: "right",
  },
  bordermain: {
    borderBottom: "1px solid rgba(145, 158, 171, 0.24)",
  },
  bgprofile: {
    paddingLeft: "0",
  },

  bgprofile1: {
    paddingLeft: "0",
  },
  bgprofileinner: {
    background: "#fff",
    padding: "3px",
    marginTop: "18px",
    borderRadius: "32px",
    backgroundSize: "contain",
    [theme.breakpoints.up("lg")]: {
      borderRadius: "32px 0px 0px 32px ",
    },
  },
  bgprofileinner2: {
    background: "#fff",
    padding: "8px",
    marginTop: "18px",
    borderRadius: "10px",
    fontSize: "19px",
    fontWeight: "500",
    border: "1px solid #e9e9e9",
    backgroundSize: "contain",
    [theme.breakpoints.up("lg")]: {
      borderRadius: "32px",
      padding: "33px",
    },
  },
  tableborder: {
    borderRight: "1px solid #999",
  },
  tablemargin: {
    paddingLeft: 15,
    borderBottom: "1px solid #999",
  },
  questions: {
    marginBottom: "20px",
    fontSize: "14px",
  },
  questiondetails: {
    marginBottom: "20px",
    borderBottom: "1px solid #b7b7b7",
    paddingBottom: "22px",
  },
  buttngroup: {
    padding: "14px 31px",
    fontWeight: "500",
    [theme.breakpoints.up("lg")]: {
      padding: "14px 42px",
    },
  },
  textmaintext: {
    background: "#000",
    borderRadius: "4px",
    padding: "9px 32px",
    letterSpacing: "3px",
    color: "#fff",
    margin: "13px auto",
    display: "block",
    fontWeight: "300",
    fontSize: "12px",
  },
  textmaintext2: {
    color: "#fff",
    background: "#000",
    float: "right",
    borderRadius: "4px",
    padding: "9px 32px",
    letterSpacing: "3px",
    margin: "13px",
    display: "block",
    fontWeight: "300",
    fontSize: "12px",
  },
  textmaintext44: {
    background: "#007c7c",
    borderRadius: "4px",
    padding: "7px 32px",
    margin: "1px 16px",
    display: "block",
    fontWeight: "300",
    fontSize: "12px",
    marginTop: 21,
    [theme.breakpoints.up("sm")]: {
      fontWeight: "600",
      marginTop: 0,
    },
  },
  textmaintext3: {
    background: "#007c7c",
    borderRadius: "4px",
    padding: "9px 15px",
    letterSpacing: "1px",
    margin: "13px auto",
    display: "block",
    fontWeight: "500",
    fontSize: "12px",
    position: "absolute",
    right: "0",
    top: "-124px",
    [theme.breakpoints.up("lg")]: {
      top: "-46px",
      padding: "9px 32px",
    },
  },
  textcneter6: {
    color: "#007c7c",
    textAlign: "center",
    fontSize: "14px",
    [theme.breakpoints.up("xs")]: {
      fontSize: "12px",
    },
  },
  buttonten: {
    position: "relative",
  },
  class11: {
    color: "#fff",
    fontSize: "14px",
    fontWeight: "500",
  },
  imgerelative: {
    position: "relative",
  },
  imgplan6: {
    position: "absolute",
    bottom: "-255px",
  },
  paddingtenso: {
    padding: 15,
    [theme.breakpoints.up("lg")]: {
      padding: 0,
    },
  },

  bgimgprofile: {
    background: "#d4ffe9",
    borderRadius: "15px",
  },
  ullicontent: {
    float: "left",
    listStyle: "none",
    margin: "0 5px",
    padding: "4px",
    borderRadius: "5px",
    color: "#007c7c",
    fontSize: 14,
  },
  textcnetercahpter: {
    display: "flex",
    paddingBottom: "12px",
    color: "#007c7c",
    fontSize: "12px",
    marginTop: "13px",
    textAlign: "left",
    fontWeight: "500",
    marginBottom: "0",
  },
  buttonright: {
    margin: "0 13px",
    padding: "7px 12px",
    fontSize: "12px",
    background: "#007c7c",
    fontWeight: "600",
    borderRadius: "4px",
    letterSpacing: "1px",
    marginTop: "22px",
    whiteSpace: "nowrap",
    [theme.breakpoints.up("sm")]: {
      marginTop: 0,
    },
  },
  buttoncorrect: {
    color: "#007c7c !important",
    background: "transparent !important",
    border: "1px solid #007c7c !important",
    fontSize: "12px",
    padding: "5px",
    boxShadow: "none",
    [theme.breakpoints.up("sm")]: {
      padding: "6px 15px",
      fontSize: "12px",
      margin: "auto",
      display: "block",
    },
  },
  redalert: {
    color: "#969696",
    fontSize: "12px",
    fontWeight: "500",
    marginTop: "12px",
    textAlign: "center",
  },
  buttonpartially: {
    color: "#febf00 !important",
    background: "transparent !important",
    border: "1px solid #febf00 !important",
    whiteSpace: "nowrap",
    fontSize: "12px",
    padding: "5px",
    boxShadow: "none",
    [theme.breakpoints.up("sm")]: {
      padding: "6px 15px",
      fontSize: "12px",
      margin: "auto",
      display: "block",
    },
  },
  incorrect: {
    color: "#fe0000 !important",
    background: "transparent !important",
    border: "1px solid #fe0000 !important",
    fontSize: "12px",
    padding: "5px",
    boxShadow: "none",
    [theme.breakpoints.up("sm")]: {
      padding: "6px 15px",
      fontSize: "12px",
      margin: "auto",
      display: "block",
    },
    "&:hover": {
      background: "#fe0000+ !important",
    },
  },
  buttoncorrectactive: {
    color: "#fff !important",
    background: "#007c7c !important",
    border: "none !important",
    fontSize: "12px",
    padding: "5px",
    [theme.breakpoints.up("sm")]: {
      padding: "6px 15px",
      fontSize: "12px",
      margin: "auto",
      display: "block",
    },
    "&:hover": {
      background: "#007c7c !important",
    },
  },
  buttonpartiallyactive: {
    color: "#fff !important",
    background: "#febf00 !important",
    border: "1px solid #febf00 !important",
    whiteSpace: "nowrap",
    fontSize: "12px",
    padding: "5px",
    boxShadow: "none",
    [theme.breakpoints.up("sm")]: {
      padding: "6px 15px",
      fontSize: "12px",
      margin: "auto",
      display: "block",
    },
    "&:hover": {
      background: "#febf00 !important",
    },
  },
  incorrectactives: {
    color: "#fff !important",
    background: "#fe0000 !important",
    border: "none !important",
    fontSize: "12px",
    padding: "5px",
    [theme.breakpoints.up("sm")]: {
      padding: "6px 15px",
      fontSize: "12px",
      margin: "auto",
      display: "block",
    },
  },
  colroblind: {
    color: "#00af50 !important",
    marginLeft: "20px",
  },
  mcqquestion: {
    marginTop: "20px",
    fontSize: 14,
  },
  mcqquestion2: {
    fontSize: 14,
  },
  buttonborder: {
    border: "none !important",
    boxShadow: "none",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "70%",
      margin: "auto",
      display: "block",
    },
  },
  popupwidth: {
    width: 242,
    [theme.breakpoints.up("sm")]: {
      width: 221,
    },
  },
  textxenter: {
    textAlign: "center",
    margin: "auto",
    marginBottom: "12px",
    [theme.breakpoints.up("sm")]: {
      textAlign: "left",

      marginBottom: 12,
      margin: "0",
      maxWidth: "auto",
    },
  },
  centeralign: {
    textAlign: "center",
  },
  centerbutton: {
    margin: "auto",
    borderTop: "1px solid #dedede",
    width: "201px",
    justifyContent: "center",
    display: "flex",
  },
  textmaintext66: {
    color: "#fff",
    float: "right",
    display: "block",
    padding: "5px 14px",
    fontSize: "12px",
    background: "#000",
    fontWeight: "300",
    borderRadius: "4px",
    letterSpacing: "0",
    cursor: "pointer",
  },
}));

export default function ReviewAnswer() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { state } = useLocation();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [reviewReport, SetReviewReport] = useState("");

  const [nextButton, setNextButton] = useState(true);
  const [reviewAnswerData, setReviewAnswerData] = useState();
  const [reviewAnswerMainData, setReviewAnswerMainData] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [userDetails, setUserDetails] = useState([]);
  const [answerStatuses, setAnswerStatuses] = useState([
    { id: 1, answerStatus: "Not Marked" },
    { id: 2, answerStatus: "Correct" },
    { id: 3, answerStatus: "Incorrect" },
    { id: 4, answerStatus: "Partially Correct" },
    { id: 5, answerStatus: "Did Not Attempt" },
  ]);
  const [showLoder, setShowLoader] = useState(false);
  const [idStuSchool, setIdStuSchool] = useState(0);
  const [idTestPapers, setIdTestPaper] = useState(0);
  const [studentData, setStudentData] = useState({});
  const [StudentName, setStudentName] = useState(false);
  const [feedbackInfo, setFeedbackInfo] = useState({});
  const [userInfo, setUserInfo] = useState(Utility.getLS("userInfo"));
  const [testPaperInfo, setTestPaperInfo] = useState({});

  useEffect(() => {
    if (state) {
      console.log(state);
      setTestPaperInfo(state);
      setSelectedUser(state);
      setIdTestPaper(state?.idQuestionPaperDelivered);
      if (state?.pageType === "REVIEW_ANSWER") {
        console.log("Review Answer:>>>", state);
        setReviewAnswerMainData(state);
        setReviewAnswerData(state.Questions);
        setSelectedUser(state?.HeaderInfo[0]);
        getIdStudentSchool(state?.HeaderInfo[0]?.FirstName);
      } else {
        getReviewAnswer(
          state?.idQuestionPaperDelivered,
          state?.idStudentSchool
        );
        if (!Utility.isInstitute()) {
          getStudentData(state?.idStudentSchool);
        }
      }
      //  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      setUserDetails(userInfo);
    }
  }, []);

  const replaceSpacesWithUnderscore = (str) => str.replace(/\s+/g, '_');

  const getIdStudentSchool = (name) => {
    //  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (!Utility.isInstitute()) {
      userInfo[0]?.StudentInfo.forEach((element, index) => {
        if (element.FirstName.trim() === name) {
          setIdStuSchool(element.idStudentSchool);
          getStudentData(element?.idStudentSchool);
        }
      });
    }
  };

  const getStudentData = (idStudentSchool) => {
    //  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (!Utility.isInstitute()) {
      userInfo[0]?.StudentInfo.forEach((element, index) => {
        if (element.idStudentSchool === idStudentSchool) {
          setStudentData(element);
          setStudentName(true);
        }
      });
    }
  };

  const getPrevious = () => {
    if (Utility.isInstitute()) {
      if (state.pageType === "REVIEW_ANSWER") {
        navigate(Utility.getPath("/reviewAnswers"), { replace: true });
      } else {
        navigate(Utility.getPath("/test-history"), { replace: true });
      }
    }
    else {
      navigate(Utility.getPath("/my-institute"), { replace: true });
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getReviewAnswer = (idTestPaper, idStudentSchool) => {
    //  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    setShowLoader(true);
    let reqsBody = {};
    if (Utility.isInstitute()) {
      const UserDetails = Utility.getUserDetails();
      console.log(UserDetails);
      reqsBody = {
        idParent: UserDetails.idParent,
        idTestPaper: Number(idTestPaper),
        idStudentSchool: Number(UserDetails.Ins_idStudentSchool),
        Bo: userInfo[0]?.Bo,
        StuFirstName: UserDetails.InstituteName,
        StuLastName: "",
        StuGender: "N",
        Stu_idStudentSchool: "0",
      };


      try {
        TestApi.reviewAnswer(reqsBody).then(
          (resp) => {
            setShowLoader(false);
            if (resp.status === 200) {
              if (resp.data.status === 200) {
                setReviewAnswerMainData(resp.data.data[0]);
                setReviewAnswerData(resp.data.data[0].Questions);
                setSelectedUser(resp.data.data[0]?.HeaderInfo[0]);
                getIdStudentSchool(resp.data.data[0]?.HeaderInfo[0]?.FirstName);
              } else if (
                resp.data.status === 201 &&
                resp.data.message === "Cooling Period On!"
              ) {
                toast.info(
                  `This paper will be available for review ${resp.data.data} minutes from now!`,
                  {
                    position: toast.POSITION.TOP_RIGHT,
                  }
                );
                setIsSubmit(true);
                setIdStuSchool(state?.idStudentSchool);
                getStudentData(state?.idStudentSchool);
              } else {
                alert(resp.data.message);
              }
            } else {
              alert(resp.message);
            }
          },
          (error) => {
            alert("Error in getReviewAnswer");
          }
        );
      } catch (error) {
        console.log("getReviewAnswer Catch", error);
      }
    } else {

      reqsBody = {
        idParent: userInfo[0].idParent,
        idTestPaper: Number(state?.idTestPaper),
        Ins_idStudentSchool: Number(state?.Ins_idStudentSchool),
        idInstitue: state?.idInstitue,
        Ins_Bo: state?.Ins_Bo,
        InstituteName: state?.InstituteName,
        Stu_idStudentSchool: Number(state?.Stu_idStudentSchool),
      };

      const response = Utility.callPostAPI("getstans", reqsBody);
      response.then(
        (resp) => {
          setShowLoader(false);
          if (resp.status === 200) {
            if (resp.data.status === 200) {
              setReviewAnswerMainData(resp.data.data[0]);
              setReviewAnswerData(resp.data.data[0].Questions);
              setSelectedUser(resp.data.data[0]?.HeaderInfo[0]);
              getIdStudentSchool(resp.data.data[0]?.HeaderInfo[0]?.FirstName);
            } else if (
              resp.data.status === 201 &&
              resp.data.message === "Cooling Period On!"
            ) {
              toast.info(
                `This paper will be available for review ${resp.data.data} minutes from now!`,
                {
                  position: toast.POSITION.TOP_RIGHT,
                }
              );
              setIsSubmit(true);
              setIdStuSchool(state?.idStudentSchool);
              getStudentData(state?.idStudentSchool);
            } else {
              alert(resp.data.message);
            }
          } else {
            alert(resp.message);
          }
        },
        (error) => {
          alert("Error in getReviewAnswer");
        }
      );
    }
  };

  const submitReviewAnswerStatus = () => {
    try {
      setShowLoader(true);
      const answerStatusArray = [];
      // eslint-disable-next-line array-callback-return
      reviewAnswerData?.map((item, index) => {
        const answerStausObj = {
          idQuestion: item.idQuestion,
          anstatus: item.anstatus,
        };
        answerStatusArray.push(answerStausObj);
      });
      //  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      const reqsBody = {
        idParent: userInfo[0].idParent,
        Bo: userInfo[0]?.Bo,
        idStudentSchool: idStuSchool,
        GUQPID: reviewAnswerMainData?.GUQPID,
        AnswerStatus: answerStatusArray,
      };
      TestApi.updateReviewAnswerStatus(reqsBody).then(
        (resp) => {
          setShowLoader(false);
          if (resp.status === 200) {
            if (resp.data.status === 200) {
              // navigate("/dashboard/test-history", { replace: true });
              const htmlData = resp.data.data.replace(/\\/g, "");
              SetReviewReport(htmlData);
              setIsSubmit(true);
            } else {
              alert(resp.data.message);
            }
          } else {
            alert(resp.message);
          }
        },
        (error) => {
          alert("Error in getReviewAnswer");
        }
      );
    } catch (error) {
      console.log("getReviewAnswer Catch", error);
    }
  };

  const setAnswer = (data) => {
    if (reviewAnswerData && reviewAnswerData.length > 0) {
      const newArray = [...reviewAnswerData];
      newArray[selectedIndex].anstatus = data.id;
      setReviewAnswerData(newArray);
    }
  };
  const setNextQuestion = () => {
    const totalLength = reviewAnswerData.length - 1;
    if (
      reviewAnswerData &&
      reviewAnswerData.length > 0 &&
      totalLength === selectedIndex + 1
    ) {
      setNextButton(false);
    } else {
      setNextButton(true);
    }
    setSelectedIndex(selectedIndex + 1);
    setShowFeedback(false);
  };
  const setPreviousQuestion = () => {
    const totalLength = reviewAnswerData.length - 1;
    if (
      reviewAnswerData &&
      reviewAnswerData.length > 0 &&
      totalLength === selectedIndex + 1
    ) {
      setNextButton(false);
    } else {
      setNextButton(true);
    }
    setSelectedIndex(selectedIndex - 1);
    setShowFeedback(false);
  };

  const renderNextButton = () => {
    if (reviewAnswerData && reviewAnswerData.length > 0) {
      return (
        <Button className={classes.colorgray} onClick={() => setNextQuestion()}>
          Did't Attempt : Next
        </Button>
      );
    }
  };
  const renderMcq = (data) => {
    if (data.mcq) {
      return data.mcqOptions.map((x) => (
        <>
          <div>
            <strong>{x.optionLabel})&nbsp;&nbsp;</strong>
            {dbDollarChange(x.mcqOption.optionContent)}
          </div>
        </>
      ));
    }
  };

  const dbDollarChange = (data) => {
    if (data) {
      return data.split("$$").map((x, index) => (
        <>
          {index % 2 === 0 &&
            x.split("$").map((y, ind) => (
              <>
                {ind % 2 === 0 && <> {y}</>}
                {ind % 2 !== 0 && (
                  <>
                    <InlineMath math={y} />{" "}
                  </>
                )}
              </>
            ))}
          {index % 2 !== 0 && (
            <>
              <BlockMath math={x} />{" "}
            </>
          )}
        </>
      ));
    }
  };

  const setFeedbackData = () => {
    setFeedbackInfo({
      idStudentSchool: idStuSchool,
      idTestPaper: idTestPapers,
    });
    setShowFeedback(true);
  };

  return (
    <Page title="Review Test | XPSOLV">
      <Grid container className="main-bg">
        <Typography variant="h4" gutterBottom className={classes.textcneter}>
          <Link
            underline="none"
            variant="contained"
            className="backbuttonbg"
            onClick={() => getPrevious()}
          >
            <ArrowBackIosIcon className="backicon" />
            Back
          </Link>
        </Typography>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box
            component="h2"
            display="inline"
            className="tabheading imgicondefine"
          >
            <img src="/static/cutoff-range.png" alt="imgtest" />
            {StudentName && (
              <>
                {studentData?.FirstName}&nbsp;
                {studentData?.LastName}
              </>
            )}
            {!StudentName && (
              <>
                {selectedUser?.FirstName}&nbsp;
                {selectedUser?.LastName}
              </>
            )}
          </Box>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} className="bg_white_upload">
          <Grid item container>
            <Grid container className={classes.thefont}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className={classes.bgprofile1}>
                <Grid container spacing={2}>
                  <Grid
                    item
                    xs={12}
                    xl={12}
                    sm={12}
                    md={12}
                    
                  >
                    <Accordion
                      fullWidth
                      className="accordion"
                      defaultExpanded={false}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <span className="forment_heading">
                          {" "}
                          Test ID : {testPaperInfo?.idQuestionPaperDelivered}
                        </span>
                      </AccordionSummary>
                      <AccordionDetails>
                      <Grid container>
                    
                    <Grid item lg={4} md={4} sm={4} xs={12}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        className="heading_file"
                      >
                        <CalendarMonthIcon className="date-icon" />
                        Date :
                        <Box
                          component="span"
                          style={{ marginLeft: "30px" }}
                          display="inline"
                          className={`f-12 ${classes.textcolor}`}
                        >
                          {" "}
                          {testPaperInfo?.DateDeliveredOn}
                        </Box>
                      </Typography>
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={12}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        className="heading_file"
                      >
                        <PostAddIcon className="date-icon" />
                        Test ID :
                        <Box
                          component="span"
                          style={{ marginLeft: "60px" }}
                          display="inline"
                          className={`f-12 ${classes.textcolor}`}
                        >
                          {" "}
                          {testPaperInfo?.idQuestionPaperDelivered}
                        </Box>
                      </Typography>
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={12}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        className="heading_file"
                      >
                        <SchoolIcon className="date-icon" />
                        Class :
                        <Box
                          component="span"
                          style={{ marginLeft: "60px" }}
                          display="inline"
                          className={`f-12 ${classes.textcolor}`}
                        >
                          {" "}
                          {testPaperInfo?.Class ? testPaperInfo?.Class : testPaperInfo?.ClassName}
                        </Box>
                      </Typography>
                    </Grid>

                    <Grid item lg={4} md={4} sm={4} xs={12}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        className="paper-details"
                      >
                        <ListAltIcon className="paper_details_icon" />
                        Subject :
                        <Box
                          component="span"
                          display="inline"
                          style={{ marginLeft: "10px" }}
                          className={`f-12 ${classes.textcolor}`}
                        >
                          {testPaperInfo?.SubjectName}
                        </Box>
                      </Typography>
                    </Grid>
                    <Grid item lg={8} md={8} sm={8} xs={12}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        className="paper-details"
                      >
                        <ContentPasteIcon className="paper_details_icon" />
                        Paper Details :
                        <Box
                          component="span"
                          display="inline"
                          style={{ marginLeft: "10px" }}
                          className={`f-12 ${classes.textcolor}`}
                        >
                          {testPaperInfo?.PFormat}
                        </Box>
                      </Typography>
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        className="chapter-name-file"
                      >
                        <ImportContactsIcon className="chapter-icon" />
                        Chapter :{" "}
                        <Box
                          component="span"
                          display="inline"
                          style={{ marginLeft: "10px" }}
                          className={`f-12 ${classes.textcolor}`}
                        >
                          {" "}
                          <ul className="chap_list_name">
                            {testPaperInfo?.ChapterInfo?.map((item, index) => (
                              <li
                                key={index}
                                className={`f-12 ${classes.ullicontent}`}
                              >
                                <Chip
                                  label={item.ChapterName}
                                  className="f-12"
                                  variant="outlined"
                                />
                              </li>
                            ))}
                          </ul>
                        </Box>
                      </Typography>
                    </Grid>
                  </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                </Grid>
                 
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        {!isSubmit && (
          <>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="padding_set_views"
            >
              <Typography
                variant="h4"
                gutterBottom
                className="review_marging_set"
              >
                <Box
                  component="h2"
                  display="inline"
                  className="tabheading imgicondefine"
                >
                  <img src="/static/cutoff-range.png" alt="imgtest" />
                  Review  Answer
                </Box>
                <Link
                  underline="none"
                  variant="contained"
                  className="skip_student backbuttonbg"
                  component={RouterLink}
                  to={Utility.isInstitute() ? Utility.getPath("/test-history") : Utility.getPath("/my-institute")}
                >
                  Exit
                </Link>
              </Typography>

              <Box >
                <Typography
                  variant="p"
                  gutterBottom
                  className={classes.questions}
                >
                  <MathJaxComponent content={reviewAnswerData
                    ? Base64.decode(reviewAnswerData[selectedIndex]?.QARev)
                    : null} />

                </Typography>
              </Box>
              {/* {Utility.isInstitute() && (
                <ButtonGroup
                  variant="contained"
                  aria-label="outlined primary button group"
                  className={`f-14 ${classes.buttonborder}`}
                >
                  {reviewAnswerData
                    ? reviewAnswerData[selectedIndex]?.anstatus
                    : null}
                  <Grid container>
                    {reviewAnswerData
                      ? answerStatuses?.map((item, index) => (
                        <>
                          {item.answerStatus !== "Not Marked" && (
                            <Grid
                              key={index}
                              item
                              lg={3}
                              md={3}
                              sm={6}
                              xs={6}
                              className={classes.textxenter}
                            >
                              {item.answerStatus === "Correct" && (
                                <div>
                                  <Button
                                    variant="contained"
                                    disabled={
                                      reviewAnswerMainData?.Marked === 1
                                    }
                                    className={
                                      parseInt(
                                        reviewAnswerData[selectedIndex]
                                          ?.anstatus,
                                        10
                                      ) === item.id
                                        ? classes.buttoncorrectactive
                                        : classes.buttoncorrect
                                    }
                                    onClick={() => setAnswer(item)}
                                  >
                                    {item.answerStatus}
                                  </Button>{" "}
                                </div>
                              )}
                              {item.answerStatus === "Partially Correct" && (
                                <div>
                                  <Button
                                    variant="contained"
                                    disabled={
                                      reviewAnswerMainData?.Marked === 1
                                    }
                                    className={
                                      parseInt(
                                        reviewAnswerData[selectedIndex]
                                          ?.anstatus,
                                        10
                                      ) === item.id
                                        ? classes.buttonpartiallyactive
                                        : classes.buttonpartially
                                    }
                                    onClick={() => setAnswer(item)}
                                  >
                                    {item.answerStatus}
                                  </Button>{" "}
                                </div>
                              )}
                              {item.answerStatus === "Incorrect" && (
                                <div>
                                  <Button
                                    variant="contained"
                                    disabled={
                                      reviewAnswerMainData?.Marked === 1
                                    }
                                    className={
                                      parseInt(
                                        reviewAnswerData[selectedIndex]
                                          ?.anstatus,
                                        10
                                      ) === item.id
                                        ? classes.incorrectactives
                                        : classes.incorrect
                                    }
                                    onClick={() => setAnswer(item)}
                                  >
                                    {item.answerStatus}
                                  </Button>{" "}
                                </div>
                              )}

                              {item.answerStatus === "Did Not Attempt" && (
                                <div>
                                  <Button
                                    variant="contained"
                                    disabled={
                                      reviewAnswerMainData?.Marked === 1
                                    }
                                    className={
                                      parseInt(
                                        reviewAnswerData[selectedIndex]
                                          ?.anstatus,
                                        10
                                      ) === item.id
                                        ? classes.colorgrayactive
                                        : classes.colorgray
                                    }
                                    onClick={() => setAnswer(item)}
                                  >
                                    {item.answerStatus}
                                  </Button>{" "}
                                </div>
                              )}
                            </Grid>
                          )}
                        </>
                      ))
                      : null}
                  </Grid>
                </ButtonGroup>
              )} */}

              {Utility.isInstitute() && (
                <Grid container className={classes.themargin}>
                  <Grid item lg={6} md={6} sm={6} xs={6} className="right_view_anglebtn">
                    {selectedIndex >= 1 ? (
                      <Button
                        variant="contained"
                        aria-label="outlined primary button group"
                        size="small"
                        align="end"
                        className="submit_btn confirm_action_btn "

                        onClick={() => setPreviousQuestion()}
                      >
                        Previous

                      </Button>
                    ) : null}
                  </Grid>

                  <Grid item lg={6} md={6} sm={6} xs={6}>
                    {nextButton && reviewAnswerData && (
                      <>
                        <Button
                          variant="contained"
                          className="submit_btn next_btn"

                          onClick={() => setNextQuestion()}
                        >
                          Next
                        </Button>
                      </>
                    )}
                    {/* {!nextButton && (
                      <>
                        <Button
                          variant="contained"
                          className="submit_btn sub_btn"
                          disabled={
                            Boolean(
                              parseInt(
                                reviewAnswerData[selectedIndex]?.anstatus,
                                10
                              ) === 1
                                ? "disabled"
                                : ""
                            ) || reviewAnswerMainData?.Marked === 1
                          }
                          onClick={() => {
                            handleClickOpen();
                          }}
                        >
                          Submit Answers
                        </Button>
                      </>
                    )} */}
                  </Grid>
                  {/* {reviewAnswerData &&
                    parseInt(reviewAnswerData[selectedIndex]?.anstatus, 10) ===
                    1 && (
                      <Grid item lg={12} md={12} sm={12} xs={12}>
                        <p className={classes.redalert}>
                          Select Correct | Incorrect | Partially Correct | Did
                          Not Attempt to enable Next button
                        </p>
                      </Grid>
                    )} */}
                </Grid>
              )}
              <>
                {!Utility.isInstitute() && (
                  <>
                    <div>&nbsp;</div>
                    <HSeparator sx={{ marginTop: 5, marginBottom: 5 }} />
                    <Grid container>
                      <Grid item lg={6} md={6} sm={6} xs={12} className="let-csto-bnt">

                        <ButtonGroup
                          variant="contained"
                          aria-label="outlined primary button group"
                          className={`f-14 `}
                        >

                          {reviewAnswerData
                            ? reviewAnswerData[selectedIndex]?.anstatus
                            : null}
                          <>

                            {reviewAnswerData
                              ? answerStatuses?.map((item, index) => (
                                <>


                                  {Number(reviewAnswerData[selectedIndex]
                                    ?.anstatus) === Number(item.id) && (
                                      <>
                                        {item.answerStatus !== "Not Marked" && reviewAnswerMainData?.Marked === 1 && (
                                          <>


                                            <Button
                                              variant="contained"
                                              disabled={
                                                reviewAnswerMainData?.Marked === 1
                                              }
                                              className={
                                                `${replaceSpacesWithUnderscore(item.answerStatus)}`
                                              }
                                              onClick={() => setAnswer(item)}
                                            >
                                              {item.answerStatus}
                                            </Button>



                                          </>
                                        )}
                                      </>
                                    )}
                                </>
                              ))
                              : null}
                          </>
                        </ButtonGroup>
                      </Grid>


                      <Grid item lg={6} md={6} sm={6} xs={12} className="right_view_anglebtn">


                        <ul className="bnt-nex-pre">
                          {selectedIndex >= 1 ? (
                            <li>
                              <Button
                                variant="contained"
                                aria-label="outlined primary button group"
                                size="small"
                                align="end"
                                className="submit_btn confirm_action_btn"
                                onClick={() => setPreviousQuestion()}
                              >

                                Previous

                              </Button></li>
                          ) : null}
                          {nextButton && reviewAnswerData && (
                            <li>

                              <Button
                                variant="contained"
                                className="submit_btn next_btn"
                                onClick={() => setNextQuestion()}
                              >
                                Next
                              </Button>
                            </li>
                          )}

                        </ul>
                      </Grid>





                    </Grid>
                  </>
                )}
              </>

              <div>&nbsp;</div>
              <HSeparator sx={{ marginTop: 5, marginBottom: 5 }} />
              <Grid container spacing={2}>
                <span className="moreText f-14">
                  {" "}
                  Have concerns on question or answer ?
                </span>
                <Grid item lg={4} md={3} sm={4} xs={12}>
                  <Button
                    variant="outlined"
                    color="primary"
                    sx={{ fontWeight: "medium" }}
                    onClick={(e) => {
                      setFeedbackData();
                    }}
                  >
                    Give Feedback
                  </Button>
                </Grid>
              </Grid>
              {showFeedback && (
                <Grid container spacing={2}>
                  <Grid
                    className="regbg"
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    sx={{ mt: 3, marginLeft: 2 }}
                  >
                    <Feedback
                      feedbackInfo={feedbackInfo}
                      idQuestions={reviewAnswerData[selectedIndex]?.idQuestion}
                    />
                  </Grid>
                </Grid>
              )}

              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
              >
                <DialogContent className={classes.popupwidth}>
                  <DialogContentText className={classes.centeralign}>
                    Are you sure?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <div className={classes.centerbutton}>
                    <Button autoFocus onClick={handleClose}>
                      Cancel
                    </Button>
                    <Button
                      onClick={() => submitReviewAnswerStatus()}
                      autoFocus
                    >
                      Submit
                    </Button>
                  </div>
                </DialogActions>
              </Dialog>
            </Grid>
          </>
        )}
        {isSubmit && reviewReport && (
          <>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className={classes.bgprofileinner2}
            >
              <Typography
                variant="h4"
                gutterBottom
                className={`f-16 ${classes.textcneter}`}
              >
                Review{" "}
                <Box
                  component="span"
                  display="inline"
                  className={classes.textcolor55}
                >
                  Report
                </Box>
                <Link
                  underline="none"
                  variant="contained"
                  className={`f-12 ${classes.textmaintext2}`}
                  component={RouterLink}
                  to={Utility.getPath("/test-history")}
                >
                  Exit
                </Link>
              </Typography>

              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Iframe
                  content={reviewReport}
                  id="printf"
                  name="printf"
                  className="heightwidth"
                />
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
      <Loader isLoader={showLoder} showHide={setShowLoader} />
    </Page>
  );
}
