 import performanceIcon from '@iconify/icons-eva/bar-chart-2-fill';
// import performanceIcon from '@iconify/icons-ic/outline-bar-chart';

import histroyIcon from '@iconify/icons-ic/baseline-history';
import reviewAnswerIcon from '@iconify/icons-ic/outline-reviews';
import referral from '@iconify/icons-ic/outline-share';
import support from '@iconify/icons-ic/outline-support-agent';
import profileIcon from '@iconify/icons-ic/person-outline';
import accountSummaryIcon from '@iconify/icons-ic/round-currency-rupee';
import GroupIcon from '@iconify/icons-ic/outline-group';
import { Icon } from '@iconify/react';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';


// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const instituteConfig = [
  {
    title: 'Profile',
    path: '/institute/app',
    icon: getIcon(profileIcon),
    type:'common'
  },
  {
    title: 'Students',
    path: '/institute/student',
    icon: getIcon(GroupIcon),
    type:'common'
  },
  {
    title: 'Test history',
    path: '/institute/test-history',
    icon: getIcon(histroyIcon),
    type:'common'
  },
  {
    title: 'Performance Report',
    path: '/institute/view-report-class',
    icon: getIcon(performanceIcon),
    type:'common'
  },

  {
    title: 'Users',
    path: '/institute/user-list',
    icon: getIcon(GroupIcon),
    type:'admin'
  },
  
];

export default instituteConfig;
