import { Icon } from "@iconify/react";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
// material
import {
  Box,
  Card,
  Container,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
// components
import Page from "../components/Page";
import { TestApi } from "../service/index";
import { fDateTime, parseCustomDateString } from "../utils/formatTime";
import Utility from "../utils/Utility";

const getIcon = (name) => (
  <Icon color="#007c7c" icon={name} width={22} height={22} />
);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  fontsizeheading: {
    fontSize: 14,
  },
  paper: {
    padding: theme.spacing(2),
  },
  cardcontent: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 16,
    paddingBottom: "0px !important",
  },
  peraclass2: {
    width: "202px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  peraclass: {
    fontSize: 10,
    paddingBottom: 10,
    fontWeight: 600,
    color: "#00007e",
  },
  emailphone: {
    fontSize: 10,
    fontWeight: 500,
    color: "#000000",
    fontFamily: "Poppins",
    marginTop: -5,
    lineBreak: "anywhere",
    marginLeft: 10,
  },
  headingfont: {
    fontSize: 14,
    fontWeight: 600,
  },
  btntext: {
    fontSize: 10,
    fontWeight: 500,
    padding: "0 9px",
    background: "#007c7c",
    color: "#ffffff",
    marginTop: "12px",
    "&:hover": {
      background: "#007c7c",
    },
  },
  textcenter: {
    textAlign: "center",
    lineHeight: 0,
    fontSize: "12px",
  },
  headingfive: {
    display: "flex",
    marginBottom: 26,
  },
  perafive: {
    color: "#929292",
    fontWeight: 500,
  },
  cardpadding: {
    paddingLeft: 0,
    paddingBottom: 10,
  },
  cardborder: {
    border: "1px solid #c8c8c8",
    "&:hover": {
      backgroundSize: "cover",
      background: "linear-gradient(to right, #edfbf4 15%, #d4ffe9 94%)",
      borderColor: "#fff",
    },
  },
  centertext: {
    textAlign: "center",
    fontSize: 16,
  },
  centertextside: {
    textAlign: "left",
    fontSize: 16,
  },
  btnmain: {
    fontWeight: 500,
    marginTop: 18,
  },
  closebtn: {
    position: "absolute",
    right: 0,
    top: -12,
    fontWeight: 700,
  },
  textcolor: {
    color: "#007c7c",
  },
  textcolor2: {
    color: "#999",
    fontWeight: 500,
  },

  plusimg: {
    width: 29,
    marginTop: "-4px",
  },
  textmainid: {
    fontSize: 12,
  },
  textright: {
    textAlign: "right",
  },
  mainbtn: {
    background: "#effff7",
    borderTop: "1px solid #d3ffe9",
  },
  h4heaidng: {
    textAlign: "left",
    marginBottom: "5px",
    fontSize: "15px",
  },
  h4heaidng2: {
    textAlign: "left",
    marginBottom: "5px",
    marginTop: "20px",
    fontSize: "12px",
  },
  colorreport: {
    color: "#007c7c",
  },
  schoolheading: {
    borderBottom: "1px solid #ececec",
    paddingBottom: 15,
    color: "#00007e",
    fontSize: 10,
    fontWeight: "600",
  },
  mtop: {
    marginTop: 33,
    borderTop: "1px solid #ececec",
  },
  imgplan333: {
    width: 15,
    float: "left",
    marginTop: 5,
    marginRight: 11,
    [theme.breakpoints.up("sm")]: {
      width: 15,
    },
  },
  accountsummary: {
    borderBottom: "1px solid #c9c9c9",
    paddingTop: 18,
    paddingLeft: 0,
    marginBottom: 22,
  },
  summaryaccout: {
    borderBottom: "1px solid #c9c9c9",
    padding: 10,
    paddingTop: 0,
    paddingLeft: 0,
    marginBottom: 0,
    width: "auto",
    fontSize: 14,
    [theme.breakpoints.up("sm")]: {
      width: "978px",
    },
  },

  amount: {
    fontSize: 14,
    color: "#000",
    textAlign: "right",
  },
  btnmargin: {
    marginBottom: "21px",
    textAlign: "center",
    paddingTop: "7px !important",
  },
  amount2: {
    fontSize: 12,
    color: "#000",
    fontWeight: 500,
  },
  btnamount: {
    background: "#000",
    marginTop: "-5px",
  },
  colorwhite: {
    color: "#",
    fontWeight: 600,
  },
  margin000: {
    margin: "0 30px",
  },
  bordertable: {
    border: "1px solid #e8e8e8",
    marginBottom: 24,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  colorwhite2: {
    color: "#000",
    fontSize: 14,
  },
  colorwhite3: {
    color: "#000",
    fontSize: 14,
  },

  showtoggle: {
    background: "#fff",
    padding: "4px 16px",
    fontWeight: 500,
    color: "#fff",
    border: 0,
    borderRadius: 8,
    float: "right",
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  imgplan2: {
    width: 15,
    [theme.breakpoints.up("sm")]: {
      width: 15,
    },
  },
  addamountbtn: {
    background: "#007c7c",
    padding: "6px",
    fontSize: "12px",
    color: "#fff",
    borderRadius: "6px",
    textDecoration: "none",
  },
  ruppeicon: {
    position: "relative",
    top: "7px",
    width: "17px",
  },
  cardtable: {
    margin: "0 30px",
  },
  cardmain: {
    padding: "13px",
    margin: "10px 0 15px 15px",
    marginBottom: 10,
  },
  cardmain2: {
    margin: "0px 0px",
    display: "block",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  infotext: {
    fontSize: "10px",
    marginTop: "9px",
    marginBottom: "0",
    fontWeight: "400",
    marginLeft: 17,
  },
  boldfont: {
    fontWeight: 600,
  },
  alertalignment: {
    marginTop: 15,
  },
  infoicons: {
    float: "left",
    width: "15px",
    marginTop: "2px",
    marginRight: "5px",
  },
  logoutbtn: {
    fontSize: 15,
    background: "#089293",
    width: "124px",
    borderRadius: "6px",
    marginTop: "14px",
    color: "#fff",
  },
  buttoncomon2: {
    height: "35px",
    margin: "10px",
    padding: "0 15px",
    marginTop: "-9px",
    width: "100%",
  },
  inputid: {
    margin: "12px",
  },
}));

export default function DashboardApp(props) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const classes = useStyles();
  const [userMobile, setUserMobile] = useState("");
  const [idParent, setIdParent] = useState("");
  const [userDetails, setUserDetails] = useState([]);
  const [acctSummaryArr, setAcctSummaryArr] = useState([]);
  const [sumAddedInfo, setSumAddedInfo] = useState([]);
  const [sumConsumedInfo, setSumConsumedInfo] = useState([]);
  const [open, setOpen] = useState(false);
  const [openTest, setOpenTest] = useState(false);
  const [maxWidth, setMaxWidth] = useState("sm");
  const [fullWidth, setFullWidth] = useState(true);
  const [selectedUserDetails, setSelectedUserDetails] = useState();
  const [isShow, setisShow] = useState(false);
  const [isLoad, setLoad] = useState(true);
  const [isShow2, setisShow2] = useState(true);
  const [selectedStudent, setSelectedStudent] = useState("");
  const [selectedPlan, setSelectedPlan] = useState("");
  const [plans, setPlans] = useState([]);
  const anchorRef = useRef(null);
  const [userInfo, setUserInfo] = useState(Utility.getLS("userInfo"));

  useEffect(() => {
    console.log(state);
    if (state && isLoad) {
      setSelectedStudent(state.idStudent);
      setLoad(false);
    }
    getPlans();
    getUserDetails();
    setLoad(false);
  }, [selectedStudent]);

  const acctSummaryInfo = () => {
    try {
      const reqsBody = {
        Bo: userDetails[0].Bo,
        idStudent: selectedStudent,
        // eslint-disable-next-line object-shorthand
        idParent: idParent,
      };
      TestApi.acctSummary(reqsBody).then((resp) => {
        if (resp.status === 200) {
          if (resp.data.status === 200) {
            setAcctSummaryArr(resp.data.data);
            console.log(resp.data.data);
            // acctSummaryData(resp.data.data);
          } else {
            alert(resp.data.message);
          }
        }
      });
    } catch (error) {
      //   setShowLoader(false);
    }
  };

  const getPlans = () => {
    try {
      if (!selectedStudent) {
        return true;
      }

      const reqsBody = {
        idStudentSchool: selectedStudent,
        // eslint-disable-next-line object-shorthand
        idParent: idParent,
      };
      TestApi.getplan(reqsBody).then((resp) => {
        if (resp.status === 200) {
          if (resp.data.status === 200) {
            setPlans(resp.data.data);
            console.log(resp.data.data);
            // acctSummaryData(resp.data.data);
          } else {
            alert(resp.data.message);
          }
        }
      });
    } catch (error) {
      //   setShowLoader(false);
    }
  };

  const getUserDetails = () => {
    // const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    // console.log("userInfo", userInfo);

    const idUserParent = userInfo[0]?.idParent;
    const studentInfo = userInfo[0]?.StudentInfo;
    if (!state && studentInfo && isLoad) {
      setSelectedStudent(studentInfo[0].idStudent);
    }
    setUserDetails(userInfo);
    setIdParent(idUserParent);
    setUserMobile(userInfo[0]?.Mobile);
    if (selectedStudent) {
      acctSummaryInfo();
    } else {
      setAcctSummaryArr([]);
    }
  };

  const acctSummaryData = (acctSummary) => {
    //  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (acctSummary[0]?.SumAddedInfo) {
      let isBlankExist = false;
      if (acctSummary[0]?.SumAddedInfo.length === 1) {
        if (
          acctSummary[0]?.SumAddedInfo[0]?.EntryDT === "" &&
          acctSummary[0]?.SumAddedInfo[0]?.TranID === "" &&
          acctSummary[0]?.SumAddedInfo[0]?.TransactionType === "" &&
          acctSummary[0]?.SumAddedInfo[0]?.Amount === ""
        ) {
          isBlankExist = true;
        }
      }
      if (!isBlankExist) setSumAddedInfo(acctSummary[0]?.SumAddedInfo);
    }
    if (acctSummary[0]?.SumConsumedInfo) {
      let isBlank = false;
      if (acctSummary[0]?.SumConsumedInfo.length === 1) {
        if (
          acctSummary[0]?.SumConsumedInfo[0]?.EntryDT === "" &&
          acctSummary[0]?.SumConsumedInfo[0]?.GUQPID === "" &&
          acctSummary[0]?.SumConsumedInfo[0]?.Amount === ""
        ) {
          isBlank = true;
        }
      }
      if (!isBlank) setSumConsumedInfo(acctSummary[0]?.SumConsumedInfo);
    }
  };

  const handelOrderNewTest = (item) => {
    if (item && item?.idStudent && item?.ClassNumeric) {
      navigate("/dashboard/plan-test", { replace: true, state: item });
      setSelectedUserDetails(item);
      setOpenTest(true);
    } else {
      navigate("/dashboard/plan-test", { replace: true, state: item });
      setSelectedUserDetails(item);
      setOpenTest(true);
    }
  };

  const handleUpdateProfile = (item) => {
    setSelectedUserDetails(item);
    setOpen(true);
  };

  const showhide = () => {
    setisShow(!isShow);

    // setOpen(false);
  };
  const buyPlan = () => {
    selectedPlan.idStudentSchool = selectedStudent;

    navigate("/dashboard/checkout", { replace: true, state: selectedPlan });
  };

  const handleClose = () => {
    setOpen(false);
    setOpenTest(false);
    getUserDetails();
  };
  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userInfo");
    navigate("/login", { replace: true });
  };

  const payment = () => {
    navigate("/dashboard/checkout");
  };

  const TestSchema = Yup.object().shape({
    testSubject: Yup.string().required("Subject is required"),
    //   chaptersIds: Yup.string().required('Chapter is required'),
    format: Yup.string().required("Format is required"),
    level: Yup.string().required("Level is required"),
  });

  const formik = useFormik({
    initialValues: {
      testSubject: "",
      //   chaptersIds: [],
      format: "",
      level: "",
    },
    validationSchema: TestSchema,
    onSubmit: (data) => {
      buyPlan(data);
    },
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
  } = formik;

  const selectedStudentData = (event) => {
    if (event.target.name === "plan") {
      setSelectedPlan(JSON.parse(event.target.value));
      formik.setFieldValue("plan", event.target.value);
    } else {
      setSelectedStudent(event.target.value);
      formik.setFieldValue("student", event.target.value);
    }
  };

  return (
    <Page title="Dashboard | XPSOLV" className="main-page">
      <>
        <Container className="main-Container">
          <Grid container spacing={2}>
            <Grid lg={12} md={12} sm={12} xs={12} mt={2}>
              <Box
                component="h2"
                display="inline"
                className="tabheading imgicondefine"
              >
                {" "}
                <img src="/static/icons/bonus-point.png" alt="imgtest" /> Account
                Summary
              </Box>
            </Grid>
            <Grid lg={4} md={4} sm={4} xs={12}>
              <Stack
                justifyContent="space-between"
                sx={{ mb: "2rem", mr: "1rem" }}
              >
                <InputLabel
                  id="demo-simple-select-filled-label"
                  className={`f-14 ${classes.label}`}
                >
                  Student
                </InputLabel>
                <Select
                  fullWidth
                  className={`f-12 ${classes.mtten}`}
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  label="Student"
                  {...getFieldProps("Student")}
                  error={Boolean(touched.student && errors.student)}
                  helperText={touched.student && errors.student}
                  value={selectedStudent}
                  onChange={(e) => {
                    selectedStudentData(e);
                  }}
                >
                  <MenuItem value="">
                    <em className="f-12">Select Student</em>
                  </MenuItem>
                  {userDetails[0]?.StudentInfo?.map((option, index) => (
                    <MenuItem
                      key={index}
                      className="f-12"
                      value={option.idStudent}
                    >
                      {option.FirstName} {option.LastName}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
            </Grid>
          </Grid>

          {acctSummaryArr.length > 0 && (
            <>
              <div>
                <TableContainer className={classes.bordertable}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow key="add">
                        <TableCell align="right" className={classes.textcenter}>
                          SR.No.
                        </TableCell>
                        <TableCell align="right" className={classes.textcenter}>
                          Plan
                        </TableCell>
                        <TableCell align="right" className={classes.textcenter}>
                          Start
                        </TableCell>
                        <TableCell align="right" className={classes.textcenter}>
                          Validity
                        </TableCell>
                        <TableCell align="right" className={classes.textcenter}>
                          Amount Paid(RS)
                        </TableCell>
                        {/* <TableCell align="right" className={classes.textcenter}>Subscribed On</TableCell> */}
                        <TableCell align="right" className={classes.textcenter}>
                          Status
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {acctSummaryArr[0].SumAddedInfo.map((item, index) => (
                        <>
                          {item?.PlanName && (
                            <TableRow
                              key={`add_${index}`}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell
                                align="right"
                                className={classes.textcenter}
                              >
                                {index + 1}
                              </TableCell>
                              <TableCell
                                align="right"
                                className={classes.textcenter}
                              >
                                {item.PlanName}
                              </TableCell>
                              <TableCell
                                align="right"
                                className={classes.textcenter}
                              >
                                {item?.startdate
                                  ? fDateTime(
                                      parseCustomDateString(item.startdate)
                                    )
                                  : "-"}
                              </TableCell>
                              <TableCell
                                align="right"
                                className={classes.textcenter}
                              >
                                {item?.enddate
                                  ? fDateTime(
                                      parseCustomDateString(item.enddate)
                                    )
                                  : "-"}
                              </TableCell>
                              <TableCell
                                align="right"
                                className={classes.textcenter}
                              >
                                {item?.Amount}
                              </TableCell>
                              {/* <TableCell align="right" className={classes.textcenter}>{item?.EntryDT ?  fDateTime(parseCustomDateString(item.EntryDT)) : "-"}</TableCell> */}
                              <TableCell
                                align="right"
                                className={classes.textcenter}
                              >
                                {item?.Status}
                              </TableCell>
                            </TableRow>
                          )}
                        </>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <Grid container item spacing={2} className="mb-3">
                <Grid item lg={12} xs={12} className={classes.cardmain2}>
                  {acctSummaryArr[0].SumAddedInfo.map((item, index) => (
                    <>
                      {item?.PlanName && (
                        <Card className={classes.cardmain}>
                          <Grid container item spacing={2}>
                            <Grid item lg={6} xs={6}>
                              <Typography
                                variant="h6"
                                gutterBottom
                                className={classes.amount2}
                              >
                                SR.NO.
                              </Typography>
                            </Grid>
                            <Grid item lg={6} xs={6}>
                              <Typography
                                variant="h6"
                                gutterBottom
                                className={classes.amount2}
                              >
                                {index + 1}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container item spacing={2}>
                            <Grid item lg={6} xs={6}>
                              <Typography
                                variant="h6"
                                gutterBottom
                                className={classes.amount2}
                              >
                                Plan
                              </Typography>
                            </Grid>
                            <Grid item lg={6} xs={6}>
                              <Typography
                                variant="h6"
                                gutterBottom
                                className={classes.amount2}
                              >
                                {item?.PlanName}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container item spacing={2}>
                            <Grid item lg={6} xs={6}>
                              <Typography
                                variant="h6"
                                gutterBottom
                                className={classes.amount2}
                              >
                                Start
                              </Typography>
                            </Grid>
                            <Grid item lg={6} xs={6}>
                              <Typography
                                variant="h6"
                                gutterBottom
                                className={classes.amount2}
                              >
                                {item?.startdate
                                  ? fDateTime(
                                      parseCustomDateString(item.startdate)
                                    )
                                  : "-"}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container item spacing={2}>
                            <Grid item lg={6} xs={6}>
                              <Typography
                                variant="h6"
                                gutterBottom
                                className={classes.amount2}
                              >
                                Validity
                              </Typography>
                            </Grid>
                            <Grid item lg={6} xs={6}>
                              <Typography
                                variant="h6"
                                gutterBottom
                                className={classes.amount2}
                              >
                                {item?.enddate
                                  ? fDateTime(
                                      parseCustomDateString(item.enddate)
                                    )
                                  : "-"}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container item spacing={2}>
                            <Grid item lg={6} xs={6}>
                              <Typography
                                variant="h6"
                                gutterBottom
                                className={classes.amount2}
                              >
                                Amount Paid (RS)
                              </Typography>
                            </Grid>
                            <Grid item lg={6} xs={6}>
                              <Typography
                                variant="h6"
                                gutterBottom
                                className={classes.amount2}
                              >
                                {item?.Amount}
                              </Typography>
                            </Grid>
                          </Grid>
                          {/* <Grid container item spacing={2}>
                      <Grid item lg={6} xs={6} >
                        <Typography variant="h6" gutterBottom className={classes.amount2}>
                        Subscribed On
                        </Typography>
                      </Grid>
                      <Grid item lg={6} xs={6}>
                        <Typography variant="h6" gutterBottom className={classes.amount2}>
                        {item?.EntryDT ? fDateTime(parseCustomDateString(item.EntryDT)) : "-"}
                        </Typography>
                      </Grid>
                    </Grid> */}
                          <Grid container item spacing={2}>
                            <Grid item lg={6} xs={6}>
                              <Typography
                                variant="h6"
                                gutterBottom
                                className={classes.amount2}
                              >
                                Status
                              </Typography>
                            </Grid>
                            <Grid item lg={6} xs={6}>
                              <Typography
                                variant="h6"
                                gutterBottom
                                className={classes.amount2}
                              >
                                {item?.Status}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Card>
                      )}
                    </>
                  ))}
                </Grid>
              </Grid>

              <Grid lg={12} md={12} sm={12} xs={12}>
                <Typography
                  variant="h6"
                  gutterBottom
                  className={classes.colorwhite2}
                >
                  <span className="forment_heading">Subcription Plan</span>
                
                </Typography>
              </Grid>
              <Grid spacing={2}>
                <Grid lg={12} md={12} sm={12} xs={12}>
                  <Stack
                    justifyContent="space-between"
                    sx={{ mb: "2rem", mr: "1rem" }}
                  >
                    <RadioGroup
                      row
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="plan"
                      label="Plan"
                      value={selectedPlan}
                      {...getFieldProps("plan")}
                      error={Boolean(touched.plan && errors.plan)}
                      helperText={touched.plan && errors.plan}
                      onChange={(e) => {
                        selectedStudentData(e);
                      }}
                    >
                      <>
                        {plans.map((option, index) => (
                          <FormControlLabel
                            value={JSON.stringify(option)}
                            key={index}
                            className="f-12 subscription-plan"
                            control={<Radio />}
                            label={`${option?.PlanName} (Rs. ${option?.PlanAmount} for ${option?.PlanDays} days)`}
                          />
                        ))}
                      </>
                    </RadioGroup>
                  </Stack>
                </Grid>
                <Grid lg={2} md={2} sm={3} xs={12}>
                  <LoadingButton
                    className={`f-12 submit_btn ${classes.boldlight}`}
                    fullWidth
                    size="large"
                    disabled={!selectedPlan}
                    type="submit"
                    fontWeight="200"
                    variant="contained"
                    loading={isSubmitting}
                    onClick={(e) => {
                      buyPlan(e);
                    }}
                  >
                    Buy Plan
                  </LoadingButton>
                </Grid>
                <Grid lg={6} md={6} sm={3} xs={0}>
                  &nbsp;
                </Grid>
              </Grid>
            </>
          )}
        </Container>
      </>
    </Page>
  );
}
