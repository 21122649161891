import { Box, Card, CardContent, Grid, Link, Paper, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
/* import rupee from '@iconify/icons-ic/round-currency-rupee'; */
import rupee from '@iconify/icons-ic/round-currency-rupee';
import { Icon } from '@iconify/react';


export default function Pricing() {

    return (
        <div style={{ marginTop: 120, padding: ' 0 10px', }}>
            <Link to="/login" component={RouterLink} style={{ display: "flex", width: "75px", padding: "9px 7px 0 6px", lineHeight: "24px", background: "#089293", border: "none", color: "#fff", borderRadius: 6, marginBottom: "14px" }}> <img src="/static/mock-images/avatars/angle-left-free-icon-font.png" alt="imgtest" style={{ width: 15, marginTop: "3px", marginRight: "6px", marginBottom: "11px" }} />back</Link>
            <h2>Pricing</h2>

            <h3 style={{ padding: ' 15px 0', fontSize: 12, color: '#797D7F' }}>Last updated on Nov 17, 2023</h3>

            <Paper elevation={2} sx={{ fontSize: 14, padding: 1 }}>
                <Grid container spacing={2}>
                    {/* <Grid item xs={0} sm={0} md={1.5} lg={1.5}>&nbsp;</Grid> */}
                    <Grid item xs={12} sm={4} md={4} lg={4}>
                        <Card variant='outlined'>
                            <CardContent>
                                <Box sx={{ width: '100%', height: '150px' }}>

                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 1.5 }}>
                                        <Typography variant="h6" color="text.secondary" gutterBottom>
                                            Free Plan 
                                        </Typography>
                                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                            (1 Month)
                                        </Typography>
                                    </Box>                                   

                                    <Box sx={{ height: 35, display: 'flex', alignItems: 'center', justifyContent: 'center', border: '0.5px solid lightgrey', borderRadius: '5px', color: '#469293' }}>
                                        <Icon fontSize="18px" icon={rupee} />
                                        <Typography variant="subtitle2">0</Typography>
                                    </Box>

                                    <Typography variant="body2">
                                        &nbsp;
                                    </Typography>

                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Typography variant="body2" sx={{ fontSize: 12 }} color="text.secondary">
                                            <b>30 days</b> free subscription for 1<sup>st</sup> student from date of registration with unlimited usage. For 2<sup>nd</sup> student, buy a plan from day one of registration.
                                        </Typography>
                                    </Box>
                                </Box>

                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={4} md={4} lg={4}>
                        <Card variant='outlined'>
                            <CardContent>
                                <Box sx={{ width: '100%', height: '150px' }}>

                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 1.5 }}>
                                        <Typography variant="h6" color="text.secondary" gutterBottom>
                                            Monthly Plan 
                                        </Typography>
                                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                            (1 Month)
                                        </Typography>
                                    </Box>                                   

                                    <Box sx={{ height: 35, display: 'flex', alignItems: 'center', justifyContent: 'center', border: '0.5px solid lightgrey', borderRadius: '5px', color: '#469293' }}>
                                        <Icon fontSize="18px" icon={rupee} />
                                        <Typography variant="subtitle2">199</Typography>
                                    </Box>

                                    <Typography variant="body2">
                                        &nbsp;
                                    </Typography>

                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Typography variant="body2" sx={{ fontSize: 12 }} color="text.secondary">
                                            Monthly cost per student is <b>Rs. 199  per month</b> with unlimited usage.
                                        </Typography>
                                    </Box>
                                </Box>

                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item xs={12} sm={4} md={4} lg={4}>
                        <Card variant='outlined'>
                            <CardContent>
                                <Box sx={{ width: '100%', height: '150px', alignContent:'center' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 1.5 }}>
                                        <Typography variant="h6" color="text.secondary" gutterBottom>
                                            Quarterly Plan 
                                        </Typography>
                                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                            (3 Months)
                                        </Typography>
                                    </Box>

                                    <Box sx={{ height: 35, display: 'flex', alignItems: 'center', justifyContent: 'center', border: '0.5px solid lightgrey', borderRadius: '5px', color: '#469293' }}>
                                    <Icon fontSize="18px" icon={rupee} />
                                        <Typography variant="subtitle2">449</Typography>
                                    </Box>
                                    <Typography variant="body2">
                                        &nbsp;
                                    </Typography>

                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Typography variant="body2" sx={{ fontSize: 12 }} color="text.secondary">
                                            Average monthly cost per student is approx. <b>Rs. 150  per month</b> with unlimited usage.
                                        </Typography>
                                    </Box>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>

               {/*      <Grid item xs={12} sm={3} md={3} lg={3}>
                        <Card variant='outlined'>
                            <CardContent>
                                <Box sx={{ width: '100%', height: '250px' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 1.5 }}>
                                        <Typography variant="h6" color="text.secondary" gutterBottom>
                                            Half-Yearly Plan 
                                        </Typography>
                                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                            (6 Months)
                                        </Typography>
                                    </Box>

                                    <Box sx={{ height: 35, display: 'flex', alignItems: 'center', justifyContent: 'center', border: '0.5px solid lightgrey', borderRadius: '5px', color: '#469293' }}>
                                        <Typography variant="subtitle2">
                                            1,599 (INR)
                                        </Typography>
                                    </Box>
                                    <Typography variant="body2">
                                        &nbsp;
                                    </Typography>

                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Typography variant="body2" sx={{ fontSize: 12 }} color="text.secondary">
                                            Average monthly cost per student is approx. <b>Rs. 267  per month</b> with unlimited usage.
                                        </Typography>
                                    </Box>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3} lg={3}>
                        <Card variant='outlined'>
                            <CardContent>
                                <Box sx={{ width: '100%', height: '250px' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb: 1.5 }}>
                                        <Typography variant="h6" color="text.secondary" gutterBottom>
                                            Annual Plan 
                                        </Typography>
                                        <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                                            (12 Months)
                                        </Typography>
                                    </Box>

                                    <Box sx={{ height: 35, display: 'flex', alignItems: 'center', justifyContent: 'center', border: '0.5px solid lightgrey', borderRadius: '5px', color: '#469293' }}>
                                        <Typography variant="subtitle2">
                                            2,199 (INR)
                                        </Typography>
                                    </Box>
                                    <Typography variant="body2">
                                        &nbsp;
                                    </Typography>

                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <Typography variant="body2" sx={{ fontSize: 12 }} color="text.secondary">
                                            Average monthly cost per student is approx. <b>Rs. 183  per month</b> with unlimited usage.
                                        </Typography>
                                    </Box>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid> */}
                </Grid>
                {/* <Grid item xs={0} sm={0} md={1.5} lg={1.5}>&nbsp;</Grid> */}
            </Paper>
        </div>
    );
}