import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import {
    Grid,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    Container,
    Card,
    CardContent,
    Typography,
    Button,
    CardActions,
    useMediaQuery,
    Divider,
    Box,
    Tabs,
    Tab,
    Stack,
    Link,
    Tooltip,
    IconButton,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { toast } from "react-toastify";
import { Icon } from '@iconify/react';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import Page from "../../components/Page";
import Loader from "../../components/Loader";
import ConfirmationModal from "../../components/popup/ConfirmationModal";
import AddUserForm from "../../components/forms/AddUserForm";
import Utility from "../../utils/Utility";
import "react-toastify/dist/ReactToastify.css";


const useStyles = makeStyles({
    tableContainer: {
        maxWidth: "100%",
        overflowX: "auto",
    },

    cardRoot: {
        marginBottom: 10,
    },
    colorreport: {
        color: "#007c7c",
    },
    cardActions: {
        display: "flex",
        justifyContent: "space-between", // Ensures buttons are spaced evenly
        "@media (max-width: 600px)": {
            justifyContent: "flex-start", // Aligns buttons to the left for small screens
        },
    },
    searchInput: {
        marginRight: 10,
        height: 36,
        marginBottom: 20,
    },
});

toast.configure();

export default function UserList() {
    const navigate = useNavigate();
    const classes = useStyles();
    const [userInfo, setUserInfo] = useState(Utility.getUserDetails());
    const [data, setData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(false);
    const [showLoder, setShowLoader] = useState(false);
    const [addUser, setAddUser] = useState(false);


    useEffect(() => {
        fetchData()
    }, []);

    const setInviate = () => {
        setAddUser(true);
    };

    const getPrevious = () => {
        setAddUser(false);
    };


    const handleConfirm = (data) => {
        setIsModalOpen(false);
        selectedUser.switch = 2;
        addreminsteacher(selectedUser);
        
    };

    const handleCancel = () => {
        console.log("Cancelled!");
        setIsModalOpen(false);
    };
    const fetchData = () => {
        setShowLoader(true);
        const reqInfo = {
            InsMobile: userInfo?.Mobile,
            idParent: userInfo?.idParent,
            Bo: userInfo?.Bo,
        };

        const response = Utility.callPostAPI("getinsteacher", reqInfo);
        response.then((data) => {
            setShowLoader(false);
            setData([]);
            if (data.data?.data && Array.isArray(data.data?.data)) {
                setData(data.data?.data);
            }

        });
    };

    const addreminsteacher = (data) => {
        setShowLoader(true);
        const reqInfo = {
            InsMobile: userInfo?.Mobile,
            idParent: userInfo?.idParent,
            Bo: userInfo?.Bo,
            TeacherName: data?.switch === 2? data?.TeacherName :  `${data?.firstName} ${data?.lastName}`,
            TeacherMob: data?.switch === 2 ? data?.TeacherMob : data?.mobile,
            Switch: data?.switch

        };

        const response = Utility.callPostAPI("addreminsteacher", reqInfo);
        response.then((data) => {
            setShowLoader(false);
            getPrevious();
            fetchData();
            if(reqInfo?.Switch === 2)
            {
                toast.success("User Deleted");
            }
            else{
                toast.success("User Added");
            }
        });
    };

   

    const handleAction = (data) => {
        setSelectedUser(data);
        setIsModalOpen(true);
    };
    

    return (
        <Page title="Institute - User List | XPSOLV">
            <Container className="main-bg">
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Stack
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                            mb={2}
                            className={classes.borderbotmten}
                        >
                            <Box
                                component="h2"
                                display="inline"
                                className="tabheading imgicondefine"
                            >
                                <img src="/static/cutoff-range.png" alt="imgtest" />
                                User List
                            </Box>
                        </Stack>
                    </Grid>
                </Grid>

                <Grid container spacing={2} className=" container">
                    <Grid item xs={6} sm={6} xl={6}>
                        {(addUser) && (
                            <Typography variant="h4" gutterBottom className={classes.textcneter}>
                                <Link
                                    underline="none"
                                    variant="contained"
                                    className="backbuttonbg"
                                    onClick={getPrevious}
                                >
                                    <ArrowBackIosIcon className="backicon" />
                                    Back
                                </Link>
                            </Typography>
                        )}
                    </Grid>
                    <Grid item xs={6} sm={6} xl={6}>

                        {(!addUser) && (

                            <Button
                                variant="contained"
                                className="submit_btn "
                                color="primary"
                                onClick={setInviate}
                                style={{ float: 'right' }}
                            >
                                Add New User
                            </Button>
                        )}
                    </Grid>

                </Grid>
                {(addUser) && (
                    <Grid
                        container
                        spacing={2}
                        style={{ marginTop: "20px", marginBottom: "20px" }}
                        className="student_bg_folow margin_width"
                    >

                        <Grid item xs={12} xl={12} sm={12} mb={5}>

                            <AddUserForm addreminsteacher={addreminsteacher}  showHide={setShowLoader} />
                        </Grid>
                    </Grid>
                )}

                {(!addUser) && (
                    <Grid container spacing={2} className="margin_width">
                        <Grid xs={12} mt={5}>
                            <Card className={classes.card}>
                                <TableContainer
                                    component={Paper}
                                    className={classes.tableContainer}
                                >
                                    <Table>
                                        <TableHead className="tableHeader">
                                            <TableRow>
                                                <TableCell>SR. NO.</TableCell>
                                                <TableCell>Name</TableCell>
                                                <TableCell>Mobile</TableCell>
                                                <TableCell>Actions</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data.length > 0 &&
                                                data.map((row, index) => (
                                                    <TableRow key={index}>
                                                        <TableCell>
                                                            {index + 1}
                                                        </TableCell>
                                                        <TableCell>
                                                            {row.TeacherName}
                                                        </TableCell>
                                                        <TableCell>
                                                            {row.TeacherMob}
                                                        </TableCell>

                                                        <TableCell>


                                                            <Tooltip title="Delete" onClick={() => handleAction(row)}>
                                                                <IconButton>
                                                                    <Icon icon={trash2Fill} />
                                                                </IconButton>
                                                            </Tooltip>


                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            {data.length === 0 && (
                                                <TableRow>
                                                    <TableCell colSpan={4}>
                                                        <center>
                                                            <b>Data Not found</b>
                                                        </center>
                                                    </TableCell>
                                                </TableRow>
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Card>
                        </Grid>
                    </Grid>
                )}

            </Container>
            <ConfirmationModal
                isOpen={isModalOpen}
                title="Confirmation"
                message="Are you sure you want to delete this."
                onConfirm={handleConfirm}
                onCancel={handleCancel}
                type="OTHER"
            />
            <Loader isLoader={showLoder} showHide={setShowLoader} />
        </Page>
    );
}
