import { format, formatDistanceToNow, } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date) {
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateTime(date) {
  return format(new Date(date), 'dd MMM yyyy HH:mm');
}

export function fDateTimeSuffix(date) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function parseCustomDateString(customDateString) {

    // Extracting the date and time components
    const [datePart, timePart, timeZone] = customDateString.split(' ');
  
    // Extracting year, month, and day from the date part
    const [year, monthStr, day] = datePart.split('-');
    const monthNames = [
      'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ];
    const month = monthNames.indexOf(monthStr) + 1;
  
    // Extracting hour and minute from the time part
    const [hour, minute] = timePart.split(':');
  
    // Creating a new Date object (Note: JavaScript handles the date in the local time zone)
    const date = new Date(`${year}-${month}-${day} ${hour}:${minute}:00`);
  
    return date;
 
}

export function fToNow(date) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true
  });
}
