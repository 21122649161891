import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// material
import { LoadingButton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import "../components/css/style.css";
import {
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
  Checkbox,
  Box,
  Tooltip,
  Button,
  Link
} from "@material-ui/core";
import VisibilityIcon from "@mui/icons-material/Visibility";
// components
import Loader from "../components/Loader";
import Page from "../components/Page";
import Student from "../components/ActionWithStudent";
import Iframe from "./Iframe";
import Utility from "../utils/Utility";
import { historyApi } from "../service/index";

import HSeparator from "../theme/Separator";

toast.configure();

const useStyles = makeStyles((theme) => ({
  heightwidth: {
    height: "1000vh",
    margin: 0,
    padding: 0,
    overflow: "scroll",
  },
  btnsend: {
    display: "initial",
    justifyContent: "none",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "end",
      display: "flex",
    },
  },
  btnprint: {
    height: "40px",
    marginTop: "7px",
    marginRight: "10px",
    marginLeft: "10px",
    padding: "0 14px",
    float: "left",
    [theme.breakpoints.up("sm")]: {
      display: "block",
      height: "40px",
    },
  },
  btnprint2: {
    marginRight: 10,
    height: "40px",
    marginTop: "7px",
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  aligndiv: {
    display: "block",
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      display: "inline-flex",
    },
  },
  widthdropdown: {
    width: "163px",
    float: "left",
  },
  /*   header:{
      display: "block",
    },
    footer:{
      display: "block",
    },
   [`@media print`]:  {
      header, footer: {
        display: 'none',
      }
    } */

  fontsizeheading: {
    fontSize: 16,
  },
  paper: {
    padding: theme.spacing(2),
  },
  cardcontent: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 16,
    paddingBottom: "0px !important",
  },
  peraclass2: {
    //  width: "202px",
    //  width:'auto',
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginBottom: "5px",
    //  whiteSpace: "nowrap"
  },
  peraclass: {
    fontSize: 13,
    paddingBottom: 10,
    fontWeight: 600,
    color: "#00007e",
  },
  emailphone: {
    fontSize: 13,
    fontWeight: 500,
    color: "#000000",
    fontFamily: "Poppins",
    marginTop: -5,
    lineBreak: "anywhere",
    marginLeft: 10,
    [theme.breakpoints.up("md")]: {
      lineBreak: "auto",
    },
  },
  
  headingfont: {
    fontSize: 16,
    fontWeight: 600,
  },
  btntext: {
    fontSize: 14,
    fontWeight: 500,
    padding: "0 9px",
    background: "#007c7c",
    color: "#ffffff",
    marginTop: "2px",
    marginBottom: "5px",
    borderRadius: "5px",
    "&:hover": {
      background: "#007c7c",
    },
  },
  btntext1: {
    padding: "0px 5px",
    "border-radius": "5px",
  },
  textcenter: {
    textAlign: "left",
    lineHeight: 0,
  },
  headingfive: {
    display: "flex",
    marginBottom: 26,
  },
  perafive: {
    color: "#929292",
    fontWeight: 500,
  },
  cardpadding: {
    paddingLeft: 0,
    paddingBottom: 10,
  },
  cardborder: {
    border: "1px solid #c8c8c8",
    "&:hover": {
      backgroundSize: "cover",
      background: "linear-gradient(to right, #edfbf4 15%, #d4ffe9 94%)",
      borderColor: "#fff",
    },
  },
  centertext: {
    textAlign: "center",
    fontSize: 24,
  },
  centertextside: {
    textAlign: "left",
    fontSize: 24,
  },
  btnmain: {
    fontWeight: 500,
    marginTop: 18,
  },
  closebtn: {
    position: "absolute",
    right: 0,
    top: -12,
    fontWeight: 700,
  },
  textcolor: {
    color: "#007c7c",
  },
  textcolor2: {
    color: "#999",
    fontWeight: 500,
  },
  colorunselect: {
    background: "#fff",
    boxShadow: "none",
    minWidth: "27px",
    width: "7px",
    height: "27px",
    borderRadius: "83px",
    padding: "14px",
    "&:hover": {
      background: "transparent",
    },
  },
  marginleftright: {
    marginRight: "13px",
    width: "12px",
    height: "27px",
    borderRadius: "110px",
    padding: "0",
    minWidth: "27px",
    background: "#fff",
    boxShadow: "none",
    "&:hover": {
      background: "transparent",
    },
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function ViewQuestionTest() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const classes = useStyles();
  const [selectedUser, setSelectedUser] = useState();
  const [showLoder, setShowLoader] = useState(false);
  const [html, setHtml] = useState("");
  const [personName, setPersonName] = useState([]);
  const [sections, setSections] = useState([]);
  const [idStudentSchool, setIdStudentSchool] = useState(0);
  const [userInfo, setUserInfo] = useState(Utility.getLS("userInfo"));
  const [userDetails, setUserDetails] = useState(Utility.getUserDetails());
  const [isPaper, setIsPaper] = useState(false);
  const [reviewPaper, setReviewPaper] = useState({});
  const [isOnlinePublish, setIsOnlinePublish] = useState(false);
  const [studentList, setStudentList] = useState([]);
  const [fromCreateTest, setFromCreateTest] = useState(false);
  const [isHomeWork, setIsHomeWork] = useState(false);
  const [isPaperHtml, setIsPaperHtml] = useState(true);
  const [isReleaseAns, setIsReleaseAns] = useState(false);
  const [studentInfo, setStudentInfo] = useState([]);
  

  const tempArray = [];

  useEffect(() => {
    if (state) {
      setSelectedUser(state?.selectedUser);
      setIdStudentSchool(state?.selectedUser?.idStudentSchool);

      if (state?.idStudentSchool) {
        setIdStudentSchool(state?.idStudentSchool);
      }
    //  setHtml(state?.data);
      

      if (state?.classId) {
        getSections(state?.classId);
      }

      if (state?.reviewData) {
        setIsPaper(true);
        setReviewPaper(state?.reviewData);
        viewQuestion(state?.reviewData);
      }

      if (state?.info) {
        setIsPaper(true);
        setFromCreateTest(true);
        if (state?.selectedUser?.ClassName) {
          state.info[0].Class = state.selectedUser.ClassName;
        }
        setReviewPaper(state.info[0]);
        viewQuestion(state.info[0]);
      }

      if (Utility.isInstitute()) {
        fetchData();
      }
    }
  }, []);

  const editPaper = () => {
    navigate(Utility.getPath("/view-edit-question"), {
      replace: true,
       state,
    });
  };

  const reviewAnswer = () => {
    navigate(Utility.getPath("/review-answer"), {
      replace: true,
      state: reviewPaper,
    });
  };

  const getSections = (classId) => {
    //  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    userInfo[0]?.ClassSectionInfo.forEach((element, index) => {
      if (element.idClass === classId) {
        element?.SectionInfo.forEach((ele, index) => {
          tempArray.push(ele?.Section);
        });
        setSections(tempArray);
      }
    });
  };

  const printQuestionPaper = () => {
    document.getElementById("printf").contentWindow.print();
  };

  const publishOnline = () => {
    if (Utility.isInstitute()) {
      fetchData();
    }
    setIsOnlinePublish(true);
    setIsPaperHtml(false);
  };

  const homeworkPublish = () => {
    if (Utility.isInstitute()) {
      fetchData();
    }
    setIsHomeWork(true);
    setIsPaperHtml(false);
  };

  const answerPublish = () => {
    if (Utility.isInstitute()) {
      getstuqpch();
    }
    
  };


  const back = () => {
    setIsOnlinePublish(false);
    setIsHomeWork(false);
    setIsReleaseAns(false);
    setIsPaperHtml(true);
  };

  const fetchData = () => {
    const reqInfo = {
      Bo: userDetails?.Bo,
      idInstitute: userDetails?.idParent,
      idRole: Number(userDetails?.idRole),
    };

    const response = Utility.callPostAPI("getinsstu", reqInfo);
    response.then((data) => {
      if (data.data?.data) {
        const filteredData = data.data?.data?.filter(
          (item) => item.idClass === reviewPaper.idClass
        );
        setStudentList(filteredData[0]);
      }
    });
  };

  const getstuqpch = () => {
   
    setShowLoader(true);
    const reqInfo = {
      Bo: userDetails?.Bo,
      idParent: userDetails?.idParent,
      idRole: Number(userDetails?.idRole),
      idTestPaper: reviewPaper?.idQuestionPaperDelivered,
      idClass: reviewPaper?.idClass,
    };

    const response = Utility.callPostAPI("getstuqpch", reqInfo);
    response.then((data) => {
      setShowLoader(false);
      if (data.data?.data) {
      
        const filteredMarkedStudents = data.data?.data.filter(
          (item) => Number(item.Checked) === 1
        );
        if(filteredMarkedStudents.length === 0)
        {
          toast.warning("Can’t Release Answers as Answers are not uploaded for any student");
          return false;
        }
        setIsPaperHtml(false);
        setIsReleaseAns(true);
        setStudentInfo(filteredMarkedStudents);
        
      }
    });
  };

  const viewQuestion = (reviewData) => {
    const studentSchoolId = reviewData?.idStudentSchool || reviewData?.Ins_idStudentSchool;
    const idQuestionPaperDelivered = reviewData?.idQuestionPaperDelivered || reviewData?.idTestPaper;
    const reqsBody = {
      idTestPaper: idQuestionPaperDelivered,
      idStudentSchool: Number(studentSchoolId),
      Bo: userDetails?.Bo,
      idParent: userDetails?.idParent,
    };
    setShowLoader(true);
    historyApi.genhtml(reqsBody).then(
      (resp) => {
        if (resp.status === 200) {
          if (resp.data.status === 200) {
            setShowLoader(false);
            setHtml(resp.data.data);
          }
        } else {
          setShowLoader(false);
          alert(resp.message);
        }
      },
      (error) => {
        setShowLoader(false);
        alert(error.response.data.error);
      }
    );
  };

  const pubmcq = (studentIds) => {
    
    const reqInfo = {
      idTestPaper: reviewPaper?.idQuestionPaperDelivered,
      idParent: userDetails?.idParent,
      Ins_Bo: userDetails?.Bo,
      Ins_idStudentSchool: userDetails?.Ins_idStudentSchool,
      SubjectName: reviewPaper?.SubjectName,
      PubTo: studentIds,
    };

    const response = Utility.callPostAPI("pubmcq", reqInfo);
    response.then((data) => {
      if (data.data?.data) {
        if (typeof data.data?.data === "string") {
          toast.warning(data.data.data);
          return true;
        }
      } else {
        toast.warning(data.data.message);
      }
      navigate(Utility.getPath("/test-history"), {
        replace: true,
        state: reviewPaper,
      });
    });
  };

  const homeWork = (requestInfo) => {
    
    const reqInfo = {
      idTestPaper: reviewPaper?.idQuestionPaperDelivered,
      idParent: userDetails?.idParent,
      Ins_Bo: userDetails?.Bo,
      Ins_idStudentSchool: userDetails?.Ins_idStudentSchool,
      InstituteName: userDetails?.InstituteName,
      PubTo: requestInfo?.studentIds,
      expIn:requestInfo?.expIn,
      isRev:requestInfo?.isRev
    };
    
    const response = Utility.callPostAPI("pubvwqp", reqInfo);
    response.then((data) => {
      if (data.data?.data) {
        if (typeof data.data?.data === "string") {
          toast.warning(data.data.data);
          return true;
        }
      } else {
        toast.warning(data.data.message);
      }
      back();
    });
  };

  

  

  return (
    <Page title="View Test | XPSOLV">
      <Grid container className="main-bg">
      {isPaperHtml && (
        <>
          <Grid container  className="view_continer">
            
              
          
            <Grid item lg={12} xs={12} className={classes.btnsend}>
              <div className={classes.aligndiv}>
              {isPaper && !fromCreateTest  && Utility.isInstitute()  && Number(reviewPaper.Published) === 0  &&  (
                
                  <LoadingButton
                    variant="contained"
                    onClick={reviewAnswer}
                    className="submit_btn"
                  >
                   <img src="/static/icons/checked.png" alt="imgtest" /> {" "}  Answer
                  </LoadingButton>
               
              )}
              {reviewPaper && Number(reviewPaper.Published) === 0  && Number(reviewPaper.RevQPVis) === 0  && Utility.isInstitute() && (
                  <LoadingButton
                    variant="contained"
                    onClick={editPaper}
                    className="submit_btn"
                  >
                    <img src="/static/icons/edit.png" alt="imgtest" />{" "} Edit

                  </LoadingButton>
                )}

              {reviewPaper  && Utility.isInstitute() && (
                  <LoadingButton
                    variant="contained"
                    onClick={homeworkPublish}
                    className="submit_btn"
                  >
                  <img src="/static/icons/homework.png" alt="imgtest" /> {" "} Homework

                  </LoadingButton>
                )}

                {reviewPaper && Number(reviewPaper.isMCQ) > 0 && Number(reviewPaper.Published) === 0 && Utility.isInstitute() && (
                  <LoadingButton
                    variant="contained"
                    onClick={publishOnline}
                    className="submit_btn"
                  >
                  <img src="/static/icons/folder.png" alt="imgtest" /> {" "}   Publish
                  </LoadingButton>
                )}

                {reviewPaper  && Utility.isInstitute() && (
                  <LoadingButton
                    variant="contained"
                    onClick={answerPublish}
                    className="submit_btn"
                  >
                   <img src="/static/icons/share.png" alt="imgtest" /> {" "} Release
                  </LoadingButton>
                )}
                
                <LoadingButton
                  variant="contained"
                  onClick={printQuestionPaper}
                  className="submit_btn btn-print printshowhide"
                >
                 <img src="/static/icons/printer.png" alt="imgtest" /> {" "}   Print
                </LoadingButton>
              </div>
            </Grid>
          </Grid>
          <Grid container  style={{marginTop:'20px'}}>
            {html && (
              // <iframe id="testPaper" className={classes.heightwidth} /* src={`/qp/${selectedUser}.html`} */ frameBorder="0"  scrolling="no"  width="100%"  title="description" />
              // document.getElementById('testPaper').innerHTML = `data:text/html;charset=utf-8,${  html}`;
              <Iframe content={html} id="printf" name="printf" />
            )}
          </Grid>
        </>
      )}

      {isOnlinePublish  && Utility.isInstitute() && (
          <Student pubmcq={pubmcq} reviewPaper={reviewPaper} studentInfo={studentList?.StudentInfo} title="Publish online test paper" back={back} type="PUBLISH_PAPER" />
        
      )}
      {isHomeWork  && Utility.isInstitute() && (
          <Student pubmcq={homeWork} reviewPaper={reviewPaper} studentInfo={studentList?.StudentInfo} title="Send As Homework" back={back} type="HOMEWORK" />
        
      )}
      {isReleaseAns  && Utility.isInstitute() && (
          <Student pubmcq={homeWork} reviewPaper={reviewPaper} studentInfo={studentInfo} title="Relase online test paper" back={back} type="ANSWER" />
        
      )}
      <Loader isLoader={showLoder} showHide={setShowLoader} />
      </Grid>
    </Page>
  );
}
