import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { useFormik, Form, FormikProvider } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';

// material
import { Stack, FormLabel, Select, MenuItem, Grid } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { makeStyles,useTheme } from '@material-ui/styles';

// ----------------------------------------------------------------------
// eslint-disable-next-line import/no-unresolved
import Utility from 'src/utils/Utility';

import { TestApi, utilApi } from '../../service/index';


const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  paper: {
    padding: theme.spacing(2)
  },
  specing :{
    marginTop:'12px !important',
  },
  specingtwo :{
     marginBottom:12,
  },
  textField :{
    width:'100%',
  },
  foenk :{
    padding:8,
  },
  boldlight:{
    fontWeight:500,
  }
 
 
}));

export default function PlanTestForm({ allUserInfo, userInfo, onChildClick }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { state } = useLocation();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState('');
  const [showUser, setShowUser] = useState({
    firstName: '', lastName: '', className: '', board: ''
  })
  const [chapterList, setChapterList] = useState([]);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState('sm');
  const [initialize, setInitialize] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [downloadId, setDownloadID] = useState('');
  const [chapters, setChapters] =  useState([]);
  const [chaptersIds, setChaptersIds] =  useState([]);

  useEffect(() => {
    console.log(allUserInfo);
    console.log(userInfo);         
      setSelectedUser(userInfo);
      initializeTest(userInfo.student.id);
    
  }, []);



  const handleClose = () => {
    setOpen(false);
  };

  const TestSchema = Yup.object().shape({
    testSubject: Yup.string().required('Subject is required'),
 //   chaptersIds: Yup.string().required('Chapter is required'),
    format: Yup.string().required('Format is required'),
  });

  const formik = useFormik({
    initialValues: {
      testSubject: "",
   //   chaptersIds: [],
      format: ""
    },
    validationSchema: TestSchema,
    onSubmit: (data) => {
      createTest(data);
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const initializeTest = (data) => {
    try {
      TestApi.initializeTest(data).then(
        (resp) => {
          if (resp.status === 200) {
            if (resp.data.status === 200) {
              setInitialize(resp.data.data);
            }
            else {
              alert(resp.data.message);
            }
          }
          else {
            alert(resp.message);
          }
        },
        (error) => { alert("Error in initializeTest"); }
      );
    }
    catch (error) {
      console.log("initializeTest Catch", error);
    }
  }

  const createTest = (data) => {
    
    try {
      // setOpen(true);
      const reqsBody = {
        "pFormatId": data.format.toString(),
        "idClassSubject": data.testSubject.toString(),
        "chapterIds": chaptersIds.toString(),
        "idStudentSchool": selectedUser.id.toString(),
        "nonRepeatDuration": "-15"
      };
      console.log("reqsBody" , reqsBody);

      TestApi.orderTest(reqsBody).then(
        (resp) => {
          if (resp.status === 200) {
            if (resp.data.status === 200) {
              downloadPDF(resp.data.data);
              setDownloadID(resp.data);
            }
            else {
              alert(resp.data.message);
            }
          }
          else {
            alert(resp.message);
          }
        },
        (error) => { alert("error in create test"); }
      );

      formik.resetForm();
    }
    catch (error) {
      console.log("create test Catch", error);
    }
  }
  const downloadPDF = (testId) => {
    try {
      if (testId) {
        TestApi.downloadTest(testId).then(
          (resp) => {
            if (resp.status === 200) {
              // Generate PDF
              utilApi.base64ToPDF(resp.data.data, "TestPaper.pdf");
              navigate(Utility.getPath('/app'), { replace: true });
            }
            else {
              alert(resp.message);
            }
          },
          (error) => { alert("Error in download pdf"); }
        );
      }
    }
    catch (error) {
      console.log("download pdf Catch", error);
    }
  }
  const handleChange = (event) => {
    if (initialize) {

      // eslint-disable-next-line array-callback-return
      initialize?.standardSubjects?.map((item) => {
        if (item.subject.id === event.target.value) {
          setChapterList(item.chapters);
          formik.setFieldValue("testSubject", event.target.value);
        }
      });
    }
  };
  const storeChapterIds = chaptersIds;
  const handleChangeChapter = (event, ids) => {
    const {
      target: { value },
    } = event;
    const name = event.target.innerText;
    // setChapters(
    //   // On autofill we get a the stringified value.
    //   typeof name === 'string' ? name.split(',') : name,
    // );
    setChaptersIds(
      // On autofill we get a the stringified value.
 
      typeof value === 'string' ? value.split(',') : value,
      // storeChapterIds.forEach(id => value.push(id)),

    );
  };

  return (
    <FormikProvider value={formik}>
     
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
     
        <Stack spacing={3}>
        
        <Grid container >
        <Grid lg={6} className={classes.foenk}>
          <FormLabel component="legend" className={classes.specing}>Subject</FormLabel>
          <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} className={classes.specing}>
                <Select
                  fullWidth
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  label="Subject"
                  {...getFieldProps('testSubject')}
                  onChange={(e) => { handleChange(e) }}
                  error={Boolean(touched.testSubject && errors.testSubject)}
                  helperText={touched.testSubject && errors.testSubject}
                >
                  <MenuItem value="">
                    <em>Select Subject</em>
                  </MenuItem>
                  {initialize?.standardSubjects?.map((option, index) => (
                    <MenuItem key={index} value={option.subject.id}>
                      {option.subject.subjectName}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
                  </Grid>
                  <Grid lg={6} className={classes.foenk}>
                <FormLabel component="legend" className={classes.specing}>Chapter</FormLabel>
                <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} className={classes.specing}>
                <Select
                  fullWidth
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  label="chaptersIds"
                  multiple
                  type="select-multiple"
                  value={chaptersIds}
                  // input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                 // {...getFieldProps('chaptersIds')}
                  onChange={(e) => { handleChangeChapter(e) }}
                  // renderValue={(selected) => (
                  //   <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  //     {selected.map((value) => (
                  //       <Chip key={value} label={value} />
                  //     ))}
                  //   </Box>
                  // )}
                  // MenuProps={MenuProps}
                  // error={Boolean(touched.chaptersIds && errors.chaptersIds)}
                  // helperText={touched.chaptersIds && errors.chaptersIds}
                >
                  <MenuItem value="">
                    <em>Select Chapter</em>
                  </MenuItem>
                  {chapterList.map((option, index) => (
                    <MenuItem key={index} value={option.id} name={option.chapterName} >
                      {option.chapterName}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
              </Grid>
              <Grid lg={6} className={classes.foenk}>
              <FormLabel component="legend" className={classes.specing}>Test Format</FormLabel>
              <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} className={classes.specing}>
                <Select
                  fullWidth
                  labelId="demo-simple-select-filled-label"
                  id="demo-simple-select-filled"
                  label="Format"
                  {...getFieldProps('format')}
                  error={Boolean(touched.format && errors.format)}
                  helperText={touched.format && errors.format}
                >
                  <MenuItem value="">
                    <em>Select Format</em>
                  </MenuItem>
                  {initialize?.testFormats?.map((option, index) => (
                    <MenuItem key={index} value={option.id}>
                      {option.pformat}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
              </Grid>
           </Grid>
           

          <LoadingButton className={classes.boldlight}
            fullWidth
            size="large"
            type="submit"
            fontWeight="200"
            variant="contained"
            loading={isSubmitting}
          >
            Create Plan Test
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
