// import { useForm } from "react-hook-form";
// import { yupResolver } from '@hookform/resolvers';
// material
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import { Form, FormikProvider, useFormik } from "formik";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
// Import toastify css file
import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import Loader from "../components/Loader";
// components
import Page from "../components/Page";
import { historyApi } from "../service/index";
import Iframe from "./Iframe";

toast.configure();

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  widthbottom: {
    width: "90%",
    margin: "auto",
    background: "#fff",
    border: "1px solid #d8d8d8",
    padding: "25px",
    borderRadius: "11px",
    [theme.breakpoints.up("sm")]: {
      width: "55%",
    },
  },
  buttonreview: {
    padding: "17px 32px",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      padding: "17px 0px",
    },
  },
  headingquestion: {
    color: "#fff",
    padding: "22px 32px 83px",
    margin: "0",
    fontSize: 22,
    [theme.breakpoints.up("sm")]: {
      fontSize: 42,
      padding: "72px 32px 143px",
    },
  },
  mbottom: {
    margin: "8px auto",
    [theme.breakpoints.up("sm")]: {
      marginBottom: 0,
    },
  },
  centertext: {
    textAlign: "center",
    marginBottom: 12,
  },
  imagepaper: {
    width: "282px",
    margin: "auto",
    marginBottom: "21px",
    borderRadius: "162px",
  },
  btntext: {
    background: "#007c7c",
    color: "#fff",
    padding: "28px 26px",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "auto",
    },
    "&:hover": {
      background: "#007c7c !important",
    },
  },
  alertmsg: {
    fontSize: "11px",
    marginTop: "5px",
    color: "#f00",
  },
  colorreport: {
    color: "#007c7c",
  },
  heightwidth: {
    height: "1000vh",
    margin: 0,
    padding: 0,
    overflow: "scroll",
    borderTop: "1px solid #d8d8d8",
    marginTop: 30,
  },
  borderchangetext: {
    fontSize: "14px !important",
  },
}));
export default function GenerateTest() {
  const classes = useStyles();
  const [showLoder, setShowLoader] = useState(false);
  const [html, setHtml] = useState("");
  return (
    <Page title="Generate Test | XPSOLV">
      <Typography variant="h4" component="h4" className={classes.centertext}>
        Generate{" "}
        <Box
          component="span"
          className={classes.colorreport}
          sx={{ display: "inline" }}
        >
          Test
        </Box>
      </Typography>
      <Grid container>
        {html && (
          /* <iframe  className={classes.heightwidth} src={`/qp/${selectedHtmlPage}`} frameBorder="0"  scrolling="no"  width="100%" height="1000px"  title="description" /> */
          <Iframe content={html} />
        )}
      </Grid>
      <Loader isLoader={showLoder} showHide={setShowLoader} />
    </Page>
  );
}
