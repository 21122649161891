

const Iframe = (props) => {
    // eslint-disable-next-line no-unused-vars
    let iframeRef = null;
    const writeHTML = (frame) => {
        if (!frame) {
            return;
        }
        iframeRef = frame;
        const doc = frame.contentDocument;
        doc.open();
        // eslint-disable-next-line react/prop-types
        doc.write(props.content);
        doc.close();
        // console.log('iFrame height', frame.contentWindow.document.body.scrollHeight)
        // const iFrameHeigth = frame.contentWindow.document.body.scrollHeight;
        frame.style.width = '100%';
        setTimeout(() => {
            if (props?.height) {
              frame.style.height = `${props?.height}vh`;
            } else {
              frame.style.height = frame?.contentWindow?.document?.body ? `${frame?.contentWindow?.document?.body?.scrollHeight> 600? frame?.contentWindow?.document?.body?.scrollHeight+50:600}px` : '650vh';
            }
          }, 1000);
          

        // console.log('iFrame height', frame.contentWindow.document.body.scrollHeight)
    };

    const recalHeight = (obj) =>{
        iframeRef.style.height = `${obj.contentWindow.document.documentElement.scrollHeight}px`;
    }

    return (
        // eslint-disable-next-line jsx-a11y/iframe-has-title
        <iframe src="about:blank" id={props.id} name={props.name}
            scrolling="no"
            frameBorder="0"
            title=""
            ref={writeHTML}
          //  onLoad="resizeIframe(recalHeight(this))"
        />
    );
};
export default Iframe;