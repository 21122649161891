import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
  Box,
  Button,
  Grid,
  Typography,
  TextField,
  Link,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/styles";
import ReactPlayer from "react-player/youtube";
import { Card, CardContent } from "@mui/material";

// components
import Page from "../../components/Page";
import Loader from "../../components/Loader";
import Utility from "../../utils/Utility";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  textcneter: {
    textAlign: "left",
    marginBottom: 18,
  },
}));

export default function UploadYoutube() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { state } = useLocation();
  const classes = useStyles();
  const [showLoder, setShowLoader] = useState(false);
  const [userInfo, setUserInfo] = useState(Utility.getUserDetails());
  const [videoUrl, setVideoUrl] = useState("");

  useEffect(() => {
    if (state) {
      if (Number(state?.LinkVd) !== 0) {
        setVideoUrl(state?.LinkVd);
      }
    }
  }, [state]);

  const getPrevious = () => {
    navigate(Utility.getPath("/test-history"), { replace: true });
  };

  const setlinkvd = (opt) => {
    setShowLoader(true);
    const reqInfo = {
      idParent: userInfo?.idParent,
      Bo: userInfo?.Bo,
      GUQPID: state?.idQuestionPaperDelivered,
      Opt: opt,
      LinkVd: youtubeCardFormik.values.url,
    };

    const response = Utility.callPostAPI("setlinkvd", reqInfo);
    response.then((data) => {
      setShowLoader(false);
      toast.success(reqInfo.Opt === 1 ?"Video Linked ":'Video Removed');
      getPrevious();
    });
  };

  const youtubeCardFormik = useFormik({
    initialValues: {
      url: Number(state?.LinkVd) !== 0?state?.LinkVd:'',
    },
    validationSchema: Yup.object({
      url: Yup.string()
        .url("Enter a valid youtube URL")
        .matches(
          /^(https?:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/,
          "Enter a valid YouTube URL"
        )
        .required("URL is required"),
    }),
    onSubmit: (values) => {
      setlinkvd(1);
      setVideoUrl(values.url);
    },
  });

  const handleRemove = () => {
    setVideoUrl("");
    setlinkvd(2);
  };

  return (
    <Page title="Upload Youtube Link | XPSOLV">
      <Grid container className="main-bg">
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography variant="h4" gutterBottom className={classes.textcneter}>
            <Link
              underline="none"
              variant="contained"
              className="backbuttonbg"
              onClick={getPrevious}
            >
              <ArrowBackIosIcon className="backicon" />
              Back
            </Link>
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box
            component="h2"
            display="inline"
            className="tabheading imgicondefine"
          >
            
            <img src="/static/cutoff-range.png" alt="imgtest" />
           
             {state?.SubjectName} ({state?.idQuestionPaperDelivered})
            {"  "} <span display="inline" className="f-10"> { state?.PFormat}</span>
           
          </Box>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} className="bg_white_upload">
          <CardContent>
            <Typography variant="h6">YouTube Link</Typography>
            <form onSubmit={youtubeCardFormik.handleSubmit}>
              <TextField
                variant="outlined"
                fullWidth
                margin="normal"
                id="url"
                name="url"
                label="YouTube URL"
                value={youtubeCardFormik.values.url}
                onChange={youtubeCardFormik.handleChange}
                error={
                  youtubeCardFormik.touched.url &&
                  Boolean(youtubeCardFormik.errors.url)
                }
                helperText={
                  youtubeCardFormik.touched.url && youtubeCardFormik.errors.url
                }
              />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: 20,
                }}
              >
                <Button color="primary" variant="contained" type="submit">
                  Update
                </Button>
                <Button
                  color="secondary"
                  variant="outline"
                  className="btn-danger"
                  onClick={handleRemove}
                >
                  Remove
                </Button>
              </div>
            </form>
            {videoUrl && (
              <div style={{ position: "relative", paddingTop: "56.25%" }}>
                <ReactPlayer
                  url={videoUrl}
                  controls
                  width="100%"
                  height="90%"
                  style={{ position: "absolute", top: 0, left: 0 }}
                />
              </div>
            )}
          </CardContent>
        </Grid>
      </Grid>
      <Loader isLoader={showLoder} showHide={setShowLoader} />
    </Page>
  );
}
