import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  MenuItem,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider
} from '@mui/material';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import '../css/style.css';
import MathJaxComponent from "../MathJaxComponent";

// Validation Schema using Yup
const validationSchema = Yup.object({
  reason: Yup.string().required('Reason is required'),
  replacementOption: Yup.string().required('Please select an option')
});

const PopupForm = ({ open, onClose, onSubmit, optionQuestion, question }) => (
  <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
    <DialogTitle className="heading_modalviewPopup">Replace Question 
      <Button
            style={{
             
              color: "#fff",
              float: "right",
            }}
            onClick={onClose}
          >
            X
          </Button></DialogTitle>
    <Formik
      initialValues={{
        reason: '',
        replacementOption: ''
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        onSubmit(values); // Handle form submission
        resetForm();
        onClose(); // Close the popup
      }}
    >
      {({ errors, touched, values, setFieldValue }) => (
        <Form>
          <DialogContent>
            <FormControl component="div">
              <FormLabel component="h2" className="tabheading popupHeading">
                {' '}
                Original Question
              </FormLabel>
              <FormLabel component="div" className="option-class">
              <MathJaxComponent content={question.Questiontext} />
                
              </FormLabel>
            </FormControl>

            <Divider className="seprater" />
            

            <FormControl component="fieldset" style={{ marginTop: 0 }}>
              <FormLabel style={{ marginBottom: 0 }} component="h2" className='tabheading popupHeading'>
                Options for Replacement
              </FormLabel>
              <Divider className="seprater" />
              <RadioGroup
                name="replacementOption"
                value={values.replacementOption}
                onChange={(event) => setFieldValue('replacementOption', event.target.value)}
              >
                {optionQuestion?.map((option, index) => (
                  <div key={index + option.idQuestion} className="question-container">
                    <FormControlLabel
                      value={option.idQuestion}
                      control={<Radio />}
                      label={
                        <MathJaxComponent content={option.Questiontext} />
                      }
                      className="form-control-label"
                    />
                    <div className="options-container">
                      {option?.Options?.map((optionHtml, opindex) => (
                        <MathJaxComponent content={`${optionHtml.OptionLabel}.  ${optionHtml.MCQOption}`} />
                        
                      ))}
                    </div>
                  </div>
                ))}
              </RadioGroup>
              {touched.replacementOption && errors.replacementOption && (
                <p style={{ color: 'red', fontSize: '0.8em', marginTop: 4 }}>
                  {errors.replacementOption}
                </p>
              )}
              
            </FormControl>
            <Divider className="seprater" />
            <Field
              as={TextField}
              name="reason"
              label="Reason For Change"
              select
              fullWidth
              error={touched.reason && Boolean(errors.reason)}
              helperText={touched.reason && errors.reason}
              margin="normal"
            >
              <MenuItem value="1">Incorrect Question</MenuItem>
              <MenuItem value="2">Question from Obsolete Area</MenuItem>
              <MenuItem value="3">Change complexity of question</MenuItem>
              <MenuItem value="4">Too many questions of the same type in paper</MenuItem>
            </Field>
          </DialogContent>

          <DialogActions>
            
            <Button onClick={onClose} color="secondary" className="confirm_action_btn">
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary" className="cancle_bnt_show">
              Submit
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  </Dialog>
);

export default PopupForm;
