import { useState,useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { LoadingButton } from "@material-ui/lab";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { makeStyles } from "@material-ui/styles";
import {
    FormControlLabel,
    FormGroup,
    Grid,
    Typography,
    Checkbox,
    Box,
    Tooltip,
    Button,
    Link,
    TextField,
} from "@material-ui/core";
import Page from "./Page";
import HSeparator from "../theme/Separator";
import Loader from "./Loader";
import "./css/style.css";
import Label from "./Label";
import Utility from "../utils/Utility";

toast.configure();

const useStyles = makeStyles((theme) => ({
    fontsizeheading: {
        fontSize: 16,
    },
    textcolor: {
        color: "#007c7c",
        fontSize:'0.7rem'
    },
    checkboxfont: {
        fontSize: 14,
    },
    smallTextField: {
        width: "100px", // Adjust width as needed
        height: "30px", // Adjust height as needed
    },
    textarea: {
        width: '100%',
        marginTop: theme.spacing(2),
    },
    charCount: {
        textAlign: 'right',
        color: theme.palette.text.secondary,
    },
}));

export default function ActionWithStudent({
    pubmcq,
    reviewPaper,
    studentInfo,
    title,
    back,
    type,
}) {
    const navigate = useNavigate();
    const { state } = useLocation();
    const classes = useStyles();
    const [showLoader, setShowLoader] = useState(false);
    const [studentIds, setStudentIds] = useState([]);
    const [expiryDay, setExpiryDay] = useState(1);
    const [expiryDayError, setExpiryDayError] = useState(false);
    const [textMsg, setTextMsg] = useState("");
    const [textMsgCount, setTextMsgCount] = useState(200);
    const getChckeboxValue = (idStudentSchool) => {
        const studentArray = [...studentIds];
        const index = studentArray.indexOf(idStudentSchool);

        if (index > -1) {
            studentArray.splice(index, 1);
        } else {
            studentArray.push(idStudentSchool);
        }
        setStudentIds(studentArray);
    };

    
    const selectAll = () => {
        const studentArray = studentInfo.map((item) => item.idStudentSchool);
        setStudentIds(studentArray);
    };

    const unselectAll = () => {
        setStudentIds([]);
    };

    const handleExpiryDayChange = (event) => {
        setExpiryDayError(false);
        setExpiryDay(event.target.value);
        if (Number(event.target.value) > 5 || Number(event.target.value) < 1) {
            setExpiryDayError(true);
            return false;
        }
    };
    const handleChange = (event) => {
        const input = event.target.value;
        if (input.length <= 200) {
          setTextMsg(input);
          setTextMsgCount(200 - input.length);
        }
        setExpiryDayError(false);
        if (input.length < 1) {
            setExpiryDayError(true);
        }
      };

    const submit = () => {
        if (type === "HOMEWORK" || type === "ANSWER") {
            setExpiryDayError(false);
            if (Number(expiryDay) > 5 || Number(expiryDay) < 1) {
                setExpiryDayError(true);
                return false;
            }
            pubmcq({
                studentIds,
                expIn: Number(expiryDay),
                isRev: type === "HOMEWORK" ? 0 : 1,
            });
        } else if (type === "MESSAGE") {
            setExpiryDayError(false);
            if (textMsg.length < 1) {
                setExpiryDayError(true);
                return false;
            }
            pubmcq({
                studentIds,
                expIn: 1,
                message: Utility.base64urlEncode(textMsg),
            });
        } else {
            pubmcq(studentIds);
        }

    };

    return (
        <Page title={`${title} | XPSOLV`}>
            <Grid item lg={12} md={12} sm={12} xs={12}>
                <Typography variant="h4" gutterBottom className={classes.textcneter}>
                    <Link
                        underline="none"
                        variant="contained"
                        className="backbuttonbg"
                        onClick={() => back()}
                    >
                        <ArrowBackIosIcon className="backicon" />
                        Back
                    </Link>
                </Typography>
            </Grid>
            <Grid container className="bg_white_upload mb-50 mt-18">
                <Grid item xs={12} md={12}>
                    <Typography
                        variant="h4"
                        gutterBottom
                        className={classes.fontsizeheading}
                    >
                        <Box
                            component="h2"
                            display="inline"
                            className="tabheading imgicondefine"
                        >
                            {" "}
                            <img src="/static/cutoff-range.png" alt="imgtest" /> {title}
                        </Box>
                    </Typography>
                </Grid>
                <Grid item xs={6} md={6}>
                    <Typography
                        variant="h4"
                        gutterBottom
                        className={classes.fontsizeheading}
                    >
                         Students{" "}
                        <Box
                            component="span"
                            display="inline"
                            className={classes.textcolor}
                        >
                            ({reviewPaper?.Class})
                        </Box>
                    </Typography>
                </Grid>

                <Grid item xs={6} md={6}>
                    <div className="floatleftdiv">
                        <Tooltip title="Select All" arrow>
                            <Button
                                variant="contained"
                                onClick={selectAll}
                                className="task_btn_list"
                            >
                                <img
                                    src="/static/mock-images/avatars/task.png"
                                    alt="iconback"
                                    style={{ width: "23px", height: "23px", margin: 0 }}
                                />
                            </Button>
                        </Tooltip>
                        <Tooltip title="Unselect All" arrow>
                            <Button
                                variant="contained"
                                onClick={unselectAll}
                                className="task_btn_close"
                            >
                                <img
                                    src="/static/mock-images/avatars/x-mark.png"
                                    alt="iconback"
                                    style={{ width: "19px", height: "19px", margin: 0 }}
                                />
                            </Button>
                        </Tooltip>
                    </div>
                </Grid>
                {(type === "HOMEWORK" || type === "ANSWER") && (
                    <>
                    {/* {( type === "ANSWER") && (
                        <Grid container xs={12} sm={12}>
                            <Label>Status: {type === 'HOMEWORK' ? 'Sent As Homework' : 'Ready for release | Released and visible to students'}</Label>
                        </Grid>
                    )} */}
                        <Grid container xs={6} sm={4}>
                            <TextField
                                label="Expire In Days"
                                variant="outlined"
                                margin="normal"
                                value={expiryDay}
                                size="small"
                                onChange={handleExpiryDayChange}
                                error={expiryDayError}
                                helperText={expiryDayError && "Expire In Days should be 1 to 5 days"}
                            />

                        </Grid>
                    </>
                )}
                <Grid container xs={12} sm={12}>
                    {studentInfo?.map((option, i) => (
                        <Grid key={i} item xs={12} md={4} sm={6}>
                            <FormGroup>
                                <FormControlLabel
                                    color="success"
                                    control={
                                        <Checkbox
                                            id={option.idStudentSchool}
                                            checked={studentIds.indexOf(option.idStudentSchool) >= 0}
                                            onChange={() => getChckeboxValue(option.idStudentSchool)}
                                        />
                                    }
                                    label={
                                        <Typography className={classes.checkboxfont}>
                                            {option.FirstName} {option.LastName}
                                        </Typography>
                                    }
                                />
                            </FormGroup>
                        </Grid>
                    ))}
                </Grid>
                {(type === "MESSAGE") && (
                    <>

                        <Grid container xs={12} sm={12}>


                            <TextField
                                label="Enter Message"
                                multiline
                                rows={2}
                                variant="outlined"
                                className={classes.textarea}
                                value={textMsg}
                                onChange={handleChange}
                                inputProps={{ maxLength: 200 }}
                                error={expiryDayError}
                                helperText={expiryDayError && "Message is required"}
                            />
                            <Typography className={classes.charCount}>
                                {textMsgCount} characters remaining
                            </Typography>

                        </Grid>
                    </>
                )}
                <Grid item xs={12} sm={12}>
                    <div>&nbsp;</div>
                    <HSeparator />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <div>
                        <LoadingButton
                            variant="contained"
                            onClick={submit}
                            disabled={studentIds.length === 0}
                            className="submit_btn"
                        >
                            Submit
                        </LoadingButton>
                    </div>
                </Grid>
            </Grid>
            <Loader isLoader={showLoader} showHide={setShowLoader} />
        </Page>
    );
}
