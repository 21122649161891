import { useState,useEffect } from 'react';
import { Outlet,useNavigate } from 'react-router-dom';

// material
import { styled } from '@material-ui/core/styles';
//
// eslint-disable-next-line import/no-unresolved
import Utility from 'src/utils/Utility';
import DashboardNavbar from './DashboardNavbar';
import DashboardSidebar from './DashboardSidebar';


// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  }
}));

// ----------------------------------------------------------------------

export default function DashboardLayout(props) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [userInfo, setUserInfo] = useState(Utility.getUserDetails());

   useEffect(() => {
 
    if(Number(userInfo.idRole) ===  1 && props.isInstitute > 1)
      {
        navigate('/dashboard/app', { replace: true });  
      }
      else if(Number(userInfo.idRole) >  1 && props.isInstitute === 1)
      {
        navigate('/institute/app', { replace: true });  
      }
      
   }, [])
   

  return (
    <RootStyle>
      <DashboardNavbar onOpenSidebar={() => setOpen(true)} />
      <DashboardSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />
      <MainStyle className='main-bgnew'>
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
