import { Form, FormikProvider, useFormik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
// material
import {
  Alert,
  Grid,
  Stack,
  TextField,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";

import { toast } from "react-toastify";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

// ----------------------------------------------------------------------
import { TestApi } from "../../service/index";
import Utility from "../../utils/Utility";

toast.configure();

export default function ReferralFormInstitute({ isListUpdate, funUpdateList,courses,courseLabel,mobile,type }) {
  const navigate = useNavigate();
  const [successMsg, setSuccessMsg] = useState("");
  const [referStatus, setReferStatus] = useState(false);
  const [userInfo, setUserInfo] = useState(Utility.getLS("userInfo"));
  const [userDetail, setUser] = useState(Utility.getLS("userInfo")?.[0]);

  const RegisterSchema = Yup.object().shape({
    fMobileNum: Yup.string()
      .min(10, "Invalid mobile number")
      .max(10, "Invalid mobile number")
      .required("Mobile number is required"),
    fName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Name is required"),
    course: Yup.object()
      .required("Enrollment is required"),
  });

  const formik = useFormik({
    initialValues: {
      fMobileNum: mobile || "",
      fName: "",
      course: "",
    },
    validationSchema: RegisterSchema,
    onSubmit: (data) => {
      // eslint-disable-next-line no-unused-expressions
      type === 'invtoadd'? invtoadd(data):refer(data);
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  const handleChange = (event) => {
    formik.setFieldValue(event.target.name, event.target.value);
  };

  const refer = (dataInfo) => {
  //  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const data = {
      idParent: userInfo[0]?.idParent,
      Bo: userInfo[0]?.Bo,
      ParentName: userDetail.InstituteName,
      Refmobilenum: dataInfo.fMobileNum,
      RefName: dataInfo.fName,
      idRole: userInfo[0]?.idRole,
      idClass : dataInfo?.course?.idClass,
      ClassName: dataInfo?.course.ClassName
    };
    TestApi.refer(data).then((resp) => {
      if (resp.data.status === 200) {
        setReferStatus(true);
        setSuccessMsg("Invite successfully sent to referral !");
        if (isListUpdate) {
          funUpdateList();
        }
        formik.resetForm();
      } else {
       // toast.warning(resp.data.message);
       setSuccessMsg("Can't refer this mobile number as it's already in the system.");
       setReferStatus(false);
      }
    });
  };

  const invtoadd = (dataInfo) => {
    //  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      const data = {
        idParent: userInfo[0]?.idParent,
        Bo: userInfo[0]?.Bo,
        mobilenum: dataInfo.fMobileNum,
        StudentName: dataInfo.fName,
        ClassName: dataInfo?.course.ClassName,
        InstituteName:userInfo[0]?.InstituteName
      };

      const response = Utility.callPostAPI("invtoadd", data);
      response.then((resp) => {
        if (resp.data.status === 200) {
          setReferStatus(true);
          setSuccessMsg("Invite successfully sent to referral !");
          if (isListUpdate) {
            funUpdateList();
          }
          formik.resetForm();
        } else {
         // toast.warning(resp.data.message);
         setSuccessMsg("Can't refer this mobile number as it's already in the system.");
         setReferStatus(false);
        }
      });
    };

  return (
    <div>
      {successMsg && (
        <Typography variant="subtitle2" /* className="f-12" */ sx={{ mb: 3, textAlign: "left" }}>
          { referStatus? 
          <Alert color="primary" severity="success"> {successMsg}</Alert>
          :<Alert color="secondary" severity="error"> {successMsg}</Alert>}
          

        </Typography>
      )}
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <Grid item xs={12} sm={6} md={4} className="p-0">
                <TextField
                  fullWidth
                  className="wt-bg"
                  label="Mob No"
                  {...getFieldProps("fMobileNum")}
                  error={Boolean(touched.fMobileNum && errors.fMobileNum)}
                  helperText={touched.fMobileNum && errors.fMobileNum}
                />
              </Grid>
              <Grid item xs={12} sm={4} md={3} className="p-0">
                <TextField
                  className="wt-bg"
                  fullWidth
                  label="Name"
                  {...getFieldProps("fName")}
                  error={Boolean(touched.fName && errors.fName)}
                  helperText={touched.fName && errors.fName}
                />
              </Grid>
              <Grid item xs={12} xl={3} sm={4}>
                    <FormControl fullWidth variant="outlined">
                    <InputLabel id="search-class-label">
                        Enrollment
                    </InputLabel>
                    <Select
                        labelId="search-class-label"
                        id="search-class"
                        label="Enrollment"
                        {...getFieldProps("course")}
                        error={Boolean(touched.course && errors.course)}
                        helperText={touched.course && errors.course}
                    >
                         <MenuItem value="">{courseLabel} Enrollment</MenuItem>
                        {courses?.map((item, index) => (
                        // eslint-disable-next-line react/jsx-key
                        <MenuItem value={item}>{item.ClassName}</MenuItem>
                        ))}
                    </Select>
                    {touched.course && errors.course && (
                      <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wb06vl-MuiFormHelperText-root">{touched.course && errors.course}</p>
                    )}
                    </FormControl>
                </Grid>
              <Grid item xs={12} sm={4} md={2} className="p-0">
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  className="submit_btn"
                >
                  Send Invite
                </LoadingButton>
              </Grid>
            </Stack>
          </Stack>
        </Form>
      </FormikProvider>
    </div>
  );
}
