import PropTypes from "prop-types";
// material
import { Box, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
   
  },
  logo: {
   
    borderBottom: "1px solid rgba(145, 158, 171, 0.24)",
    paddingBottom: 21,
    width: "100%",
   
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    height: "70px",
   
    background: "url(/static/mock-images/avatars/papper.png)",
    backgroundColor: "#fff",

 
  },
  logoinnerbg: {
    textAlign: "center",
    margin: "0px 61px",
    display: "block",
   
  },
}));

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object,
};

export default function Logo({ sx }) {
  const classes = useStyles();

  return (
    <div>
      <Grid className={classes.logo}>
        <div className={classes.logoinnerbg}>
          <Box
            component="img"
            src="/static/logo.svg"
            sx={{ width: 117, height: 40, ...sx }}
          />
        </div>
      </Grid>
    </div>
  );
}
