 import performanceIcon from '@iconify/icons-eva/bar-chart-2-fill';
// import performanceIcon from '@iconify/icons-ic/outline-bar-chart';

import histroyIcon from '@iconify/icons-ic/baseline-history';
import reviewAnswerIcon from '@iconify/icons-ic/outline-reviews';
import referral from '@iconify/icons-ic/outline-share';
import support from '@iconify/icons-ic/outline-support-agent';
import profileIcon from '@iconify/icons-ic/person-outline';
import accountSummaryIcon from '@iconify/icons-ic/round-currency-rupee';

import { Icon } from '@iconify/react';

// import checkmarkcircle2fill from '@iconify/icons-eva/checkmark-circle-2-fill';
 import fileTextFill from '@iconify/icons-eva/file-text-fill';
// import pieChart2Fill from '@iconify/icons-eva/pie-chart-2-fill';
// import profileIcon from '@iconify/icons-ic/round-account-circle';
 import myInstitute from '@iconify/icons-eva/book-open-outline';
 
 
 
// import CurrencyRupeeIcon from '@iconify/icons-ic/round-currency-rupee';

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: 'Profile',
    path: '/dashboard/app',
    icon: getIcon(profileIcon),
    type:'common'
  },
  {
    title: 'My Institute',
    path: '/dashboard/my-institute',
    icon: getIcon(myInstitute),
    type:'common'
  }, 
  // {
  //   title: 'Check Answers',
  //   path: '/dashboard/reviewAnswers',
  //   icon: getIcon(reviewAnswerIcon),
  //   type:'common'
  // },  
  /* 
  {
    title: 'Test History',
    path: '/dashboard/test-history',
    icon: getIcon(histroyIcon),
    type:'common'
  },
  */
  {
    title: 'Performance Report',
    path: '/dashboard/performanceReport',
    icon: getIcon(performanceIcon),
    type:'student'
  },
  /* 
  {
    title: 'Account  Summary',
    path: '/dashboard/account-summary',
    icon: getIcon(accountSummaryIcon),
    type:'student'
  },
  {
    title: 'Referral',
    path: '/dashboard/referral',
    icon: getIcon(referral),
    type:'student'
  },
  */
  {
    title: 'Support & Help',
    path: '/dashboard/support',
    icon: getIcon(support),
    type:'common'
  },
  {
    title: 'Registration Approval',
    path: '/dashboard/userapprove',
    icon: getIcon(support),
    type:'admin'
  },
 /*  {
    title: 'Formik Tutorial',
    path: '/dashboard/test-formik',
    icon: getIcon(fileTextFill)
  }, */
/*   {
    title: 'user',
    path: '/dashboard/user',
    icon: getIcon(peopleFill)
  }, */
  
  
  /* {
    title: 'Generate Test',
    path: '/dashboard/generateTest',
    icon: getIcon(fileAddFill),
    type:'teacher'
  },
   {
    title: 'Add New Question',
    path: '/dashboard/add-question',
    icon: getIcon(questionMarkCircleOutline)
  } */
];

export default sidebarConfig;
