import * as Yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik, Form, FormikProvider } from "formik";
import React, { useEffect, useState } from "react";
// import { useForm } from "react-hook-form";
// import { yupResolver } from '@hookform/resolvers';

// material
import {
  Typography,
  InputLabel,
  FormControl,
  FormLabel,
  Select,
  MenuItem,
  TextField,
  Box,
  Grid,
  Alert,
  AlertTitle,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/styles";
import { toast } from "react-toastify";


// Import toastify css file
import "react-toastify/dist/ReactToastify.css";
import "../components/css/style.css";

import { LoadingButton } from "@material-ui/lab";
// components
import Page from "../components/Page";
import { TestApi } from "../service/index";
import Utility from "../utils/Utility";

toast.configure();

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  widthbottom: {
    width: "auto",
    margin: "auto",
    background: "#fff",
    padding: "13px",
    borderRadius: "11px",
    marginTop: "0px",
    border: "1px solid #d8d8d8",
    [theme.breakpoints.up("sm")]: {
      width: "694px",
      padding: "0px",
    },
  },
  buttonreview: {
    padding: "17px 32px",
    width: "100%",
    marginTop: "12px",
    [theme.breakpoints.up("sm")]: {
      padding: "15px 0px",
      marginTop: "0",
    },
  },
  headingquestion: {
    color: "#fff",
    padding: "22px 32px 83px",
    margin: "0",
    fontSize: 22,
    [theme.breakpoints.up("sm")]: {
      fontSize: 42,
      padding: "72px 32px 143px",
    },
  },

  h4heaidng: {
    textAlign: "center",
    marginBottom: "27px",
    fontSize: 16,
  },
  inputid: {
    width: "96%",
    [theme.breakpoints.up("sm")]: {
      width: "100%",
    },
  },
  imagepaper: {
    width: "282px",
    margin: "auto",
  },
  paperwidth: {
    width: "auto",
    border: "1px solid #d8d8d8",

    padding: "0 16px 20px",
    margin: "auto 15px",
    borderRadius: "24px",
    [theme.breakpoints.up("sm")]: {
      width: "740px",
      margin: "auto",
    },
  },
  colorreport: {
    color: "#007c7c",
  },
  btntext: {
    background: "#007c7c",
    color: "#fff",
    padding: "28px 0",
    width: "89%",
    marginTop: "13px",
    "&:hover": {
      backgroundColor: "#007c7c",
    },
    [theme.breakpoints.up("sm")]: {
      marginTop: "0px",
    },
  },
  alertalignment: {
    marginTop: 15,
  },
}));

export default function FindPaper() {
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useState([]);
  const [isTeacher, setIsTeacher] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [userInfo, setUserInfo] = useState(Utility.getLS("userInfo"));
  const classes = useStyles();

  useEffect(() => {
   // const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    setUserDetails(userInfo[0]?.StudentInfo);
  }, []);

  const handleChange = (event) => {
    formik.setFieldValue(event.target.name, event.target.value);
  };

  const reviewAnswer = (data) => {
    try {
      const idScStudent = data.idStudentSchool;
      // const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      const filteredStudents = userInfo[0]?.StudentInfo.filter(
        (student) => student.idStudentSchool === idScStudent
      );
      const selectedStudent = filteredStudents[0];

      const reqsBody = {
        idParent: userInfo[0].idParent,
        idTestPaper: data.id,
        idStudentSchool: Number(idScStudent),
        Bo: userInfo[0]?.Bo,
        StuFirstName: selectedStudent.FirstName,
        StuLastName: selectedStudent.LastName,
        StuGender: selectedStudent.Gender,
      };
      TestApi.reviewAnswer(reqsBody).then(
        (resp) => {
          if (resp.status === 200) {
            if (
              resp.data.status === 200 &&
              resp.data.data !== "Incorrect GUQPID"
            ) {
              const response = resp.data.data[0];
              response.idQuestionPaperDelivered = resp.data.data[0]?.GUQPID;
              response.idStudentSchool = idScStudent;
              response.pageType = "REVIEW_ANSWER";
              navigate(Utility.getPath("/review-answer"), {
                replace: true,
                state: response,
              });
            } else if (
              resp.data.status === 201 &&
              resp.data.message === "Cooling Period On!"
            ) {
              toast.info(
                `This paper will be available for review ${resp.data.data} minutes from now!`,
                {
                  position: toast.POSITION.TOP_RIGHT,
                }
              );
            } else {
              //		setErrorMessage(resp.data.message);
              toast.info(`${resp.data.message}`, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          } else {
            //  setErrorMessage(resp.message);
            toast.info(`${resp.message}`, {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        },
        (error) => {
          setErrorMessage("Error in getReviewAnswer");
        }
      );
      formik.setSubmitting(false);
    } catch (error) {
      console.log("getReviewAnswer Catch", error);
    }
  };

  const validationInfo = {
    id: Yup.string().required("Id is required"),
    idStudentSchool: Yup.string().required("StudentSchoolId is required"),
  };

  const TestSchema = Yup.object().shape(validationInfo);

  const formik = useFormik({
    initialValues: {
      idStudentSchool: "",
      id: "",
    },
    validationSchema: TestSchema,
    onSubmit: (data) => {
      console.log(data);
      reviewAnswer(data);
    },
  });
  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
  } = formik;

  return (
    <Page title="Review Answer | XPSOLV">
      <Typography variant="h4" component="h4" className={classes.h4heaidng}>
        Test{" "}
        <Box
          component="span"
          className={classes.colorreport}
          sx={{ display: "inline" }}
        >
          Paper ID
        </Box>
      </Typography>
      <div className={classes.paperwidth}>
        <img
          src="/static/mock-images/avatars/91736-exams.gif"
          alt="iconback"
          className={classes.imagepaper}
        />

        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid
              spacing={3}
              container
              style={{ backgroundImage: "#999" }}
              className={classes.widthbottom}
            >
              <Grid
                item
                lg={4}
                xs={6}
                md={3}
                sm={6}
                className={classes.dotspace}
              >
                <FormControl fullWidth>
                  <InputLabel
                    id="demo-simple-select-label"
                    className={classes.borderchangetext}
                  >
                    Student
                  </InputLabel>
                  <Select
                    fullwidth="true"
                    className={classes.borderchangetext}
                    labelId="demo-simple-select-filled-label"
                    id="demo-simple-select-filled"
                    label="Student"
                    {...getFieldProps("idStudentSchool")}
                    onChange={handleChange}
                    error={Boolean(
                      touched.idStudentSchool && errors.idStudentSchool
                    )}
                    helpertext={
                      touched.idStudentSchool && errors.idStudentSchool
                    }
                  >
                    <MenuItem value="">
                      <em className={classes.borderchangetext}>
                        Select Student
                      </em>
                    </MenuItem>

                    {userDetails.map((option, index) => (
                      <MenuItem
                        key={index}
                        value={option.idStudentSchool}
                        className={classes.borderchangetext}
                      >
                        {option.FirstName} {option.LastName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                {touched.idStudentSchool && errors.idStudentSchool && (
                  <p className={classes.alertmsg}>{errors.idStudentSchool}</p>
                )}
              </Grid>

              <Grid
                item
                lg={4}
                md={4}
                sm={6}
                xs={6}
                className={classes.mbottom}
              >
                <TextField
                  id="outlined-basic"
                  label="Test ID"
                  variant="outlined"
                  className={classes.inputid}
                  {...getFieldProps("id")}
                  error={Boolean(touched.id && errors.id)}
                  helperText={touched.id && errors.id}
                />
              </Grid>
              <Grid
                item
                lg={4}
                md={2}
                sm={12}
                xs={12}
                className={classes.mbottom}
              >
                <LoadingButton
                  spacing={2}
                  size="large"
                  type="submit"
                  loading={isSubmitting}
                  className={classes.btntext}
                >
                  Review
                </LoadingButton>
              </Grid>
              <Grid
                item
                lg={12}
                md={2}
                sm={12}
                xs={12}
                className={classes.mbottom}
              >
                {errorMessage && (
                  <Alert severity="error" className={classes.alertalignment}>
                    <AlertTitle>Error</AlertTitle>
                    {errorMessage}
                  </Alert>
                )}
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </div>
    </Page>
  );
}
