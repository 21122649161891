import { Form, FormikProvider, useFormik } from "formik";
import { useCallback, useEffect, useState } from "react";
import useRazorpay from "react-razorpay";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";

// material
import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  Container,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { toast } from "react-toastify";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";
import "../components/css/style.css";

// components
import Page from "../components/Page";
import { paymentAPI } from "../service/index";
import Utility from "../utils/Utility";

toast.configure();

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 345,
  },
  paper: {
    padding: theme.spacing(2),
  },
  textcolor: {
    color: "#007c7c",
  },
  summaryaccout: {
    borderBottom: "1px solid #efefef",
    padding: 10,
    paddingTop: 0,
    paddingLeft: 0,
    marginBottom: 12,
    width: "auto",
    fontSize: 14,
  },

  amount: {
    fontSize: 12,
    color: "#000",
    textAlign: "right",
    fontWeight: 500,
  },
  amount2: {
    fontSize: 12,
    color: "#000",
    fontWeight: 500,
  },
  margin000: {
    margin: "0 30px",
  },
  infoicons: {
    float: "left",
    width: "15px",
    marginTop: "2px",
    marginRight: "5px",
  },
  buttoncomon: {
    height: "43px",
    margin: "10px 0",
    padding: "0 30px",
    fontSize: "12px",
  },
  buttoncomon33: {
    height: "58px",
    marginBottom: "23px",
    padding: "0 30px",
    fontSize: "12px",
  },
  buttoncomon22: {
    height: "43px",
    margin: "10px 0",
    padding: "0 30px",
    background: "#999",
    fontSize: "12px",
  },
  paperComponent: {
    width: "90%",
    height: "20%",
    margin: "auto",
    boxShadow: "none",
    border: " 1px solid #dfdfdf",
    padding: "39px 0",
  },
  marginalign: {
    padding: "0 15px",
  },
  bordertotal: {
    borderTop: "1px solid #dfdfdf",
    marginTop: " 4px",
    padding: "10px 0",
    borderBottom: "1px solid #dfdfdf",
  },
}));

export default function Checkout(props) {
  const navigate = useNavigate();
  const classes = useStyles();
  const [userMobile, setUserMobile] = useState("");
  const [userDetails, setUserDetails] = useState([]);
  const [isPayment, setIsPayment] = useState(true);
  const [isShow2, setisShow2] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0.0);
  const [gst, setGST] = useState(0.0);
  const [baseAomunt, setBaseAmount] = useState(0.0);
  const [paymentDetail, setPaymentDetail] = useState();
  const [orderId, setOrderId] = useState("");
  const [paymentSuccessData, setPaymentSuccessData] = useState();
  const [showAmountValidation, setShowAmountValidation] = useState(false);
  const [showAmountValidationText, setShowAmountValidationText] = useState("");
  const [userInfo, setUserInfo] = useState(Utility.getLS("userInfo"));

  const { state } = useLocation();

  useEffect(() => {
    getUserDetails();
  }, [state]);

  const validationInfo = Yup.object().shape({
    amounts: Yup.string().required("*Amount is required"),
  });

  const formik = useFormik({
    initialValues: {
      amounts: "",
    },
    validationSchema: validationInfo,
    onSubmit: (data) => {
      //	console.log(data);
    },
  });
  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
  } = formik;

  const getUserDetails = () => {
    //  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    //  console.log("userInfo", userInfo);
    setUserDetails(userInfo);
    setUserMobile(userInfo[0]?.Mobile);
  };
  const amountBreakdown = () => {
    if (formik.values.amounts > 500) {
      setShowAmountValidation(true);
      setShowAmountValidationText("*Can not add amount more then 500");
      return false;
    }
    if (formik.values.amounts < 20) {
      setShowAmountValidation(true);
      setShowAmountValidationText("*Can not add amount less then 20");
      return false;
    }
    if (formik.values.amounts !== "") {
      setShowAmountValidation(false);
      setShowAmountValidationText("");
      setIsPayment(true);
      setGST(((18 / 100) * formik.values.amounts).toFixed(2));
      const n = Number(formik.values.amounts);
      setBaseAmount(n.toFixed(2));
      setTotalAmount(((18 / 100) * formik.values.amounts + n).toFixed(2));
    }
  };

  const cancelPayment = () => {
    navigate("/dashboard/account-summary");
  };

  const amountChange = (event) => {
    if (formik.values.amounts > 20 && formik.values.amounts < 500) {
      setShowAmountValidation(false);
      setShowAmountValidationText("");
    }
    if (formik.values.amounts !== "") {
      setIsPayment(false);
      setGST(((18 / 100) * formik.values.amounts).toFixed(2));
      const n = Number(formik.values.amounts);
      setBaseAmount(n.toFixed(2));
      setTotalAmount(((18 / 100) * formik.values.amounts + n).toFixed(2));
    }
  };

  const pay = (totalAmount, baseAmt) => {
    try {
      //  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      const reqsBody = {
        amount: Number(totalAmount),
        idParent: userInfo[0].idParent,
      };
      paymentAPI.getOrderId(reqsBody).then(
        (resp) => {
          if (resp.status === 200) {
            if (resp.data && resp.data.status === 200) {
              setPaymentDetail(resp.data.data);

              const buff = Buffer.from(resp.data.data, "base64");
              const str = buff.toString("utf8");
              setOrderId(str);
              handlePayment(totalAmount, str, baseAmt);
            }
          }
        },
        (error) => {
          alert(error.response.data.error);
        }
      );
    } catch (error) {
      console.log("Payment error", error);
    }
  };

  const Razorpay = useRazorpay();

  const handlePayment = useCallback(
    (totalAmount, orderId, baseAmt) => {
      //  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      const fName = `${userInfo[0].StudentInfo[0].FirstName} ${userInfo[0].StudentInfo[0].LastName}`;
      const mobNumber = userInfo[0].Mobile;
      let emailAddress = "";

      userInfo[0]?.StudentInfo.forEach((element) => {
        if (element.Email) {
          emailAddress = element.Email;
        }
      });
      if (emailAddress === "") {
        emailAddress = `${mobNumber}@domain.com`;
      }

      const options = {
        //  key: "rzp_test_S1z9Gbgeb1djkL", // QA
        key: "rzp_live_PgOEHSP5o0gynM", // production
        amount: totalAmount * 100,
        currency: "INR",
        name: "XPSOLV",
        description: "Subscription amount",
        image: "/static/logo.svg",
        order_id: orderId,
        handler: (res) => {
          console.log(res);
          if (res) {
            validatePayments(res, orderId, baseAmt);
          }
        },
        prefill: {
          name: fName,
          email: emailAddress,
          contact: mobNumber,
        },
        config: {
          display: {
            blocks: {
              banks: {
                name: "All payment methods",
                instruments: [
                  {
                    method: "upi",
                  },
                  {
                    method: "wallet",
                  },
                ],
              },
            },
            sequence: ["block.banks"],
            preferences: {
              show_default_blocks: false,
            },
          },
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
      };
      const rzpay = new Razorpay(options);

      rzpay.on("payment.failed", (response) => {
        // alert(response.error.code);
        // alert(response.error.description);
        // alert(response.error.source);
        // alert(response.error.step);
        // alert(response.error.reason);
        // alert(response.error.metadata.order_id);
        // alert(response.error.metadata.payment_id);
      });

      rzpay.open();
    },
    [Razorpay]
  );

  const validatePayments = (data, orderId, baseAmt) => {
    try {
      //  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

      const reqsBody = {
        idParent: userInfo[0].idParent,
        Bo: userInfo[0]?.Bo,
        mobilenum: userInfo[0]?.Mobile,
        idStudent: Number(state.idStudentSchool),
        amount: baseAmt,
        "orderId": orderId,
        "pgPaymentId": data.razorpay_payment_id,
        PaySign: data.razorpay_signature,
        idType: state.idPlanMaster,
      };
      paymentAPI.validatePayment(reqsBody).then(
        (resp) => {
          if (resp.status === 200) {
            if (resp.data && resp.data.status === 200) {
              setPaymentSuccessData(resp.data);
              // toast.success(`Payment Successful`,{
              //   position: toast.POSITION.TOP_CENTER
              //   });
              navigate("/dashboard/account-summary");
            }
          }
        },
        (error) => {
          alert(error.response.data.error);
        }
      );
    } catch (error) {
      console.log("Payment error", error);
    }
  };

  return (
    <Page title="Checkout | XPSOLV" className="main-page">
      <Container className="main-Container">
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid container item spacing={2}>
              <Grid item xs={12}>
                <Typography
                  variant="h4"
                  gutterBottom
                  className={classes.summaryaccout}
                >
                  <Box
                    component="h2"
                    display="inline"
                    className="tabheading imgicondefine"
                  >
                    {" "}
                    <img src="/static/icons/bonus-point.png" alt="imgtest" />{" "}
                    Subscription Summary
                  </Box>
                </Typography>
                <Grid item md={12} xs={12}>
                  <Typography
                    variant="h6"
                    gutterBottom
                    className={classes.amount2}
                    style={{ fontWeight: 700 }}
                  >
                    {state.PlanDesc}
                  </Typography>
                </Grid>
              </Grid>

              {isPayment && (
                <Grid container className={classes.marginalign}>
                  <Grid item xs={12}>
                    <Typography
                      variant="h4"
                      gutterBottom
                      className={classes.summaryaccout}
                    >
                      Summ
                      <Box
                        component="span"
                        display="inline"
                        className={classes.textcolor}
                      >
                        ary
                      </Box>
                    </Typography>
                  </Grid>
                  <Grid container item spacing={2}>
                    <Grid item md={3} xs={8}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        className={classes.amount2}
                      >
                        <>Subscription Amount</>
                      </Typography>
                    </Grid>
                    <Grid item md={3} xs={4}>
                      <Typography
                        variant="h4"
                        gutterBottom
                        className={classes.amount}
                      >
                        {Number(state.PlanAmount).toFixed(2)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container item spacing={2}>
                    <Grid item md={3} xs={8}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        className={classes.amount2}
                      >
                        GST (18%)
                      </Typography>
                    </Grid>
                    <Grid item md={3} xs={4}>
                      <Typography
                        variant="h4"
                        gutterBottom
                        className={classes.amount}
                      >
                        {((18 / 100) * state.PlanAmount).toFixed(2)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    item
                    spacing={0}
                    className={classes.bordertotal}
                  >
                    <Grid item md={3} xs={8}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        className={classes.amount2}
                        style={{ fontWeight: 700 }}
                      >
                        Total Amount (Rs.)
                      </Typography>
                    </Grid>
                    <Grid item md={3} xs={4}>
                      <Typography
                        variant="h4"
                        gutterBottom
                        className={classes.amount}
                        style={{ fontWeight: 700 }}
                      >
                        {(
                          (18 / 100) * state.PlanAmount +
                          Number(state.PlanAmount)
                        ).toFixed(2)}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container item spacing={2}>
                    <Grid item md={2} sm={2} xs={6}>
                      <Button
                        variant="outline"
                        className={` cancel_btn ${classes.buttoncomon}`}
                        onClick={() => cancelPayment()}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item md={2} sm={2} xs={6}>
                      <Button
                        variant="contained"
                        className={` submit_btn ${classes.buttoncomon}`}
                        onClick={() =>
                          pay(
                            (
                              (18 / 100) * state.PlanAmount +
                              Number(state.PlanAmount)
                            ).toFixed(2),
                            state.PlanAmount
                          )
                        }
                      >
                        Pay
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Form>
        </FormikProvider>
      </Container>
    </Page>
  );
}
