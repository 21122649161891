/* eslint-disable object-shorthand */
import fileTextFill from '@iconify/icons-eva/file-text-fill';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Icon } from '@iconify/react';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
// import { useForm } from "react-hook-form";
// import { yupResolver } from '@hookform/resolvers';
// material
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tooltip,
  Typography,
  Radio,
  FormControl,
  FormLabel,
  RadioGroup
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { makeStyles, useTheme } from '@material-ui/styles';
import { Form, FormikProvider, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { toast } from 'react-toastify';
// Import toastify css file
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';
import Loader from '../components/Loader';
// components
import Page from '../components/Page';
import { TestApi } from '../service/index';
import Utility from '../utils/Utility';

toast.configure();

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  borderchangetext: {
    marginTop: 0,
    fontSize: 14,
    '& label.Mui-focused': {
      color: 'white'
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'yellow'
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white'
      },
      '&:hover fieldset': {
        borderColor: 'white'
      },
      '&.Mui-focused fieldset': {
        borderColor: 'yellow'
      }
    }
  },
  cardborder: {
    padding: 25
  },
  colorunselect: {
    background: '#fff',
    boxShadow: 'none',
    minWidth: '27px',
    width: '7px',
    height: '27px',
    borderRadius: '83px',
    padding: '14px',
    '&:hover': {
      background: 'transparent'
    }
  },
  marginleftright: {
    marginRight: '13px',
    width: '12px',
    height: '27px',
    borderRadius: '110px',
    padding: '0',
    minWidth: '27px',
    background: '#fff',
    boxShadow: 'none',
    '&:hover': {
      background: 'transparent'
    }
  },
  label: {
    fontSize: 13,
    marginTop: 12
  },
  colorred: {
    color: 'red',
    fontSize: 13
  },
  buttonspacing: {
    float: 'right',
    marginTop: '10px'
  },
  buttonspacing1: {
    float: 'right',
    marginTop: '10px',
    marginLeft: '10px'
  },
  btntext: {
    fontWeight: 500,
    width: '100%',
    marginTop: 23,
    color: '#fff',
    background: '#007c7c',
    '&:hover': {
      background: '#007c7c'
    }
  },

  headingpart: {
    color: '#007c7c'
  },
  themecode: {
    fontWeight: 600,
    color: '#000',
    fontSize: 16,
    textAlign: 'center',
    borderBottom: '1px solid #e1e1e1',
    paddingBottom: 15,
    marginBottom: 14
  },
  borderttep: {
    borderBottom: '1px solid #d9d9d9',
    paddingBottom: 18,
    marginBottom: 10
  },
  imgplan: {
    width: '90%',
    margin: '92px auto',

    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },
  testdialog: {
    textAlign: 'center',
    padding: '20px 0'
  },
  imgplan3: {
    width: '250px',
    margin: 'auto',
    position: 'relative'
  },
  imgpdf: {
    border: '1px solid #e3e3e3',
    position: 'relative',
    padding: 20,
    borderRadius: '8px',
    width: '400px',
    margin: '28px auto'
  },
  pdfdownload: {
    borderRadius: '4px',
    margin: 'auto',
    display: 'block',
    marginTop: 17,
    fontWeight: 600,
    padding: '11px 17px',
    background: '#007c7c',
    '&:hover': {
      background: '#007c7c'
    }
  },
  sendemail: {
    borderRadius: '4px',
    margin: 'auto',
    display: 'block',
    marginTop: 17,
    fontWeight: 600,
    padding: '14px 15px',
    background: 'transparent',
    border: '1px solid #007c7c',
    color: '#007c7c',
    boxShadow: 'none'
  },
  testgenrate: {
    padding: '0px 11px',
    color: '#000',
    fontWeight: 600,
    marginTop: 20,
    textAlign: 'center',
    fontSize: 19
  },

  textpdf: {
    textAlign: 'center',
    marginTop: 25
  },
  borderbtm: {
    paddingBottom: 0
  },
  bgtextbtn: {
    background: '#007c7c'
  },
  closebtn: {
    position: 'absolute',
    right: 0
  },
  closebtnfont: {
    fontSize: 25
  },
  mainsecrpadd: {
    paddingRight: 0,
    [theme.breakpoints.up('sm')]: {
      paddingRight: 15
    }
  },
  mainsecrpadd2: {
    paddingRight: 0,
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 15
    }
  },

  checkboxfont: {
    fontSize: '14px !important '
  },
  borderbotmmain: {
    borderBottom: '1px solid #dcdcdc',
    paddingBottom: 12
  },
  infoicons: {
    float: 'left',
    width: '15px',
    marginTop: '2px',
    marginRight: '5px'
  },
  infotext: {
    fontSize: '12px',
    marginTop: '9px',
    marginBottom: '0',
    fontWeight: '400'
  },
  boldfont: {
    fontWeight: 600
  },
  alertalignment: {
    marginTop: 15
  }
}));

export default function PlanTest() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [showLoder, setShowLoader] = useState(false);
  const { state } = useLocation();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState('');
  const [showUser, setShowUser] = useState({
    firstName: '',
    lastName: '',
    className: '',
    board: ''
  });
  const [chapterList, setChapterList] = useState([]);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState('sm');
  const [initialize, setInitialize] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [downloadId, setDownloadID] = useState('');
  const [chapters, setChapters] = useState([]);
  const [chaptersIds, setChaptersIds] = useState([]);
  const [selectedStep, setSelectedStep] = useState(0);
  const [isTestPrepare, setIsTestPrepare] = useState(true);
  const [checked, setChecked] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isChapterRequired, setIsChapterRequired] = useState(false);
  const [isTeacher, setIsTeacher] = useState(false);
  const [followUp, setFollowUp] = useState(true);
  const multiselectRef = React.createRef();
  const checkboxRef = React.createRef();
  const [classess, setClassess] = useState([]);
  const [idClass, setIdClass] = useState();
  const [hasSubChapter, setHasSubChapter] = useState(false);
  const [subjectInfo, setSubjectInfo] = useState([]);
  const [subjectListInfo, setSubjectListInfo] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [PFormatInfo, setPFormatInfo] = useState([]);
  const [idParent, setIdParent] = useState(0);
  const [userInfo, setUserInfo] = useState(Utility.getLS('userInfo'));
  const [topic, setTopic] = useState([]);
  const [topicsIds, setTopicsIds] = useState([]);

  useEffect(() => {
    if (state) {
      console.log(userInfo);
      console.log(state);
      if (Number(userInfo[0]?.idRole > 1)) {
        state.idStudentSchool = userInfo[0].Ins_idStudentSchool;
      }
      //	const userInfo = JSON.parse(localStorage.getItem("userInfo"));

      const idParent = userInfo[0]?.idParent;
      setIdParent(idParent);
      setSelectedUser(state);
      initializeTestByStudentIdAndClassId(state?.idSchool, state?.idClass, idParent);
    }
  }, []);

  useEffect(() => {
    setTopic([]);
    setTopicsIds([]);
  }, [chaptersIds, chapterList]);

  const handleClose = () => {
    setOpen(false);
  };
  const validationRules = {
    testSubject: Yup.string().required('Subject is required'),
    format: Yup.string().required('Format is required')
  };

  const TestSchema = Yup.object().shape(validationRules);
  const formik = useFormik({
    initialValues: {
      testSubject: '',
      testSubSubject: '',
      chapterIds: '',
      format: '',
      class: '',
      complexity: 0,
      classification: 0
    },
    validationSchema: TestSchema,
    onSubmit: (data) => {
      if (chaptersIds.length < 1) {
        setIsChapterRequired(true);
        formik.setSubmitting(false);
        return false;
      }
      createTest(data);
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const initializeTestByStudentIdAndClassId = (idSchool, idClass, idStudentSchool) => {
    try {
      setShowLoader(true);
      const reqsBody = {
        idParent: idParent > 0 ? idParent : idStudentSchool,
        idClass: idClass,
        idStudentSchool: idStudentSchool,
        testHWCall: 1 // added to filter class, subjects, and chapters with no data
      };
      TestApi.initializeTestByStudentIdAndClassId(reqsBody).then(
        (resp) => {
          setShowLoader(false);
          if (resp.status === 200) {
            if (resp.data.status === 200) {
              setInitialize(resp.data.data[0]);
              setFollowUp(true);
            } else {
              alert(resp.data.message);
            }
          } else {
            alert(resp.message);
          }
        },
        (error) => {
          alert('Error in initializeTestByStudentIdAndClassId', error.message);
        }
      );
    } catch (error) {
      console.log('initializeTestByStudentIdAndClassId Catch', error);
    }
  };

  const getChckeboxValue = (idChapter) => {
    console.log('event.target', idChapter, chaptersIds);
    const chapterArray = [...chaptersIds];

    const index = chapterArray.indexOf(idChapter);

    if (index > -1) {
      chapterArray.splice(index, 1);
    } else {
      chapterArray.push(idChapter);
    }
    setChaptersIds(chapterArray);
    if (chapterArray.length < 1) {
      setIsChapterRequired(true);
    } else {
      setIsChapterRequired(false);
    }

    setErrorMessage('');
  };

  const getTopicChckeboxValue = (idChapter) => {
    console.log('event.target', idChapter, topicsIds);
    const chapterArray = [...topicsIds];

    const index = chapterArray.indexOf(idChapter);

    if (index > -1) {
      chapterArray.splice(index, 1);
    } else {
      chapterArray.push(idChapter);
    }
    setTopicsIds(chapterArray);
    if (chapterArray.length < 1) {
      setIsChapterRequired(true);
    } else {
      setIsChapterRequired(false);
    }

    setErrorMessage('');
  };
  const createTest = (data) => {
    try {
      //	const userInfo = JSON.parse(localStorage.getItem("userInfo"));
      setShowLoader(true);
      const reqsBody = {
        idParent: userInfo[0].idParent,
        Bo: userInfo[0]?.Bo,
        idPattern: data.format,
        idClassSubject: data.testSubject,
        chapterids: chaptersIds.toString(),
        idStudentSchool: selectedUser.idStudentSchool,
        idDifficulty: data.complexity || 0,
        idClassify: data.classification || 0,
        topicids: topicsIds.length > 0 ? topicsIds.toString() : 0
      };
      TestApi.orderTest(reqsBody).then(
        (resp) => {
          if (resp.status === 200) {
            // if (resp.data.status === 200) {
            // toast.success('Preparing Test Paper');
            setIsTestPrepare(true);
            // setSelectedStep(1);
            // setDownloadID(resp.data.data);

            // preparingOrderTest(resp.data.data);
            // setSelectedStep(2);
            // setSelectedStep(3);
            setIsTestPrepare(false);
            setShowLoader(false);
            resp.data.selectedUser = selectedUser;
            resp.data.classId = idClass;
            navigate(Utility.getPath('/view-question'), {
              replace: true,
              state: resp.data
            });
            /* }
						else {
							setShowLoader(false);
							setErrorMessage(resp.data.message);
						} */
          } else {
            setShowLoader(false);
            setErrorMessage(resp.message);
          }
        },
        (error) => {
          setShowLoader(false);
          setErrorMessage('Question for the criteria are not available.');
        }
      );
      formik.setSubmitting(false);
    } catch (error) {
      console.log('create test Catch', error);
      setErrorMessage();
    }
  };

  const preparingOrderTest = (testId) => {
    try {
      setShowLoader(true);
      if (testId) {
        TestApi.preparingOrderTest(testId).then(
          (resp) => {
            if (resp.status === 200) {
              // Enable generate PDF
              setSelectedStep(2);
              setSelectedStep(3);
              setIsTestPrepare(false);
              setShowLoader(false);
              toast.success('Test generated successfully');
            } else {
              setShowLoader(false);
              setErrorMessage(resp.message);
            }
          },
          (error) => {
            setShowLoader(false);
            setErrorMessage('Error in preparing order test');
          }
        );
      }
    } catch (error) {
      setErrorMessage('Error in preparing order test Catch');
      console.log('Error in preparing order test Catch', error);
    }
  };
  const selectAll = (event) => {
    setChaptersIds([]);
    setTopicsIds([]);
    const chapterArray = [];
    if (chapterList) {
      chapterList.forEach((item) => {
        if (item?.subChapter && formik.getFieldProps('testSubSubject')?.value !== 'ALL') {
          if (formik.getFieldProps('testSubSubject')?.value === item?.subChapter) {
            chapterArray.push(item.idChapter);
          }
        } else {
          chapterArray.push(item.idChapter);
        }
      });
    }

    setChaptersIds(chapterArray);
    setIsChapterRequired(false);
  };

  const unselectAll = (event) => {
    setChaptersIds([]);
    setTopicsIds([]);
    setIsChapterRequired(true);
  };

  const unselectPrefrence = (event) => {
    setTopicsIds([]);
    formik.setFieldValue('complexity', '0');
    formik.setFieldValue('classification', '0');
  };

  const handleChange = (event) => {
    console.log('event', event.target.value);
    setErrorMessage('');
    if (event.target.value === '') {
      setSubjectListInfo([]);
      setSubjectInfo([]);
      setHasSubChapter(false);
      setChapterList([]);
      setPFormatInfo([]);
      formik.setFieldValue('testSubject', event.target.value);
    }
    console.log('initialize', initialize);
    console.log('chapterlist', chapterList);
    if (initialize) {
      // eslint-disable-next-line array-callback-return
      initialize?.ClassSubjectInfo?.map((item) => {
        console.log('item', item);
        if (item.idClassSubject === event.target.value) {
          setChaptersIds([]);
          setChapters([]);
          setSubjectListInfo(item.SubjectInfo);
          setSubjectInfo(item.SubjectInfo);
          setPFormatInfo(item.PFormatInfo);
          if (item?.SubjectInfo.length > 1) {
            setHasSubChapter(true);
            formik.setFieldValue('testSubSubject', 'ALL');

            const tempChapterInfo = [];
            // eslint-disable-next-line array-callback-return
            item?.SubjectInfo?.map((subjectObj) => {
              if (subjectObj?.ChapterInfo && subjectObj?.ChapterInfo.length > 0) {
                subjectObj?.ChapterInfo?.forEach((chapterItem) => {
                  chapterItem.subChapter = subjectObj.SubChapter;
                  tempChapterInfo.push(chapterItem);
                });
              }
            });

            if (tempChapterInfo && tempChapterInfo.length > 0) {
              setChapterList(tempChapterInfo);
            }
          } else {
            setHasSubChapter(false);
            setChapterList(item?.SubjectInfo[0]?.ChapterInfo);
          }
          //  multiselectRef.current.resetSelectedValues();0
          formik.setFieldValue('testSubject', event.target.value);
        }
      });
    }
  };
  const handleChangeSubSubject = (event) => {
    console.log('event', event.target.value);
    setErrorMessage('');
    if (event.target.value === '') {
      setChapterList([]);
      formik.setFieldValue('testSubSubject', event.target.value);
    }
    console.log('initialize', subjectListInfo);
    console.log('chapterlist', chapterList);
    if (subjectListInfo && event.target.value.trim() !== 'ALL') {
      // eslint-disable-next-line array-callback-return
      subjectListInfo?.map((item) => {
        console.log('item', item);
        if (item.SubChapter.trim() === event.target.value.trim()) {
          setChaptersIds([]);
          setTopicsIds([]);
          setSubjectInfo([item]);
          setChapters(item.ChapterInfo);
        }
      });
    } else {
      setChaptersIds([]);
      setTopicsIds([]);
      setSubjectInfo(subjectListInfo);
    }
    formik.setFieldValue('testSubSubject', event.target.value);
  };
  const handleClassChange = (event) => {
    setErrorMessage('');
    console.log('event', event.target.value);
    if (event.target.value === '') {
      setChapterList([]);
      setChaptersIds([]);
      setInitialize([]);
      setSubjectListInfo([]);
      setSubjectInfo([]);
      setHasSubChapter(false);
      setFollowUp(false);
      formik.setFieldValue('class', event.target.value);
    } else {
      initializeTestByStudentIdAndClassId(
        state?.idSchool,
        event.target.value,
        state?.idStudentSchool
      );
      formik.setFieldValue('class', event.target.value);
      setIdClass(event.target.value);
    }
  };

  const gettopic = () => {
    setShowLoader(true);
    const reqInfo = {
      idParent: userInfo[0].idParent,
      chapterids: chaptersIds.toString()
    };

    const response = Utility.callPostAPI('gettopic', reqInfo);
    response.then((data) => {
      setShowLoader(false);
      if (data.data?.data) {
        setTopic(data.data?.data);
      }
    });
  };

  return (
    <Page title="Plan Test | XPSOLV">
      <Container className="main-bg">
        <Grid container>
          <Grid item lg={6} md={6} sm={6} xs={6}>
            <Button
              variant="contained"
              component={RouterLink}
              to={Utility.getPath('/app')}
              className="backbuttonbg"
            >
              {' '}
              <ArrowBackIosIcon className="newmargin" />
              Profile
            </Button>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={6} className="hist_div">
            <Button
              variant="contained"
              component={RouterLink}
              to={Utility.getPath('/test-history')}
              className="submit_btn hist_btn"
              startIcon={<Icon icon={fileTextFill} />}
            >
              Test History
            </Button>
          </Grid>

          <Grid item lg={12} md={12} sm={12} xs={12} className="padding_plantest">
            <Box component="h2" display="inline" className="tabheading imgicondefine">
              <img src="/static/cutoff-range.png" alt="imgtest" />
              Plan Test
            </Box>
          </Grid>
        </Grid>

        <Grid className="student_bg_folow no_padding_set" container>
          <Grid item className={`${classes.cardborder} `} lg={12} md={12} sm={12} xs={12}>
            <FormikProvider value={formik}>
              <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={2} justifyContent="space-between">
                  <InputLabel id="demo-simple-select-filled-label" className={classes.themecode}>
                    {selectedUser?.FirstName}&nbsp;{selectedUser?.LastName}
                    &nbsp;
                    <Box component="span" display="inline" className={classes.headingpart}>
                      {' '}
                      {selectedUser?.ClassName}
                    </Box>
                  </InputLabel>
                </Stack>
                <Grid container>
                  {followUp && (
                    <>
                      <Grid item lg={6} md={6} sm={12} xs={12} className={classes.mainsecrpadd}>
                        <Stack justifyContent="space-between">
                          <InputLabel
                            id="demo-simple-select-filled-label"
                            className={classes.label}
                          >
                            Subject
                          </InputLabel>
                          <Select
                            className={classes.borderchangetext}
                            fullWidth
                            labelId="demo-simple-select-filled-label"
                            id="demo-simple-select-filled"
                            label="Subject"
                            {...getFieldProps('testSubject')}
                            onChange={handleChange}
                            //	onClick={(e) => { handleClose(e) }}
                            error={touched.testSubject && errors.testSubject}
                            helpertext={touched.testSubject && errors.testSubject}
                          >
                            <MenuItem value="">
                              <em className={classes.borderchangetext}>Select Subject</em>
                            </MenuItem>
                            {initialize?.ClassSubjectInfo?.map((option, index) => (
                              <MenuItem
                                key={index}
                                value={option.idClassSubject}
                                className={classes.borderchangetext}
                              >
                                {option.SubjectName}
                              </MenuItem>
                            ))}
                          </Select>
                          {touched.testSubject && errors.testSubject && (
                            <p className={classes.colorred}>{errors.testSubject}</p>
                          )}
                        </Stack>
                      </Grid>
                    </>
                  )}
                  {followUp && (
                    <>
                      <Grid item lg={6} md={6} sm={12} xs={12} className={classes.mainsecrpadd}>
                        <Stack justifyContent="space-between">
                          <InputLabel
                            id="demo-simple-select-filled-label"
                            className={classes.label}
                          >
                            Test Format
                          </InputLabel>
                          <Select
                            fullWidth
                            className={classes.borderchangetext}
                            labelId="demo-simple-select-filled-label"
                            id="demo-simple-select-filled"
                            label="Format"
                            {...getFieldProps('format')}
                            error={touched.format && errors.format}
                            helpertext={touched.format && errors.format}
                          >
                            <MenuItem value="">
                              <em className={classes.borderchangetext}>Select Format</em>
                            </MenuItem>
                            {PFormatInfo?.map((option, index) => (
                              <MenuItem
                                key={index}
                                value={option.idPattern}
                                className={classes.borderchangetext}
                              >
                                {option.PFormat}
                              </MenuItem>
                            ))}
                          </Select>
                          {touched.format && errors.format && (
                            <p className={classes.colorred}>{errors.format}</p>
                          )}
                        </Stack>
                      </Grid>
                    </>
                  )}
                </Grid>
                {followUp && hasSubChapter && (
                  <>
                    <Stack spacing={2} justifyContent="space-between">
                      <InputLabel id="demo-simple-select-filled-label" className={classes.label}>
                        Sub-Subject
                      </InputLabel>
                      <Select
                        className={classes.borderchangetext}
                        fullWidth
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        label="Sub-Subject"
                        {...getFieldProps('testSubSubject')}
                        onChange={handleChangeSubSubject}
                        //	onClick={(e) => { handleClose(e) }}
                        error={touched.testSubSubject && errors.testSubSubject}
                        helpertext={touched.testSubSubject && errors.testSubSubject}
                      >
                        <MenuItem value="ALL">
                          <em className={classes.borderchangetext}>All</em>
                        </MenuItem>
                        {subjectListInfo?.map((option, index) => (
                          <MenuItem
                            key={index}
                            value={option.SubChapter}
                            className={classes.borderchangetext}
                          >
                            {option.SubChapter}
                          </MenuItem>
                        ))}
                      </Select>
                      {touched.testSubSubject && errors.testSubSubject && (
                        <p className={classes.colorred}>{errors.testSubSubject}</p>
                      )}
                    </Stack>
                  </>
                )}
                {followUp && (
                  <>
                    <Stack spacing={2} justifyContent="space-between" className={classes.borderbotmmain}>
                      {chapterList.length > 0 && values.testSubject && (
                        <Grid container>
                          <Grid item xs={6} md={6}>
                            <InputLabel
                              id="demo-simple-select-filled-label"
                              className={classes.label}
                            >
                              Chapter
                            </InputLabel>
                          </Grid>
                          <Grid item xs={6} md={6}>
                            <div className={classes.buttonspacing}>
                              <Tooltip title="Select All" arrow>
                                <Button
                                  variant="contained"
                                  onClick={(event) => {
                                    selectAll(event);
                                  }}
                                  className={classes.marginleftright}
                                >
                                  <img
                                    src="/static/mock-images/avatars/task.png"
                                    alt="iconback"
                                    style={{
                                      width: '23px',
                                      height: '23px',
                                      margin: 0,
                                      marginLeft: 0
                                    }}
                                  />
                                </Button>
                              </Tooltip>
                              <Tooltip title="Unselect All" arrow>
                                <Button
                                  variant="contained"
                                  onClick={(event) => {
                                    unselectAll(event);
                                  }}
                                  className={classes.colorunselect}
                                >
                                  <img
                                    src="/static/mock-images/avatars/x-mark.png"
                                    alt="iconback"
                                    style={{
                                      minWidth: '19px',
                                      height: '19px',
                                      margin: 0,
                                      marginLeft: 0
                                    }}
                                  />
                                </Button>
                              </Tooltip>
                            </div>
                          </Grid>
                        </Grid>
                      )}
                      {hasSubChapter &&
                        subjectInfo?.map((subjectOption, index) => (
                          <>
                            <Stack key={index}>
                              <Grid item md={12} className={classes.borderbotmmain}>
                                <InputLabel
                                  id="demo-simple-select-filled-label"
                                  className={classes.label}
                                >
                                  {subjectOption?.SubChapter}
                                </InputLabel>
                              </Grid>
                              <Grid container>
                                {subjectOption?.ChapterInfo?.map((option, i) => (
                                  <Grid key={i} item xs={12} md={6}>
                                    <FormGroup>
                                      <FormControlLabel
                                        color="success"
                                        error={touched.chapterList && errors.chapterList}
                                        helpertext={touched.chapterList && errors.chapterList}
                                        name="chapterList"
                                        control={
                                          <Checkbox
                                            id={option.Chapter}
                                            error={touched.chapterList && errors.chapterList}
                                            helpertext={touched.chapterList && errors.chapterList}
                                            name="chapterList"
                                            checked={chaptersIds.indexOf(option.idChapter) >= 0}
                                            onChange={() => getChckeboxValue(option.idChapter)}
                                          />
                                        }
                                        label={
                                          <Typography className={classes.checkboxfont}>
                                            {option.Chapter}
                                          </Typography>
                                        }
                                      />
                                    </FormGroup>
                                  </Grid>
                                ))}
                              </Grid>
                            </Stack>
                          </>
                        ))}
                      {followUp && !hasSubChapter && (
                        <>
                          <Grid container>
                            {chapterList?.map((option, index) => (
                              <Grid key={index} item xs={12} md={6}>
                                <FormGroup>
                                  <FormControlLabel
                                    color="success"
                                    error={touched.chapterList && errors.chapterList}
                                    helpertext={touched.chapterList && errors.chapterList}
                                    name="chapterList"
                                    control={
                                      <Checkbox
                                        id={option.Chapter}
                                        error={touched.chapterList && errors.chapterList}
                                        helpertext={touched.chapterList && errors.chapterList}
                                        name="chapterList"
                                        checked={chaptersIds.indexOf(option.idChapter) >= 0}
                                        onChange={() => getChckeboxValue(option.idChapter)}
                                      />
                                    }
                                    label={
                                      <Typography className={classes.checkboxfont}>
                                        {option.Chapter}
                                      </Typography>
                                    }
                                  />
                                </FormGroup>
                              </Grid>
                            ))}
                          </Grid>
                        </>
                      )}
                      {isChapterRequired ? (
                        <p className={classes.colorred}>Chapter is required</p>
                      ) : (
                        ''
                      )}
                    </Stack>{' '}
                  </>
                )}

                <Grid container spacing={2}>
                  <Grid item xs={12} xl={12} sm={12} md={12}>
                    <Accordion fullWidth className="accordion">
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        className="preferences"
                      >
                        Preferences
                      </AccordionSummary>
                      <AccordionDetails>
                        <Stack spacing={2} justifyContent="space-between">
                          <Grid container className={classes.borderbotmmain}>
                            <Grid item xs={10} md={10}>
                              <FormControl>
                                <FormLabel
                                  id="demo-radio-buttons-group-label"
                                  sx={{ fontSize: '12px' }}
                                >
                                  Complexity
                                </FormLabel>
                                <RadioGroup
                                  row
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="complexity"
                                  className="f-12"
                                  {...getFieldProps('complexity')}
                                
                                  error={Boolean(touched.complexity && errors.complexity)}
                                  helperText={touched.complexity && errors.complexity}
                                >
                                  <FormControlLabel value="1" control={<Radio />}  className="option_radiobtn" label="Basic" />
                                  <FormControlLabel value="2" control={<Radio />} className="option_radiobtn" label="Advance" />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                            <Grid item xs={2} md={2}>
                              <div className={classes.buttonspacing}>
                                <Tooltip title="Unselect All" arrow>
                                  <Button
                                    variant="contained"
                                    onClick={(event) => {
                                      unselectPrefrence(event);
                                    }}
                                    className={classes.colorunselect}
                                  >
                                    <img
                                      src="/static/mock-images/avatars/x-mark.png"
                                      alt="iconback"
                                      style={{
                                        minWidth: '19px',
                                        height: '19px',
                                        margin: 0,
                                        marginLeft: 0
                                      }}
                                    />
                                  </Button>
                                </Tooltip>
                              </div>
                            </Grid>
                            <Grid item xs={12} md={12}>
                              <FormControl>
                                <FormLabel
                                  id="demo-radio-buttons-group-label"
                                  sx={{ fontSize: '12px' }}
                                >
                                  Classification
                                </FormLabel>
                                <RadioGroup
                                  row
                                  aria-labelledby="demo-radio-buttons-group-label"
                                  name="classification"
                                  {...getFieldProps('classification')}
                                  error={Boolean(touched.classification && errors.classification)}
                                  helperText={touched.classification && errors.classification}
                                >
                                  <FormControlLabel value="3" control={<Radio />} className="option_radiobtn" label="Theroy" />
                                  <FormControlLabel
                                    value="1"
                                    control={<Radio />}
                                    className="option_radiobtn"
                                    label="Scenario"
                                  />
                                  <FormControlLabel
                                    value="2"
                                    className="option_radiobtn"
                                    control={<Radio />}
                                    label="Numerical"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </Grid>
                          </Grid>
                        </Stack>

                        <Stack spacing={2} justifyContent="space-between">
                          <Grid container>
                            <Grid item xs={12} md={12}>
                              <InputLabel
                                id="demo-simple-select-filled-label"
                                className={classes.label}
                              >
                                <div>
                                  <Tooltip title="Get Topic" arrow>
                                    <Button variant="contained" onClick={gettopic}>
                                      Get Topic
                                    </Button>
                                  </Tooltip>
                                </div>
                              </InputLabel>
                            </Grid>
                          </Grid>
                        </Stack>
                        {topic && topic.length > 0 && (
                          <Stack spacing={2} justifyContent="space-between">
                            <Grid container>
                              {topic?.map((topicOption, topicindex) => (
                                <>
                                  <Grid item md={12} className={classes.borderbotmmain}>
                                    <InputLabel
                                      id="demo-simple-select-filled-label"
                                      className={classes.label}
                                    >
                                      {topicOption?.ChapterName}
                                    </InputLabel>
                                  </Grid>
                                  <Grid container>
                                    {topicOption?.TopicInfo?.map((topicInfo, ind) => (
                                      <Grid key={ind} item xs={12} md={6}>
                                        <FormGroup>
                                          <FormControlLabel
                                            color="success"
                                            error={touched.topicList && errors.topicList}
                                            helpertext={touched.topicList && errors.topicList}
                                            name="topicList"
                                            control={
                                              <Checkbox
                                                id={topicInfo.idTopic}
                                                error={touched.topicList && errors.topicList}
                                                helpertext={touched.topicList && errors.topicList}
                                                name="topicList"
                                                checked={topicsIds.indexOf(topicInfo.idTopic) >= 0}
                                                onChange={() =>
                                                  getTopicChckeboxValue(topicInfo.idTopic)
                                                }
                                              />
                                            }
                                            label={
                                              <Typography className={classes.checkboxfont}>
                                                {topicInfo.Topic}
                                              </Typography>
                                            }
                                          />
                                        </FormGroup>
                                      </Grid>
                                    ))}
                                  </Grid>
                                </>
                              ))}
                            </Grid>
                          </Stack>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                </Grid>

                {errorMessage && (
                  <Alert severity="error" className={classes.alertalignment}>
                    <AlertTitle>Error</AlertTitle>
                    {errorMessage}
                  </Alert>
                )}

                <Grid className="center-container ">
                  <LoadingButton
                    spacing={2}
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                    className="submit_btn bnt_pading_views box_shadow_none"
                  >
                    Generate Test
                  </LoadingButton>
                </Grid>
              </Form>
            </FormikProvider>
          </Grid>
        </Grid>
      </Container>
      <Loader isLoader={showLoder} showHide={setShowLoader} />
    </Page>
  );
}
