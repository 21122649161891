import { Link as RouterLink, useNavigate } from "react-router-dom";
// material
import {
  Box,
  Card,
  Container,
  Divider,
  Link,
  Typography,
  Button,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
// layouts
// components
import Utility from "../utils/Utility";
import Page from "../components/Page";
import {
  RegInstituteForm,
  AddressForm,
} from "../components/authentication/register";
import { AuthApi } from "../service/index";
import HSeparator from "../theme/Separator";

import "../components/css/style.css";
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
  stylebg: {
    background: "#000",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "50vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(3, 0),
}));

// ----------------------------------------------------------------------

export default function RegisterInstitute() {
  const [isParentInfo, setIsParentInfo] = useState(1);
  const [classStateInfo, setClassStateInfo] = useState([]);
  const [instituteInfo, setInstituteInfo] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    getclassdist();
  }, []);

  useEffect(() => {
    if (localStorage.getItem('token') && localStorage.getItem('userInfo')) {
      const userInfo = Utility.getLS("userInfo");
      if(Number(userInfo[0].idRole) > 1)
      {
         navigate('/institute/app', { replace: true });  
      }
      else{
         navigate('/dashboard/app', { replace: true });  
      }
      
    }
  }, []);

  const instituteVerify = (data) => {
    setInstituteInfo(data);
    setIsParentInfo(2);
  };

  const instituteReg = (data) => {
    const regInfo = {
      idParent: 1,
      Mobilenum: instituteInfo.mobilenum,
      ParentFirstName: Utility.toTitleCase(instituteInfo.institute),
      ParentLastName: Utility.toTitleCase(data.address),
      idPincode: data.idPincode,
      StudentFirstName: Utility.toTitleCase(instituteInfo.firstName),
      StudentLastName: Utility.toTitleCase(instituteInfo.lastName),
      Gender: "N",
      idClass: 0,
      idSchool: 0,
      idRole: instituteInfo.instituteType,
    };

    //  console.log(regInfo)
    register(regInfo);
  };

  const masterInfo = (data) => {
    setClassStateInfo(data);
  };

  const register = (reqBody) => {
    try {
      AuthApi.registerParent(reqBody).then(
        (resp) => {
          if (resp.status === 200) {
            // setIsParentInfo(2);
            if (resp.data.data) {
              navigate("/success-institute");
            } else {
              toast.warning(resp.data.message);
            }
          }
        },
        (error) => {
          toast.warning("Error in Register");
        }
      );
    } catch (error) {
      console.log("Register Catch", error);
    }
  };

  const getclassdist = () => {
    const data = {
      idParent: "1",
      idRole:1,
    };
    AuthApi.getclassdist(data).then((resp) => {
      if (resp.data.status === 200) {
        setClassStateInfo(resp.data.data);
        masterInfo(resp.data.data);
      } else {
        setClassStateInfo([]);
      }
    });
  };

  const handleBack = () => {
    setIsParentInfo((prevIsParentInfo) => prevIsParentInfo - 1);
  };

  return (
    <div className='main-bgnew auth_pages register_auth' >
    <RootStyle title="Register | XPSOLV">
   

      <Container maxWidth="sm"  className='bg_white_reg'>
        <ContentStyle>
          <div>
            <Box>
              <Typography variant="h5" gutterBottom>
                Registration
              </Typography>
            </Box>
          </div>

          <div
            className="regbg"
            style={{ display: isParentInfo === 1 ? "block" : "none" }}
          >
            <RegInstituteForm instituteVerify={instituteVerify} />
          </div>

          <div
            className="regbg"
            style={{ display: isParentInfo === 2 ? "block" : "none" }}
          >
            <Typography>
              <Button onClick={handleBack}>
                <ArrowBackIcon />
                Back
              </Button>
            </Typography>

            <AddressForm
              classStateInfo={classStateInfo}
              instituteReg={instituteReg}
            />
          </div>
          <div className="termstext">
            <Typography
              variant="caption"
              align="center"
              sx={{ color: "text.secondary", mt: 3 }}

            >
              By registering, I agree to Minimal&nbsp;
              <Link underline="always" sx={{ color: "text.primary" }}>
                Terms of Service
              </Link>
              &nbsp;and&nbsp;
              <Link underline="always" sx={{ color: "text.primary" }}>
                Privacy Policy
              </Link>
              .
            </Typography>

            <Typography variant="subtitle2" sx={{ mt: 3, textAlign: "center"}}>
              Already have an account?&nbsp;
              <Link to="/login" component={RouterLink}>
                Login
              </Link>
            </Typography>
          </div>
        </ContentStyle>
      </Container>
    </RootStyle>
    </div>
  );
}
