import { Form, FormikProvider, useFormik } from "formik";
import fuzzysort from "fuzzysort";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
// material
import {
  Autocomplete,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  ListItem,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography
} from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";

import { toast } from "react-toastify";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

// ----------------------------------------------------------------------
import { AuthApi } from "../../../service/index";

toast.configure();

export default function RegisterPreForm({ addressVerify, classStateInfo }) {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState();
  const [locationType, setLocationType] = useState("pincode");
  const [isSubmit, setIsSumbit] = useState(false);
  const [pincode, setPincode] = useState("");
  const [pincodeInfo, setPincodeInfo] = useState([]);
  const [pincodeList, setPincodeList] = useState([]);
  const [isParentInfo, setIsParentInfo] = useState(1);
  const [parentInfo, setParentInfo] = useState({});

  

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Parent first name required"),
    lastName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Parent last name required"),
    pincode: Yup.string()
      .min(6, "Too Short!")
      .max(8, "Too Long!")
      .required("Pincode is required"),
    postoffice: Yup.object().required("Post office is required"),
  });

  const RegisterSchemaAddress = Yup.object().shape({
    firstName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Parent first name required"),
    lastName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Parent last name required"),
    state: Yup.object().required("State is required"),
  //  pincodeData: Yup.object().required("Pincode is required"),
    district: Yup.object().required("District is required"),
    postoffice: Yup.object().nullable().required("Location is required"),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      pincode: "",
      state: "",
      district: "",
      pincodeData: "",
      postoffice: "",
    },
    validationSchema: locationType === "address" ? RegisterSchemaAddress : RegisterSchema,
    onSubmit: (data) => {
      const parentData = {
        ParentFirstName: data?.firstName.trim(),
        ParentLastName: data?.lastName.trim(),
        idPincode: data?.postoffice?.idPincode,
      };
      setIsParentInfo(2);
      setParentInfo(parentData);
      addressVerify(parentData);
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  

  const handleLocationType = (event) => {
    if (event.target.name === "pincode") {
      formik.setFieldValue("pincode", event.target.value);
      formik.setFieldValue("pincodeData", "");
      formik.setFieldValue("postoffice", "");
      setPincodeInfo([]);
      setPincode(event.target.value);
    } else if (event.target.name === "postoffice") {
      formik.setFieldValue("postoffice", event.target.value);
    } else if (event.target.name === "state") {
      formik.setFieldValue("state", event.target.value);
      formik.setFieldValue("district", {});
      formik.setFieldValue("pincodeData", "");
      formik.setFieldValue("postoffice", "");
      setPincodeList([]);
    } else if (event.target.name === "locationType") {
      formik.setFieldValue("locationType", event.target.value);
      formik.setFieldValue("postoffice", "");
      formik.setFieldValue("pincodeData", "");
      setLocationType(event.target.value);
    } else if (event.target.name === "pincodeData") {
      formik.setFieldValue("pincodeData", event.target.value);
      formik.setFieldValue("postoffice", {});
    } else if (event.target.name === "district") {
      // formik.setFieldValue("postoffice", {});
      // setPincodeList([]);
      // formik.setFieldValue("pincodeData", {});
      formik.setFieldValue("district", event.target.value);
      getLocationByPincode(event);
    } else {
      formik.setFieldValue(event.target.name, event.target.value);
    }
  };

  const getLocationByPincode = (event) => {
    const data = {
      idParent: "1",
      Switch: locationType === "pincode" ? 1 : 2,
      Pincode: locationType === "address" ? 302012 : Number(pincode),
      idDistrict:
        locationType === "address" ? event.target.value.idDistrict : 4,
    };
    AuthApi.getpin(data).then((resp) => {
      if (resp.data.status === 200) {
        if (locationType === "pincode") {
          if (resp.data.data?.[0]?.ValidPin > 0) {
            formik.setFieldValue("postoffice", "");
            setPincodeInfo(resp.data.data?.[0]?.PincodeInfo);
          } else {
            toast.warning("Invalid pincode.");
          }
        } else {
          console.log(resp.data.data)
          setPincodeList(resp.data.data);
        }
      } else {
        toast.warning("nvalid pincode.");
      }
    });
  };



  

  const handleFilterOptions = (options, { inputValue }) => {
    const results = fuzzysort.go(inputValue, options, {
      keys: ['idPostOffice', 'PostOffice'],
      threshold: -10000,
      limit: 100,
      all:true,
    });
    return results.map((result) => result.obj);
  };

  const sortOptions =(data,type)=>{
    const sortedOptions = data?.slice().sort((a, b) => a[type].localeCompare(b[type]));
    return sortedOptions;
  }
  

  return (
    <div>
     
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Stack spacing={1}>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <TextField
                  className="wt-bg"
                  fullWidth
                  label="First Name"
                  {...getFieldProps("firstName")}
                  error={Boolean(touched.firstName && errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                />

                <TextField
                  fullWidth
                  className="wt-bg"
                  label="Last Name"
                  {...getFieldProps("lastName")}
                  error={Boolean(touched.lastName && errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                />
              </Stack>
              <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                <FormControl>
                  <FormLabel id="demo-radio-buttons-group-label" sx={{fontSize:'14px'}}>
                    Location
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="pincode"
                    name="locationType"
                    value={locationType}
                    {...getFieldProps("locationType")}
                    error={Boolean(touched.locationType && errors.locationType)}
                    helperText={touched.locationType && errors.locationType}
                    onChange={handleLocationType}
                  >
                    <FormControlLabel
                      value="pincode"
                      control={<Radio />}
                      label="PIN Code"
                    />
                    <FormControlLabel
                      value="address"
                      control={<Radio />}
                      label="Address"
                    />
                  </RadioGroup>
                </FormControl>
              </Stack>

              {locationType === "pincode" && (
                <>
                  <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                    <Stack item spacing={1} xs={5} className="pl-0">
                      <TextField
                        inputProps={{
                          maxLength: 11,
                        }}
                        className="wt-bg"
                        fullWidth
                        type="text"
                        label="Pincode"
                        {...getFieldProps("pincode")}
                        error={Boolean(touched.pincode && errors.pincode)}
                        helperText={touched.pincode && errors.pincode}
                        onChange={handleLocationType}
                      />
                    </Stack>

                    <Stack item xs={5} spacing={1}>
                      <Button
                        type="button"
                        className=""
                        size="large"
                        variant="contained"
                        disabled={!pincode || pincode.length < 6}
                        onClick={getLocationByPincode}
                      >
                        Search
                      </Button>
                    </Stack>
                  </Stack>

                  <Stack
                    container
                    direction={{ xs: "column", sm: "column" }}
                    spacing={2}
                  >
                    {pincodeInfo.length > 0 && (
                      <Stack item xs={12} spacing={2}>
                        <InputLabel id="reg-postoffice" sx={{fontSize:'14px'}}>Post Office</InputLabel>
                        <Select
                          id="postoffice"
                          label="Post Office"
                          className="wt-bg"
                          {...getFieldProps("postoffice")}
                          error={Boolean(
                            touched.postoffice && errors.postoffice
                          )}
                          helperText={touched.postoffice && errors.postoffice}
                          onChange={handleLocationType}
                        >
                          {sortOptions(pincodeInfo,'PostOffice')?.map((option, index) => (
                            <MenuItem key={index} value={option}>
                              {option.PostOffice}
                            </MenuItem>
                          ))}
                        </Select>
                      </Stack>
                    )}
                    {pincode && !getFieldProps("postoffice").value?.PostOffice && (
                      <p className="MuiFormHelperText-root Mui-error MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-1wb06vl-MuiFormHelperText-root">Click Search to get & select Post Office details</p>
                    )}
                  </Stack>
                  <Stack direction="column" /* direction={{ xs: "column", sm: "row" }} */ spacing={2}>
                    {getFieldProps("postoffice").value?.PostOffice && (
                   
                      <>                      
                      <Grid item xs={12} sm={12} className="p-12">
                        <Typography sx={{fontSize:'14px', marginTop:'10px', marginBottom:'2px'}}>
                            Address :{" "}
                            <Divider/>
                        </Typography>   
                      </Grid>
                      <Grid item xs={12} sm={12} className="p-0">    
                         {getFieldProps("postoffice").value?.PostOffice},                   
                        <span className="uppercase"><b> &nbsp;{getFieldProps("postoffice").value?.District}</b></span>,
                        <br/>  <span className="uppercase">{getFieldProps("postoffice").value?.State}</span>,
                        &nbsp;PIN-{getFieldProps("postoffice").value?.Pincode}                        
                      </Grid>
                    </>
                    )}
                  </Stack>
                </>
              )}

              {/* Address type */}

              {locationType === "address" && (
                <>
                  <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                    <Grid item spacing={2} xs={12} sm={6}  className="p-0">
                      <FormControl fullWidth>
                        <InputLabel id="reg-state">State</InputLabel>
                        <Select
                          id="state"
                          className="wt-bg"
                          label="State"
                          {...getFieldProps("state")}
                          error={Boolean(touched.state && errors.state)}
                          helperText={touched.state && errors.state}
                          onChange={handleLocationType}
                        >
                          {sortOptions(classStateInfo[0]?.StateInfo,'State')?.map(
                            (option, index) => (
                              <MenuItem key={index} className="uppercase" value={option}>
                                {option.State}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                    </Grid>

                    <Grid item spacing={1} xs={12} sm={6} className="p-0">
                      <FormControl fullWidth>
                        <InputLabel id="reg-DistrictInfo">District</InputLabel>
                        <Select
                          id="district"
                          className="wt-bg"
                          label="District"
                          {...getFieldProps("district")}
                          error={Boolean(touched.district && errors.district)}
                          helperText={touched.district && errors.district}
                          onChange={handleLocationType}
                        >
                          {sortOptions(getFieldProps("state").value?.DistrictInfo,'District')?.map(
                            (option, index) => (
                              <MenuItem key={index} className="uppercase" value={option}>
                                {option.District}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Stack>
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    spacing={2}
                    sx={{paddingTop:'10px', paddingBottom:'10px'}}
                  >
                    <Grid item xs={12} sm={12} className="p-0">
                     
                        <FormControl fullWidth>
                  <Autocomplete
                    id="postoffice"
                    className="uppercase wt-bg"
                    options={sortOptions(pincodeList,'PostOffice')}
                    filterOptions={handleFilterOptions}
                    {...formik.getFieldProps("postoffice")}
                    value={formik.values?.schoolName}
                    onChange={(event, newValue) => {
                      formik.setFieldValue("idPostOffice", newValue?.idPincode);
                      formik.setFieldValue("postoffice", newValue);
                    }}
                    getOptionLabel={(option) => (option ? `${option.PostOffice}, ${option.Pincode}` || '' : '')}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Location"
                        variant="outlined"
                        error={
                          formik.touched.postoffice &&
                          Boolean(formik.errors.postoffice)
                        }
                        helperText={
                          formik.touched.postoffice && formik.errors.postoffice
                        }
                      />
                    )}
                    renderOption={(props, option) => (
                      <ListItem className="uppercase" {...props}>
                        <ListItemText className="uppercase" primary={`${option.PostOffice}, ${option.Pincode}`} />
                      </ListItem>
                    )}
                  />
                </FormControl>

                       
                    </Grid>

                    
                  </Stack>

                  <Stack direction="column" sx={{paddingTop:'10px', paddingBottom:'10px'}}>
                    {getFieldProps("postoffice").value && getFieldProps("postoffice").value?.PostOffice  && (
                      <>                      
                        <Grid item xs={12} sm={12} className="p-12">
                          <Typography sx={{fontSize:'14px',marginBottom:'10px'}}>
                              Address :{" "}
                              <Divider/>
                          </Typography>   
                        </Grid>
                        <Grid item xs={12} sm={12} className="p-0">                       
                          {getFieldProps("postoffice").value?.PostOffice},
                          <span className="uppercase"> <b> &nbsp;{getFieldProps("district").value?.District}</b></span>,
                          <br/><span className="uppercase">{getFieldProps("state").value?.State}</span> ,
                          &nbsp;PIN-{getFieldProps("postoffice").value?.Pincode}                        
                        </Grid>
                      </>
                    )}
                  </Stack>
                  
                </>
              )}
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
              >
                Register
              </LoadingButton>
            </Stack>
          </Form>
        </FormikProvider>
      
      {/* Otp Form */}
    </div>
  );
}
