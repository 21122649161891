import xpSolvService from "../axios/axios";

class AuthService {

    generateOTP(data) {
        return Promise.resolve(xpSolvService.post('otp/generateotp', data));
    }

    mobileotp(data) {
        return Promise.resolve(xpSolvService.post('mobileotp', data));
    }

    getschool(data) {
        return Promise.resolve(xpSolvService.post('getschool', data));
    }

    registerParent(data) {
        return Promise.resolve(xpSolvService.post('register', data));
    }

    signIn(data) {
        return Promise.resolve(xpSolvService.post('signininfo', data));
    }

    reqremove(data) {
        return Promise.resolve(xpSolvService.post('reqremove', data));
    }

    register(data) {
        return Promise.resolve(xpSolvService.post('signup/register/students', data));
    }

    getpin(data) {
        return Promise.resolve(xpSolvService.post('getpin', data));
    }

    getclassdist(data) {
        return Promise.resolve(xpSolvService.post('getclassdist', data));
    }
}

export default AuthService;