import { Form, FormikProvider, useFormik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
// material
import {
  Alert,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";

import { toast } from "react-toastify";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

// ----------------------------------------------------------------------
import { TestApi } from "../../service/index";
import Utility from "../../utils/Utility";

toast.configure();

export default function ReferralForm({ isListUpdate, funUpdateList }) {
  const navigate = useNavigate();
  const [successMsg, setSuccessMsg] = useState("");
  const [referStatus, setReferStatus] = useState(false);
  const [schoolList, setSchoolList] = useState([]);
  const [userInfo, setUserInfo] = useState(Utility.getLS("userInfo"));

  const RegisterSchema = Yup.object().shape({
    fMobileNum: Yup.string()
      .min(10, "Too Short!")
      .max(10, "Too Long!")
      .required("Mobile number required"),
    fName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Name  required"),
  });

  const formik = useFormik({
    initialValues: {
      fMobileNum: "",
      fName: "",
    },
    validationSchema: RegisterSchema,
    onSubmit: (data) => {
      console.log(data);
      refer(data);
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  const handleChange = (event) => {
    formik.setFieldValue(event.target.name, event.target.value);
  };

  const refer = (dataInfo) => {
  //  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    const data = {
      idParent: userInfo[0]?.idParent,
      Bo: userInfo[0]?.Bo,
      ParentName: userInfo[0]?.PFirstName,
      Refmobilenum: dataInfo.fMobileNum,
      RefName: dataInfo.fName,
      idRole: userInfo[0]?.idRole,
    };
    TestApi.refer(data).then((resp) => {
      if (resp.data.status === 200) {
        setReferStatus(true);
        setSuccessMsg("Invite successfully sent to referral !");
        if (isListUpdate) {
          funUpdateList();
        }
        formik.resetForm();
      } else {
       // toast.warning(resp.data.message);
       setSuccessMsg("Can't refer this mobile number as it's already in the system.");
       setReferStatus(false);
      }
    });
  };

  return (
    <div>
      {successMsg && (
        <Typography variant="subtitle2" /* className="f-12" */ sx={{ mb: 3, textAlign: "left" }}>
          { referStatus? 
          <Alert color="primary" severity="success"> {successMsg}</Alert>
          :<Alert color="secondary" severity="error"> {successMsg}</Alert>}
          

        </Typography>
      )}
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <Grid item xs={12} sm={6} md={4} className="p-0">
                <TextField
                  fullWidth
                  className="wt-bg"
                  label="Friend's Mob No"
                  {...getFieldProps("fMobileNum")}
                  error={Boolean(touched.fMobileNum && errors.fMobileNum)}
                  helperText={touched.fMobileNum && errors.fMobileNum}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={4} className="p-0">
                <TextField
                  className="wt-bg"
                  fullWidth
                  label="Friend's Name"
                  {...getFieldProps("fName")}
                  error={Boolean(touched.fName && errors.fName)}
                  helperText={touched.fName && errors.fName}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={2} className="p-0">
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  className="submit_btn"
                >
                  Send Invite
                </LoadingButton>
              </Grid>
            </Stack>
          </Stack>
        </Form>
      </FormikProvider>
    </div>
  );
}
