import { Box, Card, CardContent, Grid, Link, Paper, Typography } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';


export default function ContactUs() {

    return (
        <div style={{ marginTop: 120, padding: ' 0 50px', }}>
            <Link to="/login" component={RouterLink} style={{ display: "flex", width: "75px", padding: "9px 7px 0 6px", lineHeight: "24px", background: "#089293", border: "none", color: "#fff", borderRadius: 6, marginBottom: "14px" }}> <img src="/static/mock-images/avatars/angle-left-free-icon-font.png" alt="imgtest" style={{ width: 15, marginTop: "3px", marginRight: "6px", marginBottom: "11px" }} />back</Link>
            <h2>Contact Us</h2>

            <h3 style={{ padding: ' 15px 0', fontSize: 16, color: '#797D7F'}}>Last updated on Dec 3rd 2022</h3>

            <div>                
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    You may contact us using the information below:
                </Typography>
            </div>

            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Paper elevation={1} sx={{ fontSize: 14, padding: 1 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Box flexDirection='column'>
                                    <Box>Merchant Legal entity name:</Box>
                                    <Box sx={{ fontWeight: 800 }}><Link variant="body2" color="inherit" href='https://www.seltcorp.com' target='_blank' rel="noopener">SELTCORP</Link> </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={12}>
                                <Box flexDirection='column'>
                                    <Box>E-Mail ID:</Box>
                                    <Box sx={{ fontWeight: 800 }}>connect@xpsolv.com</Box>
                                </Box>
                            </Grid>
                        </Grid>                       

                    </Paper>
                </Grid>
                <Grid item  xs={12} sm={6} md={6} lg={6}>
                    <Card variant='outlined'>
                        <CardContent>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            Registered Address
                        </Typography>
                        <Typography variant="body2">
                            Plot 101, Sector 1                             
                        </Typography>
                        <Typography variant="body2">
                            Aditya World City                             
                        </Typography>
                        <Typography variant="body2">
                            Ghaziabad, UTTAR PRADESH                             
                        </Typography>
                        <Typography variant="body2">
                            PIN 201016                             
                        </Typography>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item  xs={12} sm={6} md={6} lg={6}>
             {/*    <Card variant='outlined'>
                        <CardContent>
                        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                            Operational Address
                        </Typography>
                        <Typography variant="body2">
                            STB-907, Sun Tower
                        </Typography>
                        <Typography variant="body2">
                            Shipra Sun City, Indirapuram
                        </Typography>
                        <Typography variant="body2">
                            Ghaziabad, UTTAR PRADESH
                        </Typography>
                        <Typography variant="body2">
                            PIN 201014
                        </Typography>
                        </CardContent>
                    </Card> */}
                </Grid>
            </Grid>
           
            <div>
                &nbsp;{/* <p style={{ fontSize: 14, }}>&nbsp;</p> */}
            </div>

        </div>
    );
}