import { Link as RouterLink, useNavigate} from 'react-router-dom';
// material
import { styled } from '@material-ui/core/styles';
import { useState, useEffect } from 'react';
import { Card, Stack, Link, Container, Typography, Box } from '@material-ui/core';
// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { MHidden } from '../components/@material-extend';
import { DeactivateForm } from '../components/authentication/Deactivate';
import Utility from "../utils/Utility";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(6, 0)
}));



// ----------------------------------------------------------------------

export default function Deactivate() {
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('token') && localStorage.getItem('userInfo')) {
      const userInfo = Utility.getLS("userInfo");
      if(Number(userInfo[0].idRole) > 1)
      {
         navigate('/institute/app', { replace: true });  
      }
      else{
         navigate('/dashboard/app', { replace: true });  
      }
      
    }
  }, []);

  return (
    <div className='main-bgnew auth_pages' >
    <RootStyle title="De-Activate | XPSOLV">
     

      

      <Container maxWidth="sm"  className='bg_white_login1'>
        <ContentStyle>
          <Stack sx={{ mb: 5 }} style={{marginBottom:20,}}>
            <Typography variant="h5" gutterBottom  className='top_title_heading'>
            De-Activate  <Box component="span" display="inline"  style={{color:'#007c7c',}}>Account</Box>
            </Typography>
            {/* <Typography sx={{ color: 'text.secondary' }}>Enter your details below.</Typography> */}
          </Stack>
         

          <DeactivateForm />

       
        </ContentStyle>
      </Container>      
    </RootStyle>
    </div>
  );
}
