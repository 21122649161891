import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// material
import {
  Box,
  Card,
  Container,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { toast } from "react-toastify";
import HSeparator from "../../theme/Separator";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

// ----------------------------------------------------------------------
import { TestApi } from "../../service/index";
import Utility from "../../utils/Utility";

toast.configure();
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  fontsizeheading: {
    fontSize: 14,
  },
  paper: {
    padding: theme.spacing(2),
  },
  cardcontent: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 16,
    paddingBottom: "0px !important",
  },
  peraclass2: {
    width: "202px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  peraclass: {
    fontSize: 10,
    paddingBottom: 10,
    fontWeight: 600,
    color: "#00007e",
  },
  emailphone: {
    fontSize: 10,
    fontWeight: 500,
    color: "#000000",
    fontFamily: "Poppins",
    marginTop: -5,
    lineBreak: "anywhere",
    marginLeft: 10,
  },
  headingfont: {
    fontSize: 14,
    fontWeight: 600,
  },
  btntext: {
    fontSize: 10,
    fontWeight: 500,
    padding: "0 9px",
    background: "#007c7c",
    color: "#ffffff",
    marginTop: "12px",
    "&:hover": {
      background: "#007c7c",
    },
  },
  textcenter: {
    textAlign: "center",
    lineHeight: 0,
    fontSize: "12px",
  },
  headingfive: {
    display: "flex",
    marginBottom: 26,
  },
  perafive: {
    color: "#929292",
    fontWeight: 500,
  },
  cardpadding: {
    paddingLeft: 0,
    paddingBottom: 10,
  },
  cardborder: {
    border: "1px solid #c8c8c8",
    "&:hover": {
      backgroundSize: "cover",
      background: "linear-gradient(to right, #edfbf4 15%, #d4ffe9 94%)",
      borderColor: "#fff",
    },
  },
  centertext: {
    textAlign: "center",
    fontSize: 16,
  },
  centertextside: {
    textAlign: "left",
    fontSize: 16,
  },
  btnmain: {
    fontWeight: 500,
    marginTop: 18,
  },
  closebtn: {
    position: "absolute",
    right: 0,
    top: -12,
    fontWeight: 700,
  },
  textcolor: {
    color: "#007c7c",
  },
  textcolor2: {
    color: "#999",
    fontWeight: 500,
  },

  plusimg: {
    width: 29,
    marginTop: "-4px",
  },
  textmainid: {
    fontSize: 12,
  },
  textright: {
    textAlign: "right",
  },
  mainbtn: {
    background: "#effff7",
    borderTop: "1px solid #d3ffe9",
  },
  h4heaidng: {
    textAlign: "left",
    marginBottom: "5px",
    fontSize: "15px",
  },
  h4heaidng2: {
    textAlign: "left",
    marginBottom: "5px",
    marginTop: "20px",
    fontSize: "12px",
  },
  colorreport: {
    color: "#007c7c",
  },
  schoolheading: {
    borderBottom: "1px solid #ececec",
    paddingBottom: 15,
    color: "#00007e",
    fontSize: 10,
    fontWeight: "600",
  },
  mtop: {
    marginTop: 33,
    borderTop: "1px solid #ececec",
  },
  imgplan333: {
    width: 15,
    float: "left",
    marginTop: 5,
    marginRight: 11,
    [theme.breakpoints.up("sm")]: {
      width: 15,
    },
  },
  accountsummary: {
    borderBottom: "1px solid #c9c9c9",
    paddingTop: 18,
    paddingLeft: 0,
    marginBottom: 22,
  },
  summaryaccout: {
    borderBottom: "1px solid #c9c9c9",
    padding: 10,
    paddingTop: 0,
    paddingLeft: 0,
    marginBottom: 0,
    width: "auto",
    fontSize: 14,
    [theme.breakpoints.up("sm")]: {
      width: "978px",
    },
  },

  amount: {
    fontSize: 14,
    color: "#000",
    textAlign: "right",
  },
  btnmargin: {
    marginBottom: "21px",
    textAlign: "center",
    paddingTop: "7px !important",
  },
  amount2: {
    fontSize: 12,
    color: "#000",
    fontWeight: 500,
  },
  btnamount: {
    background: "#000",
    marginTop: "-5px",
  },
  colorwhite: {
    color: "#",
    fontWeight: 600,
  },
  margin000: {
    margin: "0 30px",
  },
  bordertable: {
    border: "1px solid #e8e8e8",
    marginBottom: 24,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  colorwhite2: {
    color: "#000",
    fontSize: 16,
    marginBottom:'10px'
  },
  colorwhite3: {
    color: "#000",
    fontSize: 14,
  },

  showtoggle: {
    background: "#fff",
    padding: "4px 16px",
    fontWeight: 500,
    color: "#fff",
    border: 0,
    borderRadius: 8,
    float: "right",
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  imgplan2: {
    width: 15,
    [theme.breakpoints.up("sm")]: {
      width: 15,
    },
  },
  addamountbtn: {
    background: "#007c7c",
    padding: "6px",
    fontSize: "12px",
    color: "#fff",
    borderRadius: "6px",
    textDecoration: "none",
  },
  ruppeicon: {
    position: "relative",
    top: "7px",
    width: "17px",
  },
  cardtable: {
    margin: "0 30px",
  },
  cardmain: {
    padding: "13px",
    margin: "10px 0 15px 15px",
    marginBottom: 10,
  },
  cardmain2: {
    margin: "0px 0px",
    display: "block",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  infotext: {
    fontSize: "10px",
    marginTop: "9px",
    marginBottom: "0",
    fontWeight: "400",
    marginLeft: 17,
  },
  boldfont: {
    fontWeight: 600,
  },
  alertalignment: {
    marginTop: 15,
  },
  infoicons: {
    float: "left",
    width: "15px",
    marginTop: "2px",
    marginRight: "5px",
  },
  logoutbtn: {
    fontSize: 15,
    background: "#089293",
    width: "124px",
    borderRadius: "6px",
    marginTop: "14px",
    color: "#fff",
  },
  buttoncomon2: {
    height: "35px",
    margin: "10px",
    padding: "0 15px",
    marginTop: "-9px",
    width: "100%",
  },
  inputid: {
    margin: "12px",
  },
}));
export default function ReferralList({updateList}) {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(Utility.getLS("userInfo"));
  const [bonusPoint, setBonusPoint] = useState('');
  const [referralList, setReferralList] = useState([]);

  const classes = useStyles();

  useEffect(() => {
    getref();
  }, []);

  useEffect(() => {
    getref();
  }, [updateList]);

  const getref = () => {
  //  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    const data = {
      idParent: userInfo[0]?.idParent,
      Bo: userInfo[0]?.Bo
    };
    TestApi.getref(data).then((resp) => {
      if (resp.data.status === 200) {
        if(resp.data?.data[0]?.ReferalInfo)
        {
          setReferralList(resp.data?.data[0]?.ReferalInfo);
          setBonusPoint(resp.data?.data[0]?.BonusPoint);
        }
       
      } else {
        toast.warning(resp.data.message);
      }
    });
  };

  return (
    
      <Container className={`${classes.accountsummary}`}>

      <Typography variant="h6" gutterBottom className={classes.colorwhite2}>

      <Box
                component="h2"
                display="inline"
                className="tabheading imgicondefine"
              >
                {" "}
                <img src="/static/cutoff-range.png" alt="imgtest" />     <span className="forment_heading"> Bonus  Points </span>
              </Box>




        : &nbsp;{bonusPoint}              
      </Typography>

      <Typography variant="h6" gutterBottom className={classes.colorwhite2}>         
        <HSeparator sx={{ marginTop: 5, marginBottom: 5 }} />     
      </Typography>      

        <Typography variant="h6" gutterBottom className={classes.colorwhite2}>
        <span className="forment_heading">  List of   Referred Friends </span>
         
        </Typography>

        <div>
          <TableContainer className={classes.bordertable}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow key="add">
                  <TableCell align="right" className={classes.textcenter}>
                    SR.No.
                  </TableCell>
                  <TableCell align="right" className={classes.textcenter}>
                    Name
                  </TableCell>
                  <TableCell align="right" className={classes.textcenter}>
                    Mobile
                  </TableCell>
                  <TableCell align="right" className={classes.textcenter}>
                    Status
                  </TableCell>
                  <TableCell align="right" className={classes.textcenter}>
                    Date
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {referralList?.map((item, index) => (
                  <TableRow
                    key={`add_${index}`}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="right" className={classes.textcenter}>
                      {index + 1}
                    </TableCell>
                    <TableCell align="right" className={classes.textcenter}>
                      {item.RefName}
                    </TableCell>
                    <TableCell align="right" className={classes.textcenter}>
                      {item?.Refmobilenum}
                    </TableCell>
                    <TableCell align="right" className={classes.textcenter}>
                      {item?.Status}
                    </TableCell>
                    <TableCell align="right" className={classes.textcenter}>
                      {item?.CreatedOn}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
        <Grid container item spacing={2}>
          <Grid item lg={12} xs={12} className={classes.cardmain2}>
            {referralList.map((item, index) => (
              <>
                <Card className={classes.cardmain}>
                  <Grid container item spacing={2}>
                    <Grid item lg={6} xs={6}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        className={classes.amount2}
                      >
                        SR.NO.
                      </Typography>
                    </Grid>
                    <Grid item lg={6} xs={6}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        className={classes.amount2}
                      >
                        {index + 1}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container item spacing={2}>
                    <Grid item lg={6} xs={6}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        className={classes.amount2}
                      >
                        Name
                      </Typography>
                    </Grid>
                    <Grid item lg={6} xs={6}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        className={classes.amount2}
                      >
                        {item?.RefName}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container item spacing={2}>
                    <Grid item lg={6} xs={6}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        className={classes.amount2}
                      >
                        Mobile
                      </Typography>
                    </Grid>
                    <Grid item lg={6} xs={6}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        className={classes.amount2}
                      >
                        {item?.Refmobilenum}
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid container item spacing={2}>
                    <Grid item lg={6} xs={6}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        className={classes.amount2}
                      >
                        Status
                      </Typography>
                    </Grid>
                    <Grid item lg={6} xs={6}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        className={classes.amount2}
                      >
                        {item?.Status}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container item spacing={2}>
                    <Grid item lg={6} xs={6}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        className={classes.amount2}
                      >
                        Date
                      </Typography>
                    </Grid>
                    <Grid item lg={6} xs={6}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        className={classes.amount2}
                      >
                        {item?.CreatedOn}
                      </Typography>
                    </Grid>
                  </Grid>
                </Card>
              </>
            ))}
          </Grid>
        </Grid>
      </Container>
    
  );
}
