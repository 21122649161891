import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// material
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Link,
  Stack,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
// components
import Page from "../../components/Page";

import { ReferralForm } from "../../components/forms/index";

import { TestApi } from "../../service/index";
import HSeparator from "../../theme/Separator";

import Utility from "../../utils/Utility";

const getIcon = (name) => (
  <Icon color="#007c7c" icon={name} width={22} height={22} />
);

const useStyles = makeStyles((theme) => ({
  card: {
    margin: "auto",
    marginTop: 20,
    padding: 10,
  },
}));

export default function DashboardApp(props) {
  const navigate = useNavigate();
  const classes = useStyles();
  const [userDetails, setUserInfo] = useState(Utility.getLS("userInfo")?.[0]);

  console.log(userDetails);

  return (
    <Page title="Dashboard | XPSOLV">
      <Container className="profile_inner">
        <Grid container>
          <Grid item xs={12} xl={6} sm={12} md={6} className="width-setting">
            <Grid container spacing={2} className="width-set">
              <Grid item xs={12} xl={12} sm={12} md={12}>
                <Card className={`carddata ${classes.card}`}>
                  <CardContent className="padding_view_profile">
                    <Typography variant="h5" component="h2" gutterBottom>
                    {" "}
                      {userDetails.InstituteName}
                    </Typography>
                    <div className="institute_name_main">
                      <Typography
                        color="textSecondary"
                        className="addres_chocing_views"
                        gutterBottom
                      >
                        <div className="institute_address_icon">
                          <span className="img_icon_views">
                            <img src="/static/location.png" alt="fg" />
                          </span>
                        </div>{" "}
                        <div className="institute_address_full">
                          {" "}
                          {userDetails.Address}, {userDetails.District}, {userDetails.State}, Pin :  {userDetails.Pincode}
                        </div>
                      </Typography>
                    </div>
                   
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} xl={12} sm={12} md={12}>
                <Card className={`carddata ${classes.card}`}>
                  <CardContent className="padding_view_profile">
                    <Typography variant="subtitle1" gutterBottom>
                      Primary Contact
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      <span className="img_icon_views avtar_icons">
                        <img src="/static/avatar.png" alt="fg" />
                      </span>{" "}
                      {userDetails.ContactFirstName}{" "}
                      {userDetails.ContactLastName}
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                      <span className="img_icon_views call_icon">
                        <img src="/static/smartphone.png" alt="fg" />
                      </span>{" "}
                      Mobile: +91-{userDetails.Mobile}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} xl={6} sm={12} md={6} className="width-setting mobileview">
           <span className="insti_img_views"> <img src="/static/education-img.svg" alt="" /></span>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
