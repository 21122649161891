import CryptoJS from "crypto-js";
import xpSolvService from "../service/axios/axios";

const secretKey =
  "YQ0klO4uOjYQ0klO4uOj9LaQOn2A5eGdntSkkI3I8O9LaQOnYQ0klO4uOj9LaQOn2A5eGdntSkkI3I8O2A5eGdntSkkI3I8O";
const buildNo = 2;

const isMobileDevice = () => {
  const isMobile = /iPhone|iPad|iPod|Android|webOS|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );

  if (isMobile) {
    console.log("This is a mobile device.");
  } else {
    console.log("This is a desktop device.");
  }
  return isMobile;
};

// eslint-disable-next-line arrow-body-style
const  toTitleCase = (str) => {
  return str.replace(/\w\S*/g, (word) =>
    word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  );
}

const createLS = (name, value) => {
  if (value && name) {
    window.localStorage.setItem(name, encryptData(value));
    if (name === "token") {
      window.localStorage.setItem("buildno", buildNo);
    }
  }
};

const getLS = (name) => {
  let results = null;

  if (
    !window.localStorage.getItem("buildno") ||
    window.localStorage.getItem("buildno") < buildNo
  ) {
    // eslint-disable-next-line no-debugger
  //  debugger;
    localStorage.removeItem("userInfo");
    localStorage.removeItem("token");
    localStorage.removeItem("buildno");
    window.location.href = "/login";
    return false;
  }

  if (window.localStorage.getItem(name)) {
    results = decryptData(window.localStorage.getItem(name));
  }
  if (!results) {
    window.localStorage.clear();
    window.location.href = "/login";
    return false;
  }

  return results;
};

const getUserDetails = () => {
  const userInfo = getLS("userInfo");
  if (userInfo) {
    return userInfo[0];
  }
};

const addRoleInBody = (requestBody) => {
  const userInfo = getUserDetails();
  if (requestBody && userInfo && userInfo?.idRole) {
    requestBody.idRole = userInfo?.idRole;
  }
  return requestBody;
};

const getPath = (path) => {
  const userInfo = getUserDetails();
  let newPath = `/dashboard${path}`;
  if (Number(userInfo.idRole) > 1) {
    newPath = `/institute${path}`;
  }

  return newPath;
};

const isInstitute = () => {
  const userInfo = getUserDetails();
  let institute = false;
  if (Number(userInfo.idRole) > 1) {
    institute = true
  }

  return institute;
};

const encryptData = (data) => {
  const encrypted = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey);
  return encrypted.toString();
};

const decryptData = (encryptedData) => {
  const decrypted = CryptoJS.AES.decrypt(encryptedData, secretKey);
  return JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
};

const isFutureDate = (dateString) =>{
  // Get the current date (without time)
  const today = new Date();
  today.setHours(23, 59, 59, 0);

  // Parse the given date string
  const givenDate = new Date(dateString);
  
  // Compare the dates
  return givenDate >= today;
}

const base64urlEncode = (message) => {
  const base64 = btoa(message); // Convert the message to base64
  const base64url = base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, ''); // Make the base64 string URL-safe
  return base64url;
}

// Base64url decode function
const base64urlDecode = (base64url) => {
  let base64 = base64url.replace(/-/g, '+').replace(/_/g, '/'); // Replace URL-safe characters with standard Base64 characters
  while (base64.length % 4) { // Add padding characters if necessary
    base64 += '=';
  }
  const message = atob(base64); // Decode the Base64 string to a message
  return message;
}

const TwoMonthsBeforeDate = () => {
  // Create a date object for the 1st day of the current month
  const currentDate = new Date();
  const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 2);

  // Create a new date object to avoid mutating the original date
  const pastDate = new Date(firstDayOfMonth);

  // Subtract two months
  pastDate.setMonth(firstDayOfMonth.getMonth() - 3);

  // Format the date (optional, for example: YYYY-MM-DD)
  const formattedDate = pastDate.toISOString().split('T')[0];
  return formattedDate;
  
}  

const callGetAPI = (url) => Promise.resolve(xpSolvService.get(url));

const callPostAPI = (url, data) =>
  Promise.resolve(xpSolvService.post(url, data));

export default {
  isMobileDevice,
  createLS,
  getLS,
  callGetAPI,
  callPostAPI,
  getUserDetails,
  addRoleInBody,
  getPath,
  isInstitute,
  isFutureDate,
  base64urlEncode,
  base64urlDecode,
  TwoMonthsBeforeDate,
  toTitleCase
};
