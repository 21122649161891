import { Form, FormikProvider, useFormik } from "formik";
import fuzzysort from "fuzzysort";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
// material
import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  ListItem,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
  Box
} from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";

import { toast } from "react-toastify";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

// ----------------------------------------------------------------------
import { AuthApi } from "../../../service/index";

import HSeparator from "../../../theme/Separator";

toast.configure();

export default function RegisterForm({
  studentVerify,
  classStateInfo,
  idParent,
}) {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState();
  const [gender, setGender] = useState("");
  const [schoolList, setSchoolList] = useState([]);
  const [filterSchoolList, setFilterSchoolList] = useState([]);
  const [idsParent, setidsParent] = useState(1);

  useEffect(() => {
    console.log(idParent);
    setidsParent(idParent);
  }, [idParent]);

  const RegisterSchema = Yup.object().shape({
    firstName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("First name required"),
    lastName: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Last name required"),
    gender: Yup.string().required("Gender is required"),
    classname: Yup.string().required("Class is required"),
    state: Yup.string().required("State is required"),
    //  district: Yup.string().required("District is required"),
    schoolName: Yup.object().nullable().required("School is required"),
  });

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      gender: "",
      classname: "",
      state: "",
      district: "",
      schoolName: "",
      idSchool: "",
    },
    validationSchema: RegisterSchema,
    onSubmit: (data) => {
      console.log(data);
      studentVerify(data);
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  const handleChange = (event) => {
    if (event.target.name === "state") {
      formik.setFieldValue("state", event.target.value);
      formik.setFieldValue("district", "");
      formik.setFieldValue("schoolName", "");
      formik.setFieldValue("idSchool", "");
      getSchoolList(event);
    } else if (event.target.name === "district") {
      formik.setFieldValue("district", event.target.value);
    } else {
      formik.setFieldValue(event.target.name, event.target.value);
    }
  };

  const getSchoolList = (event) => {
    setSchoolList([]);
    const data = {
      idParent: idsParent,
      idState: event.target.value,
      isK12:1
    };
    AuthApi.getschool(data).then((resp) => {
      if (resp.data.status === 200) {
        setSchoolList(sortOptions(resp.data.data, "schooldesc"));
      } else {
        toast.warning("invalid District.");
      }
    });
  };

  const handleFilterOptions = (options, { inputValue }) => {
    const results = fuzzysort.go(inputValue, options, {
      keys: ['id', 'schooldesc'],
      threshold: -10000,
      limit: 100,
      all:true,
    });

    
  
    return results.map((result) => result.obj);
  };

  const sortOptions = (data, type) => {
    const sortedOptions = data
      ?.slice()
      .sort((a, b) => a[type].localeCompare(b[type]));
    return sortedOptions;
  };

  

  return (
    <div>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
              <TextField
                className="wt-bg"
                fullWidth
                label="First name"
                {...getFieldProps("firstName")}
                error={Boolean(touched.firstName && errors.firstName)}
                helperText={touched.firstName && errors.firstName}
              />

              <TextField
                className="wt-bg"
                fullWidth
                label="Last name"
                {...getFieldProps("lastName")}
                error={Boolean(touched.lastName && errors.lastName)}
                helperText={touched.lastName && errors.lastName}
              />
            </Stack>

            <Grid container spacing={2} className="margin_remove_set">
              <Grid item spacing={2} xs={12} sm={6} className="p-0 padding_left_remove">
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-gender"
                    sx={{ fontSize: "14px" }}
                  >
                    Gender
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="M"
                    name="gender"
                    value={gender}
                    {...getFieldProps("gender")}
                    error={Boolean(touched.gender && errors.gender)}
                    helperText={touched.gender && errors.gender}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="M"
                      control={<Radio />}
                      label="Male"
                    />
                    <FormControlLabel
                      value="F"
                      control={<Radio />}
                      label="Female"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>

            <HSeparator />
            <Typography
              variant="body1" className="margin_remove"
              gutterBottom
              sx={{ paddingBottom: "5px" }}
            >
              School <Box component="span" display="inline" style={{ color: "#007c7c" }}>Details</Box>
            </Typography>
            <Grid container spacing={1} className="margin_top_remove">
              <Grid item spacing={1} xs={12} sm={6} className="p-0 padding_left_remove">
                <FormControl fullWidth>
                  <InputLabel id="reg-class">Class</InputLabel>
                  <Select
                    className="wt-bg"
                    id="classname"
                    label="Class"
                    {...getFieldProps("classname")}
                    error={Boolean(touched.classname && errors.classname)}
                    helperText={touched.classname && errors.classname}
                    onChange={handleChange}
                  >
                    {classStateInfo[0]?.ClassInfo?.map((option, index) => (
                      <MenuItem key={index} value={option.idClass}>
                        {option.ClassName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid item xs={12} sm={6} className="p-0">
                <FormControl fullWidth>
                  <InputLabel id="reg-state">State</InputLabel>
                  <Select
                    className="wt-bg"
                    id="state"
                    label="State"
                    {...getFieldProps("state")}
                    error={Boolean(touched.state && errors.state)}
                    helperText={touched.state && errors.state}
                    onChange={handleChange}
                  >
                    {sortOptions(classStateInfo[0]?.StateInfo, "State")?.map(
                      (option, index) => (
                        <MenuItem key={index} value={option.idState} className="uppercase">
                          {option.State}
                        </MenuItem>
                      )
                    )}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item spacing={2} xs={12} className="p-0 padding_left_remove">
                <FormControl fullWidth>
                  <Autocomplete
                    id="schoolName"
                    className="uppercase wt-bg"
                    options={schoolList}
                    filterOptions={handleFilterOptions}
                    {...formik.getFieldProps("schoolName")}
                    value={formik.values?.schoolName}
                    onChange={(event, newValue) => {
                      formik.setFieldValue("idSchool", newValue?.id);
                      formik.setFieldValue("schoolName", newValue);
                    }}
                    getOptionLabel={(option) => (option ? option.schooldesc || '' : '')}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="School Name"
                        variant="outlined"
                        error={
                          formik.touched.schoolName &&
                          Boolean(formik.errors.schoolName)
                        }
                        helperText={
                          formik.touched.schoolName && formik.errors.schoolName
                        }
                      />
                    )}
                    renderOption={(props, option) => (
                      <ListItem className="uppercase" {...props}>
                        <ListItemText className="uppercase" primary={option.schooldesc} />
                      </ListItem>
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>
            <LoadingButton
              fullWidth
              size="large"
              type="submit" className="submit_btn"
              variant="contained"
            >
              Continue
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
    </div>
  );
}
