import fileTextFill from "@iconify/icons-eva/file-text-fill";
import { Icon } from "@iconify/react";
// import { useForm } from "react-hook-form";
// import { yupResolver } from '@hookform/resolvers';
// material
import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  Typography,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/styles";
import { useEffect, useState } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { PromotionForm } from "../components/authentication/register";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

import Loader from "../components/Loader";
// components
import Page from "../components/Page";

import { AuthApi,TestApi } from "../service/index";
import Utility from "../utils/Utility";

toast.configure();

const steps = [
  "Analysing Criteria",
  "Selecting Question",
  "Preparing Test Paper",
];
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  borderchangetext: {
    marginTop: 0,
    fontSize: 14,
    "& label.Mui-focused": {
      color: "white",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "yellow",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "yellow",
      },
    },
  },
  cardborder: {
    border: "1px solid #c8c8c8",
    borderRadius: 10,
    padding: 25,
  },
  colorunselect: {
    background: "#fff",
    boxShadow: "none",
    minWidth: "27px",
    width: "7px",
    height: "27px",
    borderRadius: "83px",
    padding: "14px",
    "&:hover": {
      background: "transparent",
    },
  },
  marginleftright: {
    marginRight: "13px",
    width: "12px",
    height: "27px",
    borderRadius: "110px",
    padding: "0",
    minWidth: "27px",
    background: "#fff",
    boxShadow: "none",
    "&:hover": {
      background: "transparent",
    },
  },
  label: {
    fontSize: 13,
    marginTop: 12,
  },
  colorred: {
    color: "red",
    fontSize: 13,
  },
  buttonspacing: {
    float: "right",
    marginTop: "10px",
  },
  btntext: {
    fontWeight: 500,
    width: "100%",
    marginTop: 23,
    color: "#fff",
    background: "#007c7c",
    "&:hover": {
      background: "#007c7c",
    },
  },

  headingpart: {
    color: "#007c7c",
  },
  themecode: {
    fontWeight: 600,
    color: "#000",
    fontSize: 16,
    textAlign: "center",
    borderBottom: "1px solid #e1e1e1",
    paddingBottom: 15,
    marginBottom: 14,
  },
  borderttep: {
    borderBottom: "1px solid #d9d9d9",
    paddingBottom: 0,
    marginBottom: 15,
  },
  imgplan: {
    width: "90%",
    margin: "92px auto",

    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  testdialog: {
    textAlign: "center",
    padding: "20px 0",
  },
  imgplan3: {
    width: "250px",
    margin: "auto",
    position: "relative",
  },
  imgpdf: {
    border: "1px solid #e3e3e3",
    position: "relative",
    padding: 20,
    borderRadius: "8px",
    width: "400px",
    margin: "28px auto",
  },
  pdfdownload: {
    borderRadius: "4px",
    margin: "auto",
    display: "block",
    marginTop: 17,
    fontWeight: 600,
    padding: "11px 17px",
    background: "#007c7c",
    "&:hover": {
      background: "#007c7c",
    },
  },
  sendemail: {
    borderRadius: "4px",
    margin: "auto",
    display: "block",
    marginTop: 17,
    fontWeight: 600,
    padding: "14px 15px",
    background: "transparent",
    border: "1px solid #007c7c",
    color: "#007c7c",
    boxShadow: "none",
  },
  testgenrate: {
    padding: "0px 11px",
    color: "#000",
    fontWeight: 600,
    marginTop: 20,
    textAlign: "center",
    fontSize: 19,
  },

  textpdf: {
    textAlign: "center",
    marginTop: 25,
  },
  borderbtm: {
    paddingBottom: 0,
  },
  bgtextbtn: {
    background: "#007c7c",
  },
  closebtn: {
    position: "absolute",
    right: 0,
  },
  closebtnfont: {
    fontSize: 25,
  },
  mainsecrpadd: {
    paddingRight: 0,
    [theme.breakpoints.up("sm")]: {
      paddingRight: 15,
    },
  },
  mainsecrpadd2: {
    paddingRight: 0,
    [theme.breakpoints.up("sm")]: {
      paddingLeft: 15,
    },
  },

  checkboxfont: {
    fontSize: "14px !important ",
  },
  borderbotmmain: {
    borderBottom: "1px solid #dcdcdc",
    paddingBottom: 12,
  },
  infoicons: {
    float: "left",
    width: "15px",
    marginTop: "2px",
    marginRight: "5px",
  },
  infotext: {
    fontSize: "12px",
    marginTop: "9px",
    marginBottom: "0",
    fontWeight: "400",
  },
  boldfont: {
    fontWeight: 600,
  },
  alertalignment: {
    marginTop: 15,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, chapters, theme) {
  return {
    fontWeight:
      chapters.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export default function StudentPromote() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [showLoder, setShowLoader] = useState(false);
  const { state } = useLocation();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [classStateInfo, setClassStateInfo] = useState([]);
  const [parentInfo, setParentInfo] = useState([]);
  const [studentInfo, setStudentInfo] = useState(state);
  const [userInfo, setUserInfo] = useState(Utility.getLS("userInfo"));

  useEffect(() => {
  //  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    setParentInfo(userInfo[0]);
    getclassdist(userInfo);
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const studentVerify = (data) => {
    register(data);
  };

  const register = (data) => {
    try {
      setShowLoader(true);
      const reqBody = {
        idParent: Number(parentInfo?.idParent),
        Bo: parentInfo?.Bo,
       idStudent: studentInfo.idStudent,
       idStudentSchoolCurrent: studentInfo.idStudentSchool,
       idClassCurrent : studentInfo.idClass,
       idSchool: data.isSchoolChange?data.idSchool:studentInfo.idStudentSchool
      };

      TestApi.stpromote(reqBody).then(
        (resp) => {
          setShowLoader(false);
          if (resp.status === 200) {
            console.log("Register", resp);
            if (resp.data.data) {
              toast.success(resp.data.message);
				
				  navigate('/dashboard/app', { replace: true });
				
            } else {
              toast.warning(resp.data.message);
            }
          }
        },
        (error) => {
          toast.warning("Error in Register");
        }
      );
    } catch (error) {
      console.log("Register Catch", error);
    }
  };

  const getclassdist = (parentInfo) => {
    setShowLoader(true);
    const data = {
      //   idParent: "1",
      idParent: parentInfo[0]?.idParent,
      idRole: parentInfo[0]?.idRole,
    };
    AuthApi.getclassdist(data).then((resp) => {
      setShowLoader(false);
      if (resp.data.status === 200) {
        setClassStateInfo(resp.data.data);
      } else {
        setClassStateInfo([]);
      }
    });
  };

  return (
    <Page title="Promotion Student | XPSOLV" className="main-page">
      <Container className="main-Container">
        <Stack
          direction="row"
          alignItems="center"
          className={classes.borderttep}
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
          <Box
            component="h2"
            display="inline"
            className="tabheading imgicondefine"
          >
            {" "}
            <img src="/static/cutoff-range.png" alt="imgtest" /> Promote Student
          </Box>
            
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="/dashboard/app"
            className={classes.bgtextbtn}
            startIcon={<Icon icon={fileTextFill} />}
          >
            Profile
          </Button>
        </Stack>
        <Container>
          <Grid container>
            <Grid
              item
              className={classes.cardborder}
              lg={8}
              md={7}
              sm={7}
              xs={12}
            >
              {parentInfo?.idParent && (
                <PromotionForm
                  studentVerify={studentVerify}
                  classStateInfo={classStateInfo}
                  idParent={parentInfo?.idParent}
                  studentInfo={studentInfo}
                />
              )}
            </Grid>
            <Grid item lg={4} md={5} sm={5} xs={12}>
              <img
                src="/static/mock-images/avatars/plantest2.png"
                alt="imgtest"
                className={classes.imgplan}
              />
            </Grid>
          </Grid>
        </Container>
      </Container>
      <Loader isLoader={showLoder} showHide={setShowLoader} />
    </Page>
  );
}
