import axios from "axios";
import Utility from "../../utils/Utility";

// const token = localStorage.getItem('token')?Utility.getLS('token'):'ZU5UNHZVZ0I5NGxMYUd0R2tJZ3BQbUhVTEF1NUpYYlB8Q053VS8vQzZ4Qjd0dHJuYTlNUnJGSmE5TlpSMEtqbCtOQW5UZklUY29CY0p0czNwY1FHaHcrNm9la2NoM1VhVys5cHhxVzdVCnVNND0';
// const token = localStorage.getItem('token')?`Bearer ${Utility.getLS('token')}`:'Bearer ZU5UNHZVZ0I5NGxMYUd0R2tJZ3BQbUhVTEF1NUpYYlB8Q053VS8vQzZ4Qjd0dHJuYTlNUnJGSmE5TlpSMEtqbCtOQW5UZklUY29CY0p0czNwY1FHaHcrNm9la2NoM1VhVys5cHhxVzdVCnVNND0';
// axios instance for making requests 
const xpSolvService = axios.create({
// <<<<<<< Updated upstream
    
//    baseURL: "https://qa.xpsolv.com/remote/router/",
// =======
     baseURL: "https://xpsolv.com/remote/router/",
// >>>>>>> Stashed changes
    headers: {
        "Content-type": "application/json"
    }
});

// request interceptor for adding token
xpSolvService.interceptors.request.use((config) => {
    // add token to request headers
    config.headers.Authorization = localStorage.getItem('token')?`Bearer ${Utility.getLS('token')}`:'Bearer ZU5UNHZVZ0I5NGxMYUd0R2tJZ3BQbUhVTEF1NUpYYlB8Q053VS8vQzZ4Qjd0dHJuYTlNUnJGSmE5TlpSMEtqbCtOQW5UZklUY29CY0p0czNwY1FHaHcrNm9la2NoM1VhVys5cHhxVzdVCnVNND0';
    return config;
});

export default xpSolvService;