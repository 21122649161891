import { Base64 } from "js-base64";
import "katex/dist/katex.min.css";
import { useEffect, useState } from "react";
import { BlockMath, InlineMath } from "react-katex";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import SchoolIcon from '@mui/icons-material/School';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PostAddIcon from '@mui/icons-material/PostAdd';
// material
import {
  Box,
  Button,
  ButtonGroup,
  CardMedia,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grid,
  Link,
  Typography,
  Stack,
  FormControl,
  Autocomplete,
  TextField,
  ListItemText,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Radio,
  RadioGroup,
  FormControlLabel,
  Divider,
} from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/styles";

// components
import ConfirmationModal from "../../components/popup/ConfirmationModal";
import Page from "../../components/Page";
import Loader from "../../components/Loader";
import { TestApi } from "../../service/index";
import Feedback from "../../components/forms/Feedback";
import Utility from "../../utils/Utility";
import HSeparator from "../../theme/Separator";

import Iframe from "../Iframe";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  centerwidth: {
    margin: "auto",
  },
  floatRight: {
    background: "transparent !important",
    height: "38px",
    marginLeft: 10,
    color: "#999999 !important",
  },
  floatRight2: {
    background: "transparent !important",
    height: "38px",
    marginLeft: 10,
    color: "#999999 !important",
    marginTop: "20px",
    float: "right",
    [theme.breakpoints.up("sm")]: {
      marginTop: "0px",
    },
  },
  themargin: {
    marginTop: "7px",
    borderTop: "1px solid #b1b1b1",
    paddingTop: "0px",
    [theme.breakpoints.up("sm")]: {
      marginTop: "26px",
      paddingTop: "22px",
    },
  },
  themarginTeacher: {
    marginTop: "7px",
    paddingTop: "0px",
    [theme.breakpoints.up("sm")]: {
      marginTop: "26px",
      paddingTop: "22px",
    },
  },
  colorgray: {
    background: "transparent !important",
    color: "#808080 !important",
    whiteSpace: "nowrap",
    marginLeft: "0",
    fontSize: "12px",
    padding: "5px",
    boxShadow: "none",
    border: "1px solid #999",
    [theme.breakpoints.up("sm")]: {
      padding: "6px 15px",
      fontSize: "12px",
      margin: "auto",
      display: "block",
    },
  },
  colorgray2: {
    border: "none",
    color: "#808080 !important",
    fontSize: "12px",
    padding: "6px 15px",
  },
  colorgrayactive: {
    background: "#808080 !important",
    color: "#fff !important",
    whiteSpace: "nowrap",
    marginLeft: "0",
    fontSize: "12px",
    padding: "5px",
    [theme.breakpoints.up("sm")]: {
      padding: "6px 15px",
      fontSize: "12px",
      margin: "auto",
      display: "block",
    },
  },
  steppershaodw: {
    boxShadow: "1px 1px 10px #000",
  },
  textcolor: {
    color: "#000000",
    lineHeight: 3.5,
    fontSize: 10,
    [theme.breakpoints.up("xs")]: {
      lineHeight: 1.5,
    },
  },
  fontmain: {
    fontSize: 10,
  },
  textcolor2: {
    color: "#007c7c",
    marginBottom: "20px",
    fontSize: 12,
  },
  textcolor55: {
    color: "#007c7c",
    marginBottom: "20px",
  },
  textcneter: {
    textAlign: "left",
    marginBottom: 18,
  },
  textcneter2: {
    textAlign: "center",
    marginBottom: 0,
  },

  textcneter3: {
    textAlign: "left",
    marginBottom: 0,
    fontWeight: 500,
    marginTop: "3px",
    fontSize: "10px",
    color: "#007c7c",
    [theme.breakpoints.up("xs")]: {
      lineHeight: 1.5,
    },
  },
  thefont: {},
  bootomMr: { marginBottom:'50px'},
  centerwidth2: {
    margin: "auto",
    border: "1px solid #d9d9d9",
    borderRadius: 10,
    marginTop: 40,
  },
  textright: {
    textAlign: "left",
    borderBottom: "1px solid #d9d9d9",
    padding: "10px 30px",
    fontWeight: 600,
  },
  paddingten: {
    padding: 30,
  },
  paddingten2: {
    padding: 30,
    borderBottom: "1px solid #d9d9d9",
  },
  tenalign: {
    fontWeight: 500,
    padding: "12px 72px",
    borderRadius: 4,
    marginLeft: "auto",
    display: "block",
  },
  summarytest: {
    fontWeight: 600,
  },
  bgcheckbox: {
    margin: 30,
    padding: "34px",
    background: "#e7fff1",
    maxWidth: "-webkit-fill-available",
    borderRadius: 10,
  },
  colrotxt: {
    color: "#007c7c",
    fontWeight: 600,
  },
  imgplan2: {
    width: "44%",
    margin: "auto",
  },
  colrotxt2: {
    margin: "9px 0",
    fontWeight: 600,
    display: "inline-flex",
    fontSize: 14,
    width: "72px",
  },
  butnspacing: {
    padding: "0 33px 29px",
  },
  imgplan4: {
    width: "75px",
    margin: "auto",
  },
  textclear: {
    textAlign: "center",
    fontWeight: 500,
  },

  boxicon: {
    border: "1px solid #d7d7d7",
    margin: 10,
    padding: 15,
    borderRadius: 6,
  },
  textclear1: {
    color: "#007c7c",
    textAlign: "center",
    fontWeight: 500,
  },
  bgsummary: {
    background: "#ececec",
    padding: "15px",
    borderRadius: 5,
    marginBottom: 30,
    margin: 10,
  },
  summarytext: {
    margin: 0,
    fontWeight: 600,
  },
  summarytext2: {
    margin: 0,
    fontWeight: 600,
    textAlign: "right",
  },
  bordermain: {
    borderBottom: "1px solid rgba(145, 158, 171, 0.24)",
  },
  bgprofile: {
    paddingLeft: "0",
  },

  bgprofile1: {
    paddingLeft: "0",
  },
  bgprofileinner: {
    background: "#fff",
    padding: "3px",
    marginTop: "18px",
    borderRadius: "32px",
    backgroundSize: "contain",
    [theme.breakpoints.up("lg")]: {
      borderRadius: "32px 0px 0px 32px ",
    },
  },
  bgprofileinner2: {
    background: "#fff",
    padding: "8px",
    marginTop: "18px",
    borderRadius: "10px",
    fontSize: "19px",
    fontWeight: "500",
    border: "1px solid #e9e9e9",
    backgroundSize: "contain",
    [theme.breakpoints.up("lg")]: {
      borderRadius: "32px",
      padding: "33px",
    },
  },
  tableborder: {
    borderRight: "1px solid #999",
  },
  tablemargin: {
    paddingLeft: 15,
    borderBottom: "1px solid #999",
  },
  questions: {
    marginBottom: "20px",
    fontSize: "14px",
  },
  questiondetails: {
    marginBottom: "20px",
    borderBottom: "1px solid #b7b7b7",
    paddingBottom: "22px",
  },
  buttngroup: {
    padding: "14px 31px",
    fontWeight: "500",
    [theme.breakpoints.up("lg")]: {
      padding: "14px 42px",
    },
  },
  textmaintext: {
    background: "#000",
    borderRadius: "4px",
    padding: "9px 32px",
    letterSpacing: "3px",
    color: "#fff",
    margin: "13px auto",
    display: "block",
    fontWeight: "300",
    fontSize: "12px",
  },
  textmaintext2: {
    color: "#fff",
    background: "#000",
    float: "right",
    borderRadius: "4px",
    padding: "9px 32px",
    letterSpacing: "3px",
    margin: "13px",
    display: "block",
    fontWeight: "300",
    fontSize: "12px",
  },
  textmaintext44: {
    background: "#007c7c",
    borderRadius: "4px",
    padding: "7px 32px",
    margin: "1px 16px",
    display: "block",
    fontWeight: "300",
    fontSize: "12px",
    marginTop: 21,
    [theme.breakpoints.up("sm")]: {
      fontWeight: "600",
      marginTop: 0,
    },
  },
  textmaintext3: {
    background: "#007c7c",
    borderRadius: "4px",
    padding: "9px 15px",
    letterSpacing: "1px",
    margin: "13px auto",
    display: "block",
    fontWeight: "500",
    fontSize: "12px",
    position: "absolute",
    right: "0",
    top: "-124px",
    [theme.breakpoints.up("lg")]: {
      top: "-46px",
      padding: "9px 32px",
    },
  },
  textcneter6: {
    color: "#007c7c",
    textAlign: "center",
    fontSize: "14px",
    [theme.breakpoints.up("xs")]: {
      fontSize: "12px",
    },
  },
  buttonten: {
    position: "relative",
  },
  class11: {
    color: "#fff",
    fontSize: "14px",
    fontWeight: "500",
  },
  imgerelative: {
    position: "relative",
  },
  imgplan6: {
    position: "absolute",
    bottom: "-255px",
  },
  paddingtenso: {
    padding: 15,
    [theme.breakpoints.up("lg")]: {
      padding: 0,
    },
  },

  bgimgprofile: {
    background: "#d4ffe9",
    borderRadius: "15px",
  },
  ullicontent: {
    float: "left",
    listStyle: "none",
    margin: "0 5px",
    padding: "4px",
    borderRadius: "5px",
    color: "#007c7c",
    fontSize: 14,
  },
  textcnetercahpter: {
    display: "flex",
    paddingBottom: "12px",
    color: "#007c7c",
    fontSize: "12px",
    marginTop: "13px",
    textAlign: "left",
    fontWeight: "500",
    marginBottom: "0",
  },
  buttonright: {
    margin: "0 13px",
    padding: "7px 12px",
    fontSize: "12px",
    background: "#007c7c",
    fontWeight: "600",
    borderRadius: "4px",
    letterSpacing: "1px",
    marginTop: "22px",
    whiteSpace: "nowrap",
    [theme.breakpoints.up("sm")]: {
      marginTop: 0,
    },
  },
  buttoncorrect: {
    color: "#007c7c !important",
    background: "transparent !important",
    border: "1px solid #007c7c !important",
    fontSize: "12px",
    padding: "5px",
    boxShadow: "none",
    [theme.breakpoints.up("sm")]: {
      padding: "6px 15px",
      fontSize: "12px",
      margin: "auto",
      display: "block",
    },
  },
  redalert: {
    color: "#969696",
    fontSize: "12px",
    fontWeight: "500",
    marginTop: "12px",
    textAlign: "center",
  },
  buttonpartially: {
    color: "#febf00 !important",
    background: "transparent !important",
    border: "1px solid #febf00 !important",
    whiteSpace: "nowrap",
    fontSize: "12px",
    padding: "5px",
    boxShadow: "none",
    [theme.breakpoints.up("sm")]: {
      padding: "6px 15px",
      fontSize: "12px",
      margin: "auto",
      display: "block",
    },
  },
  incorrect: {
    color: "#fe0000 !important",
    background: "transparent !important",
    border: "1px solid #fe0000 !important",
    fontSize: "12px",
    padding: "5px",
    boxShadow: "none",
    [theme.breakpoints.up("sm")]: {
      padding: "6px 15px",
      fontSize: "12px",
      margin: "auto",
      display: "block",
    },
    "&:hover": {
      background: "#fe0000+ !important",
    },
  },
  buttoncorrectactive: {
    color: "#fff !important",
    background: "#007c7c !important",
    border: "none !important",
    fontSize: "12px",
    padding: "5px",
    [theme.breakpoints.up("sm")]: {
      padding: "6px 15px",
      fontSize: "12px",
      margin: "auto",
      display: "block",
    },
    "&:hover": {
      background: "#007c7c !important",
    },
  },
  buttonpartiallyactive: {
    color: "#fff !important",
    background: "#febf00 !important",
    border: "1px solid #febf00 !important",
    whiteSpace: "nowrap",
    fontSize: "12px",
    padding: "5px",
    boxShadow: "none",
    [theme.breakpoints.up("sm")]: {
      padding: "6px 15px",
      fontSize: "12px",
      margin: "auto",
      display: "block",
    },
    "&:hover": {
      background: "#febf00 !important",
    },
  },
  incorrectactives: {
    color: "#fff !important",
    background: "#fe0000 !important",
    border: "none !important",
    fontSize: "12px",
    padding: "5px",
    [theme.breakpoints.up("sm")]: {
      padding: "6px 15px",
      fontSize: "12px",
      margin: "auto",
      display: "block",
    },
  },
  colroblind: {
    color: "#00af50 !important",
    marginLeft: "20px",
  },
  mcqquestion: {
    marginTop: "20px",
    fontSize: 14,
  },
  mcqquestion2: {
    fontSize: 14,
  },
  buttonborder: {
    border: "none !important",
    boxShadow: "none",
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "70%",
      margin: "auto",
      display: "block",
    },
  },
  popupwidth: {
    width: 242,
    [theme.breakpoints.up("sm")]: {
      width: 221,
    },
  },
  textxenter: {
    textAlign: "center",
    margin: "auto",
    marginBottom: "12px",
    [theme.breakpoints.up("sm")]: {
      textAlign: "left",

      marginBottom: 12,
      margin: "0",
      maxWidth: "auto",
    },
  },
  centeralign: {
    textAlign: "center",
  },
  centerbutton: {
    margin: "auto",
    borderTop: "1px solid #dedede",
    width: "201px",
    justifyContent: "center",
    display: "flex",
  },
  textmaintext66: {
    color: "#fff",
    float: "right",
    display: "block",
    padding: "5px 14px",
    fontSize: "12px",
    background: "#000",
    fontWeight: "300",
    borderRadius: "4px",
    letterSpacing: "0",
    cursor: "pointer",
  },
}));

export default function UploadData() {
  const theme = useTheme();
  const navigate = useNavigate();
  const { state } = useLocation();
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [reviewReport, SetReviewReport] = useState("");
  const [studentListMarked, setStudentListMarked] = useState([]);
  const [showLoder, setShowLoader] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState("");
  const [students, setStudents] = useState([]);
  const [testPaperInfo, setTestPaperInfo] = useState({});
  const [userInfo, setUserInfo] = useState(Utility.getUserDetails());
  const [paperInfo, setPaperInfo] = useState([]);
  const [popupTitle, setPopupTitle] = useState("Confirmation");
  const [popupMessage, setPopupMessage] = useState("");
  const [isAbsent, setIsAbsent] = useState(true);

  useEffect(() => {
    console.log(userInfo);
    if (state) {
      console.log("Upload data:>>>", state);

      getstuqpch(state);
      // setSelectedUser(state);
      // setIdTestPaper(state?.idQuestionPaperDelivered);
      //  setUserDetails(userInfo);
    }
  }, []);

  const getstuqpch = (paperInfo) => {
    setTestPaperInfo(paperInfo);
    setShowLoader(true);
    const reqInfo = {
      Bo: userInfo?.Bo,
      idParent: userInfo?.idParent,
      idRole: Number(userInfo?.idRole),
      idTestPaper: paperInfo?.idQuestionPaperDelivered,
      idClass: paperInfo?.idClass,
    };

    const response = Utility.callPostAPI("getstuqpch", reqInfo);
    response.then((data) => {
      setShowLoader(false);
      if (data.data?.data) {
        setSelectedStudent("");
        const filteredMarkedStudents = data.data?.data.filter(
          (item) => Number(item.Checked) === 1
        );
        const filteredStudents = data.data?.data.filter(
          (item) => Number(item.Checked) === 0
        );
        setStudentListMarked(filteredMarkedStudents);
        setStudents(data.data?.data);
        setIsAbsent(true);
        // fetchData(paperInfo.idClass);
      }
    });
  };

  const fetchData = (idClass) => {
    setShowLoader(true);
    const reqInfo = {
      Bo: userInfo?.Bo,
      idInstitute: userInfo?.idParent,
      idRole: Number(userInfo?.idRole),
    };

    const response = Utility.callPostAPI("getinsstu", reqInfo);
    response.then((data) => {
      setShowLoader(false);
      if (data.data?.data) {
        const filteredStudents = data.data?.data.filter(
          (item) => Number(item.idClass) === Number(idClass)
        );
       
        if (filteredStudents.length > 0) {
          setStudents(filteredStudents[0]?.StudentInfo);
        }
      }
    });
  };

  const getPaper = () => {
    setShowLoader(true);
    setIsAbsent(true);
    const reqInfo = {
      Bo: userInfo?.Bo,
      idParent: userInfo?.idParent,
      idRole: Number(userInfo?.idRole),
      StuFirstName: userInfo?.InstituteName,
      StuLastName: "",
      StuGender: "N",
      idTestPaper: Number(testPaperInfo?.idQuestionPaperDelivered),
      idStudentSchool: Number(userInfo?.Ins_idStudentSchool),
      Stu_idStudentSchool:
        Number(selectedStudent?.Checked) > 0
          ? selectedStudent?.idStudentSchool
          : 0,
    };

    const response = Utility.callPostAPI("revques", reqInfo);
    response.then((data) => {
      setShowLoader(false);
      if (data.data?.data) {
        if (data.data?.message === "Cooling Period On!") {
          toast.warning(data.data.message);
          getPrevious();
          return true;
        }
        let updatedPaperInfo = data.data?.data;
        setPaperInfo(updatedPaperInfo);
        if (Number(updatedPaperInfo[0].Marked) === 0) {
          const updatedRows = updatedPaperInfo[0]?.Questions.map((r) => ({
            ...r,
            anstatus: 0,
          }));

          updatedPaperInfo = [
            { ...updatedPaperInfo[0], Questions: updatedRows },
          ];
          setPaperInfo(updatedPaperInfo);
        }

        setIsSubmit(true);
      }
    });
  };

  const getPrevious = () => {
    if (state.pageType === "REVIEW_ANSWER") {
      navigate(Utility.getPath("/reviewAnswers"), { replace: true });
    } else {
      navigate(Utility.getPath("/test-history"), { replace: true });
    }
  };

  const setPaper = (updatedRow) => {
    setIsAbsent(false);
    const updatedRows = paperInfo[0]?.Questions.map((r) =>
      r.idQuestion === updatedRow.idQuestion ? updatedRow : r
    );

    const updatedPaperInfo = [{ ...paperInfo[0], Questions: updatedRows }];

    setPaperInfo(updatedPaperInfo);
  };

  const handleSubmit = () => {
    const questionInfo = [];
    paperInfo[0]?.Questions.map((r) =>
      questionInfo.push(
        r.anstatus > 0
          ? { idQuestion: r.idQuestion, anstatus: r.anstatus }
          : { idQuestion: r.idQuestion, anstatus: 5 }
      )
    );
    insrecres(questionInfo);
  };

  const insrecres = (questionInfo) => {
    setShowLoader(true);
    const reqInfo = {
      Ins_Bo: userInfo?.Bo,
      idParent: Number(userInfo?.idParent),
      idPattern: Number(paperInfo[0]?.HeaderInfo[0]?.idPattern),
      Stu_Bo: selectedStudent?.Bo,
      Ins_idStudentSchool: Number(userInfo?.Ins_idStudentSchool),
      DateDeliveredOn: testPaperInfo?.DateDeliveredOn,
      GUQPID: Number(testPaperInfo?.idQuestionPaperDelivered),
      idStudentSchool: Number(selectedStudent?.idStudentSchool),
      AnswerStatus: questionInfo,
    };

    const response = Utility.callPostAPI("insrecres", reqInfo);
    response.then((data) => {
      setShowLoader(false);
      if (data.data) {
        
        setIsSubmit(false);
        getstuqpch(testPaperInfo);
        toast.success(data.data.message);
      }
    });
  };

  const skipstudent = () => {
    if (paperInfo && Number(selectedStudent?.Checked) === 0) {
      setPopupMessage("Skip  will lose the data  entered ");
      setPopupTitle("Confirmation");
      setOpen(true);
    } else {
      setIsSubmit(false);
    }
  };

  const handleConfirm = () => {
    setOpen(false);
    if (popupTitle === "Confirmation") {
      setIsSubmit(false);
    }
    else if(popupTitle === "Submit")
    {
      handleSubmit();
    }
  };

  const handleConfirmSubmit = () => {
    setOpen(true);
    setPopupTitle("Submit");
    setPopupMessage("Are you sure you want to proceed?");
  };

  const handleCancel = () => {
    console.log("Cancelled!");
    setOpen(false);
  };

  return (
    <Page title="Record Result | XPSOLV">
      <Grid container className="main-bg ">
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Typography variant="h4" gutterBottom className={classes.textcneter}>
            <Link
              underline="none"
              variant="contained"
              className="backbuttonbg"
              onClick={() => getPrevious()}
            >
              <ArrowBackIosIcon className="backicon" />
              Back
            </Link>
          </Typography>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <Box
            component="h2"
            display="inline"
            className="tabheading imgicondefine"
          >
            <img src="/static/cutoff-range.png" alt="imgtest" />
            {userInfo?.InstituteName}
          </Box>
        </Grid>
        <Grid item lg={12} md={12} sm={12} xs={12} className="bg_white_upload">
          <Grid item container>
            <Grid container className={classes.thefont}>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Box className={classes.bgprofile1}>
                  <Grid container>
                    <Grid item lg={4} md={4} sm={4} xs={12}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        className="heading_file"
                      >
                        <CalendarMonthIcon className="date-icon" />
                        Date :
                        <Box
                          component="span"
                          style={{ marginLeft: "30px" }}
                          display="inline"
                          className={`f-12 ${classes.textcolor}`}
                        >
                          {" "}
                          {testPaperInfo?.DateDeliveredOn}
                        </Box>
                      </Typography>
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={12}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        className="heading_file"
                      >
                        <PostAddIcon className="date-icon" />
                        Test ID :
                        <Box
                          component="span"
                          style={{ marginLeft: "60px" }}
                          display="inline"
                          className={`f-12 ${classes.textcolor}`}
                        >
                          {" "}
                          {testPaperInfo?.idQuestionPaperDelivered}
                        </Box>
                      </Typography>
                    </Grid>
                    <Grid item lg={4} md={4} sm={4} xs={12}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        className="heading_file"
                      >
                        <SchoolIcon className="date-icon" />
                        Class :
                        <Box
                          component="span"
                          style={{ marginLeft: "60px" }}
                          display="inline"
                          className={`f-12 ${classes.textcolor}`}
                        >
                          {" "}
                          {testPaperInfo?.Class}
                        </Box>
                      </Typography>
                    </Grid>

                    <Grid item lg={4} md={4} sm={4} xs={12}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        className="paper-details"
                      >
                        <ListAltIcon className="paper_details_icon" />
                       Subject :
                        <Box
                          component="span"
                          display="inline"
                          style={{ marginLeft: "10px" }}
                          className={`f-12 ${classes.textcolor}`}
                        >
                          {testPaperInfo?.SubjectName}
                        </Box>
                      </Typography>
                    </Grid>
                    <Grid item lg={8} md={8} sm={8} xs={12}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        className="paper-details"
                      >
                        <ContentPasteIcon className="paper_details_icon" />
                        Paper Details :
                        <Box
                          component="span"
                          display="inline"
                          style={{ marginLeft: "10px" }}
                          className={`f-12 ${classes.textcolor}`}
                        >
                          {testPaperInfo?.PFormat}
                        </Box>
                      </Typography>
                    </Grid>

                    <Grid item lg={12} md={12} sm={12} xs={12}>
                      <Typography
                        variant="h6"
                        gutterBottom
                        className="chapter-name-file"
                      >
                        <ImportContactsIcon className="chapter-icon" />
                        Chapter :{" "}
                        <Box
                          component="span"
                          display="inline"
                          style={{ marginLeft: "10px" }}
                          className={`f-12 ${classes.textcolor}`}
                        >
                          {" "}
                          <ul className="chap_list_name">
                            {testPaperInfo?.ChapterInfo?.map((item, index) => (
                              <li
                                key={index}
                                className={`f-12 ${classes.ullicontent}`}
                              >
                                <Chip
                                  label={item.ChapterName}
                                  className="f-12"
                                  variant="outlined"
                                />
                              </li>
                            ))}
                          </ul>
                        </Box>
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <>
          {studentListMarked.length > 0 && (
            <Grid spacing={2}
            container
           >
              <Grid item xs={12} sm={12} mt={2} >
              <Typography
                variant="h6"
                gutterBottom
                style={{ width: "100%" }}
                className="result_record"
              >
                Results Recorded
              </Typography>
              <Grid>
                <ul className="record_list">
                  {studentListMarked.length > 0 &&
                    studentListMarked?.map((item, index) => (
                      <li key={index} className={`f-12 ${classes.ullicontent}`}>
                        <Chip
                          label={`${item.FirstName} ${item.LastName}`}
                          className="select_stname"
                          variant="outlined"
                        />
                      </li>
                    ))}
                </ul>
              </Grid>
              <Divider className="seprater" />
            </Grid>
            </Grid>
          )}

          <Grid
            spacing={2}
            container
            style={{ marginTop: "10px", width: "100%" }}
            className={ `main-container ${isSubmit && selectedStudent?classes.thefont:classes.bootomMr}`}
          >
              <Grid item xs={12} sm={4} style={{ marginRight: "10px" }}>
                <FormControl fullWidth>
                  <Autocomplete
                    id="students"
                    className="search_dropdown"
                    value={selectedStudent}
                    options={students}
                    disabled={isSubmit}
                    onChange={(event, newValue) => {
                      setSelectedStudent(newValue);
                    }}
                    getOptionLabel={(option) =>
                      option
                        ? `${option.FirstName} ${option.LastName}` || ""
                        : ""
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Student"
                        variant="outlined"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={4} className="start_btn_resp">
                <Button
                  type="button"
                  className="btn_startrecoding"
                  size="large"
                  variant="contained"
                  disabled={isSubmit}
                  onClick={getPaper}
                >
                  Start Recording
                </Button>
            </Grid>
          </Grid>
        </>
        {isSubmit && selectedStudent && (
          <>
            <Grid
              item
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className="padding_set_views"
            >
              <Typography
                variant="h4"
                gutterBottom
                className={`f-16 ${classes.textcneter}`}
              >
                <Box
                  component="h2"
                  display="inline"
                  className="tabheading imgicondefine"
                >
                  <img src="/static/cutoff-range.png" alt="imgtest" />
                  Questions Reports
                </Box>
                <Button
                  underline="none"
                  variant="contained"
                  className="skip_student"
                  onClick={skipstudent}
                >
                  Skip Student
                </Button>
              </Typography>
              <Divider className="seprater" />
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <TableContainer component={Paper}>
                  <Table aria-label="simple table" className="data_table_inputview">
                    <TableHead>
                      <TableRow>
                        <TableCell>SR</TableCell>
                        <TableCell> Question & Answer</TableCell>
                        <TableCell>Correct</TableCell>
                        <TableCell>Incorrect</TableCell>
                        {testPaperInfo?.isMCQ === 0 && (
                          <TableCell>Partially Correct</TableCell>
                        )}
                        <TableCell>Did Not Attempt</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className="input_datafild">
                      {paperInfo[0]?.Questions.map((row) => (
                        <TableRow key={row.idQuestion}>
                          <TableCell>
                            {" "}
                            Q.{row.QNumber}.{" "}
                            
                          </TableCell>
                          <TableCell>
                          <Link
                              underline="none"
                              onClick={(event) => {
                                setPopupMessage(Base64.decode(row.QARev));
                                setPopupTitle("Question Details");
                                setOpen(true);
                              }}
                              className={`f-12 quesdetails `}
                            >
                              Details
                            </Link>
                            
                          </TableCell>
                          <TableCell>
                            <RadioGroup
                              row
                              className="uploadRadio radiobtn"
                              name={`question-${row.idQuestion}`}
                              value={
                                row.anstatus > 0 ? row.anstatus.toString() : ""
                              }
                              disabled={Number(selectedStudent.Checked) > 0}
                              onChange={(event) => {
                                const updatedRow = { ...row, anstatus: 2 };
                                setPaper(updatedRow);
                              }}
                            >
                              <FormControlLabel
                                value="2"
                                className="radiobtnLabel"
                                disabled={Number(selectedStudent.Checked) > 0}
                                control={<Radio />}
                                label=""
                              />
                            </RadioGroup>
                          </TableCell>
                          <TableCell>
                            <RadioGroup
                              row
                              className="uploadRadio radiobtn"
                              name={`question-${row.idQuestion}`}
                              value={row.anstatus.toString()}
                              disabled={Number(selectedStudent.Checked) > 0}
                              onChange={(event) => {
                                const updatedRow = { ...row, anstatus: 3 };
                                setPaper(updatedRow);
                              }}
                            >
                              <FormControlLabel
                                value="3"
                                className="radiobtnLabel"
                                control={<Radio />}
                                disabled={Number(selectedStudent.Checked) > 0}
                                label=""
                              />
                              {/* Add more options as needed */}
                            </RadioGroup>
                          </TableCell>
                          {testPaperInfo?.isMCQ === 0 && (
                            <TableCell>
                              <RadioGroup
                                row
                                className="uploadRadio radiobtn"
                                name={`question-${row.idQuestion}`}
                                value={row.anstatus.toString()}
                                disabled={Number(selectedStudent.Checked) > 0}
                                onChange={(event) => {
                                  const updatedRow = { ...row, anstatus: 4 };
                                  setPaper(updatedRow);
                                }}
                              >
                                <FormControlLabel
                                  value="4"
                                  className="radiobtnLabel"
                                  control={<Radio />}
                                  disabled={Number(selectedStudent.Checked) > 0}
                                  label=""
                                />
                                {/* Add more options as needed */}
                              </RadioGroup>
                            </TableCell>
                          )}
                          <TableCell>
                            <RadioGroup
                              row
                              className="uploadRadio radiobtn"
                              name={`question-${row.idQuestion}`}
                              value={row.anstatus.toString()}
                              disabled={Number(selectedStudent.Checked) > 0}
                              onChange={(event) => {
                                const updatedRow = { ...row, anstatus: 5 };
                                setPaper(updatedRow);
                              }}
                            >
                              <FormControlLabel
                                value="5"
                                className="radiobtnLabel"
                                control={<Radio />}
                                disabled={Number(selectedStudent.Checked) > 0}
                                label=""
                              />
                              {/* Add more options as needed */}
                            </RadioGroup>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Grid
                  className="submit_btn_center"
                  item
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                >
                  {paperInfo && Number(selectedStudent?.Checked) === 0 && (
                    <Button
                      variant="contained"
                      color="primary"
                      className="submit_btn"
                      onClick={handleConfirmSubmit}
                    >
                      {isAbsent?"Absent Submit":"Submit"}
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
      <ConfirmationModal
        isOpen={open}
        title={popupTitle}
        message={popupMessage}
        onConfirm={popupTitle === "Confirmation" || popupTitle === "Submit"? handleConfirm : ""}
        onCancel={handleCancel}
      />
      <Loader isLoader={showLoder} showHide={setShowLoader} />
    </Page>
  );
}
