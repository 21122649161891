import React, { useEffect } from 'react';

const MathJaxComponent = ({ content }) => {
  useEffect(() => {
    if (window.MathJax) {
      window.MathJax.typesetPromise();
    }
  }, [content]);

  return (
    <div dangerouslySetInnerHTML={{ __html: content }} />
  );
};

export default MathJaxComponent;
