import { Link as RouterLink, useNavigate } from "react-router-dom";
// material
import {
  Box,
  Card,
  Container,
  Divider,
  Link,
  Typography,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
// layouts
// components
import Utility from "../utils/Utility";
import { MHidden } from "../components/@material-extend";
import Page from "../components/Page";
import { RegisterUserForm } from "../components/authentication/register";
import { AuthApi } from "../service/index";
import HSeparator from "../theme/Separator";
import "../components/css/style.css";
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
  stylebg: {
    background: "#000",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 550,
  margin: "auto",
  display: "flex",
  minHeight: "50vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Register() {
  const [isParentInfo, setIsParentInfo] = useState(1);
  const [classStateInfo, setClassStateInfo] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    getclassdist();
  }, []);

  useEffect(() => {
    if (localStorage.getItem('token') && localStorage.getItem('userInfo')) {
      const userInfo = Utility.getLS("userInfo");
      if(Number(userInfo[0].idRole) > 1)
      {
         navigate('/institute/app', { replace: true });  
      }
      else{
         navigate('/dashboard/app', { replace: true });  
      }
      
    }
  }, []);

  const studentVerify = (data) => {
    register(data);
  };
  const masterInfo = (data) => {
    setClassStateInfo(data);
  };

  const register = (reqBody) => {
    try {
      AuthApi.registerParent(reqBody).then(
        (resp) => {
          if (resp.status === 200) {
            // setIsParentInfo(2);
            if (resp.data.data) {
              navigate("/success");
            } else {
              toast.warning(resp.data.message);
            }
          }
        },
        (error) => {
          toast.warning("Error in Register");
        }
      );
    } catch (error) {
      console.log("Register Catch", error);
    }
  };

  const getclassdist = () => {
    const data = {
      idParent: "1",
      idRole: "1"
    };
    AuthApi.getclassdist(data).then((resp) => {
      if (resp.data.status === 200) {
        const filteredClassInfo = resp?.data.data[0]?.ClassInfo.filter(
          (item) => item.isK12 === 1
        );

        // Update the same object with the filtered ClassInfo
        resp.data.data[0].ClassInfo = filteredClassInfo;
        setClassStateInfo(resp.data.data);
        masterInfo(resp.data.data);
      } else {
        setClassStateInfo([]);
      }
    });
  };

  return (
    <div className='main-bgnew auth_pages register_auth' >
    <RootStyle title="Register | XPSOLV">
      

      <Container maxWidth="sm"  className='bg_white_reg'>
        <ContentStyle className="reg_mobile_views">
          <div style={{ display: isParentInfo === 2 ? "none" : "block" }}>
            <Box >
              <Typography variant="h4" gutterBottom>
                Registration
              </Typography>
             
              <Divider sx={{ mt: "10px", mb: "10px" }} />
            </Box>
          </div>

          <div
            style={{ display: isParentInfo === 1 ? "block" : "none" }}
            className="regbg"
          >
            <Typography
              variant="body1"
              gutterBottom
              sx={{ paddingBottom: "5px" }}
            >
              Student{" "}
              <Box
                component="span"
                display="inline"
                style={{ color: "#007c7c" }}
              >
                Details
              </Box>
            </Typography>
            <RegisterUserForm
              studentVerify={studentVerify}
              classStateInfo={classStateInfo}
              idParent={1}
            />
          </div>

          <div
            style={{ display: isParentInfo === 2 ? "block" : "none" }}
            className="regbg"
          >
            <Typography variant="subtitle1" sx={{ mt: 3, textAlign: "center" }}>
              Welcome to Xpsolv !
            </Typography>
            <div>&nbsp;</div>
            <HSeparator sx={{ marginTop: 5, marginBottom: 5 }} />

            <Typography variant="subtitle2" sx={{ mt: 3, textAlign: "center" }}>
              Thanks for registration. Your request is under review. you will
              recieve a whatsapp message for next step. Stay tuned !
            </Typography>
            {/* <Typography variant="subtitle2" sx={{ textAlign: "center" }}>
              you will recieve a whatsapp message for next step. Stay tuned !
            </Typography> */}
            <div>&nbsp;</div>
            <HSeparator sx={{ marginTop: 5, marginBottom: 5 }} />

            <Typography variant="subtitle2" sx={{ textAlign: "center" }}>
              Access app from browser: <br />
              <Link href="https://xpsolv.com">https://xpsolv.com</Link>
            </Typography>
            <Typography variant="subtitle2" sx={{ mt: 3, textAlign: "center" }}>
              Install mobile app from Google Playstore
            </Typography>
            <Typography variant="subtitle2" sx={{ mt: 3, textAlign: "center" }}>
              <a
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                target="_blank"
                rel="noreferrer"
                href="https://play.google.com/store/apps/details?id=com.seltcorp.xpsolv"
              >
                <img src="/static/playstore.png" alt="imgtest" />
              </a>
            </Typography>
          </div>

          <div style={{ display: isParentInfo === 4 ? "none" : "block" }}>
            <Typography
              variant="caption"
              align="center"
              sx={{ color: "text.secondary", mt: 3 }}
            >
              By registering, I agree to Minimal&nbsp;
              <Link underline="always" sx={{ color: "text.primary" }}>
                Terms of Service
              </Link>
              &nbsp;and&nbsp;
              <Link underline="always" sx={{ color: "text.primary" }}>
                Privacy Policy
              </Link>
              .
            </Typography>

            <Typography variant="subtitle2" sx={{ mt: 3, textAlign: "center" }}>
              Already have an account?&nbsp;
              <Link to="/login" component={RouterLink}>
                Login
              </Link>
            </Typography>
          </div>
        </ContentStyle>
      </Container>
    
    </RootStyle>
    </div>
  );
}
