import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { TextField, Button, Stack, Grid,Typography } from '@mui/material';
import { AuthApi } from '../../service/index';

const AddUserForm = ({addreminsteacher,showHide}) => {
    const [otpSent, setOtpSent] = useState(false);

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            mobile: '',
            otp: '',
        },
        validationSchema: Yup.object({
            firstName: Yup.string().required('First Name is required'),
            lastName: Yup.string().required('Last Name is required'),
            mobile: Yup.string()
                .matches(/^[0-9]{10}$/, 'Enter a valid 10-digit mobile number')
                .required('Mobile number is required'),
            otp: Yup.string()
                .when('otpSent', {
                    is: true,
                    then: Yup.string()
                        .matches(/^[0-9]{6}$/, 'Enter a valid 6-digit OTP')
                        .required('OTP is required'),
                }),
        }),
        onSubmit: (values) => {
            handleGetOtp(2,values);
        },
    });

    const handleGetOtp = (SwitchType,formData) => {
        showHide(true);
        const data = {
            mobilenum: formik.values.mobile,
            Switch: 3,
            OtpKeyed: formik.values.otp
        };

        AuthApi.mobileotp(data).then((resp) => {
            showHide(false);
            if (resp.data) {
                if (resp.data.status === 200) {
                        formData.switch = 1;
                        addreminsteacher(formData);
                }
                else {
                    const msg = resp?.data?.message;
                    if(msg === "Verification code is incorrect!")
                    {
                        formik.setFieldError('otp', resp.data?.message);
                    }
                    else{
                        formik.setFieldError('mobile', resp.data?.message);
                    }
                    
                }
            }
        });
    };

    return (
        <form onSubmit={formik.handleSubmit} autoComplete="off" noValidate>
            <Stack spacing={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm="6" md="6" xl="6">
                        <TextField
                            fullWidth
                            label="First Name"
                            {...formik.getFieldProps('firstName')}
                            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                            helperText={formik.touched.firstName && formik.errors.firstName}
                        />
                    </Grid>
                    <Grid item xs={12} sm="6" md="6" xl="6">
                        <TextField
                            fullWidth
                            label="Last Name"
                            {...formik.getFieldProps('lastName')}
                            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                            helperText={formik.touched.lastName && formik.errors.lastName}
                        />
                    </Grid>

                    <Grid item xs={12} sm="6" md="6" xl="6">
                        <TextField
                            fullWidth
                            label="Mobile Number"
                            {...formik.getFieldProps('mobile')}
                            error={formik.touched.mobile && Boolean(formik.errors.mobile)}
                            helperText={formik.touched.mobile && formik.errors.mobile}
                        />
                    </Grid>
                    <Grid item xs={12} sm="6" md="6" xl="6" style={{"marginTop":'5px'}}>
                    <Button type="submit" variant="contained"  size="small" color="primary" className='submit_btn' >
                        Save
                    </Button>
                    </Grid>
                </Grid>
                
               
            </Stack>
        </form>
    );
};

export default AddUserForm;
