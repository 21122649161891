import { Icon } from "@iconify/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
// material
import { Box, Container, Grid, Stack, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
// components
import Page from "../components/Page";
import HSeparator from "../theme/Separator";

import { ReferralForm, ReferralList } from "../components/forms/index";

const getIcon = (name) => (
  <Icon color="#007c7c" icon={name} width={22} height={22} />
);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  fontsizeheading: {
    fontSize: 16,
  },
  paper: {
    padding: theme.spacing(2),
  },
  cardcontent: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 16,
    paddingBottom: "0px !important",
  },
  peraclass2: {
    //  width: "202px",
    //  width:'auto',
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginBottom: "5px",
    //  whiteSpace: "nowrap"
  },
  peraclass: {
    fontSize: 13,
    paddingBottom: 10,
    fontWeight: 600,
    color: "#00007e",
  },
  emailphone: {
    fontSize: 13,
    fontWeight: 500,
    color: "#000000",
    fontFamily: "Poppins",
    marginTop: -5,
    lineBreak: "anywhere",
    marginLeft: 10,
    [theme.breakpoints.up("md")]: {
      lineBreak: "auto",
    },
  },
  headingfont: {
    fontSize: 16,
    fontWeight: 600,
  },
  btntext: {
    fontSize: 14,
    fontWeight: 500,
    padding: "0 9px",
    background: "#007c7c",
    color: "#ffffff",
    marginTop: "2px",
    marginBottom: "5px",
    "&:hover": {
      background: "#007c7c",
    },
  },
  textcenter: {
    textAlign: "left",
    lineHeight: 0,
  },
  headingfive: {
    display: "flex",
    marginBottom: 26,
  },
  perafive: {
    color: "#929292",
    fontWeight: 500,
  },
  cardpadding: {
    paddingLeft: 0,
    paddingBottom: 10,
  },
  cardborder: {
    border: "1px solid #c8c8c8",
    "&:hover": {
      backgroundSize: "cover",
      background: "linear-gradient(to right, #edfbf4 15%, #d4ffe9 94%)",
      borderColor: "#fff",
    },
  },
  centertext: {
    textAlign: "center",
    fontSize: 24,
  },
  centertextside: {
    textAlign: "left",
    fontSize: 24,
  },
  btnmain: {
    fontWeight: 500,
    marginTop: 18,
  },
  closebtn: {
    position: "absolute",
    right: 0,
    top: -12,
    fontWeight: 700,
  },
  textcolor: {
    color: "#007c7c",
  },
  textcolor2: {
    color: "#999",
    fontWeight: 500,
  },

  plusimg: {
    width: 29,
    marginTop: "-4px",
  },
  textmainid: {
    fontSize: 14,
  },
  textright: {
    textAlign: "right",
  },
  mainbtn: {
    background: "#effff7",
    borderTop: "1px solid #d3ffe9",
  },
  h4heaidng: {
    textAlign: "left",
    marginBottom: "5px",
    fontSize: "15px",
  },
  h4heaidng2: {
    textAlign: "left",
    marginBottom: "5px",
    marginTop: "20px",
    fontSize: "15px",
  },
  colorreport: {
    color: "#007c7c",
  },
  schoolheading: {
    borderBottom: "1px solid #ececec",
    paddingBottom: 15,
    color: "#00007e",
    fontSize: 13,
    fontWeight: "600",
  },
  mtop: {
    marginTop: 33,
    borderTop: "1px solid #ececec",
  },
  imgplan333: {
    width: 15,
    float: "left",
    marginTop: 5,
    marginRight: 11,
    [theme.breakpoints.up("sm")]: {
      width: 15,
    },
  },
  accountsummary: {
    borderBottom: "1px solid #c9c9c9",
    paddingTop: 18,
    paddingLeft: 0,
    marginBottom: 22,
  },
  summaryaccout: {
    borderBottom: "1px solid #c9c9c9",
    padding: 10,
    paddingTop: 0,
    paddingLeft: 0,
    marginBottom: 0,
    width: "auto",
    fontSize: 16,
    [theme.breakpoints.up("sm")]: {
      width: "978px",
    },
  },

  amount: {
    fontSize: 16,
    color: "#000",
    textAlign: "right",
  },
  btnmargin: {
    marginBottom: "21px",
    textAlign: "center",
    paddingTop: "7px !important",
  },
  amount2: {
    fontSize: 14,
    color: "#000",
    fontWeight: 500,
  },
  btnamount: {
    background: "#000",
    marginTop: "-5px",
  },
  colorwhite: {
    color: "#",
    fontWeight: 600,
  },
  margin000: {
    margin: "0 30px",
  },
  bordertable: {
    border: "1px solid #e8e8e8",
    marginBottom: 24,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  colorwhite2: {
    color: "#000",
  },
  colorwhite3: {
    color: "#000",
    fontSize: 16,
  },

  showtoggle: {
    background: "#fff",
    padding: "4px 16px",
    fontWeight: 500,
    color: "#fff",
    border: 0,
    borderRadius: 8,
    float: "right",
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  imgplan2: {
    width: 15,
    [theme.breakpoints.up("sm")]: {
      width: 15,
    },
  },
  addamountbtn: {
    background: "#007c7c",
    padding: "6px",
    fontSize: "14px",
    color: "#fff",
    borderRadius: "6px",
    textDecoration: "none",
  },
  ruppeicon: {
    position: "relative",
    top: "7px",
    width: "17px",
  },
  cardtable: {
    margin: "0 30px",
  },
  cardmain: {
    padding: "13px",
    margin: "10px 0 15px 15px",
    marginBottom: 10,
  },
  cardmain2: {
    margin: "0px 0px",
    display: "block",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

export default function Referral(props) {
  const navigate = useNavigate();
  const classes = useStyles();
  const [userMobile, setUserMobile] = useState("");
  const [userDetails, setUserDetails] = useState([]);
  const [isListUpdate, setListUpdate] = useState(0);

  const funUpdateList = () => {
    setListUpdate(isListUpdate + 1);
  };
  return (
    <Page title="Refferal | XPSOLV" className="main-page">
      <Container className="main-Container">
        <Grid container spacing={2}>
          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className={`padding_left_remove ${classes.textcenter}`}
          >
            <Stack
              direction="column"
              alignItems="left"
              justifyContent="space-between"
              mb={0}
            >
              <Box
                component="h2"
                display="inline"
                className="tabheading imgicondefine"
              >
                {" "}
                <img src="/static/cutoff-range.png" alt="imgtest" /> Refer A
                Friend
              </Box>

              <div className="regbg">
                <ReferralForm isListUpdate={1} funUpdateList={funUpdateList} />
              </div>
              <Typography
                variant="caption"
                gutterBottom
                className="f-12"
                sx={{ marginTop: 1 }}
              >
                Registration link will be sent to entered Mob No. on Whatsapp.
              </Typography>

              <HSeparator sx={{ marginTop: 20, marginBottom: 5 }} />
            </Stack>
          </Grid>
        </Grid>
      </Container>
      <Container className="main-Container top_space_page">
        <Grid container spacing={2}>
          <ReferralList updateList={isListUpdate} />

          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className={classes.textcenter}
          >
            <Stack
              direction="column"
              alignItems="left"
              justifyContent="space-between"
              mb={0}
            >
              <Typography
                variant="h4"
                gutterBottom
                className={classes.fontsizeheading}
              >
                Disclaimer:
              </Typography>
              <Stack className="margin-set-ref" sx={{ marginLeft: 2 }}>
                <ul className="poin-trem_and_conditions">
                  <li>
                    <Typography variant="caption" gutterBottom className="f-12">
                      10 Bonus Points (Rs.5 per BP) for each successful
                      registration.
                    </Typography>
                  </li>
                  {/*  <li>
                  <Typography variant="caption" gutterBottom className="f-12">
                  Redeem only after a quarter from date of award.
                </Typography>
              </li> */}
                  <li>
                    <Typography variant="caption" gutterBottom className="f-12">
                      On successful registration of 4 referred candidates, 1
                      month usage is free.
                    </Typography>
                  </li>
                  <li>
                    <Typography variant="caption" gutterBottom className="f-12">
                      Discount will be offered in subscription plan amount for
                      awarded points.
                    </Typography>
                  </li>
                </ul>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
