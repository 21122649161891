import React, { useEffect, useState } from 'react';
import { Box, Container, Typography, List, ListItem, Divider, Stack } from '@mui/material';
import Utility from '../utils/Utility';
import Page from '../components/Page';

const MessageShow = () => {
  const [messages, setMessages] = useState([]);
  const [userInfo, setUserMobile] = useState(Utility.getUserDetails());
  const [studentName, setStudentName] = useState({});
  useEffect(() => {
    fetchNotifications();
  }, []);

  const fetchNotifications = async () => {
    try {
      const LSTidStudentSchoolArray = userInfo?.StudentInfo.map(student => student.idStudentSchool);
      const studentLookup = userInfo?.StudentInfo.reduce((acc, student) => {
        acc[student.idStudentSchool] = `${student.FirstName} ${student?.LastName} ` 
        return acc;
      }, {});
      setStudentName(studentLookup);

      const reqInfo = {
        idParent: userInfo?.idParent,
        Bo: userInfo?.Bo,
        LSTidStudentSchool: LSTidStudentSchoolArray.join(','),
      };

      const response = await Utility.callPostAPI('getstmsgins', reqInfo);
      if (response.data?.data) {
        setMessages(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  return (
    <Page title="Student | XPSOLV">
    <Container   className="main-Container mt-5" mt={5}>
    <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h4" component="h2" display="flex" alignItems="center">
            <img
              src="/static/icons/my-institute.png"
              alt="Institute Icon"
              style={{ marginRight: 8, width: 24, height: 24 }}
            />
            Messages
          </Typography>
        </Stack>

        <Divider />

        <Box my={4} sx={{ height: '60vh', overflowY: 'auto', padding: 2 }}>
          <List>
            {messages.map((message, index) => (
              <React.Fragment key={index}>
                <ListItem
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: message.isUser ? 'flex-end' : 'flex-start',
                    mb: 1,
                  }}
                >
                  <Typography variant="subtitle2" color="textSecondary" sx={{ mt: 0.5 }}>
                  {studentName[message.idStudentSchool]}
                </Typography>
                  <Box
                    sx={{
                      width: '100%',
                      bgcolor: message.isUser ? 'primary.main' : 'grey.300',
                      color: message.isUser ? 'white' : 'black',
                      borderRadius: 2,
                      p: 2,
                      boxShadow: 1,
                    }}
                  >
                    <Typography variant="body2" component="p">
                    {Utility.base64urlDecode(message.msg)}
                    </Typography>
                  </Box>
                  <Typography variant="caption" color="textSecondary" sx={{ mt: 0.5 }}>
                  {message.InstituteName}
                  </Typography>
                </ListItem>
                {index < messages.length - 1 && <Divider component="li" />}
              </React.Fragment>
            ))}
          </List>
        </Box>
      </Container>
    </Page>
  );
};

export default MessageShow;
