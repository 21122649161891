import { Icon } from "@iconify/react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import IconButton from "@mui/material/IconButton";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// material
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
// components

import Page from "../components/Page";

import ConfirmationModal from "../components/popup/ConfirmationModal";

import { TestApi } from "../service/index";
import Utility from "../utils/Utility";

import "../components/css/style.css";

const getIcon = (name) => (
  <Icon color="#007c7c" icon={name} width={22} height={22} />
);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  fontsizeheading: {
    fontSize: 14,
  },
  paper: {
    padding: theme.spacing(2),
  },
  cardcontent: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 16,
    paddingBottom: "0px !important",
  },
  peraclass2: {
    width: "202px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
  peraclass: {
    fontSize: 10,
    paddingBottom: 10,
    fontWeight: 600,
    color: "#00007e",
  },
  emailphone: {
    fontSize: 10,
    fontWeight: 500,
    color: "#000000",
    fontFamily: "Poppins",
    marginTop: -5,
    lineBreak: "anywhere",
    marginLeft: 10,
  },
  headingfont: {
    fontSize: 14,
    fontWeight: 600,
  },
  btntext: {
    fontSize: 10,
    fontWeight: 500,
    padding: "0 9px",
    background: "#007c7c",
    color: "#ffffff",
    marginTop: "12px",
    "&:hover": {
      background: "#007c7c",
    },
  },
  iconcolor: {
    color: "#ffffff",
    padding: 0,
    marginRight: 10,
    fontSize: "14px",
    [theme.breakpoints.up("sm")]: {
      color: "#007c7c",
    },
  },
  iconcolor2: {
    fontSize: "13px",
    padding: "0",
    color: "#007c7c",
  },
  textcenter: {
    textAlign: "center",
    lineHeight: 0,
    fontSize: "12px",
  },
  headingfive: {
    display: "flex",
    marginBottom: 26,
  },
  perafive: {
    color: "#929292",
    fontWeight: 500,
  },
  cardpadding: {
    paddingLeft: 0,
    paddingBottom: 10,
  },
  cardborder: {
    border: "1px solid #c8c8c8",
    "&:hover": {
      backgroundSize: "cover",
      background: "linear-gradient(to right, #edfbf4 15%, #d4ffe9 94%)",
      borderColor: "#fff",
    },
  },
  centertext: {
    textAlign: "center",
    fontSize: 16,
  },
  centertextside: {
    textAlign: "left",
    fontSize: 16,
  },
  btnmain: {
    fontWeight: 500,
    marginTop: 18,
  },
  closebtn: {
    position: "absolute",
    right: 0,
    top: -12,
    fontWeight: 700,
  },
  textcolor: {
    color: "#007c7c",
  },
  textcolor2: {
    color: "#999",
    fontWeight: 500,
  },

  plusimg: {
    width: 29,
    marginTop: "-4px",
  },
  textmainid: {
    fontSize: 12,
  },
  textright: {
    textAlign: "right",
  },
  mainbtn: {
    background: "#effff7",
    borderTop: "1px solid #d3ffe9",
  },
  h4heaidng: {
    textAlign: "left",
    marginBottom: "5px",
    fontSize: "15px",
  },
  h4heaidng2: {
    textAlign: "left",
    marginBottom: "5px",
    marginTop: "20px",
    fontSize: "12px",
  },
  colorreport: {
    color: "#007c7c",
  },
  schoolheading: {
    borderBottom: "1px solid #ececec",
    paddingBottom: 15,
    color: "#00007e",
    fontSize: 10,
    fontWeight: "600",
  },
  mtop: {
    marginTop: 33,
    borderTop: "1px solid #ececec",
  },
  imgplan333: {
    width: 15,
    float: "left",
    marginTop: 5,
    marginRight: 11,
    [theme.breakpoints.up("sm")]: {
      width: 15,
    },
  },
  accountsummary: {
    borderBottom: "1px solid #c9c9c9",
    paddingTop: 18,
    paddingLeft: 0,
    marginBottom: 22,
  },
  summaryaccout: {
    borderBottom: "1px solid #c9c9c9",
    padding: 10,
    paddingTop: 0,
    paddingLeft: 0,
    marginBottom: 0,
    width: "auto",
    fontSize: 14,
    [theme.breakpoints.up("sm")]: {
      width: "978px",
    },
  },

  amount: {
    fontSize: 14,
    color: "#000",
    textAlign: "right",
  },
  btnmargin: {
    marginBottom: "21px",
    textAlign: "center",
    paddingTop: "7px !important",
  },
  amount2: {
    fontSize: 12,
    color: "#000",
    fontWeight: 500,
  },
  btnamount: {
    background: "#000",
    marginTop: "-5px",
  },
  colorwhite: {
    color: "#",
    fontWeight: 600,
  },
  margin000: {
    margin: "0 30px",
  },
  bordertable: {
    border: "1px solid #e8e8e8",
    marginBottom: 24,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  colorwhite2: {
    color: "#000",
    fontSize: 14,
  },
  colorwhite3: {
    color: "#000",
    fontSize: 14,
  },

  showtoggle: {
    background: "#fff",
    padding: "4px 16px",
    fontWeight: 500,
    color: "#fff",
    border: 0,
    borderRadius: 8,
    float: "right",
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  imgplan2: {
    width: 15,
    [theme.breakpoints.up("sm")]: {
      width: 15,
    },
  },
  addamountbtn: {
    background: "#007c7c",
    padding: "6px",
    fontSize: "12px",
    color: "#fff",
    borderRadius: "6px",
    textDecoration: "none",
  },
  ruppeicon: {
    position: "relative",
    top: "7px",
    width: "17px",
  },
  cardtable: {
    margin: "0 30px",
  },
  cardmain: {
    padding: "13px",
    margin: "10px 0 15px 15px",
    marginBottom: 10,
  },
  cardmain2: {
    margin: "0px 0px",
    display: "block",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  infotext: {
    fontSize: "10px",
    marginTop: "9px",
    marginBottom: "0",
    fontWeight: "400",
    marginLeft: 17,
  },
  boldfont: {
    fontWeight: 600,
  },
  alertalignment: {
    marginTop: 15,
  },
  infoicons: {
    float: "left",
    width: "15px",
    marginTop: "2px",
    marginRight: "5px",
  },
  logoutbtn: {
    fontSize: 15,
    background: "#089293",
    width: "124px",
    borderRadius: "6px",
    marginTop: "14px",
    color: "#fff",
  },
  buttoncomon2: {
    height: "35px",
    margin: "10px",
    padding: "0 15px",
    marginTop: "-9px",
    width: "100%",
  },
  inputid: {
    margin: "12px",
  },
  papers: {
    padding: theme.spacing(2),
    marginBottom: "10px",
    border: "1px solid #c9c9c9",
  },
  infoItem: {
    marginBottom: theme.spacing(1),
  },
}));

export default function RegistrationApproval(props) {
  const navigate = useNavigate();
  const { state } = useLocation();
  const classes = useStyles();
  const [userMobile, setUserMobile] = useState("");
  const [idParent, setIdParent] = useState("");
  const [userDetails, setUserDetails] = useState([]);
  const [regUserList, setRegUserList] = useState([]);

  const [open, setOpen] = useState(false);
  const [openTest, setOpenTest] = useState(false);
  const [maxWidth, setMaxWidth] = useState("sm");
  const [fullWidth, setFullWidth] = useState(true);
  const [selectedUserDetails, setSelectedUserDetails] = useState("");
  const [isShow, setisShow] = useState(false);
  const [isShow2, setisShow2] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState("");
  const [isPage, setIsPage] = useState("approvelList");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userInfo, setUserInfo] = useState(Utility.getLS("userInfo"));

  const anchorRef = useRef(null);

  useEffect(() => {
    getRegUserList();
    getUserDetails();
  }, []);

  const getRegUserList = () => {
  //  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const idUserParent = userInfo[0]?.idParent;
   
    try {
      const reqsBody = {
        Token: "eyJhbGciOiJIUzI1NiJ9.eyJSb2xlIjoiMSIsIklzc3VlciI6IjEiLCJ",
        idParent: idUserParent,
      };
      TestApi.getreg(reqsBody).then((resp) => {
        if (resp.status === 200) {
          if (resp.data.status === 200) {
            setRegUserList(resp.data.data);
            console.log(resp.data.data);
            // acctSummaryData(resp.data.data);
          } else {
            alert(resp.data.message);
          }
        }
      });
    } catch (error) {
      //   setShowLoader(false);
    }
  };

  const getUserDetails = () => {
  //  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
  //  console.log("userInfo", userInfo);

    const idUserParent = userInfo[0]?.idParent;
    setUserDetails(userInfo);
    setIdParent(idUserParent);
    setUserMobile(userInfo[0]?.Mobile);

    console.log(userDetails);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userInfo");
    navigate("/login", { replace: true });
  };

  const selectUser = (data) => {
    setSelectedUserDetails(data);
    setIsPage("approve");
  };

  const approveUser = () => {
    userApproveDecline(1);
  };

  const gotoBack = () => {
    setSelectedUserDetails("");
    setIsPage("approvelList");
  };

  const userApproveDecline = (verdictid) => {
  //  const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    const idUserParent = userInfo[0]?.idParent;
    try {
      const reqsBody = {
        Token: "eyJhbGciOiJIUzI1NiJ9.eyJSb2xlIjoiMSIsIklzc3VlciI6IjEiLCJ",
        idParent: idUserParent,
        RegMobilenum: selectedUserDetails?.Mobilenum,
        Verdict: verdictid,
      };
      TestApi.verdict(reqsBody).then((resp) => {
        if (resp.status === 200) {
          if (resp.data.status === 200) {
            getRegUserList();
            gotoBack();
            // acctSummaryData(resp.data.data);
          } else {
            alert(resp.data.message);
          }
        }
      });
    } catch (error) {
      //   setShowLoader(false);
    }
  };

  const handleConfirm = () => {
    console.log("Confirmed!");
    setIsModalOpen(false);
    userApproveDecline(2);
  };

  const handleCancel = () => {
    console.log("Cancelled!");
    setIsModalOpen(false);
  };
  return (
    <Page title="Registration Approval | XPSOLV">
      <div className={classes.margin000}>
      <Typography variant="h4">
              Registration{" "}
              <Box
                component="span"
                display="inline"
                className={` ${classes.textcolor}`}
              >
                Approval
              </Box>
            </Typography>
        {regUserList.length > 0 && (
          <>
            
            {isPage === "approvelList" && (
              <Container className={classes.accountsummary}>
                <Typography
                  variant="h6"
                  gutterBottom
                  className={classes.colorwhite2}
                >
                  Review -{" "}
                  <Box
                    component="span"
                    display="inline"
                    className={`f-14 ${classes.textcolor}`}
                  >
                    Approve or Reject
                  </Box>
                </Typography>

                <div>
                  <TableContainer className={classes.bordertable}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow key="add">
                          <TableCell
                            align="right"
                            className={classes.textcenter}
                          >
                            SR.No.
                          </TableCell>
                          <TableCell
                            align="right"
                            className={classes.textcenter}
                          >
                            Parent's Name
                          </TableCell>
                          <TableCell
                            align="right"
                            className={classes.textcenter}
                          >
                            Mobile
                          </TableCell>
                          <TableCell
                            align="right"
                            className={classes.textcenter}
                          >
                            Pin
                          </TableCell>
                          <TableCell
                            align="right"
                            className={classes.textcenter}
                          >
                            Location
                          </TableCell>
                          <TableCell
                            align="right"
                            className={classes.textcenter}
                          >
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {regUserList?.map((item, index) => (
                          <TableRow
                            key={`add_${index}`}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                          >
                            <TableCell
                              align="right"
                              className={classes.textcenter}
                            >
                              {index + 1}
                            </TableCell>
                            <TableCell
                              align="right"
                              className={classes.textcenter}
                            >
                              {item?.ParentFirstName} {item?.ParentLastName}
                            </TableCell>
                            <TableCell
                              align="right"
                              className={classes.textcenter}
                            >
                              {item?.Mobilenum}
                            </TableCell>
                            <TableCell
                              align="right"
                              className={classes.textcenter}
                            >
                              {item?.Pincode}
                            </TableCell>
                            <TableCell
                              align="right"
                              className={classes.textcenter}
                            >
                              {item?.State}, {item?.District}
                            </TableCell>
                            <TableCell
                              align="right"
                              className={classes.textcenter}
                            >
                              <IconButton
                                className={classes.iconcolor}
                                aria-label="View"
                                color="primary"
                                onClick={() => selectUser(item)}
                              >
                                {" "}
                                <Tooltip title="View" arrow>
                                  <VisibilityIcon />
                                </Tooltip>
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                <Grid container item spacing={2}>
                  <Grid item lg={12} xs={12} className={classes.cardmain2}>
                    {regUserList?.map((item, index) => (
                      <>
                        <Card className={classes.cardmain}>
                          <Grid container item spacing={2}>
                            <Grid item lg={6} xs={6}>
                              <Typography
                                variant="h6"
                                gutterBottom
                                className={classes.amount2}
                              >
                                SR.NO.
                              </Typography>
                            </Grid>
                            <Grid item lg={6} xs={6}>
                              <Typography
                                variant="h6"
                                gutterBottom
                                className={classes.amount2}
                              >
                                {index + 1}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container item spacing={2}>
                            <Grid item lg={6} xs={6}>
                              <Typography
                                variant="h6"
                                gutterBottom
                                className={classes.amount2}
                              >
                                Parent's Name
                              </Typography>
                            </Grid>
                            <Grid item lg={6} xs={6}>
                              <Typography
                                variant="h6"
                                gutterBottom
                                className={classes.amount2}
                              >
                                {item?.ParentFirstName} {item?.ParentLastName}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container item spacing={2}>
                            <Grid item lg={6} xs={6}>
                              <Typography
                                variant="h6"
                                gutterBottom
                                className={classes.amount2}
                              >
                                Mobile
                              </Typography>
                            </Grid>
                            <Grid item lg={6} xs={6}>
                              <Typography
                                variant="h6"
                                gutterBottom
                                className={classes.amount2}
                              >
                                {item?.Mobilenum}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container item spacing={2}>
                            <Grid item lg={6} xs={6}>
                              <Typography
                                variant="h6"
                                gutterBottom
                                className={classes.amount2}
                              >
                                Pin
                              </Typography>
                            </Grid>
                            <Grid item lg={6} xs={6}>
                              <Typography
                                variant="h6"
                                gutterBottom
                                className={classes.amount2}
                              >
                                {item?.Pincode}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container item spacing={2}>
                            <Grid item lg={6} xs={6}>
                              <Typography
                                variant="h6"
                                gutterBottom
                                className={classes.amount2}
                              >
                                Location
                              </Typography>
                            </Grid>
                            <Grid item lg={6} xs={6}>
                              <Typography
                                variant="h6"
                                gutterBottom
                                className={classes.amount2}
                              >
                                {item?.State}, {item?.District}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid container item spacing={2}>
                            <Grid item lg={6} xs={6}>
                              <Typography
                                variant="h6"
                                gutterBottom
                                className={classes.amount2}
                              >
                                Action
                              </Typography>
                            </Grid>
                            <Grid item lg={6} xs={6}>
                              <Typography
                                variant="h6"
                                gutterBottom
                                className={classes.amount2}
                              >
                                <IconButton
                                  aria-label="View"
                                  color="primary"
                                  onClick={() => selectUser(item)}
                                >
                                  {" "}
                                  <Tooltip title="View" arrow>
                                    <VisibilityIcon />
                                  </Tooltip>
                                </IconButton>
                              </Typography>
                            </Grid>
                          </Grid>
                        </Card>
                      </>
                    ))}
                  </Grid>
                </Grid>
              </Container>
            )}
          </>
        )}
        <>
          {isPage === "approve" && selectedUserDetails?.Role !=='Institute'  && (
            <Container className={classes.accountsummary}>
              <Paper className={classes.papers} elevation={1}>
                <Typography
                  variant="h6"
                  gutterBottom
                  className={classes.colorwhite2}
                >
                  Registration -{" "}
                  <Box
                    component="span"
                    display="inline"
                    className={`f-14 ${classes.textcolor}`}
                  >
                    Info
                  </Box>
                </Typography>
                <Grid container item spacing={2}>
                  <Grid item lg={12} xs={6}>
                    <Typography variant="subtitle2">Name</Typography>
                    <Typography variant="body2">
                      {selectedUserDetails?.ParentFirstName}{" "}
                      {selectedUserDetails?.ParentLastName}
                    </Typography>
                  </Grid>

                  <Grid item lg={12} xs={6}>
                    <Typography variant="subtitle2">Mobile</Typography>
                    <Typography variant="body2">
                      {selectedUserDetails?.Mobilenum}
                    </Typography>
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <Typography variant="subtitle2">Address</Typography>
                    <Typography variant="body2">
                      {selectedUserDetails?.State},{" "}
                      {selectedUserDetails?.District},{" "}
                      {selectedUserDetails?.Pincode}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>              
              <Paper className={classes.papers} elevation={1}>
                <Typography
                  variant="h6"
                  gutterBottom
                  className={classes.colorwhite2}
                >
                  Student{" "}
                  <Box
                    component="span"
                    display="inline"
                    className={`f-14 ${classes.textcolor}`}
                  >
                    Details
                  </Box>
                </Typography>
                <Grid container item spacing={2}>
                  <Grid item lg={12} xs={6}>
                    <Typography variant="subtitle2">Student</Typography>
                    <Typography variant="body2">
                      {selectedUserDetails?.StudentFirstName}{" "}
                      {selectedUserDetails?.StudentLastName} (
                      {selectedUserDetails?.Gender})
                    </Typography>
                  </Grid>

                  <Grid item lg={12} xs={6}>
                    <Typography variant="subtitle2">Class & Board</Typography>
                    <Typography variant="body2">
                      {selectedUserDetails?.ClassName},{" "}
                      {selectedUserDetails?.Board}
                    </Typography>
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <Typography variant="subtitle2">School</Typography>
                    <Typography variant="body2">
                      {selectedUserDetails?.SchoolName}, <br/>{selectedUserDetails?.Address}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
              <Grid container spacing={2} className="container-btn">
                <Grid item lg={2} sm={6} xs={4}>
                  <Button
                    variant="contained"
                    className="back-btn"
                    onClick={gotoBack}
                  >
                    Back
                  </Button>
                </Grid>
                <Grid item lg={2} sm={6} xs={4}>
                  <Button
                    variant="contained"
                    className="approve-btn"
                    onClick={approveUser}
                  >
                    Approve
                  </Button>
                </Grid>
                <Grid item lg={2} sm={6} xs={4}>
                  <Button variant="contained" className="rejact-btn" onClick={() => setIsModalOpen(true)}>
                    Reject
                  </Button>
                </Grid>
              </Grid>
              <div>
               
                <ConfirmationModal
                  isOpen={isModalOpen}
                  title="Confirmation"
                  message="Are you sure you want to proceed?"
                  onConfirm={handleConfirm}
                  onCancel={handleCancel}
                />
              </div>
            </Container>
          )}
        </>

        <>
          {isPage === "approve" && selectedUserDetails?.Role ==='Institute'  && (
            <Container className={classes.accountsummary}>
              <Paper className={classes.papers} elevation={1}>
                <Typography
                  variant="h6"
                  gutterBottom
                  className={classes.colorwhite2}
                >
                  Registration -{" "}
                  <Box
                    component="span"
                    display="inline"
                    className={`f-14 ${classes.textcolor}`}
                  >
                    Info
                  </Box>
                </Typography>
                <Grid container item spacing={2}>
                  <Grid item lg={12} xs={6}>
                    <Typography variant="subtitle2">Institute Name</Typography>
                    <Typography variant="body2">
                      {selectedUserDetails?.ParentFirstName}{" "}
                     
                    </Typography>
                  </Grid>

                  <Grid item sm={12} md={6} lg={6} xs={6}>
                    <Typography variant="subtitle2">Primary Details</Typography>
                    <Typography variant="body2">
                      {selectedUserDetails?.StudentFirstName}{" "}
                      {selectedUserDetails?.StudentLastName} 
                    </Typography>
                  </Grid>

                  <Grid item sm={12}  md={6} lg={6} xs={6}>
                    <Typography variant="subtitle2">Mobile</Typography>
                    <Typography variant="body2">
                      {selectedUserDetails?.Mobilenum}
                    </Typography>
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <Typography variant="subtitle2">Address</Typography>
                    <Typography variant="body2">
                    {selectedUserDetails?.ParentLastName},{" "}
                    {selectedUserDetails?.Resident_District},{" "}
                      {selectedUserDetails?.Resident_State},{" "}
                      {selectedUserDetails?.Pincode}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>              
              
              <Grid container spacing={2} className="container-btn">
                <Grid item lg={2} sm={6} xs={4}>
                  <Button
                    variant="contained"
                    className="back-btn"
                    onClick={gotoBack}
                  >
                    Back
                  </Button>
                </Grid>
                <Grid item lg={2} sm={6} xs={4}>
                  <Button
                    variant="contained"
                    className="approve-btn"
                    onClick={approveUser}
                  >
                    Approve
                  </Button>
                </Grid>
                <Grid item lg={2} sm={6} xs={4}>
                  <Button variant="contained" className="rejact-btn" onClick={() => setIsModalOpen(true)}>
                    Reject
                  </Button>
                </Grid>
              </Grid>
              <div>
               
                <ConfirmationModal
                  isOpen={isModalOpen}
                  title="Confirmation"
                  message="Are you sure you want to proceed?"
                  onConfirm={handleConfirm}
                  onCancel={handleCancel}
                />
              </div>
            </Container>
          )}
        </>
      </div>
    </Page>
  );
}
