import { Form, FormikProvider, useFormik } from 'formik';
import fuzzysort from 'fuzzysort';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
// material
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  ListItem,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';

import { toast } from 'react-toastify';

import { LoadCanvasTemplate, loadCaptchaEnginge, validateCaptcha } from 'react-simple-captcha';

// Import toastify css file
import 'react-toastify/dist/ReactToastify.css';

import { makeStyles } from '@material-ui/styles';

// ----------------------------------------------------------------------
import { AuthApi } from '../../../service/index';

import HSeparator from '../../../theme/Separator';

toast.configure();

export default function RegisterForm({ studentVerify, classStateInfo, idParent }) {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState();
  const [gender, setGender] = useState('');
  const [schoolList, setSchoolList] = useState([]);
  const [filterSchoolList, setFilterSchoolList] = useState([]);
  const [idsParent, setidsParent] = useState(1);
  const [showPassword, setShowPassword] = useState(false);
  const [showValidation, setshowValidation] = useState(false);
  const [showValidationText, setShowValidationText] = useState('');
  const [showPassValidation, setshowPassValidation] = useState(false);
  const [showPassValidationText, setShowPassValidationText] = useState('');
  const [showCaptchaValidation, setShowCaptchaValidation] = useState(false);
  const [showCaptchValidationText, setShowCaptchValidationText] = useState('');
  const [otpMsg, setOtpMsg] = useState(false);
  const [count, setCount] = useState(60);
  const [timer, setTimer] = useState(60);
  const [intervalId, setIntervalId] = useState(0);
  const [validation, setValidation] = useState({});
  const [schoolType, setSchoolType] = useState('0');

  useEffect(() => {
    loadCaptchaEnginge(4, '#f2f5fc', 'black', 'numbers');
  }, []);

  useEffect(() => {
    setidsParent(idParent);
  }, [idParent]);

  useEffect(() => {
    let validationInfo = {};
    if(Number(schoolType) !== 2)
    {
      validationInfo =  {
        firstName: Yup.string()
          .min(2, 'Name must be longer than 2 charaters')
          .max(50, 'Name is too long')
          .required('Student name is required'),
    
        gender: Yup.string().required('Gender is required'),
        schoolType: Yup.string().required('Type is required'),
        classname: Yup.string().required('Class is required'),
        state: Yup.string().required('State is required'),
        parentName: Yup.string()
          .min(2, 'Name must be longer than 2 charaters')
          .max(50, 'Name is too long')
          .required('Parent name is required'),
        mobilenum: Yup.string().max(10, 'Only 10-digits allowed').required('Mobile number is required'),
        otp: Yup.string().max(6, 'Only 6-digits allowed').required('Verification code is required'),
        schoolName: Yup.object().nullable().required('School is required')
      }
    }
    else{
      validationInfo =  {
        firstName: Yup.string()
          .min(2, 'Name must be longer than 2 charaters')
          .max(50, 'Name is too long')
          .required('Student name is required'),
    
        gender: Yup.string().required('Gender is required'),
        parentName: Yup.string()
          .min(2, 'Name must be longer than 2 charaters')
          .max(50, 'Name is too long')
          .required('Parent name is required'),
        mobilenum: Yup.string().max(10, 'Only 10-digits allowed').required('Mobile number is required'),
        otp: Yup.string().max(6, 'Only 6-digits allowed').required('Verification code is required'),
      }
    }
    setValidation(validationInfo);
  }, [schoolType]);

  

  const RegisterSchema = Yup.object().shape(validation);

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      gender: '',
      schoolType: '0',
      classname: '',
      state: '',
      parentName: '',
      mobilenum: '',
      otp: '',
      schoolName: '',
      idSchool: ''
    },
    validationSchema: RegisterSchema,
    onSubmit: (data) => {
      const registerInfo = {
        idParent: 1,
        Mobilenum: data.mobilenum,
        ParentFirstName: data.parentName,
        ParentLastName: '',
        idPincode: 1,
        StudentFirstName: data.firstName,
        StudentLastName: data.lastName,
        Gender: data.gender,
        idClass: data.classname,
        idSchool: data.idSchool,
        idRole: 1
      };
      verifyOtp(registerInfo);
    }
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  const handleChange = (event) => {
    console.log(event.target.value);
    console.log(event.target.name);
    if (event.target.name === 'state') {
      formik.setFieldValue('state', event.target.value);
      formik.setFieldValue('district', '');
      formik.setFieldValue('schoolName', '');
      formik.setFieldValue('idSchool', '');
      getSchoolList(event);
    } else if (event.target.name === 'schoolType') {
      setSchoolType(event.target.value);
      formik.setFieldValue('schoolType', event.target.value);
      formik.setFieldValue('state', '');
      formik.setFieldValue('schoolName', '');
      formik.setFieldValue('idSchool', '');
      formik.setFieldValue('classname', '');
      if(Number(event.target.value) === 2){
        formik.setFieldValue('idSchool', '30026');
        formik.setFieldValue('classname', '33');
      }
    } else if (event.target.name === 'district') {
      formik.setFieldValue('district', event.target.value);
    } else {
      if (event.target.name === 'otp') {
        setOtpMsg(false);
      }
      if (event.target.name === 'mobilenum') {
        setshowValidation(false);
        setShowValidationText('');
      }
      formik.setFieldValue(event.target.name, event.target.value);
    }
  };

  const getSchoolList = (event) => {
    setSchoolList([]);
    const data = {
      idParent: idsParent,
      idState: event.target.value,
      isK12: schoolType > 0 ? 0 : 1
    };
    AuthApi.getschool(data).then((resp) => {
      if (resp.data.status === 200) {
        setSchoolList(sortOptions(resp.data.data, 'schooldesc'));
      } else {
        toast.warning('invalid District.');
      }
    });
  };

  const handleFilterOptions = (options, { inputValue }) => {
    const results = fuzzysort.go(inputValue, options, {
      keys: ['id', 'schooldesc'],
      threshold: -10000,
      limit: 100,
      all: true
    });

    return results.map((result) => result.obj);
  };

  const sortOptions = (data, type) => {
    const sortedOptions = data?.slice().sort((a, b) => a[type].localeCompare(b[type]));
    return sortedOptions;
  };

  const doSubmit = () => {
    if (formik.values.mobilenum === '') {
      setshowValidation(true);
      setShowValidationText('Mobile is required');
    }
    if (formik.values.captcha === '') {
      setShowCaptchaValidation(true);
      setShowCaptchValidationText('Captcha is required');
      return false;
    }
    if (validateCaptcha(formik.values.captcha) === true) {
      generateOTP();
      loadCaptchaEnginge(4, '#f2f5fc', 'black', 'numbers');
      //   document.getElementById('user_captcha_input').value = '';
    } else {
      setShowCaptchaValidation(true);
      setShowCaptchValidationText('Captcha Does Not Match');

      //  document.getElementById('user_captcha_input').value = '';
    }
  };

  const generateOTP = () => {
    setshowValidation(false);
    setShowValidationText('');
    setshowPassValidation(false);
    setShowPassValidationText('');
    setShowCaptchaValidation(false);
    setShowCaptchValidationText('');
    if (formik.values.mobilenum === '') {
      setshowValidation(true);
      setShowValidationText('Mobile is required');
    } else if (formik.values.mobilenum && formik.values.mobilenum.length < 9) {
      setshowValidation(true);
      setShowValidationText('Mobile No. should be atleast 10 digits.');
    } else if (formik.values.mobilenum && formik.values.mobilenum.length > 10) {
      setshowValidation(true);
      setShowValidationText('Mobile No. should be 10 digits.');
    } else {
      const data = {
        mobilenum: formik.values.mobilenum,
        Switch: 1,
        OtpKeyed: formik.values.otp
      };
      AuthApi.mobileotp(data).then((resp) => {
        // eslint-disable-next-line no-debugger
        //   debugger;
        //  console.log("generateOTP", resp);
        if (resp.data) {
          if (resp.data.status === 200) {
            formik.setFieldValue('otp', resp.data.data);
            setCount(59);
            handleClick();
            formik.setFieldValue('captcha', '');
          } else {
            setshowValidation(true);
            setShowValidationText(resp.data.message);
          }
        } else {
          setshowValidation(true);
          setShowValidationText('student not found');
        }
      });
    }
  };

  const verifyOtp = (reqBodyInfo) => {
    try {
      const reqBody = {
        mobilenum: reqBodyInfo.Mobilenum,
        Switch: 2,
        OtpKeyed: formik.values.otp
      };

      AuthApi.mobileotp(reqBody).then(
        (resp) => {
          if (resp.status === 200) {
            console.log('Register', resp);
            //  formik.resetForm();
            if (resp.data.data) {
              console.log('Register', resp.data.data);
              studentVerify(reqBodyInfo);
            } else {
              //  toast.warning(resp.data.message);
              setOtpMsg(true);
            }
          }
        },
        (error) => {
          formik.setSubmitting(false);
          toast.warning('Error in Register');
        }
      );
    } catch (error) {
      console.log('Register Catch', error);
    }
  };

  const handleClick = () => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(0);
      setCount(59);
    }

    const newIntervalId = setInterval(() => {
      setCount((prevCount) => prevCount - 1);
    }, 1000);
    setIntervalId(newIntervalId);
  };

  const useStyles = makeStyles((theme) => ({
    borderchangetext: {
      '& label.Mui-focused': {
        color: '#007c7c'
      },
      // focused color for input with variant='outlined'
      '& .MuiOutlinedInput-root': {
        '&.Mui-focused fieldset': {
          borderColor: '#007c7c'
        }
      }
    },
    textrighte: {
      textAlign: 'right'
    },
    bggreen: {
      background: '#007c7c'
    },
    getotpbg: {
      color: '#007c7c',
      padding: '28px !important',
      position: 'absolute',
      right: '0px',
      borderRadius: '0px 8px 8px 0px',
      fontWeight: '600'
    }
  }));
  const classes = useStyles();

  return (
    <div>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                className="wt-bg"
                fullWidth
                label="Student full name"
                {...getFieldProps('firstName')}
                error={Boolean(touched.firstName && errors.firstName)}
                helperText={touched.firstName && errors.firstName}
              />
            </Stack>

            <Grid container spacing={2} sx={{ marginTop: '0px !important' }}>
              <Grid item spacing={2} xs={12} sm={12} className="p-0">
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-gender"
                    sx={{
                      fontSize: '14px'
                    }}
                  >
                    Gender
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="M"
                    name="gender"
                    value={gender}
                    {...getFieldProps('gender')}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="M"
                      className="option_radiobtn"
                      control={<Radio />}
                      label="Male"
                    />
                    <FormControlLabel
                      value="F"
                      className="option_radiobtn"
                      control={<Radio />}
                      label="Female"
                    />
                  </RadioGroup>
                  {Boolean(touched.gender && errors.gender) && (
                    <div style={{ color: 'red', fontSize: '12px' }}>{errors.gender}</div>
                  )}
                </FormControl>
              </Grid>

              <Grid item spacing={2} xs={12} sm={12} className="p-0">
                <FormControl>
                  <FormLabel
                    id="demo-radio-buttons-group-gender"
                    sx={{
                      fontSize: '14px'
                    }}
                  >
                    Enrolled In
                  </FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-radio-buttons-group-label"
                    name="schoolType"
                    value={schoolType}
                    {...getFieldProps('schoolType')}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="0"
                      className="option_radiobtn"
                      control={<Radio />}
                      label="School"
                    />
                    <FormControlLabel
                      value="1"
                      className="option_radiobtn"
                      control={<Radio />}
                      label="University"
                    />

                    <FormControlLabel
                      value="2"
                      className="option_radiobtn"
                      control={<Radio />}
                      label="Not Enrolled/ Drop Out"
                    />
                  </RadioGroup>
                  {Boolean(touched.schoolType && errors.schoolType) && (
                    <div style={{ color: 'red', fontSize: '12px' }}>{errors.schoolType}</div>
                  )}
                </FormControl>
              </Grid>
            </Grid>
            {Number(schoolType) !== 2 && (
              <>
                <HSeparator />
                <Typography variant="body1" gutterBottom sx={{ marginTop: '10px !important' }}>
                  Academic{' '}
                  <Box component="span" display="inline" style={{ color: '#007c7c' }}>
                    Details
                  </Box>
                </Typography>
                <Grid
                  container
                  spacing={1}
                  className="width_set_regi"
                  sx={{ marginTop: '0px !important' }}
                >
                  <Grid item spacing={1} xs={12} sm={6} className="p-0 res_regist_mobile ">
                    <FormControl fullWidth>
                      <InputLabel id="reg-class">Class</InputLabel>
                      <Select
                        className="wt-bg"
                        id="classname"
                        label="Class"
                        {...getFieldProps('classname')}
                        error={Boolean(touched.classname && errors.classname)}
                        helperText={touched.classname && errors.classname}
                        onChange={handleChange}
                      >
                        {Number(schoolType) === 0 &&
                          classStateInfo[0]?.ClassInfo?.map((option, index) => (
                            <MenuItem key={index} value={option.idClass}>
                              {option.ClassName}
                            </MenuItem>
                          ))}

                        {Number(schoolType) === 1 &&
                          classStateInfo[0]?.Postk12?.map((option, index) => (
                            <MenuItem key={index} value={option.idClass}>
                              {option.ClassName}
                            </MenuItem>
                          ))}
                      </Select>
                      {Boolean(touched.classname && errors.classname) && (
                        <div style={{ color: 'red', fontSize: '12px' }}>{errors.classname}</div>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} sm={6} className="p-0 res_regist_mobile">
                    <FormControl fullWidth>
                      <InputLabel id="reg-state">State</InputLabel>
                      <Select
                        className="wt-bg"
                        id="state"
                        label="State"
                        {...getFieldProps('state')}
                        error={Boolean(touched.state && errors.state)}
                        helperText={touched.state && errors.state}
                        onChange={handleChange}
                      >
                        {sortOptions(classStateInfo[0]?.StateInfo, 'State')?.map(
                          (option, index) => (
                            <MenuItem key={index} value={option.idState} className="uppercase">
                              {option.State}
                            </MenuItem>
                          )
                        )}
                      </Select>
                      {Boolean(touched.state && errors.state) && (
                        <div style={{ color: 'red', fontSize: '12px' }}>{errors.state}</div>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid
                  container
                  spacing={1}
                  className="width_set_regi"
                  sx={{ marginTop: '0px !important' }}
                >
                  <Grid item spacing={2} xs={12} className="p-0 res_regist_mobile">
                    <FormControl fullWidth>
                      <Autocomplete
                        id="schoolName"
                        className="uppercase wt-bg"
                        options={schoolList}
                        filterOptions={handleFilterOptions}
                        {...formik.getFieldProps('schoolName')}
                        value={formik.values?.schoolName}
                        onChange={(event, newValue) => {
                          formik.setFieldValue('idSchool', newValue?.id);
                          formik.setFieldValue('schoolName', newValue);
                        }}
                        getOptionLabel={(option) => (option ? option.schooldesc || '' : '')}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="School Name"
                            variant="outlined"
                            error={formik.touched.schoolName && Boolean(formik.errors.schoolName)}
                            helperText={formik.touched.schoolName && formik.errors.schoolName}
                          />
                        )}
                        renderOption={(props, option) => (
                          <ListItem className="uppercase" {...props}>
                            <ListItemText className="uppercase" primary={option.schooldesc} />
                          </ListItem>
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </>
            )}
            <HSeparator />
            <Typography variant="body1" gutterBottom sx={{ marginTop: '10px !important' }}>
              User Account{' '}
              <Box component="span" display="inline" style={{ color: '#007c7c' }}>
                Details
              </Box>
            </Typography>
            <Stack spacing={2} sx={{ marginTop: '0px !important' }}>
              <Grid container spacing={1} sx={{ marginTop: '0px !important' }}>
                <Grid item spacing={2} xs={12} md={12} className="p-0 res_regist_mobile ">
                  <TextField
                    className="wt-bg"
                    fullWidth
                    label="Parent/Guardian"
                    {...getFieldProps('parentName')}
                    error={Boolean(touched.parentName && errors.parentName)}
                    helperText={touched.parentName && errors.parentName}
                  />
                </Grid>
                <Grid item spacing={2} xs={12} md={12} className="p-0 ">
                  <TextField
                    className={`${'wt-bg'} ${classes.borderchangetext}`}
                    fullWidth
                    autoComplete="username"
                    type="text"
                    label="Mobile Number"
                    {...getFieldProps('mobilenum')}
                    onChange={handleChange}
                    error={Boolean(touched.mobilenum && errors.mobilenum) || showValidation}
                    helperText={(touched.mobilenum && errors.mobilenum) || showValidationText}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={6} md={3}>
                  <Typography className="chapc_retext">
                    <LoadCanvasTemplate reloadColor="#007c7c" reloadText="Refresh" />{' '}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={9} sm={6} style={{ position: 'relative' }}>
                  <TextField
                    className="wt-bg"
                    id="outlined-basic"
                    label="Enter Captcha"
                    variant="outlined"
                    style={{ borderRadius: '8px 0 0 8px', width: '100%' }}
                    {...getFieldProps('captcha')}
                    onChange={handleChange}
                    error={Boolean(touched.captcha && errors.captcha) || showCaptchaValidation}
                    helperText={(touched.captcha && errors.captcha) || showCaptchValidationText}
                  />

                  {count >= timer && (
                    <Button variant="contained" className="otpBtn" onClick={doSubmit}>
                      Get Code
                    </Button>
                  )}
                  <>
                    {count < 0 && (
                      <Button
                        className={classes.getotpbg}
                        size="large"
                        variant="text"
                        onClick={doSubmit}
                      >
                        Resend Code
                      </Button>
                    )}
                  </>
                  <>
                    {count >= 0 && count < timer && (
                      <Button className={classes.getotpbg} size="large" variant="text">
                        {count} Sec.
                      </Button>
                    )}
                  </>
                  <Typography style={{ display: 'flex', float: 'right' }}>
                    {' '}
                    <img
                      src="/static/icons/whatsapp.png"
                      className="whasappicon"
                      alt="whatsapp"
                    />{' '}
                    <span className="display-inline">+91-81309-12201 </span>
                  </Typography>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} md={12}>
                  <TextField
                    className={`${'wt-bg'} ${classes.borderchangetext}`}
                    fullWidth
                    autoComplete="current-password"
                    type={showPassword ? 'text' : 'text'}
                    label="Enter verification code"
                    {...getFieldProps('otp')}
                    onChange={handleChange}
                    error={Boolean(touched.otp && errors.otp) || showPassValidation}
                    helperText={(touched.otp && errors.otp) || showPassValidationText}
                  />
                  {otpMsg && (
                    <div style={{ color: 'red', fontSize: '12px' }}>
                      Incorrect verification code. Enter correct verification code and click
                      Register again !
                    </div>
                  )}
                </Grid>
              </Grid>
            </Stack>
            <Grid container className="div_reg_btn">
              <LoadingButton
                fullWidth
                mb={5}
                size="large"
                type="submit"
                variant="contained"
                className="login_front_bg box_shadow_none"
              >
                Register
              </LoadingButton>
            </Grid>
          </Stack>
        </Form>
      </FormikProvider>
    </div>
  );
}
