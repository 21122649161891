import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  Grid,
  Box,
  Chip
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import PlayCircleOutlineIcon from '@mui/icons-material/YouTube';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ReactPlayer from 'react-player/youtube';
import ConfirmationModal from './popup/ConfirmationModal';
import Utility from '../utils/Utility';
import { historyApi } from '../service/index';

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 400,
    border: '1px solid #ddd',
    borderRadius: '8px',
    // padding: theme.spacing(2),
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    padding: '3px 10px',
    borderBottom: '1px solid #ccc'
  },
  icon: {
    marginRight: theme.spacing(0.5),
    fontSize: '1rem'
  },
  mediaIcon: {
    marginLeft: theme.spacing(1),
    fontSize: '2rem'
  },
  chapterButton: {
    backgroundColor: '#e0f7fa',
    padding: '5px 10px',
    color: '#00796b',
    marginRight: theme.spacing(1),
    '&:hover': {
      backgroundColor: '#b2ebf2'
    }
  },
  viewButton: {
    backgroundColor: '#26a69a',
    padding: '5px 15px',
    color: '#fff',
    marginRight: theme.spacing(1),
    '&:hover': {
      backgroundColor: '#00796b'
    }
  },
  checkButton: {
    backgroundColor: '#0277bd',
    padding: '5px 10px',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#01579b'
    }
  },
  counter: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#757575',
    background: '#f5f5f5',
    borderRadius: '50%',
    width: '35px',
    height: '35px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5px'
  },
  timeText: {
    display: 'flex',
    alignItems: 'center',
    color: '#757575'
  },
  title: {
    fontWeight: 'bold'
  }
}));

const CardComponent = ({ cardInfo }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [contentType, setContentType] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userInfo, setUserInfo] = useState(Utility.getLS('userInfo'));
  const navigate = useNavigate();

  const showVideo = () => {
    setIsModalOpen(true);
    setContentType('video');
  };

  const showChapter = () => {
    setIsModalOpen(true);
    setContentType('chapter');
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const reviewAnswer = () => {
    cardInfo.idStudentSchool = Number(cardInfo?.Ins_idStudentSchool);
    cardInfo.idQuestionPaperDelivered = Number(cardInfo?.idTestPaper);
    cardInfo.pageType = 'MY_INSTITUTE';
    navigate(Utility.getPath('/review-answer'), { replace: true, state: cardInfo });
  };
  const viewQuestion = () => {
    console.log(cardInfo);
    const reqsBody = {
      idTestPaper: cardInfo?.idTestPaper,
      idStudentSchool: cardInfo?.Ins_idStudentSchool,
      idParent: userInfo[0].idParent,
      Bo: userInfo[0].Bo
    };

    historyApi.genhtml(reqsBody).then(
      (resp) => {
        if (resp.status === 200) {
          if (resp.data.status === 200) {
            resp.data.reviewData = cardInfo;
            navigate(Utility.getPath('/view-question'), {
              replace: true,
              state: resp.data
            });
          }
        } else {
          alert(resp.message);
        }
      },
      (error) => {
        alert(error.response.data.error);
      }
    );
  };
  return (
    <>
      <Card className={classes.card}>
        <CardContent style={{ padding: '0px' }}>
          <Grid container className={classes.header}>
            <Typography variant="body2" className={classes.timeText}>
              <CalendarTodayIcon className={classes.icon} />
              {new Date(cardInfo?.DateDeliveredOn?.substr(0, 20)).toLocaleDateString('en-us', {
                day: 'numeric',
                year: 'numeric',
                month: 'short'
              })}
              , {new Date(cardInfo?.DateDeliveredOn?.substr(0, 20)).toLocaleTimeString()}
            </Typography>
            <Box className={classes.counter}>{cardInfo?.idTestPaper}</Box>
          </Grid>
          <Grid container className={classes.header}>
            <Typography variant="body1" className={classes.title} gutterBottom>
              {cardInfo?.SubjectName} ({cardInfo?.ClassName})
            </Typography>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              {cardInfo?.PFormat}
            </Typography>
            <Grid container alignItems="center" justifyContent="space-between" pb={2}>
              <Button variant="outlined" className={classes.chapterButton} onClick={showChapter}>
                Chapter List
              </Button>
              {Number(cardInfo?.ytblk) !== 0 && (
                <PlayCircleOutlineIcon
                  color="secondary"
                  className={`red ${classes.mediaIcon}`}
                  onClick={showVideo}
                />
              )}
            </Grid>
          </Grid>
        </CardContent>
        <CardActions>
          <Grid container alignItems="center" justifyContent="space-between">
            <Button size="small" className={classes.viewButton} onClick={viewQuestion}>
              <VisibilityIcon className={classes.icon} />
              View
            </Button>

            {Number(cardInfo?.isRev) !== 0 && (
              <Button size="small" className={classes.checkButton} onClick={reviewAnswer}>
                <CheckCircleOutlineIcon className={classes.icon} />
                Check Answer
              </Button>
            )}
          </Grid>
        </CardActions>
      </Card>
      {isModalOpen && (
        <ConfirmationModal
          isOpen={isModalOpen}
          title={contentType === 'video' ? 'Youtube' : 'Chapters'}
          message={
            contentType === 'video' ? (
              <div style={{ position: 'relative', paddingTop: '56.25%' }}>
                <ReactPlayer
                  url={cardInfo?.ytblk}
                  controls
                  width="100%"
                  height="90%"
                  style={{ position: 'absolute', top: 0, left: 0 }}
                />
              </div>
            ) : (
              <ul className="chpterPopup">
                {cardInfo?.ChapterInfo &&
                  cardInfo?.ChapterInfo?.map((item, index) => (
                    <li key={index} className={classes.ullicontent}>
                      <Chip label={item.ChapterName} variant="outlined" />
                    </li>
                  ))}
              </ul>
            )
          }
          onConfirm=""
          onCancel={handleCancel}
          type="OTHER"
        />
      )}
    </>
  );
};

export default CardComponent;
