import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// material
import { LoadingButton ,TabPanel,TabContext} from "@material-ui/lab";
import { makeStyles } from "@material-ui/styles";
import "../../components/css/style.css";
import {
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
  Checkbox,
  Box,
  Tooltip,
  Button,
  Stack,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  FormControl,
  Autocomplete,
  Tabs,
  Tab,
  Container,
} from "@material-ui/core";

// components
import Loader from "../../components/Loader";
import Page from "../../components/Page";
import { TestApi } from "../../service/index";
import Iframe from "../Iframe";
import Utility from "../../utils/Utility";

import HSeparator from "../../theme/Separator";

toast.configure();

const useStyles = makeStyles((theme) => ({
  heightwidth: {
    height: "1000vh",
    margin: 0,
    padding: 0,
    overflow: "scroll",
  },
  
  btnsend: {
    display: "initial",
    justifyContent: "none",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "end",
      display: "flex",
    },
  },
  btnprint: {
    height: "40px",
    marginTop: "7px",
    marginRight: "10px",
    marginLeft: "10px",
    padding: "0 14px",
    float: "left",
    [theme.breakpoints.up("sm")]: {
      display: "block",
      height: "40px",
    },
  },
  btnprint2: {
    marginRight: 10,
    height: "40px",
    marginTop: "7px",
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  aligndiv: {
    display: "block",
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      display: "inline-flex",
    },
  },
  widthdropdown: {
    width: "163px",
    float: "left",
  },
  /*   header:{
      display: "block",
    },
    footer:{
      display: "block",
    },
   [`@media print`]:  {
      header, footer: {
        display: 'none',
      }
    } */

  
  paper: {
    padding: theme.spacing(2),
  },
  cardcontent: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 16,
    paddingBottom: "0px !important",
  },
  peraclass2: {
    //  width: "202px",
    //  width:'auto',
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginBottom: "5px",
    //  whiteSpace: "nowrap"
  },
  peraclass: {
    fontSize: 13,
    paddingBottom: 10,
    fontWeight: 600,
    color: "#00007e",
  },
  emailphone: {
    fontSize: 13,
    fontWeight: 500,
    color: "#000000",
    fontFamily: "Poppins",
    marginTop: -5,
    lineBreak: "anywhere",
    marginLeft: 10,
    [theme.breakpoints.up("md")]: {
      lineBreak: "auto",
    },
  },
  headingfont: {
    fontSize: 16,
    fontWeight: 600,
  },
  btntext: {
    fontSize: 14,
    fontWeight: 500,
    padding: "0 9px",
    background: "#007c7c",
    color: "#ffffff",
    marginTop: "2px",
    marginBottom: "5px",
    borderRadius: "5px",
    "&:hover": {
      background: "#007c7c",
    },
  },
  btntext1: {
    padding: "0px 5px",
    "border-radius": "5px",
  },
  textcenter: {
    textAlign: "left",
    lineHeight: 0,
  },
  headingfive: {
    display: "flex",
    marginBottom: 26,
  },
  perafive: {
    color: "#929292",
    fontWeight: 500,
  },
  cardpadding: {
    paddingLeft: 0,
    paddingBottom: 10,
  },
  cardborder: {
    border: "1px solid #c8c8c8",
    "&:hover": {
      backgroundSize: "cover",
      background: "linear-gradient(to right, #edfbf4 15%, #d4ffe9 94%)",
      borderColor: "#fff",
    },
  },
  centertext: {
    textAlign: "center",
    fontSize: 24,
  },
  centertextside: {
    textAlign: "left",
    fontSize: 24,
  },
  btnmain: {
    fontWeight: 500,
    marginTop: 18,
  },
  closebtn: {
    position: "absolute",
    right: 0,
    top: -12,
    fontWeight: 700,
  },
  textcolor: {
    color: "#007c7c",
  },
  textcolor2: {
    color: "#999",
    fontWeight: 500,
  },
  colorunselect: {
    background: "#fff",
    boxShadow: "none",
    minWidth: "27px",
    width: "7px",
    height: "27px",
    borderRadius: "83px",
    padding: "14px",
    "&:hover": {
      background: "transparent",
    },
  },
  marginleftright: {
    marginRight: "13px",
    width: "12px",
    height: "27px",
    borderRadius: "110px",
    padding: "0",
    minWidth: "27px",
    background: "#fff",
    boxShadow: "none",
    "&:hover": {
      background: "transparent",
    },
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function ViewReportClass() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const classes = useStyles();

  const [userInfo, setUserInfo] = useState(Utility.getUserDetails());
  const [courses, setCourse] = useState([]);
  const [isCourse, setIsCourse] = useState(0);
  const [allCourses, setAllCourse] = useState([]);
  const [type, setType] = useState(1);

  const [selectedDate, setSelectedDate] = useState(Utility.TwoMonthsBeforeDate());
  const [selectedCourse, setSelectedCourse] = useState({});
  const [selectedSubject, setSelectedSubject] = useState({});
  const [showLoder, setShowLoader] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState("");

  const [reportData, setReportData] = useState([]);
  const [validationRequired, setValidationRequired] = useState({
    selectedCourse: false,
    selectedSubject: false,
    selectedDate: false,
  });
  const [currentTab, setCurrentTab] = useState("/institute/view-report-class");
  const [currentGraphTab, setCurrentGraphTab] = useState("graph6");

  const tabChange = (event, newValue) => {
    navigate(newValue);
  };

  const tabGraphChange = (event, newValue) => {
    setCurrentGraphTab(newValue);
  };

  useEffect(() => {
    getcourse();
  }, []);

  const getcourse = () => {
    setShowLoader(true);
    const reqInfo = {
      idParent: userInfo?.idParent,
    };

    const response = Utility.callPostAPI("getcourse", reqInfo);
    response.then((data) => {
      setShowLoader(false);
      if (data.data?.data) {
        const filteredCourse = data.data?.data.filter(
          (item) => Number(item.isClass) === type
        );
        setCourse(filteredCourse);

        const filteredCourseData = data.data?.data.filter(
          (item) => Number(item.isClass) === 0
        );
        setIsCourse(filteredCourseData.length);
        setAllCourse(data.data?.data);
      }
    });
  };

  const getReport = (imPlanVal) => {
    
    setCurrentGraphTab(imPlanVal > 0 ? 'graph4':'graph3');
    const reqInfo = {
      Bo: userInfo?.Bo,
      idInstitute: userInfo?.idParent,
      idClass: selectedCourse?.idClass,
      ClassName: selectedCourse?.ClassName,
      idSubject: selectedSubject?.idSubject,
      SubjectName: selectedSubject?.SubjectName,
      idRole: userInfo?.idRole,
      imPlan: imPlanVal,
      Stu_idStudentSchool: selectedStudent
        ? selectedStudent?.idStudentSchool
        : 0,
      FromDate: selectedDate,
    };
    setValidationRequired({
      selectedCourse: false,
      selectedSubject: false,
      selectedDate: false,
    });
    let isRequired = false;
    if (!selectedCourse?.ClassName) {
      setValidationRequired((prevState) => ({
        ...prevState,
        selectedCourse: true,
      }));

      isRequired = true;
    }

    if (!selectedSubject?.SubjectName) {
      setValidationRequired((prevState) => ({
        ...prevState,
        selectedSubject: true,
      }));

      isRequired = true;
    }

    if (!selectedDate) {
      setValidationRequired((prevState) => ({
        ...prevState,
        selectedDate: true,
      }));

      isRequired = true;
    }

    if (!isRequired) {
      insperf(reqInfo);
    }
  };

  const insperf = (reqInfo) => {
    setShowLoader(true);
    const response = Utility.callPostAPI("insperf", reqInfo);
    response.then((data) => {
      setShowLoader(false);
      if (data.data?.data) {
        console.log(data.data?.data[0]?.graphinfo);
        setReportData(data.data?.data[0]?.graphinfo);
      } else {
        toast.warning(data.data.message);
      }
    });
  };

  const handleChange = (event) => {
    if (event.target.name === "type") {
      setType(event.target.value);
      const filteredCourse = allCourses.filter(
        (item) => Number(item.isClass) === Number(event.target.value)
      );
      setCourse(filteredCourse);
      setSelectedCourse({});
      setSelectedSubject({});

      setSelectedStudent("");
      setReportData("");
    } else if (event.target.name === "course") {
      setSelectedCourse(event.target.value);
      setSelectedSubject({});

      setSelectedStudent("");
      setReportData("");
    } else if (event.target.name === "subject") {
      setSelectedSubject(event.target.value);
      setReportData("");
    } else if (event.target.name === "date") {
      setSelectedDate(event.target.value);
      setReportData("");
    }
  };

  return (
    <Page title="View Report Class | XPSOLV">
      <Container className="main-bg">
       
        <Grid container spacing={2} style={{ marginBottom: "15px" }}>
          <Tabs
            value={currentTab}
            onChange={tabChange}
            textColor="primary"
            indicatorColor="primary"
            aria-label="secondary tabs example"
            className="page-tab"
          >
            <Tab
              value="/institute/view-report-class"
              className="currenttab page-menu-tab"
              label="Class Level"
            />
            <Tab
              value="/institute/view-report"
              className="currenttab page-menu-tab"
              label="Student Level"
            />
          </Tabs>
        </Grid>
        <div className="student_bg_folow">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={2}
              className={classes.borderbotmten}
            >
              <Box
                component="h2"
                display="inline"
                className="tabheading imgicondefine"
              >
                <img src="/static/icons/performance-report.png" alt="imgtest" />
                Student  Report
                
                 
             
              </Box>
            </Stack>

            <HSeparator />
          </Grid>
        </Grid>
       
        <Grid container spacing={2}>
          {isCourse !== 0 && (
          <Grid item sm={6} xs={12}  md={3} lg={3}>
            <Stack justifyContent="space-between">
              <FormControl fullWidth variant="outlined">
                <InputLabel id="student-label" className={classes.label}>
                  Type
                </InputLabel>
                <Select
                  className={classes.borderchangetext}
                  fullWidth
                  id="type"
                  label="Type"
                  name="type"
                  onChange={handleChange}
                  value={type}
                >
                  <MenuItem value="1">Class</MenuItem>
                  <MenuItem value="0">Course</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </Grid>
          )}
          <Grid item sm={6} xs={12}  md={isCourse?3:4} lg={isCourse?3:4}>
            <Stack justifyContent="space-between">
              <FormControl fullWidth variant="outlined">
                <InputLabel id="student-label" className={classes.label}>
                  {Number(type) ===1?"Class":"Course"}
                </InputLabel>
                <Select
                  className={classes.borderchangetext}
                  fullWidth
                  id="course"
                  name="course"
                  label="Class/Course"
                  value={selectedCourse}
                  onChange={handleChange}
                >
                  {courses &&
                    courses.map((item, index) => (
                      <MenuItem key={`class- ${index}`} value={item}>
                        {item.ClassName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              {validationRequired?.selectedCourse && (
                <p className="f-10 red"> {Number(type) ===1?"Class":"Course"} is required</p>
              )}
            </Stack>
          </Grid>
          <Grid item sm={6} xs={12}  md={isCourse?3:4} lg={isCourse?3:4}>
            <Stack justifyContent="space-between">
              <FormControl fullWidth variant="outlined">
                <InputLabel id="student-label" className={classes.label}>
                  Subject
                </InputLabel>
                <Select
                  className={classes.borderchangetext}
                  fullWidth
                  id="subject"
                  name="subject"
                  label="Subject"
                  value={selectedSubject}
                  onChange={handleChange}
                >
                  {selectedCourse &&
                    selectedCourse?.SubjectInfo?.map((item, index) => (
                      <MenuItem key={`idSubject- ${index}`} value={item}>
                        {item.SubjectName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              {validationRequired?.selectedSubject && (
                <p className="f-10 red">Subject is required</p>
              )}
            </Stack>
          </Grid>
          <Grid item sm={6} xs={12}  md={isCourse?3:4} lg={isCourse?3:4}>
            <Stack justifyContent="space-between">
              <TextField
                id="date"
                name="date"
                type="date"
                label="Period from"
                value={selectedDate}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              {validationRequired?.selectedDate && (
                <p className="f-10 red">Date is required</p>
              )}
            </Stack>
          </Grid>

          <div className="action_button">
               <Grid item>
            <Stack justifyContent="space-between">
              <Button
                type="button"
                className="reportviews"
                size="large"
                variant="contained"
                onClick={() => getReport(0)}
              >
               <img src="/static/view-report.png" alt="imgtest" /> &nbsp;  View Report
              </Button>
            </Stack>
          </Grid>

          <Grid item>
            <Stack justifyContent="space-between">
              <Button
                type="button"
                className="reportviews"
                size="large"
                variant="contained"
                onClick={() => getReport(1)}
              >
               <img src="/static/improvement-plan.png" alt="imgtest" /> &nbsp;  Improvement Plan
              </Button>
            </Stack>
          </Grid>
          </div>
        </Grid>
        
        </div>
        
        <HSeparator />
        <Grid container className="resultshow_views"  spacing={2}>
          
        <TabContext value={currentGraphTab}>
          <Tabs
            value={currentGraphTab}
            onChange={tabGraphChange}
            textColor="primary"
            indicatorColor="primary"
            aria-label="secondary tabs example"
            className="page-tab top_tabsetting"
          >
            {reportData &&
              reportData.map((item, index) => (
                // eslint-disable-next-line react/jsx-key
                <Tab
                  value={item.name}
                  className="currenttab page-menu-tab"
                  label={item.label}
                />
              ))}
          </Tabs>
        
          {reportData &&
            reportData.map((item, index) => (
              
              // eslint-disable-next-line react/jsx-key
              <TabPanel value={item.name} style={{width:'100%'}}>
              
                  <Iframe
                    content={item.render}
                    id="printf"
                    name="printf"
                   
                    className="reportIfrem"
                  />
                
             </TabPanel>
            ))}
            </TabContext>
        </Grid>
      </Container>
      <Loader isLoader={showLoder} showHide={setShowLoader} />
    </Page>
  );
}
