import { Icon } from '@iconify/react';
import { useEffect, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import {
  Box,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  Container,
  Grid,
  Link,
  Stack,
  Tooltip,
  Typography,
  Paper,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { toast } from 'react-toastify';
import ConfirmationModal from '../components/popup/ConfirmationModal';
import CardComponent from '../components/CardComponent';
// components
import Page from '../components/Page';

import { ReferralForm } from '../components/forms/index';

import { TestApi } from '../service/index';
import HSeparator from '../theme/Separator';

import Utility from '../utils/Utility';
import 'react-toastify/dist/ReactToastify.css';

const getIcon = (name) => <Icon color="#007c7c" icon={name} width={22} height={22} />;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 345
  },
  media: {
    height: 140
  },
  fontsizeheading: {
    fontSize: 16
  },
  paper: {
    padding: theme.spacing(2)
  },
  cardcontent: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 16,
    paddingBottom: '0px !important'
  },
  peraclass2: {
    //  width: "202px",
    //  width:'auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginBottom: '5px'
    //  whiteSpace: "nowrap"
  },
  peraclass: {
    fontSize: 13,
    paddingBottom: 10,
    fontWeight: 600,
    color: '#00007e'
  },
  emailphone: {
    fontSize: 13,
    fontWeight: 500,
    color: '#000000',
    fontFamily: 'Poppins',
    marginTop: -5,
    lineBreak: 'anywhere',
    marginLeft: 10,
    [theme.breakpoints.up('md')]: {
      lineBreak: 'auto'
    }
  },
  headingfont: {
    fontSize: 16,
    fontWeight: 600
  },
  btntext: {
    fontSize: 14,
    fontWeight: 500,
    padding: '0 9px',
    background: '#007c7c',
    color: '#ffffff',
    marginTop: '2px',
    marginBottom: '5px',
    borderRadius: '5px',
    '&:hover': {
      background: '#007c7c'
    }
  },
  btntext1: {
    padding: '0px 5px',
    'border-radius': '5px'
  },
  textcenter: {
    textAlign: 'left',
    lineHeight: 0
  },
  headingfive: {
    display: 'flex',
    marginBottom: 26
  },
  perafive: {
    color: '#929292',
    fontWeight: 500
  },
  cardpadding: {
    paddingLeft: 0,
    paddingBottom: 10
  },
  cardborder: {
    border: '1px solid #c8c8c8',
    '&:hover': {
      backgroundSize: 'cover',
      background: 'linear-gradient(to right, #edfbf4 15%, #d4ffe9 94%)',
      borderColor: '#fff'
    }
  },
  centertext: {
    textAlign: 'center',
    fontSize: 24
  },
  centertextside: {
    textAlign: 'left',
    fontSize: 24
  },
  btnmain: {
    fontWeight: 500,
    marginTop: 18
  },
  closebtn: {
    position: 'absolute',
    right: 0,
    top: -12,
    fontWeight: 700
  },
  textcolor: {
    color: '#007c7c'
  },
  textcolor2: {
    color: '#999',
    fontWeight: 500
  },

  plusimg: {
    width: 29,
    marginTop: '-4px'
  },
  textmainid: {
    fontSize: 14
  },
  textright: {
    textAlign: 'right'
  },
  mainbtn: {
    background: '#effff7',
    borderTop: '1px solid #d3ffe9'
  },
  h4heaidng: {
    textAlign: 'left',
    marginBottom: '5px',
    fontSize: '15px'
  },
  h4heaidng2: {
    textAlign: 'left',
    marginBottom: '5px',
    marginTop: '20px',
    fontSize: '15px'
  },
  colorreport: {
    color: '#007c7c'
  },
  schoolheading: {
    borderBottom: '1px solid #ececec',
    paddingBottom: 15,
    color: '#00007e',
    fontSize: 13,
    fontWeight: '600'
  },
  mtop: {
    marginTop: 33,
    borderTop: '1px solid #ececec'
  },
  imgplan333: {
    width: 15,
    float: 'left',
    marginTop: 5,
    marginRight: 11,
    [theme.breakpoints.up('sm')]: {
      width: 15
    }
  },
  accountsummary: {
    borderBottom: '1px solid #c9c9c9',
    paddingTop: 18,
    paddingLeft: 0,
    marginBottom: 22
  },
  summaryaccout: {
    borderBottom: '1px solid #c9c9c9',
    padding: 10,
    paddingTop: 0,
    paddingLeft: 0,
    marginBottom: 0,
    width: 'auto',
    fontSize: 16,
    [theme.breakpoints.up('sm')]: {
      width: '978px'
    }
  },

  amount: {
    fontSize: 16,
    color: '#000',
    textAlign: 'right'
  },
  btnmargin: {
    marginBottom: '21px',
    textAlign: 'center',
    paddingTop: '7px !important'
  },
  amount2: {
    fontSize: 14,
    color: '#000',
    fontWeight: 500
  },
  btnamount: {
    background: '#000',
    marginTop: '-5px'
  },
  colorwhite: {
    color: '#',
    fontWeight: 600
  },
  margin000: {
    margin: '0 30px'
  },
  bordertable: {
    border: '1px solid #e8e8e8',
    marginBottom: 24,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },
  colorwhite2: {
    color: '#000'
  },
  colorwhite3: {
    color: '#000',
    fontSize: 16
  },

  showtoggle: {
    background: '#fff',
    padding: '4px 16px',
    fontWeight: 500,
    color: '#fff',
    border: 0,
    borderRadius: 8,
    float: 'right',
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },
  imgplan2: {
    width: 15,
    [theme.breakpoints.up('sm')]: {
      width: 15
    }
  },
  addamountbtn: {
    background: '#007c7c',
    padding: '6px',
    fontSize: '14px',
    color: '#fff',
    borderRadius: '6px',
    textDecoration: 'none'
  },
  ruppeicon: {
    position: 'relative',
    top: '7px',
    width: '17px'
  },
  cardtable: {
    margin: '0 30px'
  },
  cardmain: {
    padding: '13px',
    margin: '10px 0 15px 15px',
    marginBottom: 10
  },
  cardmain2: {
    margin: '0px 0px',
    display: 'block',
    [theme.breakpoints.up('sm')]: {
      display: 'none'
    }
  }
}));
toast.configure();

export default function MyInstitute(props) {
  const navigate = useNavigate();
  const classes = useStyles();
  const [userInfo, setUserMobile] = useState(Utility.getUserDetails());
  const [userDetails, setUserDetails] = useState(Utility.getLS('userInfo'));
  const [instituteList, setInstituteList] = useState([]);
  const [mcqData, setMcqData] = useState({});
  const [selectedStudent, setSelectedStudent] = useState(
    userDetails[0]?.StudentInfo[0]?.idStudentSchool || ''
  );
  const [removeIns, setRemoveIns] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedInstitute, setSelectedInstitute] = useState('');
  const [testPaperList, setTestPaperList] = useState([]);
  const [isExam, setIsExam] = useState({});

  //  const [readMore,setReadMore]=useState(false);

  useEffect(() => {
    getProfileInfo();
    stuinsinfo(selectedStudent, 0);
  }, []);

  useEffect(() => {
    stuinsinfo(selectedStudent, 0);
  }, [selectedStudent]);

  const getProfileInfo = () => {
    try {
      const reqsBody = {
        mobilenum: userInfo.Mobile,
        idParent: Number(userInfo.idParent)
      };
      TestApi.getProfileinfo(reqsBody).then((resp) => {
        if (resp.status === 200) {
          if (resp.data.status === 200) {
            // localStorage.setItem("token", resp.data.JWTToken);
            Utility.createLS('userInfo', resp?.data?.data);
            setUserDetails(Utility.getLS('userInfo'));
          } else {
            alert(resp.data.message);
          }
        }
      });
    } catch (error) {
      //   setShowLoader(false);
    }
  };

  const onlineTest = () => {
    navigate('/dashboard/online-test', {
      replace: true,
      state: isExam
    });
  };
  const handleDelete = (item) => {
    setIsModalOpen(true);
    setRemoveIns(item);
  };

  const getMcqOnline = () => {
    stuinsinfo(selectedStudent, 1);
  };

  const stuinsinfo = (selectedStudent, getQPInfo) => {
    const reqInfo = {
      idParent: userInfo?.idParent,
      Bo: userInfo?.Bo,
      idStudentSchool: selectedStudent,
      GetQPInfo: getQPInfo
    };

    const response = Utility.callPostAPI('stuinsinfo', reqInfo);
    response.then((data) => {
      if (data.data?.data) {
        if (getQPInfo > 0) {
          setMcqData(data.data?.data[0]);
          const mcqDataInfo = data.data?.data[0];

          const filteredStudents = userInfo?.StudentInfo?.filter(
            (student) => student.idStudentSchool === selectedStudent
          );

          const paperInfo = {
            mcqData: mcqDataInfo,
            selectedStudent: filteredStudents.length > 0 ? filteredStudents[0] : ''
          };
          setIsExam(paperInfo);
        } else {
          setInstituteList(data.data?.data);
          setSelectedInstitute(data.data?.data[0]);
          getstqpins(data.data?.data[0]?.idInstitute,data.data?.data[0]?.idClass);
          setIsExam({});
          getMcqOnline();
        }
      } else if (getQPInfo === 0) {
        setInstituteList([]);
      }
    });
  };

  const delinksi = () => {
    const reqInfo = {
      Ins_Bo: removeIns?.Ins_Bo,
      Stu_Bo: userInfo?.Bo,
      idParent: userInfo?.idParent,
      idRole: Number(userInfo?.idRole),
      idInstitute: Number(removeIns?.idInstitute),
      Stu_idStudentSchool: Number(selectedStudent)
    };

    const response = Utility.callPostAPI('delinksi', reqInfo);
    response.then((data) => {
      toast.success(data.data?.message);
      stuinsinfo(selectedStudent, 0);
    });
  };

  const getstqpins = (idInstitute,idClass) => {
    const reqInfo = {
      Bo: userInfo?.Bo,
      idParent: userInfo?.idParent,
      idInstitute: Number(idInstitute),
      idStudentSchool: Number(selectedStudent),
      idClass
    };

    const response = Utility.callPostAPI('getstqpins', reqInfo);
    response.then((data) => {
      if (data.data?.data) {
        setTestPaperList(data.data?.data);
      } else {
        setTestPaperList([]);
        // toast.warning(data.data?.message);
      }
    });
  };

  const handleConfirm = () => {
    console.log(userInfo);
    console.log(removeIns);
    setIsModalOpen(false);
    delinksi();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleChange = (event) => {
    console.log(event.target.name);
    if (event.target.name === 'student') {
      setSelectedInstitute('');
      setSelectedStudent(event.target.value);
    } else if (event.target.name === 'institute') {
      setSelectedInstitute(event.target.value);
      getstqpins(event.target.value?.idInstitute,event.target.value?.idClass);
    }
  };

  return (
    <Page title="My Institute | XPSOLV" className="main-page">
      <Container className="main-Container">
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={0}>
          <Box component="h2" display="inline" className="tabheading imgicondefine">
            <img src="/static/icons/my-institute.png" alt="imgtest" /> My Institute
          </Box>
        </Stack>

        <HSeparator />

        <Grid container spacing={2}>
          <Grid item lg={3} md={3} sm={3} xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="select-student-label">Select Student</InputLabel>
              <Select
                labelId="select-student-label"
                value={selectedStudent}
                onChange={handleChange}
                label="Select Student"
                name="student"
              >
                {userDetails[0]?.StudentInfo?.map((item) => (
                  <MenuItem key={item.idStudentSchool} value={item.idStudentSchool}>
                    {item?.FirstName} {item?.LastName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item lg={4} md={4} sm={3} xs={12}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="select-institute-label">Select Institute</InputLabel>
              <Select
                labelId="select-institute-label"
                value={selectedInstitute}
                onChange={handleChange}
                label="Select Institute"
                name="institute"
              >
                {instituteList?.map((item) => (
                  <MenuItem key={item.idInstitute} value={item}>
                    {item?.InstituteName} ({item?.ClassName})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>

        <Box my={1}>
          <HSeparator />
        </Box>
        {isExam && isExam?.mcqData && (
          <>
            <Grid container spacing={2}>
              <Grid item lg={12} md={12} sm={12} xs={12} display="flex" alignItems="center">
                <Typography>MCQ Paper available, please </Typography>
              </Grid>

              <Grid item lg={5} md={5} sm={6} xs={12} display="flex" alignItems="center">
                <Box justifyContent="space-between" flexGrow={1} display="flex" alignItems="center">
                  <Button
                    variant="contained"
                    color="primary"
                    className={classes.submitBtn}
                    onClick={onlineTest}
                  >
                    Attempt Now
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Box my={1}>
              <HSeparator />
            </Box>
          </>
        )}
        {testPaperList.length > 0 && (
          <Grid container spacing={2}>
            <Grid item lg={12} md={12} sm={12} xs={12} className={classes.textCenter}>
              <Typography variant="h6" className="heading_forment_views" mb={2}>
                Released Test Papers
              </Typography>
            </Grid>
            {testPaperList?.map((item) => (
              <Grid item key={item.idTestPaper} xs={12} sm={6} md={4}>
                <CardComponent cardInfo={{ ...item, Stu_idStudentSchool: selectedStudent }} />
              </Grid>
            ))}
          </Grid>
        )}
      </Container>

      <ConfirmationModal
        isOpen={isModalOpen}
        title="Confirmation"
        message="Are you sure you want to remove student in the institute?"
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
    </Page>
  );
}
