import React from "react";
import { Button, Divider } from "@material-ui/core";

import { makeStyles } from "@material-ui/styles";

import Iframe from "../../pages/Iframe";

const useStyles = makeStyles((theme) => ({
  fontsizeheading: {
    fontSize: 14,
    padding: "10px 0",
  },
  cancelBtn: {
    // background: 'none',
    // color: '#999',
  },
}));

const ConfirmationModalIrame = ({ isOpen, title, message, onConfirm, onCancel }) => {
  const modalStyle = {
    display: isOpen ? "block" : "none",
    position: "fixed",
    zIndex: 9999,
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    overflow: "auto",
    backgroundColor: "rgba(0,0,0,0.4)",
  };

  const modalContentStyle = {
    backgroundColor: "#fefefe",
    margin: "1% auto",
    padding: "20px",
    border: "1px solid #888",
    width: "80%",
    
    fontSize: "12px",
    borderRadius: "5px",
  };

  const modalActionsStyle = {
    marginTop: "10px",
    textAlign: "right",
  };

  const buttonStyle = {
    // Style for buttons
    fontSize: "14px", // Set the font size for buttons
    marginRight: "10px", // Adjust the right margin between buttons
  };

  const classes = useStyles();

  return (
    <div style={modalStyle} id={title}>
      <div style={modalContentStyle}>
        <h2 className={classes.fontsizeheading}>
          {title}{" "}
          <Button
            style={{
              position: "relative",
              top: "-15px",
              right: "-15px",
              float: "right",
            }}
            onClick={onCancel}
          >
            X
          </Button>
        </h2>
        <Divider className="seprater" />
        { message && (
        <div >
        <Iframe content={message} id="printf" height={60} name="printf" />
        </div>
     
      )}
        <div style={modalActionsStyle}>
          <>
          {onConfirm && (
          <Button style={buttonStyle} variant="outlined" onClick={onConfirm}>
            Confirm
          </Button>
          )}
          </>
          <Button
            style={buttonStyle}
            className={classes.cancelBtn}
            onClick={onCancel}
          >
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModalIrame;
