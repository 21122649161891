import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Container,
  Card,
  CardContent,
  Typography,
  Button,
  Box,
  useMediaQuery,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Stack,
} from "@material-ui/core";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { makeStyles } from "@material-ui/styles";
import { toast } from "react-toastify";
import ConfirmationModal from "../../components/popup/ConfirmationModal";
import ActionWithStudent from "../../components/ActionWithStudent";
import Loader from "../../components/Loader";
import Page from "../../components/Page";
import Utility from "../../utils/Utility";

import "react-toastify/dist/ReactToastify.css";

const useStyles = makeStyles({
  tableContainer: {
    maxWidth: "100%",
    overflowX: "auto",
  },
  searchInput: {
    // marginBottom: 20,
  },
  cardRoot: {
    marginBottom: 10,
  },
  colorreport: {
    color: "#007c7c",
  },
  cardActions: {
    display: "flex",
    justifyContent: "space-between", // Ensures buttons are spaced evenly
    "@media (max-width: 600px)": {
      justifyContent: "flex-start", // Aligns buttons to the left for small screens
    },
  },
  addStudentButton: {
    textAlign: "right", // Align button to the right
  },
});

toast.configure();

export default function Student() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [showLoder, setShowLoader] = useState(false);
  const [name, setName] = useState("");
  const [userInfo, setUserInfo] = useState(Utility.getUserDetails());
  const [mobile, setMobile] = useState("");
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [courses, setCourse] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [removeStudent, setRemoveStudent] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSendMsg, setIsSendMsg] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' });
  const [sortedStudentInfo, setSortedStudentInfo] = useState( []);

  useEffect(() => {
    getcourse();
    fetchData();
  }, []);

  const handelOrderNewTest = (item) => {
    navigate("/institute/plan-test", { replace: true, state: item });
  };
  const addMsg = (item) => {
    const foundClass = data.find(cls => cls.idClass === item.idClass);
    if(foundClass)
    {
      setIsSendMsg(true);
    }
    else{
      toast.warning("Student not register for selected class/course");
    }
   
  };

  const back = () => {
    setIsSendMsg(false);
  };

  const getcourse = () => {
    setShowLoader(true);
    const reqInfo = {
      idParent: userInfo?.idParent,
    };

    const response = Utility.callPostAPI("getcourse", reqInfo);
    response.then((data) => {
      setShowLoader(false);
      if (data.data?.data) {
        setCourse(data.data?.data);
      }
    });
  };

  const fetchData = () => {
    setShowLoader(true);
    const reqInfo = {
      Bo: userInfo?.Bo,
      idInstitute: userInfo?.idParent,
      idRole: Number(userInfo?.idRole),
    };

    const response = Utility.callPostAPI("getinsstu", reqInfo);
    response.then((data) => {
      setShowLoader(false);
      if (data.data?.data) {
        setData(data.data?.data);
        setFilteredData(data.data?.data);
      }
    });
  };

  const delinksi = () => {
    setShowLoader(true);
    const reqInfo = {
      Ins_Bo: userInfo?.Bo,
      Stu_Bo: removeStudent?.Bo,
      idParent: userInfo?.idParent,
      idRole: Number(userInfo?.idRole),
      idInstitute: Number(userInfo?.idParent),
      Stu_idStudentSchool: Number(removeStudent?.idStudentSchool),
    };

    const response = Utility.callPostAPI("delinksi", reqInfo);
    response.then((data) => {
      setShowLoader(false);
      fetchData();
      toast.success(data.data?.message);
    });
  };

  const filterData = (name, mobile) => {
    let updatedData = data;
    updatedData = data.map((classInfo) => {
      const matchedStudentInfo = classInfo.StudentInfo.filter(
        (student) =>
          student.FirstName.toLowerCase().includes(name.toLowerCase()) &&
          student.MobileNumber.includes(mobile)
      );
      return { ...classInfo, StudentInfo: matchedStudentInfo };
    });
    return updatedData;
  };

  const handleSearch = () => {
    setFilteredData(filterData(name, mobile));
  };

  const handleClear = () => {
    setName("");
    setMobile("");
    setFilteredData(filterData("", ""));
  };

  const handleConfirm = () => {
    setIsModalOpen(false);
    delinksi();
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const pubmsgto = (requestInfo) => {
    const reqInfo = {
      idParent: userInfo?.idParent,
      Ins_Bo: userInfo?.Bo,
      msg: requestInfo?.message,
      InstituteName: userInfo?.InstituteName,
      PubTo: requestInfo?.studentIds,
      expIn: requestInfo?.expIn,
    };

    const response = Utility.callPostAPI("pubmsgto", reqInfo);
    response.then((data) => {
      if (data.data?.data) {
        if (typeof data.data?.data === "string") {
          toast.warning(data.data.data);
          return true;
        }
      } else {
        toast.warning(data.data.message);
      }
      back();
    });
  };

  const sortedData = (key, direction) => {
    const sortableItems = [...sortedStudentInfo]; // Copy the data

    // Sorting based on the column key
    sortableItems.sort((a, b) => {
      let aKey = "";
      let bKey="";

      if (key === 'Name') {
        aKey = `${a.FirstName} ${a.LastName}`;
        bKey = `${b.FirstName} ${b.LastName}`;
      } else if (key === 'School') {
        aKey = a.SchoolName;
        bKey = b.SchoolName;
      }

      // Sorting by ascending or descending order
      if (aKey < bKey) {
        return direction === 'ascending' ? -1 : 1;
      }
      if (aKey > bKey) {
        return direction === 'ascending' ? 1 : -1;
      }
      return 0;
    });

    setSortedStudentInfo(sortableItems); // Update the sorted data in state
  };

  // Function to toggle sorting direction and set sorting key
  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
    sortedData(key, direction); // Call sorting function when header is clicked
  };
  const getSortIcon = (column) => {
    if (sortConfig.key === column) {
      return sortConfig.direction === 'ascending' ? '▲' : '▼';
    }
    return '';
  };

  useEffect(() => {
    // Find the matching row based on ClassName
    filteredData.forEach((row) => {
      setSortConfig({ key: '', direction: '' });
      if (selectedClass && selectedClass.ClassName === row.ClassName) {
        setSortConfig({ key: '', direction: '' });
        setSortedStudentInfo(row?.StudentInfo);
      }
    });
  }, [selectedClass, filteredData]);

  // Check if the screen size is small (mobile)
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Page title="Student | XPSOLV">
      <Container className="" >
      {!isSendMsg && (
        <>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          style={{"marginTop":"20px"}}
          mb={2}
        >
          <Box
            component="h2"
            display="inline"
            className="tabheading imgicondefine"
          >
            {" "}
            <img src="/static/cutoff-range.png" alt="imgtest" /> Student List
          </Box>
        </Stack>
        <div className="student_bg_folow">
        <Grid container className="" spacing={2}>
          <Grid item xs={12} xl={3} sm={12} md={4}>
            <FormControl fullWidth variant="outlined">
              <InputLabel id="search-class-label">Students List For</InputLabel>
              <Select
                labelId="search-class-label"
                id="search-class"
                className="select_field_area"
                label=" Students List For"
                value={selectedClass}
                onChange={(event) => setSelectedClass(event.target.value)}
              >
                {courses?.map((item) => (
                  // eslint-disable-next-line react/jsx-key
                  <MenuItem value={item}>{item.ClassName}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12} md={8} xl={9}  className="btn_center">
            <ul className="tow_action_btn">
              <li>
                <Button
                  onClick={(e) => {
                    handelOrderNewTest(selectedClass);
                  }}
                  variant="contained"
                  className="submit_btn addStuSt add_status_resp resp_div_student"
                  disabled={!selectedClass}
                >
                  Create Test
                </Button>
              </li>
              <li>
                <Button
                  onClick={(e) => {
                    addMsg(selectedClass);
                  }}
                  variant="contained"
                  className="submit_btn addStuSt add_status_resp resp_div_student"
                  disabled={!selectedClass}
                >
                  Send Message
                </Button>
              </li>
              <li>
                <Button
                  component={Link}
                  to="/institute/add-student"
                  variant="contained"
                  className="submit_btn addStuSt add_status_resp resp_div_student"
                >
                  Add New Student
                </Button>
              </li>
            </ul>
          </Grid>

          <Divider className="seprater" />
        </Grid>
        </div>
        </>
        )}

        {filteredData.map((row, index) => (
          <>
          
            {selectedClass && selectedClass.ClassName === row.ClassName && (
              
              <>
             
                {isSendMsg && (
                  <ActionWithStudent
                    pubmcq={pubmsgto}
                    reviewPaper={{"Class": selectedClass?.ClassName }}
                    studentInfo={row?.StudentInfo}
                    title="Send Message"
                    back={back}
                    type="MESSAGE"
                  />
                )}
                
                {!isSendMsg && (
                  <>
                   <div className="student_bg_folow">
                <Grid container spacing={2}>
                  <Grid
                    
                    xs={12}
                    xl={12}
                    sm={12}
                    md={12}
                  style={{ marginBottom: 10,width:'100%' }}
                  sx={{
                    paddingLeft: {
                      md: "16px",
                      xs: 0,
                      
                    },
                  }}
                  >
                    <Accordion
                      fullWidth
                      className="accordion"
                      defaultExpanded={false}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <span className="forment_heading">
                          {" "}
                          Additional Filter{" "}
                        </span>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={12}
                            xl={4}
                            sm={12}
                            md={4}
                            
                          >
                            <TextField
                              className="input_field_area"
                              label="Search by First Name"
                              variant="outlined"
                              value={name}
                              onChange={(event) => setName(event.target.value)}
                              fullWidth
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            xl={4}
                            sm={12}
                            md={4}
                            
                          >
                            <TextField
                              className="input_field_area"
                              label="Search by Mobile"
                              variant="outlined"
                              value={mobile}
                              onChange={(event) =>
                                setMobile(event.target.value)
                              }
                              fullWidth
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={12}
                            md={4}
                            xl={4}
                            
                          >
                            <ul className="btn_set_tracks">
                              <li>
                                <Button
                                  variant="contained"
                                  className="submit_btn"
                                  color="primary"
                                  onClick={handleSearch}
                                >
                                  Search
                                </Button>
                              </li>

                              <li>
                                <Button
                                  variant="contained"
                                  className="submit_btn"
                                  color="primary"
                                  onClick={handleClear}
                                >
                                  Clear All
                                </Button>
                              </li>
                            </ul>
                          </Grid>
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  {isSmallScreen ? (
                    // Render card layout for small screens
                    <Grid xs={12} className="mobilecard">
                      {row?.StudentInfo.map((item, index) => (
                        <Card key={index} className="margin_bottom">
                          <CardContent className="mCard">
                            <Typography className="studetn_name" variant="h6">
                              <b>
                                {" "}
                                {item.FirstName} {item.LastName}
                              </b>
                            </Typography>

                            <Typography className="student_basic_details university_name">
                              <span className="call_up_icons">
                                <img src="/static/school.png" alt="fg" />
                              </span>{" "}
                              {item.SchoolName}
                            </Typography>
                            <Typography className="student_basic_details mobile_univesity">
                              {" "}
                              <span className="call_up_icons">
                                <img src="/static/smartphone.png" alt="fg" />
                              </span>{" "}
                              +91-{item.MobileNumber}
                            </Typography>

                            <Typography className="deleted_button">
                              {" "}
                              <Button
                                variant="outlined"
                                startIcon={<DeleteOutlinedIcon />}
                                className="red bnt_delect"
                                style={{ border: "red" }}
                                onClick={() => {
                                  setIsModalOpen(true);
                                  setRemoveStudent(item);
                                }}
                              />
                            </Typography>
                          </CardContent>
                        </Card>
                      ))}
                    </Grid>
                  ) : (
                    // Render table layout for larger screens
                    <>
                      <Grid className="desktopmain" xs={12}>
                        <Card className={classes.card}>
                          <TableContainer
                            component={Paper}
                            className={classes.tableContainer}
                          >
                            <Table>
                              <TableHead className="tableHeader">
                                <TableRow>
                                <TableCell>SR. NO.</TableCell>
                                <TableCell onClick={() => requestSort('Name')}>
                                  Name {getSortIcon('Name')}
                                </TableCell>
                                <TableCell onClick={() => requestSort('School')}>
                                  School {getSortIcon('School')}
                                </TableCell>
                                 <TableCell>Mobile</TableCell>
                                  <TableCell>Action</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {sortedStudentInfo.map((item, indexChild) => (
                                  <TableRow key={index + indexChild}>
                                    <TableCell>
                                      {indexChild+1}
                                    </TableCell>
                                    <TableCell>
                                      {item.FirstName} {item.LastName}
                                    </TableCell>
                                    <TableCell> {item.SchoolName} </TableCell>
                                    <TableCell>
                                      +91-{item.MobileNumber}
                                    </TableCell>
                                    <TableCell>
                                      <Button
                                        variant=""
                                        startIcon={<DeleteOutlinedIcon />}
                                        className="red"
                                        onClick={() => {
                                          setIsModalOpen(true);
                                          setRemoveStudent(item);
                                        }}
                                      >
                                        &nbsp;
                                      </Button>
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Card>
                      </Grid>
                    </>
                  )}
                </Grid>
                </div>
                </>
                 )}
                
              </>
            )}
          </>
        ))}
      </Container>
      <ConfirmationModal
        isOpen={isModalOpen}
        title="Confirmation"
        message="Are you sure you want to remove student in the institute?"
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
      <Loader isLoader={showLoder} showHide={setShowLoader} />
    </Page>
  );
}
