import React, { useState } from "react";
import {
  Alert,
  Grid,
  Button,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
  fontsizeheading: {
    fontSize: 14,
    padding: "10px 0",
  },
  cancelBtn: {
    // background: 'none',
    // color: '#999',
  },
}));

const ConfirmationModal = ({ isOpen, title, courses, onConfirm, onCancel }) => {
  const [course, setCourse] = useState("");
  const modalStyle = {
    display: isOpen ? "block" : "none",
    position: "fixed",
    zIndex: 9999,
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",
    overflow: "auto",
    backgroundColor: "rgba(0,0,0,0.4)",
  };

  const modalContentStyle = {
    backgroundColor: "#fefefe",
    margin: "5% auto",
    width: "40%",
    fontSize: "12px",
    borderRadius: "5px",
    
  };

  const modalActionsStyle = {
    marginTop: "10px",
    textAlign: "right",
  };

  const buttonStyle = {
    // Style for buttons
    fontSize: "14px", // Set the font size for buttons
    marginRight: "10px", // Adjust the right margin between buttons
  };

  const classes = useStyles();

  return (
    <div style={modalStyle}>
      <div style={modalContentStyle}  className="modelContentarea">
        <h2 className="heading_modalview">
          {title}{" "}
          <Button className="popup-heading" onClick={onCancel}>
            X
          </Button>
        </h2>
        <Divider className="seprater" />
        <Grid container spacing={2} className="popup_with_setview">
        <Grid item xs={12} xl={12} md={12} sm={12}>
          <FormControl fullWidth variant="outlined">
            <div className="title_heading_put">
              <b>Select Enrollment </b>
            </div>
            <select
              className="form-select MuiSelect-select design_select_option"
              htmlFor="search-class-label"
              value={course} 
              onChange={(event) => {
                const selectedValue = event.target.value;
                setCourse(selectedValue);
              }}
            >
              <option value="">Select Course Enrollment</option>
              {courses?.map((item, index) => (
                <option key={index} value={JSON.stringify(item)}>
                  {item.ClassName}
                </option>
              ))}
            </select>
          </FormControl>
        </Grid>
        <Grid item  xs={12} xl={12} md={12} sm={12}>
        <div className="btn_wrapper border_none">
        <Button
            style={buttonStyle}
            className="confirm_action_btn"
            onClick={onCancel}
          >
            Cancel
          </Button> 

          <Button
          style={buttonStyle}
           className="cancle_bnt_show"
            variant="outlined"
            disabled={!course}
            onClick={() => {
              onConfirm(JSON.parse(course));
              setCourse("");
            }}
          >
            Confirm
          </Button>
          
        </div>
        </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ConfirmationModal;
