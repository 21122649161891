import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import AboutUs from './pages/AboutUs';
import AccountSummary from './pages/AccountSummary';
import AddNewQuestion from './pages/AddNewQuestion';
import AddStudent from './pages/addStudent';
import ApplyPattern from './pages/ApplyPattern';
import BuildPattern from './pages/BuildPattern';
import Checkout from './pages/Checkout';
import ContactUs from './pages/ContactUs';
import DashboardApp from './pages/DashboardApp';
import Findpaper from './pages/Findpaper';
import GenerateTest from './pages/GenerateTest';
import HelpVideos from './pages/HelpVideos';

import Login from './pages/Login';
import Deactivate from './pages/Deactivate';
import DeactivateSuccess from './pages/DeactivateSuccess';
import NotFound from './pages/Page404';
import PerformanceReport from './pages/PerformanceReport';
import PlanTest from './pages/PlanTest';
import Pricing from './pages/Pricing';
import PriPolicy from './pages/PrivacyPolicy';
import QuestionDetail from './pages/QuestionDetail';
import Referral from './pages/Referral';
import Refund from './pages/Refund';
import Register from './pages/Register';
import ReviewAnswer from './pages/ReviewAnswer';
import ShippingAndDelivery from './pages/ShippingAndDelivery';
import Support from './pages/Support';
import TermsAndConditions from './pages/TermsAndConditions';
import TestHistory from './pages/TestHistory';
import User from './pages/User';
import UserApprove from './pages/UserApprove';
import ViewQuestionTest from './pages/ViewQuestionTest';
import Success from './pages/Success';
import SuccessInstitute from './pages/SuccessInstitute';
import StudentPromote from './pages/studentPromote'
import RegisterInstitute from './pages/RegisterInstitute';
import MyInstitute from './pages/MyInstitute';
import OnlineTest from './pages/OnlineTest';
import Messages from './pages/MessageShow';

// Institute Pages

import Profile from './pages/institute/Profile';
import Student from './pages/institute/Student';
import AddStudentIns from './pages/institute/AddStudent';
import AddStudentInstitute from './pages/institute/AddStudentInstitute';
import UploadData from './pages/institute/UploadData';
import UploadYoutube from './pages/institute/UploadYoutube';
import ViewReport from './pages/institute/ViewReport';
import ViewReportClass from './pages/institute/ViewReportClass';
import ViewEditQuestionTest from './pages/institute/ViewEditQuestionTest';
import UserList from './pages/institute/UserList';


// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout isInstitute = {1} />,
      children: [
        { path: '', element: <Navigate to="/login" replace /> },
        { path: 'app', element: <DashboardApp /> },
        /* { path: 'buildPattern', element: <BuildPattern /> }, */
        { path: 'user', element: <User /> },
        { path: 'plan-test', element: <PlanTest /> },
        { path: 'build-pattern', element: <BuildPattern /> },
        { path: 'review-answer', element: <ReviewAnswer /> },
        { path: 'view-question', element: <ViewQuestionTest /> },
        { path: 'question-detail', element: <QuestionDetail /> },
        { path: 'apply-pattern', element: <ApplyPattern /> },
        /* { path: 'privacy-policy', element: <PrivacyPolicy /> }, */
        { path: 'add-new-question', element: <AddNewQuestion /> },
        { path: 'test-history', element: <TestHistory /> },

        { path: 'reviewAnswers', element: <Findpaper /> },
        { path: 'performancereport', element: <PerformanceReport /> },
        { path: 'generateTest', element: <GenerateTest /> },
        { path: 'checkout', element: <Checkout/> },
        { path: 'account-summary', element: <AccountSummary /> },
        { path: 'support', element: <Support /> },   
        { path: 'userapprove', element: <UserApprove /> },   
        { path: 'helpvideos', element: <HelpVideos /> },   
            
        { path: 'addStudent', element: <AddStudent /> },        
        { path: 'student-promote', element: <StudentPromote /> },        
        { path: 'referral', element: <Referral /> },        
        { path: 'my-institute', element: <MyInstitute /> },        
        { path: 'online-test', element: <OnlineTest /> },        
        { path: 'Messages', element: <Messages /> },        
         /* { path: 'test-formik', element: <FormikTestForm /> } */
      ]
    },
    {
    path: '/institute',
      element: <DashboardLayout isInstitute={2} />,
      children: [
        { path: '', element: <Navigate to="/login" replace /> },
        { path: 'app', element: <Profile /> },
        { path: 'student', element: <Student /> },
        { path: 'add-student', element: <AddStudentIns /> },
        { path: 'add-student-institute', element: <AddStudentInstitute /> },
        { path: 'test-history', element: <TestHistory /> },
        { path: 'view-question', element: <ViewQuestionTest /> },
        { path: 'view-edit-question', element: <ViewEditQuestionTest /> },
        { path: 'reviewAnswers', element: <Findpaper /> },
        { path: 'review-answer', element: <ReviewAnswer /> },
        { path: 'performanceReport', element: <PerformanceReport /> },
        { path: 'plan-test', element: <PlanTest /> },
        { path: 'upload-data', element: <UploadData /> },
        { path: 'upload-youtube', element: <UploadYoutube /> },
        { path: 'view-report', element: <ViewReport /> },
        { path: 'view-report-class', element: <ViewReportClass /> },
        { path: 'user-list', element: <UserList /> },
        
      ]
    },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: 'login', element: <Login /> },
      //  { path: 'register', element: <Register /> },
        { path: 'register-institute', element: <RegisterInstitute /> },
        { path: 'success', element: <Success /> },
        { path: 'success-institute', element: <SuccessInstitute /> },
        { path: 'privacypolicy', element: <PriPolicy /> },
        { path: 'termsandconditions', element: <TermsAndConditions /> },
        { path: 'contactus', element: <ContactUs /> },
        { path: 'shippinganddelivery', element: <ShippingAndDelivery /> },
        { path: '/deactivate', element: <Deactivate /> },
        { path: '/deactivate-success', element: <DeactivateSuccess /> },
        /* { path: 'helpvideos', element: <HelpVideos /> },  */
        /* { path: 'support', element: <Support /> }, */
        { path: 'pricing', element: <Pricing /> },
        { path: 'aboutus', element: <AboutUs /> },
        { path: 'refund', element: <Refund /> },
        { path: '404', element: <NotFound /> },
        { path: '/', element: <Navigate to="/login" /> },
        { path: '*', element: <Navigate to="/404" /> }
      ]
    },

    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}
