import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

// material
import { LoadingButton } from '@material-ui/lab';
import { makeStyles } from '@material-ui/styles';
import '../../components/css/style.css';
import {
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
  Checkbox,
  Box,
  Tooltip,
  Button,
  Link
} from '@material-ui/core';
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

// components
import Loader from '../../components/Loader';
import Page from '../../components/Page';
import PopupForm from '../../components/popup/PopupForm';
import Iframe from '../Iframe';
import Utility from '../../utils/Utility';
import { historyApi } from "../../service/index";

toast.configure();

const useStyles = makeStyles((theme) => ({
  heightwidth: {
    height: '1000vh',
    margin: 0,
    padding: 0,
    overflow: 'scroll'
  },
  btnsend: {
    display: 'initial',
    justifyContent: 'none',
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'end',
      display: 'flex'
    }
  },
  btnprint: {
    height: '40px',
    marginTop: '7px',
    marginRight: '10px',
    marginLeft: '10px',
    padding: '0 14px',
    float: 'left',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
      height: '40px'
    }
  },
  btnprint2: {
    marginRight: 10,
    height: '40px',
    marginTop: '7px',
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block'
    }
  },
  aligndiv: {
    display: 'block',
    textAlign: 'center',
    [theme.breakpoints.up('sm')]: {
      display: 'inline-flex'
    }
  },
  widthdropdown: {
    width: '163px',
    float: 'left'
  },
  /*   header:{
      display: "block",
    },
    footer:{
      display: "block",
    },
   [`@media print`]:  {
      header, footer: {
        display: 'none',
      }
    } */

  fontsizeheading: {
    fontSize: 16
  },
  paper: {
    padding: theme.spacing(2)
  },
  cardcontent: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 16,
    paddingBottom: '0px !important'
  },
  peraclass2: {
    //  width: "202px",
    //  width:'auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginBottom: '5px'
    //  whiteSpace: "nowrap"
  },
  peraclass: {
    fontSize: 13,
    paddingBottom: 10,
    fontWeight: 600,
    color: '#00007e'
  },
  emailphone: {
    fontSize: 13,
    fontWeight: 500,
    color: '#000000',
    fontFamily: 'Poppins',
    marginTop: -5,
    lineBreak: 'anywhere',
    marginLeft: 10,
    [theme.breakpoints.up('md')]: {
      lineBreak: 'auto'
    }
  },

  headingfont: {
    fontSize: 16,
    fontWeight: 600
  },
  btntext: {
    fontSize: 14,
    fontWeight: 500,
    padding: '0 9px',
    background: '#007c7c',
    color: '#ffffff',
    marginTop: '2px',
    marginBottom: '5px',
    borderRadius: '5px',
    '&:hover': {
      background: '#007c7c'
    }
  },
  btntext1: {
    padding: '0px 5px',
    'border-radius': '5px'
  },
  textcenter: {
    textAlign: 'left',
    lineHeight: 0
  },
  headingfive: {
    display: 'flex',
    marginBottom: 26
  },
  perafive: {
    color: '#929292',
    fontWeight: 500
  },
  cardpadding: {
    paddingLeft: 0,
    paddingBottom: 10
  },
  cardborder: {
    border: '1px solid #c8c8c8',
    '&:hover': {
      backgroundSize: 'cover',
      background: 'linear-gradient(to right, #edfbf4 15%, #d4ffe9 94%)',
      borderColor: '#fff'
    }
  },
  centertext: {
    textAlign: 'center',
    fontSize: 24
  },
  centertextside: {
    textAlign: 'left',
    fontSize: 24
  },
  btnmain: {
    fontWeight: 500,
    marginTop: 18
  },
  closebtn: {
    position: 'absolute',
    right: 0,
    top: -12,
    fontWeight: 700
  },
  textcolor: {
    color: '#007c7c'
  },
  textcolor2: {
    color: '#999',
    fontWeight: 500
  },
  colorunselect: {
    background: '#fff',
    boxShadow: 'none',
    minWidth: '27px',
    width: '7px',
    height: '27px',
    borderRadius: '83px',
    padding: '14px',
    '&:hover': {
      background: 'transparent'
    }
  },
  marginleftright: {
    marginRight: '13px',
    width: '12px',
    height: '27px',
    borderRadius: '110px',
    padding: '0',
    minWidth: '27px',
    background: '#fff',
    boxShadow: 'none',
    '&:hover': {
      background: 'transparent'
    }
  }
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

export default function ViewEditQuestionTest() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [showLoder, setShowLoader] = useState(false);
  const [html, setHtml] = useState('');
  const [userInfo, setUserInfo] = useState(Utility.getLS('userInfo'));
  const [userDetails, setUserDetails] = useState(Utility.getUserDetails());
  const [isPaper, setIsPaper] = useState(false);
  const [reviewPaper, setReviewPaper] = useState({});
  const [fromCreateTest, setFromCreateTest] = useState(false);
  const [isPaperHtml, setIsPaperHtml] = useState(true);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [optionQuestion, setOptionQuestion] = useState([]);
  const [question, setQuestion] = useState("");

  // Handle form submission
  const handleFormSubmit = (values) => {
    console.log("Form Submitted:", values);
    updatepaper(values?.replacementOption,values?.reason);
  };

  useEffect(() => {
    if (state) {
      if (Utility.isInstitute()) {
        fetchData();
      }
    }
  }, []);

  const viewQuestion = () => {
    // const resp={};
    // resp.data.reviewData = state?.reviewData;
    navigate(Utility.getPath("/view-question"), {
      replace: true,
      state,
    });
  };

  const back = () => {
    viewQuestion();
  };

  const fetchData = () => {
    setShowLoader(true);
    const reqInfo = {
      Bo: userDetails?.Bo,
      idParent: userDetails?.idParent,
      idRole: Number(userDetails?.idRole),
      idTestPaper: state?.reviewData?.idQuestionPaperDelivered,
      idStudentSchool: state?.reviewData?.idStudentSchool
    };

    const response = Utility.callPostAPI('editgenpaperhtml', reqInfo);
    response.then((data) => {
        setShowLoader(false);
      if (data?.data) {
        setHtml(data?.data?.data);
        
      }
    });
  };

  window.editQuestion = function (idQuestion, paper) {
    quesoption(idQuestion, paper);
  };

  const quesoption = (idQues, paper) => {
    setShowLoader(true);
    const reqInfo = {
      Bo: userDetails?.Bo,
      idParent: userDetails?.idParent,
      idRole: Number(userDetails?.idRole),
      idTestPaper: paper,
      idQuestion: idQues,
      idStudentSchool: state?.reviewData?.idStudentSchool
    };

    const response = Utility.callPostAPI('quesoption', reqInfo);
    response.then((data) => {
      if (data?.data) {
        setOptionQuestion(data?.data?.data);
        setQuestion(data?.data?.ques[0]);
        setPopupOpen(true);
        setShowLoader(false);
      }
    });
  };

  const updatepaper = (idQues, idReasons) => {
    setShowLoader(true);
    const reqInfo = {
      Bo: userDetails?.Bo,
      idParent: userDetails?.idParent,
      idRole: Number(userDetails?.idRole),
      idTestPaper: state?.reviewData?.idQuestionPaperDelivered,
      idStudentSchool: state?.reviewData?.idStudentSchool,
      idQuestionNew : idQues,
      idQuestionOld : question?.idQuestion,
      idReason: idReasons
    };

    const response = Utility.callPostAPI('updatepaper', reqInfo);
    response.then((data) => {
      if (data?.data) {
        fetchData();
        setPopupOpen(false);
        setShowLoader(false);
      }
    });
  };

  return (
    <Page title="Change Questions | XPSOLV">
      <Grid container className="main-bg">
        {isPaperHtml && (
          <>
            <Grid container>
              <Grid item lg={3} xs={3} mb={2}>
                
                  <div>
                    <LoadingButton
                      variant="contained"
                      onClick={back}
                      className="backbuttonbg"
                    >
                    <ArrowBackIosIcon className="backicon" style={{marginTop:6}} />
                     Back
                    </LoadingButton>
                  </div>
              
              </Grid>
            </Grid>
            <Grid container>{html && <Iframe content={html} id="printf" name="printf" />}</Grid>
          </>
        )}
        <>
        <PopupForm
        open={isPopupOpen}
        onClose={() => setPopupOpen(false)}
        onSubmit={handleFormSubmit}
        optionQuestion={optionQuestion}
        question ={question}
      /></>
        <Loader isLoader={showLoder} showHide={setShowLoader} />
      </Grid>
    </Page>
  );
}
