import { Link as RouterLink } from "react-router-dom";
// material
import {
  Box,
  Card,
  Container,
  Divider,
  Link,
  Typography,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

// layouts
// components
import { MHidden } from "../components/@material-extend";
import Page from "../components/Page";
import HSeparator from "../theme/Separator";

import "../components/css/style.css";
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
  stylebg: {
    background: "#000",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "50vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Success() {
  return (
    <div className='main-bgnew' style={{height:'100%'}}>
    <RootStyle title="Success | XPSOLV">
      <Container maxWidth="sm"  className='bg_white_login'>
        <ContentStyle>
          <div className="regbg">
            <Typography variant="subtitle2" sx={{ textAlign: "center" }} className="top_title_heading">
              Welcome to Xpsolv !
            </Typography>
            

            <Typography variant="subtitle2" sx={{ mt: 3, textAlign: "center" }}>
              Thanks for registration. Your request is under review. you will
              recieve a whatsapp message for next step. Stay tuned !
            </Typography>

            <div>&nbsp;</div>
            <HSeparator sx={{ marginTop: 5, marginBottom: 5 }} />

            <Typography variant="subtitle2" sx={{ textAlign: "center" }}>
              Access app from browser: <br />
              <Link href="https://xpsolv.com">https://xpsolv.com</Link>
            </Typography>
            <Typography variant="subtitle2" sx={{ mt: 3, textAlign: "center" }}>
              Install mobile app from Google Playstore
            </Typography>
            <Typography variant="subtitle2" sx={{ mt: 3, textAlign: "center" }}>
              <a
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                target="_blank"
                rel="noreferrer"
                href="https://play.google.com/store/apps/details?id=com.seltcorp.xpsolv"
              >
                <img src="/static/playstore.png" alt="imgtest" width={200} />
              </a>
            </Typography>
          </div>
        </ContentStyle>
      </Container>
    </RootStyle>
    </div>
  );
}
