import xpSolvService from "../axios/axios";
// eslint-disable-next-line import/order, import/no-unresolved
import Utility from "src/utils/Utility";

class TestService {

    getClasses() {
        return Promise.resolve(xpSolvService.get('ordertest/register/classes'));
    }

    getSchools() {
        return Promise.resolve(xpSolvService.get('ordertest/register/schools'));
    }

    initializeTest(data) {
        return Promise.resolve(xpSolvService.get(`plantest/initializetest/${data}`));
    }

    orderTest(data) {
        data =  Utility.addRoleInBody(data);
        return Promise.resolve(xpSolvService.post('genpaperhtml', data));
    }

    preparingOrderTest(data) {
        return Promise.resolve(xpSolvService.get(`pdf/generatequespaper?id=${data}&format=pdf&fallback=0`));
    }

    downloadTest(data) {
        return Promise.resolve(xpSolvService.get(`pdf/getquespaperpdf/${data}`));
    }

    viewTestQuestion(data) {
        return Promise.resolve(xpSolvService.get(`pdf/generatequespaper?id=${data}&format=html&fallback=0`, {responseType:'text/html'}));
    }

    sendEmailTest(data) {
        return Promise.resolve(xpSolvService.post('sendtest', data));
    }

    reviewAnswer(data) {
        data =  Utility.addRoleInBody(data);
        return Promise.resolve(xpSolvService.post('revques',data));
    }

    updateReviewAnswerStatus(data) {
        data =  Utility.addRoleInBody(data);
        return Promise.resolve(xpSolvService.post('updateanstatus', data));
    }
    
    initializeTestByStudentIdAndClassId(data) {
        data =  Utility.addRoleInBody(data);
        return Promise.resolve(xpSolvService.post('initializetest', data));
    }

    teacherClasses(data) {
        return Promise.resolve(xpSolvService.post(`teacherclasslist`,data));
    }

    publishToStudent(data) {
        return Promise.resolve(xpSolvService.post('gurupub', data));
    }

    acctSummary(data) {
        data =  Utility.addRoleInBody(data);
        return Promise.resolve(xpSolvService.post('accsummary', data));
    }

    getProfileinfo(data){
        return Promise.resolve(xpSolvService.post('getprofileinfo', data));
    }
    
    getplan(data){
        return Promise.resolve(xpSolvService.post('getplan', data));
    }

    getreg(data){
        return Promise.resolve(xpSolvService.post('getreg', data));
    }

    verdict(data){
        return Promise.resolve(xpSolvService.post('verdict', data));
    }

    addstu(data){
        return Promise.resolve(xpSolvService.post('addstu', data));
    }

    stpromote(data){
        return Promise.resolve(xpSolvService.post('stpromote', data));
    }

    refer(data){
        return Promise.resolve(xpSolvService.post('refer', data));
    }

    getref(data){
        return Promise.resolve(xpSolvService.post('getref', data));
    }
    
    getqfeedb(data){
        return Promise.resolve(xpSolvService.post('getqfeedb', data));
    }

    getinsteacher(data){
        return Promise.resolve(xpSolvService.post('getinsteacher', data));
    }

    addreminsteacher(data){
        return Promise.resolve(xpSolvService.post('addreminsteacher', data));
    }
}

export default TestService;