import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import bellIcon from '@iconify/icons-eva/bell-outline';
import { useEffect, useState } from 'react';
import { useLocation,useNavigate } from 'react-router-dom';
// material
import { alpha, styled } from '@material-ui/core/styles';
import { Box, Stack, AppBar, Toolbar, IconButton, Badge } from '@material-ui/core';
// components
import { makeStyles } from '@material-ui/styles';
import { MHidden } from '../../components/@material-extend';
//
import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
import LanguagePopover from './LanguagePopover';
import NotificationsPopover from './NotificationsPopover';

import Utility from "../../utils/Utility";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`
  }
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func
};

export default function DashboardNavbar({ onOpenSidebar }) {
  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      maxWidth: 345,
    },
    emailphone: {
      color: '#646464',
      fontWeight: 500,
    },
    bordertell: {
      borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
      height: '70px !important',
      justifyContent: 'center',
      alignItems: 'right',
      display: 'flex',
      background: 'url(/static/mock-images/avatars/papper.png)',
      backgroundColor: '#fff',
    },
  }));

  const [userInfo, setUserMobile] = useState(Utility.getUserDetails());
  const [notificationInfo, setNotificationInfo] = useState([]);
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();

  const fetchNotifications = async () => {
    try {
      const LSTidStudentSchoolArray = userInfo?.StudentInfo.map(student => student.idStudentSchool);

      const reqInfo = {
        idParent: userInfo?.idParent,
        Bo: userInfo?.Bo,
        LSTidStudentSchool: LSTidStudentSchoolArray.join(','),
      };

      const response = await Utility.callPostAPI('getstmsgins', reqInfo);
      if (response.data?.data) {
        setNotificationInfo(response.data.data);
        Utility.createLS("notificationInfo", response.data.data);
      } else {
        setNotificationInfo([]);
      }
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  useEffect(() => {
    if (!Utility.isInstitute()) {
      fetchNotifications();
    }
  }, [location]);

  const handleBellClick = () => {
    navigate('/dashboard/messages'); // Adjust the route to match your messages page
  };

  return (
    <RootStyle className={classes.bordertell}>
      <ToolbarStyle>
        <MHidden width="lgUp">
          <IconButton onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
            <Icon icon={menu2Fill} />
          </IconButton>
        </MHidden>

        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          <AccountPopover />
          {!Utility.isInstitute() && (
            <IconButton onClick={handleBellClick}>
              <Badge badgeContent={notificationInfo.length} color="error">
                <Icon icon={bellIcon} style={{ width: '24px', height: '24px' }} />
              </Badge>
            </IconButton>
          )}
          <div className={classes.notification2}>
            <Box className={classes.notification}>
              <NotificationsPopover />
            </Box>
          </div>
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
