import { Box, Grid, Paper, Typography, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Page from "../components/Page";


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      maxWidth: 345,
    },
    textcolor: {
      color: '#007c7c',
    },
    textcolor2: {
      color: '#999',
      fontWeight: 500,
    },    
    accountsummary: {
      borderBottom: '1px solid #c9c9c9',
      paddingTop: 18,
      paddingLeft: 0,
      marginBottom: 22,
    }, 
    summaryaccout: {
        borderBottom: '1px solid #c9c9c9',
        padding: 10,
        paddingTop: 0,
        paddingLeft: 0,
        marginBottom: 0,
        width: 'auto',
        fontSize: 16,
        [theme.breakpoints.up('sm')]: {
          width: '978px',
        },
      },
    
  }));

  
export default function Support() {

      const classes = useStyles();

    return (
      <Page title="Support & Help | XPSOLV" className="main-page">
      <Container className="main-Container">
        <div >            
            <Typography variant="h4" gutterBottom className={classes.summaryaccout}>
            <Box
            component="h2"
            display="inline"
            className="tabheading imgicondefine"
          >
            {" "}
            <img src="/static/icons/support-help.png" alt="imgtest" /> Support & Help
            </Box>
            </Typography>          

            <Grid container spacing={2} marginTop={1}>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    
                        <Typography sx={{ mb: 1.5, fontSize: 14 }} color="text.primary">
                        Xpsolv has setup a support channel to receive feedbacks, complaints, and help requests through an email id. All applications users – students & parents – can use this email to write to Xpsolv support team. 
                        </Typography>
                        <Typography sx={{ mb: 2, fontSize: 14 }} color="text.primary">
                            For any feedbacks, complaints, and help requests, send an email to below mention support email id. 
                        </Typography>
                        <Typography sx={{ mb: 2, fontSize: 14 }} color="text.primary">
                            <span>Support Email Id:</span> <span style={{fontWeight:800}}><a href='mailto:connect@xpsolv.com'>connect@xpsolv.com</a></span>
                        </Typography>
                        <Typography sx={{ mb: 1.5, fontSize: 14 }} color="text.primary">
                        On receiving the email, a ticket will be created on support web portal and ticket number will be shared through email response. Xpsolv Support Team will work on the ticket and will resolve it in 2-3 working days. On resolution of ticket, updates will be shared with user through their email id, used for reporting the matter.  
                        </Typography>

                   
                </Grid>            
         
            </Grid>
           
            <div>
                &nbsp;{/* <p style={{ fontSize: 14, }}>&nbsp;</p> */}
            </div>

        </div>
        </Container>
        </Page>
    );
}