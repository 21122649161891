import { Form, FormikProvider, useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
// material
import {
  Alert,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";

import { toast } from "react-toastify";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

// ----------------------------------------------------------------------
import { TestApi } from "../../service/index";
import Utility from "../../utils/Utility";

toast.configure();

export default function Feedback({ feedbackInfo, idQuestions }) {
  const navigate = useNavigate();
  const [successMsg, setSuccessMsg] = useState("");
  const [referStatus, setReferStatus] = useState(false);
  const [characterCount, setCharacterCount] = useState(0);
  const maxCharacterLimit = 200;
  const [userInfo, setUserInfo] = useState(Utility.getLS("userInfo"));

  useEffect(() => {
    setSuccessMsg("");
    formik.resetForm();
    setCharacterCount(0);
  }, [idQuestions]);

  const handleInputChange = (event) => {
    formik.setFieldValue(event.target.name, event.target.value);
    const inputText = event.target.value;
    setCharacterCount(inputText.length);
  };

  const RegisterSchema = Yup.object().shape({
    idFeedbackType: Yup.string().required("Feedback Type required"),
    Feedback: Yup.string()
      .max(200, "Too Long!")
      .required("Additional Remarks  required"),
  });

  const formik = useFormik({
    initialValues: {
      idFeedbackType: "",
      Feedback: "",
    },
    validationSchema: RegisterSchema,
    onSubmit: (data) => {
    //  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

      const dataInfo = {
        idParent: userInfo[0]?.idParent,
        Bo: userInfo[0]?.Bo,
        idQuestion: idQuestions,
        idStudentSchool: feedbackInfo?.idStudentSchool,
        idFeedbackType: data.idFeedbackType,
        Feedback: data.Feedback,
        idTestPaper: feedbackInfo?.idTestPaper,
      };
      getqfeedb(dataInfo);
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  const handleChange = (event) => {
    formik.setFieldValue(event.target.name, event.target.value);
  };

  const getqfeedb = (dataInfo) => {
    TestApi.getqfeedb(dataInfo).then((resp) => {
      if (resp.data.status === 200) {
        setReferStatus(true);
        setSuccessMsg("Feedback successfully sent to support team !");
        setCharacterCount(0);
        formik.resetForm();
      } else {
        toast.warning(resp.data.message);
        // setReferStatus(false);
      }
    });
  };

  return (
    <div>
      {successMsg && (
        <Typography variant="subtitle2" sx={{ mb: 3, textAlign: "left" }}>
          {referStatus ? (
            <Alert color="primary" severity="success">
              {" "}
              {successMsg}
            </Alert>
          ) : (
            <Alert color="secondary" severity="error">
              {" "}
              {successMsg}
            </Alert>
          )}
        </Typography>
      )}
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={3}>
            <Grid item lx={5} xs={12} sm={6} md={5} className="p-0">
              <InputLabel component="legend" className="f-12">
                Feedback Type
              </InputLabel>
              <Select
                className="wt-bg f-12"
                fullWidth
                {...getFieldProps("idFeedbackType")}
                error={Boolean(touched.idFeedbackType && errors.idFeedbackType)}
                helperText={touched.idFeedbackType && errors.idFeedbackType}
              >
                <MenuItem key="sel0" value="">
                  Select
                </MenuItem>
                <MenuItem key="sel1" value="1">
                  Answer is too long
                </MenuItem>
                <MenuItem key="sel2" value="2">
                  Question OR Answer is incorrect
                </MenuItem>
                <MenuItem key="sel3" value="3">
                  Method used to solve is incorrect
                </MenuItem>
                <MenuItem key="sel4" value="4">                  
                  Question topic is out of syllabus
                </MenuItem>
                <MenuItem key="sel5" value="5">
                  Other
                </MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12} lx={12} sm={12} md={12} className="p-0">
              <FormLabel component="legend" className="f-12">
                Additional Remarks
              </FormLabel>
              <TextField
                className="wt-bg f-12 textarea"
                fullWidth
                multiline
                rows={2}
                {...getFieldProps("Feedback")}
                error={Boolean(touched.Feedback && errors.Feedback)}
              //  helperText={touched.Feedback && errors.Feedback}
                onChange={handleInputChange}
                inputProps={{ maxLength: maxCharacterLimit }}
                // You can customize the helper text to display the remaining characters
               
                helperText={`${characterCount}/${maxCharacterLimit} characters remaining`}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={2} className="p-0">
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                className="submit_btn"
              >
                Submit
              </LoadingButton>
            </Grid>
          </Stack>
        </Form>
      </FormikProvider>
    </div>
  );
}
