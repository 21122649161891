import { Form, FormikProvider, useFormik } from "formik";
import fuzzysort from "fuzzysort";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
// material
import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  ListItem,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
  Checkbox,
  FormGroup,
  Box,
} from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";

import { toast } from "react-toastify";

// Import toastify css file
import "react-toastify/dist/ReactToastify.css";

// ----------------------------------------------------------------------
import { AuthApi } from "../../../service/index";

import HSeparator from "../../../theme/Separator";

toast.configure();

export default function PromotionForm({
  studentVerify,
  classStateInfo,
  idParent,
  studentInfo
}) {
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState();
  const [gender, setGender] = useState("");
  const [schoolList, setSchoolList] = useState([]);
  const [filterSchoolList, setFilterSchoolList] = useState([]);
  const [idsParent, setidsParent] = useState(1);
  const [isSchoolChange, setIsSchoolChange] = useState(false);

  useEffect(() => {
    console.log(idParent);
    setidsParent(idParent);
  }, [idParent]);

  const handleCheckboxChange = (event) => {
    setIsSchoolChange(event.target.checked);
  };

  const RegisterSchema = Yup.object().shape({
    state: Yup.string().required("State is required"),
    schoolName: Yup.object().nullable().required("School is required"),
  });

  const PromotionSchema = Yup.object().shape({});

  const formik = useFormik({
    initialValues: {
      state: "",
      schoolName: "",
      idSchool: "",
    },
    validationSchema: isSchoolChange ? RegisterSchema : PromotionSchema,
    onSubmit: (data) => {
      data.isSchoolChange = isSchoolChange;
      studentVerify(data);
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;
  const handleChange = (event) => {
    if (event.target.name === "state") {
      formik.setFieldValue("state", event.target.value);
      formik.setFieldValue("district", "");
      formik.setFieldValue("schoolName", "");
      formik.setFieldValue("idSchool", "");
      getSchoolList(event);
    } else if (event.target.name === "district") {
      formik.setFieldValue("district", event.target.value);
    } else {
      formik.setFieldValue(event.target.name, event.target.value);
    }
  };

  const getSchoolList = (event) => {
    setSchoolList([]);
    const data = {
      idParent: idsParent,
      idState: event.target.value,
      isK12:1
    };
    AuthApi.getschool(data).then((resp) => {
      if (resp.data.status === 200) {
        setSchoolList(sortOptions(resp.data.data, "schooldesc"));
      } else {
        toast.warning("invalid District.");
      }
    });
  };

  const handleFilterOptions = (options, { inputValue }) => {
    const results = fuzzysort.go(inputValue, options, {
      keys: ["id", "schooldesc"],
      threshold: -10000,
      limit: 100,
      all: true,
    });

    return results.map((result) => result.obj);
  };

  const sortOptions = (data, type) => {
    const sortedOptions = data
      ?.slice()
      .sort((a, b) => a[type].localeCompare(b[type]));
    return sortedOptions;
  };

  return (
    <div>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={1}>
            <Grid container spacing={1}>
            <Grid item spacing={1} xs={12} sm={12}>
                <Typography variant="body1" gutterBottom>
                 <b>{studentInfo?.FirstName} {studentInfo?.LastName}</b> 
                  
                </Typography>
              </Grid>
              <Grid item spacing={1} xs={12} sm={12}>
                <Typography variant="body1" className="f-14" gutterBottom>
                  Promoting to{" "}
                  <Box
                    component="span"
                    display="inline"
                    style={{ color: "#007c7c" }}
                  >
                    {" "}
                    NEXT Class !!{" "}
                  </Box>
                </Typography>
              </Grid>
              <Grid item spacing={1} xs={12} sm={12}>
                <FormGroup>
                  <FormControlLabel className="check_font_form"
                    control={
                      <Checkbox
                        checked={isSchoolChange}
                        onChange={handleCheckboxChange}
                      />
                    }
                    label="Is there a school change?"
                  />
                </FormGroup>
              </Grid>
              {isSchoolChange && (
                <>
                  <Grid item xs={12} sm={12} className="p-0">
                    <FormControl fullWidth>
                      <InputLabel id="reg-state">State</InputLabel>
                      <Select
                        className="wt-bg mb-15"
                        
                        id="state"
                        label="State"
                        {...getFieldProps("state")}
                        error={Boolean(touched.state && errors.state)}
                        helperText={touched.state && errors.state}
                        onChange={handleChange}
                      >
                        {sortOptions(
                          classStateInfo[0]?.StateInfo,
                          "State"
                        )?.map((option, index) => (
                          <MenuItem
                            key={index}
                            value={option.idState}
                            className="uppercase"
                          >
                            {option.State}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} className="p-0">
                    <FormControl fullWidth>
                      <Autocomplete
                        id="schoolName"
                        className="uppercase wt-bg mb-15"
                        options={schoolList}
                        filterOptions={handleFilterOptions}
                        {...formik.getFieldProps("schoolName")}
                        value={formik.values?.schoolName}
                        onChange={(event, newValue) => {
                          formik.setFieldValue("idSchool", newValue?.id);
                          formik.setFieldValue("schoolName", newValue);
                        }}
                        getOptionLabel={(option) =>
                          option ? option.schooldesc || "" : ""
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="School Name"
                            variant="outlined"
                            error={
                              formik.touched.schoolName &&
                              Boolean(formik.errors.schoolName)
                            }
                            helperText={
                              formik.touched.schoolName &&
                              formik.errors.schoolName
                            }
                          />
                        )}
                        renderOption={(props, option) => (
                          <ListItem className="uppercase" {...props}>
                            <ListItemText
                              className="uppercase"
                              primary={option.schooldesc}
                            />
                          </ListItem>
                        )}
                      />
                    </FormControl>
                  </Grid>
                </>
              )}
            </Grid>
            <Grid item xs={4} sm={4} className="p-0">
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                variant="contained"
                className="submit_btn"
              >
                Proceed
              </LoadingButton>
            </Grid>
          </Stack>
        </Form>
      </FormikProvider>
    </div>
  );
}
