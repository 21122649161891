import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
// material
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Link,
  Stack,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
// components
import Page from "../components/Page";

import { ReferralForm } from "../components/forms/index";

import { TestApi } from "../service/index";
import HSeparator from "../theme/Separator";

import Utility from "../utils/Utility";

const getIcon = (name) => (
  <Icon color="#007c7c" icon={name} width={22} height={22} />
);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  fontsizeheading: {
    fontSize: 16,
  },
  paper: {
    padding: theme.spacing(2),
  },
  cardcontent: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 16,
    paddingBottom: "0px !important",
  },
  peraclass2: {
    //  width: "202px",
    //  width:'auto',
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginBottom: "5px",
    //  whiteSpace: "nowrap"
  },
  peraclass: {
    fontSize: 13,
    paddingBottom: 10,
    fontWeight: 600,
    color: "#00007e",
  },
  emailphone: {
    fontSize: 13,
    fontWeight: 500,
    color: "#000000",
    fontFamily: "Poppins",
    marginTop: -5,
    lineBreak: "anywhere",
    marginLeft: 10,
    [theme.breakpoints.up("md")]: {
      lineBreak: "auto",
    },
  },
  headingfont: {
    fontSize: 16,
    fontWeight: 600,
  },
  btntext: {
    fontSize: 14,
    fontWeight: 500,
    padding: "0 9px",
    background: "#007c7c",
    color: "#ffffff",
    marginTop: "2px",
    marginBottom: "5px",
    borderRadius: "5px",
    "&:hover": {
      background: "#007c7c",
    },
  },
  btntext1: {
    padding: "0px 5px",
    "border-radius": "5px",
  },
  textcenter: {
    textAlign: "left",
    lineHeight: 0,
  },
  headingfive: {
    display: "flex",
    marginBottom: 26,
  },
  perafive: {
    color: "#929292",
    fontWeight: 500,
  },
  cardpadding: {
    paddingLeft: 0,
    paddingBottom: 10,
  },
  cardborder: {
    border: "1px solid #c8c8c8",
    "&:hover": {
      backgroundSize: "cover",
      background: "linear-gradient(to right, #edfbf4 15%, #d4ffe9 94%)",
      borderColor: "#fff",
    },
  },
  centertext: {
    textAlign: "center",
    fontSize: 24,
  },
  centertextside: {
    textAlign: "left",
    fontSize: 24,
  },
  btnmain: {
    fontWeight: 500,
    marginTop: 18,
  },
  closebtn: {
    position: "absolute",
    right: 0,
    top: -12,
    fontWeight: 700,
  },
  textcolor: {
    color: "#007c7c",
  },
  textcolor2: {
    color: "#999",
    fontWeight: 500,
  },

  plusimg: {
    width: 29,
    marginTop: "-4px",
  },
  textmainid: {
    fontSize: 14,
  },
  textright: {
    textAlign: "right",
  },
  mainbtn: {
    background: "#effff7",
    borderTop: "1px solid #d3ffe9",
  },
  h4heaidng: {
    textAlign: "left",
    marginBottom: "5px",
    fontSize: "15px",
  },
  h4heaidng2: {
    textAlign: "left",
    marginBottom: "5px",
    marginTop: "20px",
    fontSize: "15px",
  },
  colorreport: {
    color: "#007c7c",
  },
  schoolheading: {
    borderBottom: "1px solid #ececec",
    paddingBottom: 15,
    color: "#00007e",
    fontSize: 13,
    fontWeight: "600",
  },
  mtop: {
    marginTop: 33,
    borderTop: "1px solid #ececec",
  },
  imgplan333: {
    width: 15,
    float: "left",
    marginTop: 5,
    marginRight: 11,
    [theme.breakpoints.up("sm")]: {
      width: 15,
    },
  },
  accountsummary: {
    borderBottom: "1px solid #c9c9c9",
    paddingTop: 18,
    paddingLeft: 0,
    marginBottom: 22,
  },
  summaryaccout: {
    borderBottom: "1px solid #c9c9c9",
    padding: 10,
    paddingTop: 0,
    paddingLeft: 0,
    marginBottom: 0,
    width: "auto",
    fontSize: 16,
    [theme.breakpoints.up("sm")]: {
      width: "978px",
    },
  },

  amount: {
    fontSize: 16,
    color: "#000",
    textAlign: "right",
  },
  btnmargin: {
    marginBottom: "21px",
    textAlign: "center",
    paddingTop: "7px !important",
  },
  amount2: {
    fontSize: 14,
    color: "#000",
    fontWeight: 500,
  },
  btnamount: {
    background: "#000",
    marginTop: "-5px",
  },
  colorwhite: {
    color: "#",
    fontWeight: 600,
  },
  margin000: {
    margin: "0 30px",
  },
  bordertable: {
    border: "1px solid #e8e8e8",
    marginBottom: 24,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  colorwhite2: {
    color: "#000",
  },
  colorwhite3: {
    color: "#000",
    fontSize: 16,
  },

  showtoggle: {
    background: "#fff",
    padding: "4px 16px",
    fontWeight: 500,
    color: "#fff",
    border: 0,
    borderRadius: 8,
    float: "right",
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  imgplan2: {
    width: 15,
    [theme.breakpoints.up("sm")]: {
      width: 15,
    },
  },
  addamountbtn: {
    background: "#007c7c",
    padding: "6px",
    fontSize: "14px",
    color: "#fff",
    borderRadius: "6px",
    textDecoration: "none",
  },
  ruppeicon: {
    position: "relative",
    top: "7px",
    width: "17px",
  },
  cardtable: {
    margin: "0 30px",
  },
  cardmain: {
    padding: "13px",
    margin: "10px 0 15px 15px",
    marginBottom: 10,
  },
  cardmain2: {
    margin: "0px 0px",
    display: "block",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

export default function DashboardApp(props) {
  const navigate = useNavigate();
  const classes = useStyles();
  const [userMobile, setUserMobile] = useState("");
  const [userDetails, setUserDetails] = useState([]);
  const [userInfo, setUserInfo] = useState(Utility.getLS("userInfo"));

  //  const [readMore,setReadMore]=useState(false);

  useEffect(() => {
    getProfileInfo();
    getUserDetails();
  }, []);

  const getProfileInfo = () => {
    try {
      const userInfo = Utility.getLS("userInfo");
      const idParent = userInfo[0]?.idParent;
      const reqsBody = {
        mobilenum: userInfo[0].Mobile,
        idParent: Number(idParent),
      };
      TestApi.getProfileinfo(reqsBody).then((resp) => {
        if (resp.status === 200) {
          if (resp.data.status === 200) {
            // localStorage.setItem("token", resp.data.JWTToken);
            Utility.createLS("userInfo", resp?.data?.data);
            setUserInfo(Utility.getLS("userInfo"));
            getUserDetails();
          } else {
            alert(resp.data.message);
          }
        }
      });
    } catch (error) {
      //   setShowLoader(false);
    }
  };

  const getUserDetails = () => {
    setUserDetails(Utility.getLS("userInfo"));
    setUserMobile(userInfo[0]?.Mobile);
  };
  const handelOrderNewTest = (item) => {
    navigate("/dashboard/plan-test", { replace: true, state: item });
  };

  const gotobuyplan = (item) => {
    navigate("/dashboard/account-summary", { replace: true, state: item });
  };

  const studentPromote = (item) => {
    navigate("/dashboard/student-promote", { replace: true, state: item });
  };

  const addStudent = () => {
    navigate("/dashboard/addStudent", { replace: true });
  };

  const funUpdateList = () => { };

  return (
    <Page title="Dashboard | XPSOLV" className="main-page">
      <Container className="main-Container">
        <Typography variant="h4" component="h4" className={classes.h4heaidng}>
          Welcome{" "}
          <Box
            component="span"
            className={classes.colorreport}
            sx={{ display: "inline" }}
          >
            {" "}
            {userDetails[0]?.PFirstName}&nbsp;{userDetails[0]?.PLastName} !
          </Box>
        </Typography>

        <Grid container item spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <Typography
              variant="span"
              component="span"
              className={`f-12 ${classes.schoolheading2}`}
            >
              {" "}
              <img
                src="/static/mock-images/avatars/call.png"
                alt="imgtest"
                className={classes.imgplan333}
              />
              +91 {userDetails[0]?.Mobile}
            </Typography>
          </Grid>
        </Grid>
        <div>&nbsp;</div>
        <HSeparator sx={{ marginTop: 5, marginBottom: 5 }} />
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={0}
        >
          <Typography
            variant="h4"
            gutterBottom
            className={classes.fontsizeheading}
          >

            <Box
              component="h2"
              display="inline"
              className="tabheading imgicondefine"
            >
              {" "}
              <img src="/static/cutoff-range.png" alt="imgtest" /> Student{" "}List
            </Box>
          </Typography>
        </Stack>
        {/* <Stack direction="row" alignItems="center" justifyContent="space-between" mb={0}>
          <Typography variant="h6" gutterBottom className={classes.textmainid}>
          <Box component="span" display="inline" className={classes.textcolor2}>User Profile</Box> @ {userMobile}
          </Typography>
          </Stack>        */}

        <HSeparator />

        <Grid container item spacing={3}>
          {userDetails[0]?.StudentInfo?.map((item, index) => (
            <Grid key={index} item lg={4} md={4} sm={6} xs={12}>
              <Card
                variant="outlined"
                sx={{ boxShadow: 0 }}
                key={index}
                className={`card_unrounded ${classes.cardborder}`}
              >
                <CardActionArea>
                  <Grid container item>
                    <Grid item lg={3} md={3} sm={3} xs={3}>
                      {item?.Gender ? (
                        <CardMedia
                          key={index}
                          image={`/static/mock-images/avatars/${item?.Gender}.png`}
                          style={{ width: "70px", height: "70px", margin: 10 }}
                        />
                      ) : (
                        <CardMedia
                          key={index}
                          image="/static/mock-images/avatars/demo.png"
                          style={{
                            width: "70px",
                            height: "70px",
                            margin: 10,
                            marginLeft: 40,
                          }}
                        />
                      )}
                    </Grid>
                    <Grid item lg={9} md={9} sm={9} xs={9}>
                      <CardContent className={classes.cardcontent}>
                        <Typography variant="h4" className="f-12">
                          {item?.FirstName}&nbsp;{item?.LastName}&nbsp;
                          {item?.Gender ? `(${item?.Gender})` : ""}
                        </Typography>
                        <Tooltip title={item?.SchoolName} placement="top">
                          <p className={`f-10 ${classes.peraclass2}`}>
                            {item?.SchoolName}
                          </p>
                        </Tooltip>
                        <p className={`f-10 ${classes.peraclass}`}>
                          {item?.ClassName ? (
                            <>
                              {item?.ClassName}
                              <sup>
                                {item.ClassNumeric === 1 ? "st" : ""}{" "}
                                {item.ClassNumeric === 2 ? "nd" : ""}{" "}
                                {item.ClassNumeric === 3 ? "rd" : ""}{" "}
                                {item.ClassNumeric > 3 ? "th" : ""}{" "}
                              </sup>
                              {/* {item?.Promote === 1 ? (
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  sx={{ fontWeight: "medium" }}
                                  className={`f-12 ${classes.btntext1}`}
                                  onClick={(e) => {
                                    studentPromote(item);
                                  }}
                                >
                                  Promote Class
                                </Button>
                              ) : null} */}
                            </>
                          ) : (
                            ""
                          )}
                        </p>
                        
                        <Grid container item>
                          <Grid
                            item
                            lg={12}
                            md={6}
                            sm={12}
                            xs={12}
                            className={classes.textcenter}
                          >
                            {userDetails[0]?.Mobile ==="9811943159" &&
                            <>
                            {item?.Access === 1 ? (
                              <Button
                                variant="contained"
                                sx={{ fontWeight: "medium" }}
                                className={`f-12 ${classes.btntext}`}
                                onClick={(e) => {
                                  handelOrderNewTest(item);
                                }}
                              >
                                Create Test
                              </Button>
                            ) : (
                              <Button
                                variant="contained"
                                sx={{ fontWeight: "medium" }}
                                className={`f-12 ${classes.btntext}`}
                                onClick={(e) => {
                                  gotobuyplan(item);
                                }}
                              >
                                Buy Plan
                              </Button>
                            )}{" "}
                            </>
                          }
                          </Grid>
                        </Grid>
                        
                      </CardContent>
                    </Grid>
                  </Grid>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
        <div>&nbsp;</div>
        <HSeparator sx={{ marginTop: 5, marginBottom: 5 }} />
      </Container>
       {/*
      <Container className="main-Container" sx={{ marginTop: 3 }} >
        <Grid container spacing={2}>
          <span className="moreText"> Have more students in family ?</span>
          <Grid item lg={4} md={3} sm={4} xs={12}>
            <Button
              variant="outlined"
              color="primary"
              sx={{ fontWeight: "medium" }}
              onClick={(e) => {
                addStudent();
              }}
            >
              Add Student
            </Button>
          </Grid>

          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className={classes.textcenter}
          >
            <HSeparator sx={{ marginTop: 5, marginBottom: 10 }} />
            <Stack
              direction="column"
              alignItems="left"
              justifyContent="space-between"
              mb={0}
              className="regbg"
            >

              <Box
                component="h2"
                display="inline"
                className="tabheading imgicondefine"
              >
                {" "}
                <img src="/static/cutoff-range.png" alt="imgtest" /> Refer A  Friend
              </Box>


             

              <ReferralForm
                isListUpdate={false}
                funUpdateList={funUpdateList}
              />

              <Typography
                variant="caption"
                gutterBottom
                className="f-12"
                sx={{ marginTop: 1 }}
              >
                Registration link will be sent to entered Mob No. on Whatsapp.
              </Typography>
             
              
            </Stack>
          </Grid>

          <Grid
            item
            lg={12}
            md={12}
            sm={12}
            xs={12}
            className={classes.textcenter}
          >
            <HSeparator sx={{ marginTop: 10, marginBottom: 5 }} />
            <Button
              variant="outlined"
              color="primary"
              sx={{ fontWeight: "medium" }}
            >
              <Link
                component={RouterLink}
                className="usexpsol"
                to="/dashboard/helpvideos"
              >
                How to use Xpsolv ?
              </Link>
            </Button>
          </Grid>
        </Grid>
      </Container>
      */}
    </Page>
  );
}
