import { Form, FormikProvider, useFormik } from "formik";
import { useEffect, useState } from "react";
import fuzzysort from "fuzzysort";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
// material
import {
  Stack,
  TextField,
  Grid,
  Typography,
  Button,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
  RadioGroup,
  Box,
  InputLabel,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  Divider,
  Autocomplete,
} from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import { toast } from "react-toastify";
import {
  LoadCanvasTemplate,
  loadCaptchaEnginge,
  validateCaptcha,
} from "react-simple-captcha";
// Import toastify css file

import "react-toastify/dist/ReactToastify.css";
import { makeStyles } from "@material-ui/styles";

// ----------------------------------------------------------------------
import { AuthApi } from "../../../service/index";

toast.configure();
export default function RegInstituteForm({ instituteVerify }) {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  // const [showOTP, setShowOTP] = useState('');
  const [showLoder, setShowLoader] = useState(false);
  const [showValidation, setshowValidation] = useState(false);
  const [showValidationText, setShowValidationText] = useState("");
  const [showPassValidation, setshowPassValidation] = useState(false);
  const [showPassValidationText, setShowPassValidationText] = useState("");
  const [showCaptchaValidation, setShowCaptchaValidation] = useState(false);
  const [showCaptchValidationText, setShowCaptchValidationText] = useState("");
  const [count, setCount] = useState(60);
  const [timer, setTimer] = useState(60);
  const [intervalId, setIntervalId] = useState(0);
  const [locationType, setLocationType] = useState("pincode");
  const [instituteType, setInstituteType] = useState("institute");
  const [pincode, setPincode] = useState("");
  const [pincodeInfo, setPincodeInfo] = useState([]);
  const [pincodeList, setPincodeList] = useState([]);
  //  const [classStateInfo, setClassStateInfo] = useState([]);
  useEffect(() => {
    loadCaptchaEnginge(4, "#f2f5fc", "black", "numbers");
  }, []);
  const handleLocationType = (event) => {
    if (event.target.name === "pincode") {
      formik.setFieldValue("pincode", event.target.value);
      formik.setFieldValue("pincodeData", "");
      formik.setFieldValue("postoffice", "");
      setPincodeInfo([]);
      setPincode(event.target.value);
    } else if (event.target.name === "postoffice") {
      formik.setFieldValue("postoffice", event.target.value);
    } else if (event.target.name === "state") {
      formik.setFieldValue("state", event.target.value);
      formik.setFieldValue("district", {});
      formik.setFieldValue("pincodeData", "");
      formik.setFieldValue("postoffice", "");
      setPincodeList([]);
    } else if (event.target.name === "locationType") {
      formik.setFieldValue("locationType", event.target.value);
      formik.setFieldValue("postoffice", "");
      formik.setFieldValue("pincodeData", "");
      setLocationType(event.target.value);
    } else if (event.target.name === "pincodeData") {
      formik.setFieldValue("pincodeData", event.target.value);
      formik.setFieldValue("postoffice", {});
    } else if (event.target.name === "district") {
      // formik.setFieldValue("postoffice", {});
      // setPincodeList([]);
      // formik.setFieldValue("pincodeData", {});
      formik.setFieldValue("district", event.target.value);
      getLocationByPincode(event);
    } else {
      formik.setFieldValue(event.target.name, event.target.value);
    }
  };

  const OtpSchema = Yup.object().shape({
    otp: Yup.string()
      .min(4, "Too Short!")
      .max(6, "Too Long!")
      .required("Verification code is required"),
    institute: Yup.string().required("Institute name is required"),
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    mobilenum: Yup.string()
      .min(10, "Too Short!")
      .max(10, "Too Long!")
      .required("Mobile number is required"),
  });

  const formik = useFormik({
    initialValues: {
      otp: "",
      instituteType: "institute",
      institute: "",
      firstName: "",
      lastName: "",
      mobilenum: "",
    },
    validationSchema: OtpSchema,
    onSubmit: () => {
      register();
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  const register = () => {
    try {
      const reqBody = {
        mobilenum: formik.values.mobilenum,
        Switch: 2,
        OtpKeyed: formik.values.otp,
      };

      AuthApi.mobileotp(reqBody).then(
        (resp) => {
          if (resp.status === 200) {
            console.log("Register", resp);
            //  formik.resetForm();
            if (resp.data.data) {
              console.log("Register", resp.data.data);
              const data = {
                instituteType: formik.values.instituteType,
                institute: formik.values.institute,
                firstName: formik.values.firstName,
                lastName: formik.values.lastName,
                mobilenum: formik.values.mobilenum,
              };
              instituteVerify(data);
            } else {
              toast.warning(resp.data.message);
            }
          }
        },
        (error) => {
          formik.setSubmitting(false);
          toast.warning("Error in Register");
        }
      );
    } catch (error) {
      console.log("Register Catch", error);
    }
  };

  const doSubmit = () => {
    if (formik.values.mobilenum === "") {
      setshowValidation(true);
      setShowValidationText("Mobile is required");
    }
    if (formik.values.captcha === "") {
      setShowCaptchaValidation(true);
      setShowCaptchValidationText("Captcha is required");
      return false;
    }
    if (validateCaptcha(formik.values.captcha) === true) {
      generateOTP();
      loadCaptchaEnginge(4, "#f2f5fc", "black", "numbers");
      //   document.getElementById('user_captcha_input').value = '';
    } else {
      setShowCaptchaValidation(true);
      setShowCaptchValidationText("Captcha Does Not Match");

      //  document.getElementById('user_captcha_input').value = '';
    }
  };
  const handleClick = () => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(0);
      setCount(59);
    }

    const newIntervalId = setInterval(() => {
      setCount((prevCount) => prevCount - 1);
    }, 1000);
    setIntervalId(newIntervalId);
  };

  const generateOTP = () => {
    setshowValidation(false);
    setShowValidationText("");
    setshowPassValidation(false);
    setShowPassValidationText("");
    setShowCaptchaValidation(false);
    setShowCaptchValidationText("");
    if (formik.values.mobilenum === "") {
      setshowValidation(true);
      setShowValidationText("Mobile is required");
    } else if (formik.values.mobilenum && formik.values.mobilenum.length < 9) {
      setshowValidation(true);
      setShowValidationText("Mobile No. should be atleast 10 digits.");
    } else if (formik.values.mobilenum && formik.values.mobilenum.length > 10) {
      setshowValidation(true);
      setShowValidationText("Mobile No. should be 10 digits.");
    } else {
      const data = {
        mobilenum: formik.values.mobilenum,
        Switch: 1,
        OtpKeyed: formik.values.otp,
      };
      setShowLoader(true);
      AuthApi.mobileotp(data).then((resp) => {
        setShowLoader(false);
        
        console.log("generateOTP", resp);
        if (resp.data) {
          if (resp.data.status === 200) {
            formik.setFieldValue("otp", resp.data.data);
            setCount(59);
            handleClick();
            formik.setFieldValue("captcha", "");
          } else {
            setshowValidation(true);
            setShowValidationText(resp.data.message);
          }
        } else {
          setshowValidation(true);
          setShowValidationText("student not found");
        }
      });
    }
  };

  const getLocationByPincode = (event) => {
    const data = {
      idParent: "1",
      Switch: locationType === "pincode" ? 1 : 2,
      Pincode: locationType === "address" ? 302012 : Number(pincode),
      idDistrict:
        locationType === "address" ? event.target.value.idDistrict : 4,
    };
    AuthApi.getpin(data).then((resp) => {
      if (resp.data.status === 200) {
        if (locationType === "pincode") {
          if (resp.data.data?.[0]?.ValidPin > 0) {
            formik.setFieldValue("postoffice", "");
            setPincodeInfo(resp.data.data?.[0]?.PincodeInfo);
          } else {
            toast.warning("Invalid pincode.");
          }
        } else {
          console.log(resp.data.data);
          setPincodeList(resp.data.data);
        }
      } else {
        toast.warning("nvalid pincode.");
      }
    });
  };

  const handleFilterOptions = (options, { inputValue }) => {
    const results = fuzzysort.go(inputValue, options, {
      keys: ["idPostOffice", "PostOffice"],
      threshold: -10000,
      limit: 100,
      all: true,
    });
    return results.map((result) => result.obj);
  };

  const sortOptions = (data, type) => {
    const sortedOptions = data
      ?.slice()
      .sort((a, b) => a[type].localeCompare(b[type]));
    return sortedOptions;
  };

  const useStyles = makeStyles((theme) => ({
    borderchangetext: {
      "& label.Mui-focused": {
        color: "#007c7c",
      },
      // focused color for input with variant='outlined'
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: "#007c7c",
        },
      },
    },
    textrighte: {
      textAlign: "right",
    },
    bggreen: {
      background: "#007c7c",
    },
    getotpbg: {
      color: "#007c7c",
      padding: "28px !important",
      position: "absolute",
      right: "0px",
      borderRadius: "0px 8px 8px 0px",
      fontWeight: "600",
    },
    footer: {
      position: "relative",
      textAlign: "center",
      textDecoration: "none",
      width: "100%",
      padding: "9px",
      borderRadius: "0",
      borderTop: "1px solid #dbdbdb",
      marginTop: 120,
    },
    copyright: {
      fontSize: 12,
    },
  }));
  const classes = useStyles();
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <FormControl>
            <FormLabel
              id="demo-radio-buttons-group-label"
              sx={{ fontSize: "14px" }}
            >
              Institute Type
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={instituteType}
              name="instituteType"
              value={instituteType}
              {...getFieldProps("instituteType")}
              error={Boolean(touched.instituteType && errors.instituteType)}
              helperText={touched.instituteType && errors.instituteType}
              onChange={handleLocationType}
            >
              <FormControlLabel
                value="2"
                control={<Radio />}
                label="Institute"
              />
              <FormControlLabel
                value="3"
                control={<Radio />}
                label="Tutor"
              />
            </RadioGroup>
          </FormControl>
        </Stack>

        <Stack spacing={3}>
          <TextField
            className={`${"wt-bg"} ${classes.borderchangetext}`}
            fullWidth
            autoComplete="institute"
            type="text"
            label="Institute"
            {...getFieldProps("institute")}
            error={Boolean(touched.institute && errors.institute)}
            helperText={touched.institute && errors.institute}
          />
        </Stack>
        <Typography variant="body1"  sx={{ padding: "10px" }}>
          Primary{" "}
          <Box component="span" display="inline" style={{ color: "#007c7c" }}>
            Contact
          </Box>
        </Typography>
        <Stack spacing={3}>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <TextField
              className="wt-bg"
              fullWidth
              label="First Name"
              {...getFieldProps("firstName")}
              error={Boolean(touched.firstName && errors.firstName)}
              helperText={touched.firstName && errors.firstName}
            />

            <TextField
              fullWidth
              className="wt-bg"
              label="Last Name"
              {...getFieldProps("lastName")}
              error={Boolean(touched.lastName && errors.lastName)}
              helperText={touched.lastName && errors.lastName}
            />
          </Stack>
        </Stack>
        <Typography variant="body1"  sx={{ padding: "10px" }}>
          Validate{" "}
          <Box component="span" display="inline" style={{ color: "#007c7c" }}>
            Mobile
          </Box>
        </Typography>
        <Stack spacing={3}>
          <TextField
            className={`${"wt-bg"} ${classes.borderchangetext}`}
            fullWidth
            autoComplete="username"
            type="text"
            label="Mobile Number"
            {...getFieldProps("mobilenum")}
            error={
              Boolean(touched.mobilenum && errors.mobilenum) || showValidation
            }
            helperText={
              (touched.mobilenum && errors.mobilenum) || showValidationText
            }
          />
          <Grid container>
            <Grid item xs={12} sm={6} md={3}>
              <Typography
                className="chapc_retext"
              >
                <LoadCanvasTemplate
                  reloadColor="#007c7c"
                  reloadText="Refresh"
                />{" "}
              </Typography>
            </Grid>
            <Grid item xs={12} md={9} sm={6} style={{ position: "relative" }}>
              <TextField
                className="wt-bg"
                id="outlined-basic"
                label="Enter Captcha"
                variant="outlined"
                style={{ borderRadius: "8px 0 0 8px", width: "100%" }}
                {...getFieldProps("captcha")}
                error={
                  Boolean(touched.captcha && errors.captcha) ||
                  showCaptchaValidation
                }
                helperText={
                  (touched.captcha && errors.captcha) ||
                  showCaptchValidationText
                }
              />

              {count >= timer && (
                <Button
                  variant="contained"
                  className="otpBtn"
                  onClick={doSubmit}
                >
                  Get Code
                </Button>
              )}
              <>
                {count < 0 && (
                  <Button
                    className={classes.getotpbg}
                    size="large"
                    variant="text"
                    onClick={doSubmit}
                  >
                    Resend Code
                  </Button>
                )}
              </>
              <>
                {count >= 0 && count < timer && (
                  <Button
                    className={classes.getotpbg}
                    size="large"
                    variant="text"
                  >
                    {count} Sec.
                  </Button>
                )}
              </>
              <Typography style={{display:"flex",float:"right"}}> <img src="/static/icons/whatsapp.png" className="whasappicon" alt="whatsapp" /> <span className="display-inline">+91-81309-12201 </span></Typography>
            </Grid>
            
          </Grid>

          <TextField
            className={`${"wt-bg"} ${classes.borderchangetext}`}
            fullWidth
            autoComplete="current-password"
            type={showPassword ? "text" : "text"}
            label="Enter verification code"
            {...getFieldProps("otp")}
            error={Boolean(touched.otp && errors.otp) || showPassValidation}
            helperText={(touched.otp && errors.otp) || showPassValidationText}
          />
        </Stack>

        <Stack
          direction="column"
          sx={{ paddingTop: "10px", marginTop:'20px', paddingBottom: "10px" }}
        >
          <LoadingButton
            fullWidth 
            size="large"
            type="submit"
            variant="contained"
          >
            Continue
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
