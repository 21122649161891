import { Link as RouterLink } from "react-router-dom";
// material
import {
  Box,
  Card,
  Container,
  Divider,
  Link,
  Typography,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

// layouts
// components
import { MHidden } from "../components/@material-extend";
import Page from "../components/Page";
import HSeparator from "../theme/Separator";

import "../components/css/style.css";
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
  stylebg: {
    background: "#000",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "50vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Success() {
  return (
    <div className='main-bgnew' style={{height:'100%'}}>
    <RootStyle title="Success | XPSOLV">
      <Container maxWidth="sm"  className='bg_white_login'>
        <ContentStyle>
          <div className="regbg">
          <Typography variant="subtitle2" sx={{ textAlign: "center" }} className="top_title_heading">
            De-Activate Request!
            </Typography>
            <Typography variant="subtitle2" sx={{ mt: 3, textAlign: "center" }}>
            Thanks for your request. Xpsolv team will connect with you for confirmation and subsequently deactivate the account.
            </Typography>

            
            
          </div>
        </ContentStyle>
      </Container>
    </RootStyle>
    </div>
  );
}
