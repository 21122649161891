import { Form, FormikProvider, useFormik } from "formik";
import { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  LoadCanvasTemplate,
  loadCaptchaEnginge,
  validateCaptcha,
} from "react-simple-captcha";

import { toast } from "react-toastify";
import * as Yup from "yup";

import { makeStyles } from "@material-ui/styles";
import "../../css/style.css";

// material
import {
  Button,
  Checkbox,
  Grid,
  Link,
  Stack,
  TextField,
  Typography
} from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import { AuthApi } from "../../../service/index";
import Utility from "../../../utils/Utility";

// ----------------------------------------------------------------------
toast.configure();

export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  // const [showOTP, setShowOTP] = useState('');
  const [showLoder, setShowLoader] = useState(false);
  const [showValidation, setshowValidation] = useState(false);
  const [showValidationText, setShowValidationText] = useState("");
  const [showPassValidation, setshowPassValidation] = useState(false);
  const [showPassValidationText, setShowPassValidationText] = useState("");
  const [showCaptchaValidation, setShowCaptchaValidation] = useState(false);
  const [showCaptchValidationText, setShowCaptchValidationText] = useState("");
  const [count, setCount] = useState(60);
  const [timer, setTimer] = useState(60);
  const [intervalId, setIntervalId] = useState(0);
  const [termandCondition, setTermAnsCondition] = useState(true);

  useEffect(() => {
    loadCaptchaEnginge(4, "#f2f5fc", "black", "numbers");
  }, []);

  const handleClick = () => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(0);
      setCount(59);
    }

    const newIntervalId = setInterval(() => {
      setCount((prevCount) => prevCount - 1);
    }, 1000);
    setIntervalId(newIntervalId);
  };
  const trimObjData = (data) => {
    if (typeof data === "object" && data !== null) {
      const trimmedData = {};
      // eslint-disable-next-line no-restricted-syntax
      for (const key in data) {
        // eslint-disable-next-line no-prototype-builtins
        if (data.hasOwnProperty(key)) {
          trimmedData[key] = data[key].trim();
        }
      }
      return trimmedData;
    }
    return data;
  };

  const LoginSchema = Yup.object().shape({
    mobilenum: Yup.string().required("Mobile is required"),
    otp: Yup.string().required("Verification code is required"),
  });

  const formik = useFormik({
    initialValues: {
      mobilenum: "",
      otp: "",
      captcha: "",
    },
    validationSchema: LoginSchema,
    onSubmit: (data) => {
      signIn(trimObjData(data));
    },
  });

  const useStyles = makeStyles((theme) => ({
    borderchangetext: {
      "& label.Mui-focused": {
        color: "#007c7c",
      },
      // focused color for input with variant='outlined'
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: "#007c7c",
        },
      },
    },
    textrighte: {
      textAlign: "right",
    },
    bggreen: {
      background: "#007c7c",
    },
    getotpbg: {
      color: "#007c7c",
      padding: "28px !important",
      position: "absolute",
      right: "0px",
      borderRadius: "0px 8px 8px 0px",
      fontWeight: "600",
    },
    footer: {
      position: "relative",
      textAlign: "center",
      textDecoration: "none",
      width: "100%",
      paddingTop: "10px!important",
      borderRadius: "0",
      borderTop: "1px solid #dbdbdb",
      marginTop: 10,
    },
    copyright: {
      fontSize: 12,
    },
  }));
  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    getFieldProps,
  } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const generateOTP = () => {
    setshowValidation(false);
    setShowValidationText("");
    setshowPassValidation(false);
    setShowPassValidationText("");
    setShowCaptchaValidation(false);
    setShowCaptchValidationText("");
    if (formik.values.mobilenum === "") {
      setshowValidation(true);
      setShowValidationText("Mobile is required");
    } else if (formik.values.mobilenum && formik.values.mobilenum.length < 9) {
      setshowValidation(true);
      setShowValidationText("Mobile No. should be atleast 10 digits.");
    } else {
      const data = {
        mobilenum: formik.values.mobilenum.trim(),
      };
      setShowLoader(true);
      AuthApi.generateOTP(data).then((resp) => {
        setShowLoader(false);
        if (resp.data) {
          if (resp.data.status === 200) {
            formik.setFieldValue("otp", resp.data.data);
            setCount(59);
            handleClick();
            formik.setFieldValue("captcha", "");
          } else {
            setshowValidation(true);
            setShowValidationText(resp.data.message);
          }
        } else {
          setshowValidation(true);
          setShowValidationText("student not found");
        }
      });
    }
  };

  const doSubmit = () => {
    if (formik.values.mobilenum === "") {
      setshowValidation(true);
      setShowValidationText("Mobile is required");
    }
    if (formik.values.captcha === "") {
      setShowCaptchaValidation(true);
      setShowCaptchValidationText("Captcha is required");
      return false;
    }
    if (validateCaptcha(formik.values.captcha.trim()) === true) {
      generateOTP();
      loadCaptchaEnginge(4, "#f2f5fc", "black", "numbers");
      //   document.getElementById('user_captcha_input').value = '';
    } else {
      setShowCaptchaValidation(true);
      setShowCaptchValidationText("Captcha Does Not Match");

      //  document.getElementById('user_captcha_input').value = '';
    }
  };

  const signIn = (data) => {
    setShowLoader(true);
    setshowPassValidation(false);
    setShowPassValidationText("");
    try {
      AuthApi.signIn(data).then(
        (resp) => {
          setShowLoader(false);
          if (resp.status === 200) {
            if (resp.data.status === 200) {
              formik.resetForm();

              Utility.createLS("token", resp?.data?.JWTToken);
              Utility.createLS("userInfo", resp?.data?.data);
              const userInfo = resp?.data?.data;
              if (Number(userInfo[0].idRole) > 1) {
                navigate("/institute/app", { replace: true });
              } else {
                navigate("/dashboard/app", { replace: true });
              }
            } else {
              formik.setSubmitting(false);
              // toast.warning('Invalid OTP')
              setshowPassValidation(true);
              setShowPassValidationText(resp.data.message);
            }
          } else {
            formik.setSubmitting(false);
            // toast.warning('Invalid OTP')
            setshowPassValidation(true);
            setShowPassValidationText("Invalid verification code");
          }
        },
        (error) => {
          setShowLoader(false);
          formik.setSubmitting(false);
          toast.warning("Invalid verification code");
        }
      );
    } catch (error) {
      setShowLoader(false);
      console.log("Catch");
    }
  };
  const classes = useStyles();
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <div className="regbg">
          <Stack spacing={3}>
            <TextField
              className={`${classes.borderchangetext} wt-bg`}
              fullWidth
              autoComplete="username"
              type="text"
              label="Mobile Number"
              {...getFieldProps("mobilenum")}
              error={
                Boolean(touched.mobilenum && errors.mobilenum) || showValidation
              }
              helperText={
                (touched.mobilenum && errors.mobilenum) || showValidationText
              }
            />
            <Grid container>
              <Grid item xs={12} sm={6} md={3}>
                <Typography className="chapc_retext">
                  <LoadCanvasTemplate
                    reloadColor="#007c7c"
                    reloadText="Refresh"
                  />{" "}
                </Typography>
              </Grid>
              <Grid item xs={12} md={9} sm={6}  style={{ position: "relative" }}>
                <TextField
                  id="outlined-basic"
                  label="Enter Captcha"
                  variant="outlined"
                  className="wt-bg"
                  style={{ borderRadius: "8px 0 0 8px", width: "100%" }}
                  {...getFieldProps("captcha")}
                  error={
                    Boolean(touched.captcha && errors.captcha) ||
                    showCaptchaValidation
                  }
                  helperText={
                    (touched.captcha && errors.captcha) ||
                    showCaptchValidationText
                  }
                />

                {count >= timer && (
                  <Button
                    variant="contained"
                    className="otpBtn"
                    onClick={doSubmit}
                  >
                    Get Code
                  </Button>
                )}
                <>
                  {count < 0 && (
                    <Button
                      className={classes.getotpbg}
                      size="large"
                      variant="text"
                      onClick={doSubmit}
                    >
                      Resend Code
                    </Button>
                  )}
                </>
                <>
                  {count >= 0 && count < timer && (
                    <Button
                      className={classes.getotpbg}
                      size="large"
                      variant="text"
                    >
                      {count} Sec.
                    </Button>
                  )}
                </>
                <Typography style={{display:"flex",float:"right"}}> <img src="/static/icons/whatsapp.png" className="whasappicon" alt="whatsapp" /> <span className="display-inline">+91-81309-12201 </span></Typography>
              </Grid>
              
            </Grid>

            <TextField
              className={`${classes.borderchangetext} wt-bg`}
              fullWidth
              autoComplete="current-password"
              type={showPassword ? "text" : "text"}
              label="Enter verification code"
              {...getFieldProps("otp")}
              error={Boolean(touched.otp && errors.otp) || showPassValidation}
              helperText={(touched.otp && errors.otp) || showPassValidationText}
            />
          </Stack>
          <Grid container>
            <Typography
              variant="span"
              component="span"
              className="agree_trem_text"
              style={{ fontSize: 11 }}
            >
              <Checkbox
                defaultChecked className="agree_trem_text"
                style={{ width: 13, marginRight: 4 }}
                onChange={(e) => {
                  setTermAnsCondition(e.target.checked);
                }}
              />{" "}
              I agree with
              <Link
                to="/termsandconditions"
                component={RouterLink}
                style={{
                  marginTop: "0",
                  textAlign: "left",
                  fontSize: 11,
                  marginLeft: 4,
                  marginRight: 4,
                }}
              >
                T&C
              </Link>
              and
              <Link
                to="/privacypolicy"
                component={RouterLink}
                style={{
                  marginTop: "0",
                  textAlign: "left",
                  fontSize: 11,
                  marginLeft: 4,
                }}
              >
                Privacy Policy
              </Link>
            </Typography>
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <LoadingButton
              spacing={3}
              size="small"
              type="submit"
              variant="contained"
              fullWidth
              loading={isSubmitting}
              className="login_front_bg box_shadow_none"
              disabled={!termandCondition}
            >
              Login
            </LoadingButton>
          </Grid>
          {/* <Grid container spacing={2} justifyContent="center">
            <Grid
              item
              className="f-14 registertab"
              style={{ marginTop: "10px" }}
            >
              Don't have account?{" "}
              <Link className="f-14" component={RouterLink} to="/register">
                {" "}
                Register{" "}
              </Link>{" "}
              now
            </Grid>
          </Grid> */}
        </div>
       
      </Form>

      {/* <Loader isLoader={showLoder} showHide={setShowLoader} /> */}
    </FormikProvider>
  );
}
