import { Form, FormikProvider, useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
// material
import { Stack, TextField, Grid, Typography, Button } from "@material-ui/core";
import { LoadingButton } from "@material-ui/lab";
import { toast } from "react-toastify";
import {
  LoadCanvasTemplate,
  loadCaptchaEnginge,
  validateCaptcha,
} from "react-simple-captcha";
// Import toastify css file

import "react-toastify/dist/ReactToastify.css";
import { makeStyles } from "@material-ui/styles";

// ----------------------------------------------------------------------
import { AuthApi } from "../../../service/index";

toast.configure();
export default function OtpForm({ mobileVerify }) {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  // const [showOTP, setShowOTP] = useState('');
  const [showLoder, setShowLoader] = useState(false);
  const [showValidation, setshowValidation] = useState(false);
  const [showValidationText, setShowValidationText] = useState("");
  const [showPassValidation, setshowPassValidation] = useState(false);
  const [showPassValidationText, setShowPassValidationText] = useState("");
  const [showCaptchaValidation, setShowCaptchaValidation] = useState(false);
  const [showCaptchValidationText, setShowCaptchValidationText] = useState("");
  const [count, setCount] = useState(60);
  const [timer, setTimer] = useState(60);
  const [intervalId, setIntervalId] = useState(0);

  useEffect(() => {
    loadCaptchaEnginge(4, "white", "black", "numbers");
  }, []);

  const OtpSchema = Yup.object().shape({
    otp: Yup.string()
      .min(4, "Too Short!")
      .max(6, "Too Long!")
      .required("Verification code is required"),
  });

  const formik = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: OtpSchema,
    onSubmit: () => {
      register();
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, getFieldProps } = formik;

  const register = () => {
    try {
      const reqBody = {
        mobilenum: formik.values.mobilenum,
        Switch: 2,
        OtpKeyed: formik.values.otp,
      };

      AuthApi.mobileotp(reqBody).then(
        (resp) => {
          if (resp.status === 200) {
            console.log("Register", resp);
          //  formik.resetForm();
            if (resp.data.data) {
              console.log("Register", resp.data.data);
              mobileVerify(formik.values.mobilenum);
            } else {
              toast.warning(resp.data.message);
            }
          }
        },
        (error) => {
          formik.setSubmitting(false);
          toast.warning("Error in Register");
        }
      );
    } catch (error) {
      console.log("Register Catch", error);
    }
  };

  const doSubmit = () => {
    if (formik.values.mobilenum === "") {
      setshowValidation(true);
      setShowValidationText("Mobile is required");
    }
    if (formik.values.captcha === "") {
      setShowCaptchaValidation(true);
      setShowCaptchValidationText("Captcha is required");
      return false;
    }
    if (validateCaptcha(formik.values.captcha) === true) {
      generateOTP();
      loadCaptchaEnginge(4, "white", "black", "numbers");
      //   document.getElementById('user_captcha_input').value = '';
    } else {
      setShowCaptchaValidation(true);
      setShowCaptchValidationText("Captcha Does Not Match");

      //  document.getElementById('user_captcha_input').value = '';
    }
  };
  const handleClick = () => {
    if (intervalId) {
      clearInterval(intervalId);
      setIntervalId(0);
      setCount(59);
    }

    const newIntervalId = setInterval(() => {
      setCount((prevCount) => prevCount - 1);
    }, 1000);
    setIntervalId(newIntervalId);
  };

  const generateOTP = () => {
    setshowValidation(false);
    setShowValidationText("");
    setshowPassValidation(false);
    setShowPassValidationText("");
    setShowCaptchaValidation(false);
    setShowCaptchValidationText("");
    if (formik.values.mobilenum === "") {
      setshowValidation(true);
      setShowValidationText("Mobile is required");
    } else if (formik.values.mobilenum && formik.values.mobilenum.length < 9) {
      setshowValidation(true);
      setShowValidationText("Mobile No. should be atleast 10 digits.");
    }
    else if (formik.values.mobilenum && formik.values.mobilenum.length > 10) {
      setshowValidation(true);
      setShowValidationText("Mobile No. should be 10 digits.");
    } else {
      const data = {
        mobilenum: formik.values.mobilenum,
        Switch: 1,
        OtpKeyed: formik.values.otp,
      };
      setShowLoader(true);
      AuthApi.mobileotp(data).then((resp) => {
        setShowLoader(false);
        // eslint-disable-next-line no-debugger
        debugger;
        console.log("generateOTP", resp);
        if (resp.data) {
          if (resp.data.status === 200) {
            formik.setFieldValue("otp", resp.data.data);
            setCount(59);
            handleClick();
            formik.setFieldValue("captcha", "");
          } else {
            setshowValidation(true);
            setShowValidationText(resp.data.message);
          }
        } else {
          setshowValidation(true);
          setShowValidationText("student not found");
        }
      });
    }
  };

  const useStyles = makeStyles((theme) => ({
    borderchangetext: {
      "& label.Mui-focused": {
        color: "#007c7c",
      },
      // focused color for input with variant='outlined'
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: "#007c7c",
        },
      },
    },
    textrighte: {
      textAlign: "right",
    },
    bggreen: {
      background: "#007c7c",
    },
    getotpbg: {
      color: "#007c7c",
      padding: "28px !important",
      position: "absolute",
      right: "0px",
      borderRadius: "0px 8px 8px 0px",
      fontWeight: "600",
    },
    footer: {
      position: "relative",
      textAlign: "center",
      textDecoration: "none",
      width: "100%",
      padding: "9px",
      borderRadius: "0",
      borderTop: "1px solid #dbdbdb",
      marginTop: 120,
    },
    copyright: {
      fontSize: 12,
    },
  }));
  const classes = useStyles();
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            className={`${ "wt-bg"} ${classes.borderchangetext}`}
            fullWidth
            autoComplete="username"
            type="text"
            label="Mobile Number"
            {...getFieldProps("mobilenum")}
            error={
              Boolean(touched.mobilenum && errors.mobilenum) || showValidation
            }
            helperText={
              (touched.mobilenum && errors.mobilenum) || showValidationText
            }
          />
          <Grid container>
            <Grid item xs={4} md={3}>
              <Typography
                style={{
                  alignItems: "center",
                  height: 54,
                  fontSize: "20px",
                  textAlign: "center",
                }}
              >
                <LoadCanvasTemplate
                  reloadColor="#007c7c"
                  reloadText="Refresh"
                />{" "}
              </Typography>
            </Grid>
            <Grid item xs={8} md={9} style={{ position: "relative" }}>
              <TextField
              className="wt-bg"
                id="outlined-basic"
                label="Enter Captcha"
                variant="outlined"
                style={{ borderRadius: "8px 0 0 8px", width: "100%" }}
                {...getFieldProps("captcha")}
                error={
                  Boolean(touched.captcha && errors.captcha) ||
                  showCaptchaValidation
                }
                helperText={
                  (touched.captcha && errors.captcha) ||
                  showCaptchValidationText
                }
              />

              {count >= timer && (
                <Button
                  variant="contained"
                
                  className="otpBtn"
                  onClick={doSubmit}
                >
                  Get Code
                </Button>
              )}
              <>
                {count < 0 && (
                  <Button
                    className={classes.getotpbg}
                    size="large"
                    variant="text"
                    onClick={doSubmit}
                  >
                    Resend Code
                  </Button>
                )}
              </>
              <>
                {count >= 0 && count < timer && (
                  <Button
                    className={classes.getotpbg}
                    size="large"
                    variant="text"
                  >
                    {count} Sec.
                  </Button>
                )}
              </>
              <Typography style={{display:"flex",float:"right"}}> <img src="/static/icons/whatsapp.png" className="whasappicon" alt="whatsapp" /> <span className="display-inline">+91-81309-12201 </span></Typography>
            </Grid>
          </Grid>

          <TextField
            className={`${ "wt-bg"} ${classes.borderchangetext}`}
            fullWidth
            autoComplete="current-password"
            type={showPassword ? "text" : "text"}
            label="Enter verification code"
            {...getFieldProps("otp")}
            error={Boolean(touched.otp && errors.otp) || showPassValidation}
            helperText={(touched.otp && errors.otp) || showPassValidationText}
          />
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
          >
            Verify Code
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
