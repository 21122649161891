import { Link as RouterLink, useNavigate } from "react-router-dom";
import {
  Box,
  Card,
  Container,
  Divider,
  Link,
  Typography,
} from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import Utility from "../utils/Utility";
import Page from "./Page";
import { AddStudentInstitute } from "./authentication/register";
import { AuthApi, TestApi } from "../service/index";
import HSeparator from "../theme/Separator";
import "./css/style.css";

export default function Register({
  successFun,
  mobileInfo,
  isSchool,
  classList,
}) {
  const [classStateInfo, setClassStateInfo] = useState([]);
  const [userInfo, setUserInfo] = useState(Utility.getUserDetails());
  const navigate = useNavigate();

  useEffect(() => {
    getclassdist();
  }, []);

  const studentVerify = (data) => {
    register(data);
  };

  const masterInfo = (data) => {
    setClassStateInfo(data);
  };

  const register = (reqBody) => {
    try {
      AuthApi.registerParent(reqBody).then(
        (resp) => {
          if (resp.status === 200) {
            if (resp.data.data) {
              userApproveDecline(reqBody?.Mobilenum);
              successFun();
            } else {
              toast.warning(resp.data.message);
            }
          }
        },
        (error) => {
          toast.warning("Error in Register");
        }
      );
    } catch (error) {
      console.log("Register Catch", error);
    }
  };

  const userApproveDecline = async (Mobilenum) => {
    try {
      const reqsBody = {
        Token: "eyJhbGciOiJIUzI1NiJ9.eyJSb2xlIjoiMSIsIklzc3VlciI6IjEiLCJ",
        idParent: "1",
        RegMobilenum: Mobilenum,
        Verdict: 1,
      };
  
      const response = await fetch("https://xpsolv.com/remote/router/verdict", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ZU5UNHZVZ0I5NGxMYUd0R2tJZ3BQbUhVTEF1NUpYYlB8Q053VS8vQzZ4Qjd0dHJuYTlNUnJGSmE5TlpSMEtqbCtOQW5UZklUY29CY0p0czNwY1FHaHcrNm9la2NoM1VhVys5cHhxVzdVCnVNND0`,
        },
        body: JSON.stringify(reqsBody),
      });
  
      const data = await response.json();
  
      if (response.ok) {
        if (data.status === 200) {
          console.log("Auto Approved");
        } else {
          alert(data.message);
        }
      } else {
        console.error("API Error:", data);
        alert("Something went wrong!");
      }
    } catch (error) {
      console.error("Network Error:", error);
    }
  };

  const getclassdist = () => {
    const data = {
      idParent: userInfo?.idParent,
      idRole: 1,
    };
    AuthApi.getclassdist(data).then((resp) => {
      if (resp.data.status === 200) {
        const filteredClassInfo = resp?.data.data[0]?.ClassInfo.filter(
          (item) => item.isK12 === 1
        );

        resp.data.data[0].ClassInfo = filteredClassInfo;
        setClassStateInfo(resp.data.data);
        masterInfo(resp.data.data);
      } else {
        setClassStateInfo([]);
      }
    });
  };

  return (
    <>
      <Typography variant="body1" gutterBottom sx={{ paddingBottom: "5px" }}>
        Student{" "}
        <Box component="span" display="inline" style={{ color: "#007c7c" }}>
          Details
        </Box>
      </Typography>
      <AddStudentInstitute
        studentVerify={studentVerify}
        classStateInfo={classStateInfo}
        idParent={userInfo?.idParent}
        mobileNo={mobileInfo}
        isSchool={isSchool}
        classList={classList}
      />
    </>
  );
}
