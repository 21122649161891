import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Container,
  Card,
  CardContent,
  Typography,
  Button,
  CardActions,
  useMediaQuery,
  Divider,
  Box,
  Tabs,
  Tab,
  Stack,
  Link
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { toast } from "react-toastify";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Page from "../../components/Page";
import Loader from "../../components/Loader";
import ConfirmationCorses from "../../components/popup/ConfirmationCourse";
import AddStudentByInstitute from "../../components/AddStudentByInstitute";
import Utility from "../../utils/Utility";
import { ReferralFormInstitute } from "../../components/forms/index";
import "react-toastify/dist/ReactToastify.css";
import HSeparator from "../../theme/Separator";

const useStyles = makeStyles({
  tableContainer: {
    maxWidth: "100%",
    overflowX: "auto",
  },

  cardRoot: {
    marginBottom: 10,
  },
  colorreport: {
    color: "#007c7c",
  },
  cardActions: {
    display: "flex",
    justifyContent: "space-between", // Ensures buttons are spaced evenly
    "@media (max-width: 600px)": {
      justifyContent: "flex-start", // Aligns buttons to the left for small screens
    },
  },
  searchInput: {
    marginRight: 10,
    height: 36,
    marginBottom: 20,
  },
});

toast.configure();

export default function AddStudentInstitute() {
  const navigate = useNavigate();
  const classes = useStyles();
  const [isSearch, setSearch] = useState("");
  const [userInfo, setUserInfo] = useState(Utility.getUserDetails());
  const [data, setData] = useState([]);
  const [mobile, setMobile] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState(false);
  const [courses, setCourse] = useState([]);
  const [classInfo, setClassInfo] = useState([]);
  const [currentTab, setCurrentTab] = useState("/institute/add-student-institute");
  const [showLoder, setShowLoader] = useState(false);
  const [isInvite, setIsInviate] = useState(false);

  const tabChange = (event, newValue) => {
    navigate(newValue);
  };
  useEffect(() => {
    getcourse();
  }, []);

  const setInviate = () => {
    setIsInviate(true);
  };

  const successFun = () => {
    setIsInviate(false);
    toast.success("Student Added");
    
  };

  const getcourse = () => {
    setShowLoader(true);
    const reqInfo = {
      idParent: userInfo?.idParent,
    };


    const response = Utility.callPostAPI("getcourse", reqInfo);
    response.then((data) => {
      setShowLoader(false);
      if (data.data?.data) {
        const filteredCourse = data.data?.data.filter(item => item.isClass === 0);
        setCourse(filteredCourse);
      }
      if (data.data?.data) {
        const filteredClass = data.data?.data.filter(item => item.isClass === 1);
        setClassInfo(filteredClass);
      }
    });
  };

  const handleConfirm = (data) => {
    console.log(data);
    setIsModalOpen(false);
    linkstuins(data);
  };

  const handleCancel = () => {
    console.log("Cancelled!");
    setIsModalOpen(false);
  };
  const fetchData = () => {

    setShowLoader(true);
    const reqInfo = {
      mobilenum: mobile,
      idParent: userInfo?.idParent,
    };

    const response = Utility.callPostAPI("getmobstu", reqInfo);
    response.then((data) => {
      setShowLoader(false);
      setData([]);
      setSearch(true);
      if (data.data?.data && Array.isArray(data.data?.data)) {
        setData(data.data?.data);
      }

    });
  };

  const linkstuins = (req) => {
    // eslint-disable-next-line no-debugger
    debugger;
    setShowLoader(true);
    const reqInfo = {
      idParent: userInfo?.idParent,
      Bo: userInfo?.Bo,
      idStudentSchool: selectedStudent.idStudentSchool,
      idStudent: selectedStudent.idStudent,
      FirstName: selectedStudent.FirstName,
      LastName: selectedStudent.LastName,
      Gender: selectedStudent.Gender,
      idSchool: selectedStudent.idSchool,
      idClass: req.idClass,
      BoStu: data[0].Bo,
      mobilenum: data[0].Mobile,
      InstituteName: userInfo.InstituteName,
      ClassName: req.ClassName,
    };

    const response = Utility.callPostAPI("linkstuins", reqInfo);
    response.then((data) => {
      setShowLoader(false);
      if (data.data) {
        console.log(data);
        toast.success(data.data?.message);
      }
    });
  };
  const funUpdateList = () => { };

  const handleAction = (data) => {
    // Handle action based on id
    console.log(data);
    setSelectedStudent(data);
    setIsModalOpen(true);
  };
  const handleSearch = () => {
    setIsInviate(false);
    fetchData();

  };
  const getPrevious = () => {
    navigate(Utility.getPath("/student"), { replace: true });
  };
  // Check if the screen size is small (mobile)
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  return (
    <Page title="Institute - Add Student | XPSOLV">
      <Container className="main-bg">
        <Grid container spacing={2}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant="h4" gutterBottom className={classes.textcneter}>
              <Link
                underline="none"
                variant="contained"
                className="backbuttonbg"
                onClick={getPrevious}
              >
                <ArrowBackIosIcon className="backicon" />
                Back
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Tabs
              value={currentTab}
              onChange={tabChange}
              textColor="primary"
              indicatorColor="primary"
              aria-label="secondary tabs example"
              className=" insttabs"
            >
              <Tab
                value="/institute/add-student"
                className="currenttab page-menu-tab"
                label="Class (6-12)"
              />
              <Tab
                value="/institute/add-student-institute"
                className="currenttab page-menu-tab"
                label="Entrance Exams"
              />
            </Tabs>
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              mb={2}
              className={classes.borderbotmten}
            >
              <Box
                component="h2"
                display="inline"
                className="tabheading imgicondefine"
              >
                <img src="/static/cutoff-range.png" alt="imgtest" />
                Search & Add Students
              </Box>
            </Stack>
          </Grid>
        </Grid>

        <Grid container spacing={2} className="student_bg_folow container"  style={{marginLeft:0}}>
          <Grid item xs={12} xl={4} sm={4}>
            <TextField
              className="input_search"
              label="Search by Mobile"
              variant="outlined"
              value={mobile}
              onChange={(event) => {
                setMobile(event.target.value);
                setSearch("");
                setData([]);
                setIsInviate(false);
                setData([])
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={8} xl={8}>
            <Button
              variant="contained"
              className="submit_btn maring_setvidi"
              color="primary"
              onClick={handleSearch}
              disabled={mobile.length !== 10}
            >
              Search
            </Button>


            <Button
              variant="contained"
              className="submit_btn maring_setvidi"
              color="primary"
              onClick={setInviate}
              disabled={(data.length === 0  || mobile.length !== 10 || !isSearch) }
            >
              Add  Missing
            </Button>

          </Grid>
        </Grid>

        {isSearch && (
          <>
            <>
              {data.length > 0 && (
                <Grid container spacing={2} mt={isInvite ? 5 : 0} className="margin_width">

                  {!isInvite && (
                    <>
                      {isSmallScreen ? (
                        // Render card layout for small screens
                        <Grid mt={5} xs={12}>
                          {data.length > 0 &&
                            data[0]?.StudentInfo.map((row, index) => (
                              <Card
                                key={index}
                                className={` main_stud_card ${classes.cardRoot}`}
                              >
                                <CardContent style={{ padding: "0px" }}>
                                  <Typography variant="h6" className="stuName">
                                    {row.FirstName} {row.LastName}
                                  </Typography>
                                  <Typography>
                                    {" "}
                                    <span className="add_st_icon">
                                      <img src="/static/class-icon.png" alt="fg" />
                                    </span>{" "}
                                    {row.ClassName}
                                  </Typography>
                                  <Typography>
                                    {" "}
                                    <span className="add_st_icon">
                                      <img src="/static/school.png" alt="fg" />
                                    </span>{" "}
                                    {row.SchoolName}
                                  </Typography>
                                  <Typography>
                                    {" "}
                                    <span className="add_st_icon">
                                      <img src="/static/smartphone.png" alt="fg" />
                                    </span>{" "}
                                    +91-{data[0].Mobile}
                                  </Typography>
                                  <Divider
                                    variant="fullWidth"
                                    className="seprater space_top"
                                  />
                                  <CardActions className={` set_div_btnstd ${classes.cardActions}`}>
                                    {courses.map((item) => (
                                      <>
                                        {item.ClassName === row.ClassName && (
                                          <Button
                                            variant="outlined" className="submit_btn style_bnt_div"
                                            color="primary"
                                            onClick={() => handleAction(row)}
                                          >
                                            Add
                                          </Button>
                                        )}
                                      </>
                                    ))}
                                  </CardActions>
                                </CardContent>
                              </Card>
                            ))}
                        </Grid>
                      ) : (
                        // Render table layout for larger screens
                        <>
                          <Grid xs={12} mt={5}>
                            <Card className={classes.card}>
                              <TableContainer
                                component={Paper}
                                className={classes.tableContainer}
                              >
                                <Table>
                                  <TableHead className="tableHeader">
                                    <TableRow>
                                      <TableCell>Name</TableCell>
                                      <TableCell>Class</TableCell>
                                      <TableCell>School</TableCell>
                                      <TableCell>Mobile</TableCell>
                                      <TableCell>Actions</TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {data.length > 0 &&
                                      data[0]?.StudentInfo.map((row, index) => (
                                        <TableRow key={index}>
                                          <TableCell>
                                            {row.FirstName} {row.LastName}
                                          </TableCell>
                                          <TableCell>{row.ClassName}</TableCell>
                                          <TableCell>{row.SchoolName}</TableCell>
                                          <TableCell>+91-{data[0].Mobile}</TableCell>
                                          <TableCell>

                                            <Button
                                              variant="outlined"
                                              color="primary"
                                              onClick={() => handleAction(row)}
                                            >
                                              Add
                                            </Button>

                                          </TableCell>
                                        </TableRow>
                                      ))}
                                    {data.length === 0 && (
                                      <TableRow>
                                        <TableCell colSpan={4}>
                                          <center>
                                            <b>Data Not found</b>
                                          </center>
                                        </TableCell>
                                      </TableRow>
                                    )}
                                  </TableBody>
                                </Table>
                              </TableContainer>
                            </Card>
                          </Grid>
                        </>
                      )}
                    </>
                  )}
                </Grid>
              )}
            </>
            {/* {data.length === 0 && (
              <>
                
                <Grid container spacing={2} className="student_bg_folow margin_width">
                  <Grid item xs={12} xl={12} sm={12} mb={5}>
                    <ReferralFormInstitute
                      isListUpdate={false}
                      funUpdateList={funUpdateList}
                      courses={courses}
                      courseLabel="Class"
                    />
                  </Grid>
                </Grid>
              </>
            )} */}
          </>
        )}
        {(isInvite || (data.length === 0 && isSearch)) && mobile.length === 10 && (
          <Grid
            container
            spacing={2}
            style={{ marginTop: "20px", marginBottom: "20px" }}
            className="student_bg_folow margin_width"
          >
            {data.length === 0 && (
              <Grid item xs={12} >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  className={classes.borderbotmten}
                >
                  <Box
                    component="h2"
                    display="inline"
                    className="tabheading imgicondefine subHeading"
                  >
                    <img src="/static/add-user.png" alt="imgtest" />
                    Can't find the student? Add Student
                  </Box>
                </Stack>


              </Grid>
            )}
            <Grid item xs={12} xl={12} sm={12} mb={5}>

              {mobile ? <AddStudentByInstitute successFun={successFun} mobileInfo={mobile} isSchool={false} classList={classInfo} /> : ''}
            </Grid>
          </Grid>
        )}
      </Container>
      <ConfirmationCorses
        isOpen={isModalOpen}
        title="Confirmation"
        courses={courses}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      />
      <Loader isLoader={showLoder} showHide={setShowLoader} />
    </Page>
  );
}
