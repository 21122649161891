import { Base64 } from "js-base64";
import { Icon } from "@iconify/react";
import { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
// material
import {
  Box,
  Button,
  Radio,
  RadioGroup,
  FormControlLabel,
  Container,
  Grid,
  Link,
  Stack,
  Tooltip,
  Typography,
  Paper,
  CardMedia,
  TextField,
  ButtonGroup,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { toast } from "react-toastify";
// components
import Page from "../components/Page";
import Loader from "../components/Loader";
import MathJaxComponent from "../components/MathJaxComponent";

import HSeparator from "../theme/Separator";

import Utility from "../utils/Utility";
import "react-toastify/dist/ReactToastify.css";

import ConfirmationModal from "../components/popup/ConfirmationModal";

const getIcon = (name) => (
  <Icon color="#007c7c" icon={name} width={22} height={22} />
);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  fontsizeheading: {
    fontSize: 16,
  },
  paper: {
    padding: theme.spacing(2),
  },
  cardcontent: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 16,
    paddingBottom: "0px !important",
  },
  peraclass2: {
    //  width: "202px",
    //  width:'auto',
    overflow: "hidden",
    textOverflow: "ellipsis",
    marginBottom: "5px",
    //  whiteSpace: "nowrap"
  },
  peraclass: {
    fontSize: 13,
    paddingBottom: 10,
    fontWeight: 600,
    color: "#00007e",
  },
  emailphone: {
    fontSize: 13,
    fontWeight: 500,
    color: "#000000",
    fontFamily: "Poppins",
    marginTop: -5,
    lineBreak: "anywhere",
    marginLeft: 10,
    [theme.breakpoints.up("md")]: {
      lineBreak: "auto",
    },
  },
  headingfont: {
    fontSize: 16,
    fontWeight: 600,
  },
  btntext: {
    fontSize: 14,
    fontWeight: 500,
    padding: "0 9px",
    background: "#007c7c",
    color: "#ffffff",
    marginTop: "2px",
    marginBottom: "5px",
    borderRadius: "5px",
    "&:hover": {
      background: "#007c7c",
    },
  },
  btntext1: {
    padding: "0px 5px",
    "border-radius": "5px",
  },
  textcenter: {
    textAlign: "left",
    // lineHeight: 0,
  },
  headingfive: {
    display: "flex",
    marginBottom: 26,
  },
  perafive: {
    color: "#929292",
    fontWeight: 500,
  },
  cardpadding: {
    paddingLeft: 0,
    paddingBottom: 10,
  },
  cardborder: {
    border: "1px solid #c8c8c8",
    "&:hover": {
      backgroundSize: "cover",
      background: "linear-gradient(to right, #edfbf4 15%, #d4ffe9 94%)",
      borderColor: "#fff",
    },
  },
  centertext: {
    textAlign: "center",
    fontSize: 24,
  },
  centertextside: {
    textAlign: "left",
    fontSize: 24,
  },
  btnmain: {
    fontWeight: 500,
    marginTop: 18,
  },
  closebtn: {
    position: "absolute",
    right: 0,
    top: -12,
    fontWeight: 700,
  },
  textcolor: {
    color: "#007c7c",
  },
  textcolor2: {
    color: "#999",
    fontWeight: 500,
  },

  plusimg: {
    width: 29,
    marginTop: "-4px",
  },
  textmainid: {
    fontSize: 14,
  },
  textright: {
    textAlign: "right",
  },
  mainbtn: {
    background: "#effff7",
    borderTop: "1px solid #d3ffe9",
  },
  h4heaidng: {
    textAlign: "left",
    marginBottom: "5px",
    fontSize: "15px",
  },
  h4heaidng2: {
    textAlign: "left",
    marginBottom: "5px",
    marginTop: "20px",
    fontSize: "15px",
  },
  colorreport: {
    color: "#007c7c",
  },
  schoolheading: {
    borderBottom: "1px solid #ececec",
    paddingBottom: 15,
    color: "#00007e",
    fontSize: 13,
    fontWeight: "600",
  },
  mtop: {
    marginTop: 33,
    borderTop: "1px solid #ececec",
  },
  imgplan333: {
    width: 15,
    float: "left",
    marginTop: 5,
    marginRight: 11,
    [theme.breakpoints.up("sm")]: {
      width: 15,
    },
  },
  accountsummary: {
    borderBottom: "1px solid #c9c9c9",
    paddingTop: 18,
    paddingLeft: 0,
    marginBottom: 22,
  },
  summaryaccout: {
    borderBottom: "1px solid #c9c9c9",
    padding: 10,
    paddingTop: 0,
    paddingLeft: 0,
    marginBottom: 0,
    width: "auto",
    fontSize: 16,
    [theme.breakpoints.up("sm")]: {
      width: "978px",
    },
  },

  amount: {
    fontSize: 16,
    color: "#000",
    textAlign: "right",
  },
  btnmargin: {
    marginBottom: "21px",
    textAlign: "center",
    paddingTop: "7px !important",
  },
  amount2: {
    fontSize: 14,
    color: "#000",
    fontWeight: 500,
  },
  btnamount: {
    background: "#000",
    marginTop: "-5px",
  },
  colorwhite: {
    color: "#",
    fontWeight: 600,
  },
  margin000: {
    margin: "0 30px",
  },
  bordertable: {
    border: "1px solid #e8e8e8",
    marginBottom: 24,
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  colorwhite2: {
    color: "#000",
  },
  colorwhite3: {
    color: "#000",
    fontSize: 16,
  },

  showtoggle: {
    background: "#fff",
    padding: "4px 16px",
    fontWeight: 500,
    color: "#fff",
    border: 0,
    borderRadius: 8,
    float: "right",
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  imgplan2: {
    width: 15,
    [theme.breakpoints.up("sm")]: {
      width: 15,
    },
  },
  addamountbtn: {
    background: "#007c7c",
    padding: "6px",
    fontSize: "14px",
    color: "#fff",
    borderRadius: "6px",
    textDecoration: "none",
  },
  ruppeicon: {
    position: "relative",
    top: "7px",
    width: "17px",
  },
  cardtable: {
    margin: "0 30px",
  },
  cardmain: {
    padding: "13px",
    margin: "10px 0 15px 15px",
    marginBottom: 10,
  },
  cardmain2: {
    margin: "0px 0px",
    display: "block",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
}));

toast.configure();

export default function OnlineTest(props) {
  const navigate = useNavigate();
  const classes = useStyles();
  const { state } = useLocation();
  const [userInfo, setUserMobile] = useState(Utility.getUserDetails());
  const [userDetails, setUserDetails] = useState(Utility.getLS("userInfo"));
  const [paperInfo, setPaperInfo] = useState("");
  const [headerInfo, setHeaderInfo] = useState({});
  const [mcqData, setMcqData] = useState({});
  const [selectedStudent, setSelectedStudent] = useState(
    userDetails[0]?.StudentInfo[0]?.idStudentSchool || ""
  );
  const [showLoder, setShowLoader] = useState(false);
  const [time, setTime] = useState(45 * 60);
  const [showQuestion, setShowQuestion] = useState(1);
  const [questionNum, setQuestionNum] = useState("");
  const [skipQuestion, setSkipQuestion] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSubmit, setIsSubmit] = useState(true);
  //  const [readMore,setReadMore]=useState(false);

  useEffect(() => {
    console.log(state);
    console.log(userInfo);
    if (state?.mcqData) {
      setMcqData(state?.mcqData);
      setSelectedStudent(state?.selectedStudent);
    }
  }, []);

  useEffect(() => {
    if (time < 0) {
      if (isSubmit) {
        recmcq();
        toast.warning("time is over");
      }
    }
  }, [time]);

  const startPaper = () => {
    renderMCQ();
  };

  const getTimer = () => {
    if (time > 0) {
      const timer = setInterval(() => {
        setTime((prevTime) => prevTime - 1);
      }, 1000);

      return () => {
        clearInterval(timer);
      };
    }
  };

  const renderMCQ = () => {
    setShowLoader(true);
    const reqInfo = {
      idParent: userInfo?.idParent,
      idInstitute: mcqData?.idInstitute,
      Ins_Bo: mcqData?.Ins_Bo,
      idTestPaper: mcqData?.GUQPID,
      Ins_idStudentSchool: mcqData?.Ins_idStudentSchool,
      idRole: 2,
      Stu_idStudentSchool:selectedStudent?.idStudentSchool,
      Stu_Bo:userInfo?.Bo
    };

    const response = Utility.callPostAPI("rendermcq", reqInfo);
    response.then((data) => {
      setShowLoader(false);
      if (data.data?.data) {
        if (typeof data.data?.data === "string") {
          toast.warning(data.data.data);
          return true;
        }
        data.data?.data[0].Questions.forEach((question) => {
          question.answered = "";
          question.attempted = 0;
        });

        setPaperInfo(data.data?.data[0]);
        setHeaderInfo(data.data?.data[0]?.HeaderInfo[0]?.TotalDuration);

        setTime(Number(data.data?.data[0]?.HeaderInfo[0]?.TotalDuration) * 60);
        
        getTimer();
        console.log(data.data.data[0]);
      } else {
        toast.warning(data.data.message);
      }
    });
  };

  const recmcq = () => {

    const updatedQuestions = paperInfo.Questions.map((question) => {
      const { QARev, QNumber, ...rest } = question;
      return {
        ...rest,
        attempted: question.attempted,
        answered: question.answered,
      };
    });
    setShowLoader(true);
    const reqInfo = {
      idParent: userInfo?.idParent,
      Ins_Bo: mcqData?.Ins_Bo,
      GUQPID: mcqData?.GUQPID,
      Ins_idStudentSchool: mcqData?.Ins_idStudentSchool,
      idInstitute: mcqData?.idInstitute,
      idStudentSchool: selectedStudent?.idStudentSchool,
      Stu_Bo: userInfo?.Bo,
      idPattern: paperInfo?.HeaderInfo[0]?.idPattern,
      DateDeliveredOn: paperInfo?.HeaderInfo[0]?.DateDeliveredOn,
      AnswerStatus: updatedQuestions,
    };

    const response = Utility.callPostAPI("recmcq", reqInfo);
    response.then((data) => {
      setShowLoader(false);
      setIsSubmit(false);
      if (data.data?.data) {
        console.log(data.data?.data);
      } else {
        toast.warning(data.data.message);
      }
      navigate("/dashboard/my-institute", { replace: true });
    });
  };

  const formatTime = (seconds) => {
    const min = Math.floor(seconds / 60);
    const sec = seconds % 60;
    return `${min.toString().padStart(2, "0")}:${sec
      .toString()
      .padStart(2, "0")}`;
  };

  const saveAnswer = (row, value) => {
    if (value) {
      gotoSkip(Number(row?.QNumber));
    }

    const updatedQuestions = paperInfo.Questions.map((question, i) => {
      if (row.QNumber === question.QNumber) {
        return {
          ...question,
          answered: value,
          attempted: value ? 1 : 0,
        };
      }
      return question;
    });

    setPaperInfo({
      ...paperInfo,
      Questions: updatedQuestions,
    });
  };

  const previousQues = () => {
    setShowQuestion(showQuestion - 1);
  };

  const gotoSkip = (quesNo) => {
    if (skipQuestion.includes(quesNo)) {
      setSkipQuestion(skipQuestion.filter((item) => item !== quesNo));
    }
    setShowQuestion(quesNo);
  };

  const nextQues = () => {
    setShowQuestion(showQuestion + 1);
  };

  const SkipQues = () => {
    if (!skipQuestion.includes(showQuestion)) {
      const numberskip = [...skipQuestion, showQuestion].sort((a, b) => a - b);
      setSkipQuestion(numberskip);
    }
    saveAnswer(paperInfo?.Questions[showQuestion - 1], "");
    if (paperInfo?.Questions.length !== showQuestion) {
      setShowQuestion(showQuestion + 1);
    }
  };

  const submitAnswer = () => {
    setIsModalOpen(true);
  };

  const gotoQuestion = (event) => {
    if (
      !Number.isNaN(Number(questionNum)) &&
      questionNum !== "" &&
      questionNum > 0 &&
      paperInfo?.Questions.length >= questionNum
    ) {
      setShowQuestion(Number(questionNum));
    } else {
      toast.warning("Invalid question number");
    }
  };

  const handleChange = (event) => {
    setQuestionNum(event.target.value);
  };

  const handleConfirm = () => {
    console.log("Confirmed!");
    setIsModalOpen(false);
    recmcq();
  };

  const handleCancel = () => {
    console.log("Cancelled!");
    setIsModalOpen(false);
  };

  return (
    <Page title="Online Test | XPSOLV">
      <Container className="main-bg">
        <Grid container spacing={2}>
          <Grid item xs={12} className="padding_setviews-tracks">
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              className={classes.borderbotmten}
            >
              <Box
                component="h2"
                display="inline"
                className="tabheading imgicondefine"
              >
                <img src="/static/cutoff-range.png" alt="imgtest" />
                MCQ Paper Online
              </Box>
            </Stack>
          </Grid>
        </Grid>
        {!paperInfo && (
          <div className="student_bg_folow mb-50">
            <Grid container spacing={2}>
              <Grid item xs={4} sm={3} className="cover-space">
                <Typography variant="subtitle2">Class</Typography>
              </Grid>
              <Grid item xs={8} sm={9}>
                <Typography variant="body2">
                  {selectedStudent.ClassName}
                </Typography>
              </Grid>

              <Grid item xs={4} sm={3} className="cover-space">
                <Typography variant="subtitle2">Subject</Typography>
              </Grid>
              <Grid item xs={8} sm={9} className="cover-space">
                <Typography variant="body2">{mcqData.SubjectName}</Typography>
              </Grid>
              <Grid item xs={12} sm={12} className="cover-space">
                <div className="space_point">&nbsp;</div>
                <HSeparator />
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} className="til_spacepoint">
                <div className="instruction_point" >
                  Instructions -
                </div>
              </Grid>
              <Grid item xs={12} sm={12}>
                <ul className="list_trackviews">
                  <li>All questions are mandatory.</li>
                  <li>Total duration of the test is 45 mins.</li>
                  <li>
                    Each question carry 1.5 marks and there is no negative
                    marking.
                  </li>
                  <li>
                    Among 4 options, only one is correct. Select the correct
                    option.
                  </li>
                </ul>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Button
                  variant="contained"
                  color="primary"
                  className="submit_btn"
                  disabled={paperInfo}
                  size="sm"
                  onClick={startPaper}
                >
                  Start
                </Button>
              </Grid>
            </Grid>
          </div>
        )}
        {paperInfo && (
          <div className="student_bg_folow">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={9}>
                <div className="mok_date">
                  MCQ Test - {mcqData.SubjectName}{" "}
                </div>
              </Grid>
              <Grid item xs={12} sm={3} className="timer-heading">
                <div className="sand_clock">
               
                <img src="/static/clock-watch.png" width={35} height={35} alt="imgtest" />  <div className="time_formet">{formatTime(time)}</div> 
                </div>
              </Grid>

              <Grid item xs={7} sm={6} md={8}>
                <div className="skip_qucations" >
                  Skip Questions:
                </div>

                <div className="skipBtns">
                  {skipQuestion?.map((item, index) => (
                    // eslint-disable-next-line react/jsx-key
                    <Button
                      variant="contained"
                      className="skipBtn"
                      size="small"
                      onClick={() => gotoSkip(item)}
                    >
                      {item}
                    </Button>
                  ))}
                </div>
              </Grid>
              <Grid item xs={5} sm={6} md={4} className="align_putright">
                <TextField
                  type="number"
                  variant="outlined"
                  size="small"
                  className="questionNum search_qucations_number track-padding-set"
                  name="questionNum"
                  value={questionNum}
                  onChange={handleChange}
                />

                <Button
                  variant="contained"
                  color="primary"
                  className="questionNumBtn submit_btn height_update-btn set-absel"
                  onClick={() => gotoQuestion()}
                >
                  Go
                </Button>
              </Grid>

              <Grid lg={12} md={12} sm={12} xs={12} className="testsep">
                <div>&nbsp;</div>
                <HSeparator />
              </Grid>

              <Grid
                item
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className={classes.textcenter}
              >
                {paperInfo?.Questions?.map((item, index) => (
                  <>
                    {showQuestion === Number(item.QNumber) && (
                      <>
                      <MathJaxComponent content={Base64.decode(item.QARev)} />
                        

                        <Grid
                          lg={6}
                          md={6}
                          sm={6}
                          xs={12}
                          className={classes.textcenter}
                        >
                          <HSeparator
                            className="devider"
                            sx={{ marginTop: 5, marginBottom: 5 }}
                          />
                        </Grid>

                        <Typography
                          variant="subtitle2"
                          className="select_correct-optons"
                        >
                          Select Correct Option
                        </Typography>
                        <Grid container spacing={2}>
                          <Grid
                          item
                            lg={6}
                            md={6}
                            sm={8}
                            xs={12}
                            className={classes.textcenter}
                          >
                            <Button
                              className={`saveBtn ${
                                item.answered === "a" ? "active" : ""
                              }`}
                              variant="contained"
                              color="primary"
                              onClick={() => saveAnswer(item, "a")}
                            >
                              A
                            </Button>
                            <Button
                              className={`saveBtn ${
                                item.answered === "b" ? "active" : ""
                              }`}
                              variant="contained"
                              color="primary"
                              onClick={() => saveAnswer(item, "b")}
                            >
                              B
                            </Button>
                            <Button
                              className={`saveBtn ${
                                item.answered === "c" ? "active" : ""
                              }`}
                              variant="contained"
                              color="primary"
                              onClick={() => saveAnswer(item, "c")}
                            >
                              C
                            </Button>
                            <Button
                              className={`saveBtn ${
                                item.answered === "d" ? "active" : ""
                              }`}
                              variant="contained"
                              color="primary"
                              onClick={() => saveAnswer(item, "d")}
                            >
                              D
                            </Button>
                          </Grid>
                     
                        <Grid
                          lg={6}
                          md={6}
                          sm={4}
                          xs={12}
                          item
                          className="skip_btn_new"
                        >
                          <Button
                            className="saveBtns submit_btn mb_resposive"
                            variant="contained"
                            color="primary"
                            style={{ backgroundColor: "orange" }}
                            onClick={() => SkipQues()}
                          >
                            Skip Question
                          </Button>
                        </Grid>
                        </Grid>
                      </>
                    )}
                  </>
                ))}
              </Grid>

              <Grid
                lg={12}
                md={12}
                sm={12}
                xs={12}
                className="testsep "
              >
                <HSeparator marginBottom={0} />
              </Grid>
              <Grid lg={6} md={6} sm={6} xs={12} className="next-button_set">
                <Button
                  className="saveBtns submit_btn mb_resposive outline_put "
                  variant="contained"
                  color="primary"
                  disabled={showQuestion === 1}
                  onClick={() => previousQues()}
                >
                  Previous
                </Button>
                <Button
                  className="saveBtns submit_btn mb_resposive outline_put"
                  variant="contained"
                  color="primary"
                  disabled={
                    paperInfo?.Questions.length === showQuestion ||
                    paperInfo?.Questions[showQuestion - 1]?.attempted === 0
                  }
                  onClick={() => nextQues()}
                >
                  Next
                </Button>
              </Grid>
              <Grid lg={6} md={6} sm={6} xs={12} className="submit_value_btn">
                <Button
                  className="saveBtns submit_btn"
                  variant="contained"
                  color="primary"
                  onClick={() => submitAnswer()}
                >
                  Submit Answers
                </Button>
              </Grid>
            </Grid>
          </div>
        )}
        <ConfirmationModal
          isOpen={isModalOpen}
          title="Confirmation"
          message="Are you sure you want to proceed?"
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      </Container>
      <Loader isLoader={showLoder} showHide={setShowLoader} />
    </Page>
  );
}
